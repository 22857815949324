import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import cross from "../../../../../../assets/svg/Cross.svg"
import { AlphaPicker, HuePicker, SketchPicker } from 'react-color';
import "./ColorModal.css"


const ColoModal = ({ handleSave, value, hexColor }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [finalColor, setFinalColor] = useState();
    const [finalFinalColor, setFinalFinalColor] = useState();
    const [bg, setBg] = useState("#FFFFFF");

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setFinalFinalColor(value);
        hexColor && setBg(hexColor);

        return () => {

        }
    }, []);

    console.log(value);


    const colorArray = finalColor && Object.entries(finalColor);
    const colorString = colorArray?.map(([key, value]) => value).join(', ');
    const finalString = `rgba(${colorString})`;
    console.log(finalString);
    // let g = `rgba(${Object.entries(finalColor)?.map(([key, value]) => value).join(', ')}`
    return (
        <>
            {
                finalFinalColor ?
                    <button className='colorBox' onClick={showModal}><div style={{ backgroundColor: finalFinalColor }}></div>{bg}</button>
                    :
                    <button className='colorOption' onClick={showModal}>
                        <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD COLOR
                    </button>
            }
            <Modal destroyOnClose centered className='colorModal' closeIcon={<img src={cross} alt="Cross" />} title={false} footer={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='inner'>
                    <div className='left'>
                        <h4>Choose a color</h4>
                        <p>Move the circle on the color field. Use the color slider and saturation slider.</p>
                        <HuePicker color={finalColor} onChange={(value) => { setFinalColor(value.rgb); setBg(value.hex); setFinalFinalColor(`rgba(${Object.entries(value.rgb)?.map(([key, value]) => value).join(', ')})`) }} />
                        <AlphaPicker onChange={(value) => { setFinalColor(value.rgb); console.log(value); setFinalFinalColor(`rgba(${Object.entries(value.rgb)?.map(([key, value]) => value).join(', ')})`) }} color={finalColor} />
                        <div className='colorCon'>
                            <span>Hex</span>
                            <div className='coloInner'>
                                <div>{bg}</div>
                                <div>{finalColor?.a * 100}%</div>
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        <SketchPicker color={finalColor} onChange={(value) => { setFinalColor(value.rgb); setBg(value.hex); setFinalFinalColor(`rgba(${Object.entries(value.rgb)?.map(([key, value]) => value).join(', ')})`) }} />
                    </div>
                </div>
                <div className='btnContainer'>
                    <button onClick={() => { handleSave(finalFinalColor, bg); handleCancel() }}>SAVE</button>
                    <button onClick={handleCancel}>CANCEL</button>
                </div>
            </Modal>
        </>
    )
}

export default ColoModal;
