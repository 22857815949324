import React from 'react'
import classes from './CompanyRow.module.scss'

const CompanyRow = ({ children, title }) => {
  return (
    <div className={classes.wrapper}>
      {title && <h3 className={classes.title}>{title}</h3>}
      {children}
    </div>
  )
}

export default CompanyRow
