import React, { useRef, useState } from 'react'
import classes from './InvoiceModal.module.scss'
import CommonModal from '../../../../../../constant/public/CommonModal/CommonModal'
import moment from 'moment'
import { Col, Row } from 'antd'
import { ViewImage } from './ViewImage/ViewImage'
import axios from 'axios'

const InvoiceModal = ({ modalOpened, setModalOpened, modalData, buyer }) => {
  const [sliceIt, setSliceIt] = useState(1);
  const pdfRef = useRef();
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem('token')


  const generatePdf = (id) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/invoice/invoice-download/${id}`, { amount: "" }, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      },
      )
      .then((res) => {
        ;
        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'uplio-invoice.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response)
      })
  }

  return (
    <div className={classes.invoiceModal}>
      <CommonModal
        className={classes.invoiceModal}
        title={`Invoice #${modalData?.invoiceNumber}`}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
      >
        {modalData && (
          <div className={classes.invoiceModalWrapper} ref={pdfRef}>
            <div className={classes.topBar}>
              <p className={classes.subTitle}>Date: {moment(modalData?.issueDate).format("MM/DD/YYYY")}</p>
              <div className={classes.status}>
                <div>Status: </div> <span className={`${modalData?.status === 1 ? classes.status1 : modalData?.status === 2 ? classes.status2 : modalData?.status === 3 && classes.status3}`}>
                  {
                    modalData?.status === 1 ?
                      "Pending Payment"
                      :
                      modalData?.status === 2 ?
                        "Partially Paid"
                        :
                        modalData?.status === 3 &&
                        "Fully Paid"
                  }
                </span>
              </div>
            </div>
            <div className={classes.modalMain}>
              {
                modalData?.products?.slice(0, sliceIt)?.map((product, index) => {
                  return (
                    <div className={classes.tableRow}>
                      <div className={`${classes.infoItem} ${classes.infoItem2}`}>
                        <h3 className={classes.rowTitle}>Custom Product Description</h3>
                        <p><b>{index + 1}.</b> {product?.description}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>QTY</h3>
                        <p>{product.quantity}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Unit Price</h3>
                        <p>${product?.price}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Total Value</h3>
                        <p>${product?.totalPrice}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Image</h3>
                        <ViewImage imagesArray={product?.images} />
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {
              sliceIt === 1 ?
                <div className={classes.seeMore}>
                  <button onClick={() => setSliceIt(20)}>
                    SEE MORE
                  </button>
                </div>
                :
                <div className={classes.seeMore}>
                  <button onClick={() => setSliceIt(1)}>
                    SEE LESS
                  </button>
                </div>
            }
            <div className={classes.modalBottom}>
              <div className={classes.modalBottomInfo}>
                <Row className={classes.infoItem}>
                  <Col xs={12} md={12}>
                    <h3 className={classes.rowTitle}>Payment Term</h3>
                  </Col>
                  <Col xs={12} md={12}>
                    <p>{modalData?.paymentTermsDescription}</p>
                  </Col>
                </Row>
                <Row className={classes.infoItem}>
                  <Col xs={12} md={12}>
                    <h3 className={classes.rowTitle}>Buyer Name</h3>
                  </Col>
                  <Col xs={12} md={12}>
                    {
                      modalData?.buyer?.firstname ?
                        <p>{modalData?.buyer?.firstname} {modalData?.buyer?.lastname}</p>
                        :
                        <p>{modalData?.buyer?.name}</p>
                    }
                  </Col>
                </Row>
                <Row className={classes.infoItem}>
                  <Col xs={24} md={12}>
                    <h3 className={classes.rowTitle}>Shipping Address</h3>
                  </Col>
                  <Col xs={24} md={12}>
                    <p style={{ textAlign: "start" }}>{modalData?.buyer?.shippingAddresses[0]?.address1}</p>
                  </Col>
                </Row>
                <Row className={classes.infoItem}>
                  <Col xs={12} md={12}>
                    <h3 className={classes.rowTitle}>Quote ID</h3>
                  </Col>
                  <Col xs={12} md={12}>
                    <p>#{modalData?.quote?.quoteNumber}</p>
                  </Col>
                </Row>
                <Row className={classes.infoItem} gutter={[16, 16]}>
                  <Col xs={15} md={12}>
                    <h3 className={classes.rowTitle}>Total Products Quantity</h3>
                  </Col>
                  <Col xs={9} md={4}>
                    <p>{modalData?.products?.reduce((a, b) => a + b.quantity, 0)}</p>
                  </Col>
                  <Col xs={24} md={8} className="InvoiceBgCont">
                    <div classNam="footer footer2">
                      <h6>TOTAL </h6>
                      <span>${modalData?.invoiceTotal}</span>
                    </div>
                    <div classNam="footer">
                      <h6>TOTAL DUE </h6>
                      <span>${modalData?.status === 3 ? 0 : modalData?.payments?.filter(p => p?.paymentStatusDescription === "Unpaid")[0]?.amount}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr></hr>
            <div className={classes.downloadBtn}>
              <button className={loading ? classes.focused : null} onClick={() => generatePdf(modalData?._id)}>Download</button>
            </div>
          </div>
        )
        }
      </CommonModal >
    </div>
  )
}

export default InvoiceModal
