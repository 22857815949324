import React from 'react'
import { Link } from 'react-router-dom'
export default function Career() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Career Section</h1>
      <Link to="/">Back to Homepage</Link>
    </div>
  )
}
