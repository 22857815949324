import { Row, Col, Card } from 'antd'
import classes from './ProductCard.module.scss'
import verifiedTag from '../../../../assets/svg/verified_tag.svg'
import tag from '../../../../assets/svg/tag.svg'
import React, { useState, useEffect } from 'react'
import arrow from '../../../../assets/svg/Arrow_Right_Black.svg'
import Carousel, { CarouselItem } from './ProductCarousel/Carosuel'
import { useHistory } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

const ProductCard = (props) => {
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()

  useEffect(() => {
    if (swiper) {
      setActiveIndex(swiper.activeIndex)
    }
  }, [swiper])

  return (
    <div className={classes.productCard}>
      {/* <ProductsCarousel data={props.data.images} /> */}
      {/* <img src={props.data.images[0]} width={379} /> */}
      {/* <Carousel>
        {props.data.images.map((item) => (
          <CarouselItem>
            <img width={379} height={284} src={item} alt="Uplio" />
          </CarouselItem>
        ))}
      </Carousel> */}
      <div className={classes.productSwiper}>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {props.data.images.map((item) => (
            <SwiperSlide>
              <img width={379} height={284} src={item} alt="Uplio" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={classes.supplierDescription}>
        <div className={classes.supplierTitle}>
          <img
            className={classes.supplierLogo}
            width={48}
            height={48}
            src={props.data.companyLogo}
            alt="Uplio"
          />
          <div className={classes.supplierWrapper}>
            <h2>{props.data.companyName}</h2>
            <h4>
              <img src={verifiedTag} alt="Uplio" />
              Verified Supplier
            </h4>
          </div>
          {/* <button>
              <img src={tag} alt="Uplio" />
            </button> */}
        </div>
        <h3>
          Categories: <strong>{props.category}</strong>
        </h3>
        <h3>
          Minimum Order: <strong>{props.data.MOQ} units</strong>
        </h3>
        {/* <h3>
            Location: <strong>Canada</strong>
          </h3> */}
        <div className={classes.number}>
          {activeIndex + 1}/{swiper && swiper.slides.length}
        </div>
        <div className={classes.supplierDescriptionBottom}>
          <div className={classes.swiperNavigation}>
            <button onClick={() => swiper.slidePrev()}>
              <svg
                width="48"
                height="16"
                viewBox="0 0 48 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM48 7L1 7V9L48 9V7Z"
                  fill="#303030"
                />
              </svg>
            </button>
            <button onClick={() => swiper.slideNext()}>
              <svg
                width="48"
                height="16"
                viewBox="0 0 48 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0L0 9Z"
                  fill="#303030"
                />
              </svg>
            </button>
          </div>
          <button
            className={classes.viewMore}
            onClick={() =>
              history.push({ pathname: `/profile/${props.data._id}` })
            }
          >
            VIEW MORE <img src={arrow} alt="uplio" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default ProductCard
