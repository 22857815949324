import { Radio } from 'antd'
import clsx from 'clsx';
import React, { useEffect, useState } from 'react'
import upIndicator from '../../../assets/svg/up_indicator.svg'
import classes from './BrowseProducts.module.scss'
import downArrow from "../../../assets/images/downArrow.svg"
import axios from 'axios';


const MobileFIilterBy = ({ handleUpdate, handleSort, sort }) => {
    const [showFilters, setShowFilters] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [categoryFilterData, setCategoryFilterData] = useState([])
    const [valueFilterData, setValueFilterData] = useState([])
    const [currentCategory, setCurrentCategory] = useState([])
    const [currentValueBasedFilter, setCurrentValueBasedFilter] = useState([])
    const [filterQuantity, setFilterQuantity] = useState("");
    const [selectedCountry, setSelectedCountry] = useState([])
    const [showCategory, setShowCategory] = useState(true);
    const [showValueFilters, setShowValueFilters] = useState(true);
    const [showMinQuantity, setShowMinQuantity] = useState(true);
    const [showLocation, setShowLocation] = useState(true)
    const [locationFilterData, setLocationFileterData] = useState([
        { name: 'USA', slug: 'USA' },
        { name: 'Canada', slug: 'Canada' },
        { name: 'Mexico', slug: 'Mexico' },
        { name: 'Turkey', slug: 'Turkey' },
    ])


    const handleFilterCategory = async (e) => {
        if (currentCategory.includes(e.target.value)) {
            setCurrentCategory(currentCategory.filter((i) => i !== e.target.value));
        } else {
            setCurrentCategory([...currentCategory, e.target.value]);
        }
    };

    const handleFilterValueBased = async (e) => {
        if (currentValueBasedFilter.includes(e.target.value)) {
            setCurrentValueBasedFilter(currentValueBasedFilter.filter((i) => i !== e.target.value));
        } else {
            setCurrentValueBasedFilter([...currentValueBasedFilter, e.target.value]);
        }
    };

    const handleFilterCountry = async (e) => {
        if (selectedCountry.includes(e.target.value)) {
            setSelectedCountry(selectedCountry.filter((i) => i !== e.target.value));
        } else {
            setSelectedCountry([...selectedCountry, e.target.value]);
        }
    };


    const handleFilterQuantity = (e) => {
        setFilterQuantity(e.target.value)
    }

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/categories`)
            .then(async (res) => {
                let newCategoryFilterData = []
                await res.data.categories.map((item) => {
                    newCategoryFilterData.push({
                        name: item.name,
                        slug: item._id,
                        status: true,
                        _id: item._id,
                        checked: true,
                    })
                })
                console.log(newCategoryFilterData)

                setCategoryFilterData(newCategoryFilterData)
            })
            .catch((err) => {
                console.log(err)
            })
        axios
            .get(`${process.env.REACT_APP_API_URL}/specializations?limit=50&page=1`)
            .then(async (res) => {
                setValueFilterData(res.data.results)
            })
            .catch((err) => {
                console.log(err.response)
            })
    }, []);

    const handleUpdation = () => {
        handleUpdate(currentCategory, filterQuantity, selectedCountry, currentValueBasedFilter);
        setShowFilters(false)
    }

    const handleSorting = (val) => {
        handleSort(val);
        setShowSort(false);
    }

    return (
        <div>
            <div className={`${classes.section} ${classes.sidebar} ${classes.mobileSidebar}`}>
                <div>
                    <button onClick={() => { setShowFilters(!showFilters); setShowSort(false) }}>
                        <h4>Filter By:</h4>
                        <img
                            src={downArrow}
                            style={
                                !showFilters
                                    ? { transform: 'none' }
                                    : { transform: 'rotate(180deg)' }
                            }
                            alt="Uplio"
                        />
                    </button>
                    <button onClick={() => { setShowSort(!showSort); setShowFilters(false) }}>
                        <h4>Sort By:</h4>
                        <img
                            src={downArrow}
                            style={
                                !showSort
                                    ? { transform: 'none' }
                                    : { transform: 'rotate(180deg)' }
                            }
                            alt="Uplio"
                        />
                    </button>
                </div>
                {
                    showSort &&
                    <div className={classes.sortSection}>
                        <button className={sort === "createdAt:desc" && classes.boldBtn} onClick={() => handleSorting("createdAt:desc")}>Featured</button>
                        <button className={sort === "price:asc" && classes.boldBtn} onClick={() => handleSorting("price:asc")}>$ Low to High</button>
                        <button className={sort === "price:desc" && classes.boldBtn} onClick={() => handleSorting("price:desc")}>$ High to Low</button>
                    </div>
                }
                {
                    showFilters &&
                    <div style={{ minHeight: "100vh" }}>
                        <div className={classes.inner}>
                            <div className={clsx([`searchTabAside`, classes.filterOption])}>
                                <button onClick={() => setShowCategory(!showCategory)}>
                                    Category
                                    <img
                                        src={upIndicator}
                                        style={
                                            showCategory
                                                ? { transform: 'none' }
                                                : { transform: 'rotate(180deg)' }
                                        }
                                        alt="Uplio"
                                    />
                                </button>
                                <div className="egl-radio-with-check">
                                    <div className="eachRadio">
                                        <Radio
                                            className="radio-input"
                                            type="radio"
                                            checked={currentCategory.length === 0}
                                            onClick={() => setCurrentCategory([])}
                                            id={""}
                                            name={""}
                                            value={[]}
                                        >
                                            All
                                        </Radio>
                                        {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                                    </div>
                                </div>
                                {showCategory ? (
                                    <div className="egl-radio-with-check">
                                        {categoryFilterData.map((item) => {
                                            return (
                                                <div className="eachRadio">
                                                    <Radio
                                                        className="radio-input"
                                                        type="radio"
                                                        checked={currentCategory.includes(item.slug)}
                                                        onClick={handleFilterCategory}
                                                        id={item.name}
                                                        name={item.name}
                                                        value={item.slug}
                                                    >
                                                        {item.name}
                                                    </Radio>
                                                    {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </div>
                            <div className={clsx([`searchTabAside`, classes.filterOption])}>
                                <button onClick={() => setShowMinQuantity(!showMinQuantity)}>
                                    Minimum Quantity
                                    <img
                                        style={
                                            showMinQuantity
                                                ? { transform: 'none' }
                                                : { transform: 'rotate(180deg)' }
                                        }
                                        src={upIndicator}
                                        alt="Uplio"
                                    />
                                </button>
                                {showMinQuantity ? (
                                    <Radio.Group
                                        onChange={handleFilterQuantity}
                                        value={filterQuantity}
                                    >
                                        <div className={classes.qtyEachRadio}>
                                            <Radio value="">All</Radio>
                                            <div >0</div>
                                        </div>
                                        <div className={classes.qtyEachRadio}>
                                            <Radio value={500}>500 units or less</Radio>
                                            <div className={classes.totalProductsCount}>0</div>
                                        </div>
                                        <div className={classes.qtyEachRadio}>
                                            <Radio value={1000}>
                                                1,000 units or less
                                            </Radio>
                                            <div className={classes.totalProductsCount}>0</div>
                                        </div>
                                        <div className={classes.qtyEachRadio}>
                                            <Radio value={10000}>
                                                10,000 units or less
                                            </Radio>
                                            <div className={classes.totalProductsCount}>0</div>
                                        </div>
                                        <div className={classes.qtyEachRadio}>
                                            <Radio value={100000}>
                                                10,000 units or more
                                            </Radio>
                                            <div className={classes.totalProductsCount}>0</div>
                                        </div>
                                    </Radio.Group>
                                ) : null}
                            </div>
                            <div className={clsx([`searchTabAside`, classes.filterOption])}>
                                <button onClick={() => setShowLocation(!showLocation)}>
                                    Location
                                    <img
                                        style={
                                            showLocation
                                                ? { transform: 'none' }
                                                : { transform: 'rotate(180deg)' }
                                        }
                                        src={upIndicator}
                                        alt="Uplio"
                                    />
                                </button>
                                <div className="egl-radio-with-check">
                                    <div className="eachRadio">
                                        <Radio
                                            className="radio-input"
                                            type="radio"
                                            checked={selectedCountry.length === 0}
                                            onClick={() => setSelectedCountry([])}
                                            id={""}
                                            name={""}
                                            value={[]}
                                        >
                                            All
                                        </Radio>
                                        {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                                    </div>
                                </div>
                                {showLocation ? (
                                    <div className="egl-radio-with-check">
                                        {locationFilterData.map((item) => {
                                            return (
                                                <div className="eachRadio">
                                                    <Radio
                                                        className="radio-input"
                                                        type="radio"
                                                        checked={selectedCountry.includes(item.slug)}
                                                        onClick={handleFilterCountry}
                                                        id={item.name}
                                                        name={item.name}
                                                        value={item.slug}
                                                    >
                                                        {item.name}
                                                    </Radio>
                                                    {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                                {/* {showLocation ? (
                <RadioGroup
                  radioTags={locationFilterData}
                  onChange={(evt) => setSelectedCountry(evt.target.value)}
                  value={selectedCountry} />
              ) : null} */}
                            </div>
                            <div className={clsx([`searchTabAside`, classes.filterOption])}>
                                <button onClick={() => setShowValueFilters(!showValueFilters)}>
                                    Value-Based Filters
                                    <img
                                        src={upIndicator}
                                        style={
                                            showValueFilters
                                                ? { transform: 'none' }
                                                : { transform: 'rotate(180deg)' }
                                        }
                                        alt="Uplio"
                                    />
                                </button>
                                <div className="egl-radio-with-check">
                                    <div className="eachRadio">
                                        <Radio
                                            className="radio-input"
                                            type="radio"
                                            checked={currentValueBasedFilter.length === 0}
                                            onClick={() => setCurrentValueBasedFilter([])}
                                            id={""}
                                            name={""}
                                            value={[]}
                                        >
                                            All
                                        </Radio>
                                        {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                                    </div>
                                </div>
                                {showValueFilters ? (
                                    <div className={classes.valueBasedFilterSection}>
                                        <div className="egl-radio-with-check">
                                            {
                                                valueFilterData.map((item) => {
                                                    return (
                                                        <div className="eachRadio">
                                                            <Radio
                                                                className="radio-input"
                                                                type="radio"
                                                                checked={currentValueBasedFilter.includes(item._id)}
                                                                onClick={handleFilterValueBased}
                                                                id={item.name}
                                                                name={item.name}
                                                                value={item._id}
                                                            >
                                                                {item.name}
                                                            </Radio>
                                                            {/* <div className='totalProductsCount'>
                              0
                            </div> */}
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <button className={classes.applyBtn} onClick={handleUpdation}>
                                Apply
                            </button>
                        </div>
                    </div>
                }
            </div>
            <div>
            </div>
        </div>
    )
}

export default MobileFIilterBy
