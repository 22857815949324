import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import classes from './Profile.module.scss'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import Footer from '../../../../constant/public/Footer/Footer'
import { useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import Orders from '../Orders/Orders'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ref } from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import {
  userDataStatus,
  userLoggedIn,
  userLoggedOut,
} from '../../../../redux/actions/user.action'
import SaveModal from '../../../../constant/public/SaveModal/SaveModal'
import ComingSoon from '../../Supplier/ComingSoon/ComingSoon'
import BuyerAside from '../BuyerAside/BuyerAside'
import Leads from '../Leads/Leads'
import BuyerInfo from '../BuyerInfo/BuyerInfo'
import ShippingAndPayment from '../ShippingAndPayment/ShippingAndPayment'
import Loading from '../../../../constant/Loading/Loading'
import MyInquiries from '../MyInquiries/MyInquiries'
import BuyerInvoices from '../Invoices/Invoices'
import InvoicePayment from '../Invoices/InvoiceTable/Payment/Payment'
import PaymentComplete from '../Invoices/InvoiceTable/Payment/PaymentComplete/PaymentComplete'


const BuyerProfile = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const path = useParams();
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(path.profileTab);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useSelector((state) => state.user.user)


  useEffect(() => {
    setCurrentTab(path.profileTab)
  }, [path])

  const Formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      brandName: '',
      category: '',
      categoryName: '',
      annualSales: '',
      websiteUrl: '',
      instagramUrl: '',
      profileImage: '',
      password: '',
      oldPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email.').required('Email is Required'),
      firstName: yup.string().required("First Name is Required"),
      lastName: yup.string().required("Last Name is Required"),
      phone: yup
        .string()
        .matches(
          /^\+\d\s(?!0{2})\d{3}(?!0{2})\d{3}(?!0{3})\d{4}$/,
          'Please enter valid phone number. Format: +X XXXXXXXXXX'
        )
        .max(15, 'Phone can only be upto 15 Characters'),
      websiteUrl: yup
        .string()
        .required('Website URL is required')
        .matches(
          /^(https?:\/\/)(?:(?=[^:@]+:[^:@]*@)([^:@]+):?([^:@]*)@)?((?:(?:(?:[a-z0-9\u00a1-\uffff]-*)*[a-z0-9\u00a1-\uffff]+\.)*[a-z\u00a1-\uffff\d]{2,}\.(?:com|edu|co|org|net|io|gov|mil|biz|info|name|museum|[a-z]{2}))(?:[^\s/?#]+)?(?:\/[^\s]*)?(?:\?[^#]*)?(?:#(.*))?)$/,
          'Please enter valid website url. Format: https://uplio.com'
        )
      ,
      profileImage: yup.object().required('Profile Image is required'),
      annualSales: yup.string().required("Annual Sales is Required"),
      brandName: yup.string().required("Brand Name is required"),
      category: yup.array().required("Category run is required"),
      categoryName: yup.string(),
      password: yup.string().required('Password is required.'),
      oldPassword: yup.string().required('Old Password is required.'),
      confirmPassword: yup
        .string()
        .required('Password is required.')
        .oneOf([ref('password')], 'Passwords do not match'),
    }),
    onSubmit: (values) => {
      // handleProfileUpdated
      alert(JSON.stringify(values, null, 2));
    },

  })

  const { setFieldValue } = Formik;

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const handleProfileUpdated = () => {
    Formik.handleSubmit();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        {
          firstname: Formik.values.firstName,
          lastname: Formik.values.lastName,
          email: Formik.values.email,
          phone: Formik.values.phone,
          brandName: Formik.values.brandName,
          brandCategories: Formik.values.category,
          annualSalesRevenue: Formik.values.annualSales,
          website: Formik.values.websiteUrl,
          instagram: Formik.values.instagramUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(userDataStatus(res.data))
        dispatch(userLoggedIn())
        setLoading(false)
        setEditing(true);
        setIsModalOpen(!isModalOpen)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }


  useEffect(() => {
    if (userData && userData !== null && userData !== undefined) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${userData?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false)
          const data = res.data.user;
          setFieldValue('firstName', data.firstname)
          setFieldValue('lastName', data.lastname)
          setFieldValue('phone', data.phone)
          setFieldValue('email', data.email)
          setFieldValue('brandName', data.brandName)
          setFieldValue('category', data.brandCategories);
          setFieldValue('categoryName', data.brandCategories[0].name);
          setFieldValue('annualSales', data.annualSalesRevenue)
          setFieldValue('websiteUrl', data.website)
          setFieldValue('instagramUrl', data.instagram)
          setFieldValue('profileImage', data.profileImage)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return () => {

    }
  }, []);




  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }



  const handleUpdatePassword = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${user ? user.id : null
        }/password`,
        {
          oldPassword: Formik.values.oldPassword,
          newPassword: Formik.values.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        openNotificationWithIcon("success", "Password changed successfully");
      })
      .catch((err) => {
        console.log(err.response)
        openNotificationWithIcon("error", err.response?.data?.message);
      })
  }


  return (
    <>
      <Navigation />
      <main className={clsx([classes.main, classes.supplier])}>
        <div className="container">
          <div className={classes.dashboardWrapper}>
            <BuyerAside
              currentTab={currentTab}
              handleSignOut={handleSignOut}
            />
            {
              loading ?
                <div>
                  <Loading />
                </div>
                :
                currentTab === 'profile' ? (
                  <BuyerInfo
                    handleProfileUpdated={handleProfileUpdated}
                    handleUpdatePassword={handleUpdatePassword}
                    Formik={Formik}
                  />
                ) : currentTab === 'lead' ? (
                  <Leads />
                ) : currentTab === 'coming-soon' ? (
                  <div className={classes.ComingSoon}>
                    <ComingSoon />
                  </div>
                ) : currentTab === 'orders' ? (
                  <Orders />
                ) :
                  currentTab === 'invoices' ? (
                    <BuyerInvoices />
                  ) :
                    currentTab === 'invoice-payment' ? (
                      <InvoicePayment />
                    ) :
                      currentTab === 'payment-complete' ? (
                        <PaymentComplete />
                      ) :
                        currentTab === "shipping-and-payment" ?
                          <ShippingAndPayment />
                          :
                          currentTab === "inquiries" ?
                            <MyInquiries />
                            :
                            null
              // }
            }
          </div>
        </div>
        {
          <SaveModal openModal={isModalOpen} />
        }
      </main>
      <Footer />
    </>
  )
}

export default BuyerProfile
