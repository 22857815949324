import React from 'react'
import classes from './OrdersModal.module.scss'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import moment from 'moment'

const OrdersModal = ({ modalOpened, setModalOpened, modalData, buyer }) => {
  return (
    <div className={classes.ordersModal}>
      <CommonModal
        className={classes.ordersModal}
        title="Order Info"
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
      >
        {modalData && (
          <div className={classes.ordersModalWrapper}>
            <div className={classes.modalMain}>
              <div className={classes.tableRow}>
                <div className={classes.infoItem}>
                  <h3 className={classes.rowTitle}>Order ID:</h3>
                  <p>{modalData?.shortId}</p>
                </div>
                <div className={classes.infoItem}>
                  <h3 className={classes.rowTitle}>Date:</h3>
                  <p>{moment(modalData.orderDatetime).format('MMM D, YYYY')}</p>
                </div>
                <div className={classes.infoItem}>
                  {
                    buyer ?
                      <>
                        <h3 className={classes.rowTitle}>Buyer:</h3>
                        <p>{modalData?.buyer?.name}</p>
                      </>
                      :
                      <>
                        <h3 className={classes.rowTitle}>Supplier:</h3>
                        <p>{modalData?.supplier?.name}</p>
                      </>
                  }

                </div>
                <div className={classes.infoItem}>
                  <h3 className={classes.rowTitle}>Status:</h3>
                  <div className={classes.statusInput}>{modalData?.statusDescription}</div>
                </div>
                {
                  buyer ?
                    <div className={classes.infoItem}>
                      <h3 className={classes.rowTitle}>Payment Status:</h3>
                      <p>
                        {modalData?.status === 12 ? "Refunded" : "Paid"}
                      </p>
                    </div>
                    :
                    null
                }
              </div>
            </div>
            {
              modalData && modalData?.products?.length > 0 && modalData?.products?.map(product => {
                let img = product?.images?.filter(f => f.cover)[0];
                return (
                  <div className={classes.modalBottom}>
                    <div className={classes.modalBottomInfo}>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Item Name:</h3>
                        <p>{product?.name}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Amount:</h3>
                        <p>${product?.price * product?.quantity}</p>
                      </div>
                      <div className={classes.infoItem} data-item="description">
                        <h3 className={classes.rowTitle}>Item Description:</h3>
                        <p style={{ marginTop: "8px" }}>{product?.description}</p>
                      </div>
                    </div>
                    {
                      img ?
                        <div className={classes.modalBottomImage}>
                          <img width="100%" src={img?.url} alt="Uplio" />
                        </div>
                        :
                        <div className={classes.modalBottomImage}>
                          <img width="100%" src={product?.images[0]?.url} alt="Uplio" />
                        </div>
                    }

                  </div>
                )
              })
            }
            {/* <div className={classes.modalBottom}>
            <div className={classes.modalBottomInfo}>
              <div className={classes.infoItem}>
                <h3 className={classes.rowTitle}>Item Name:</h3>
                <p>{modalData.itemName}</p>
              </div>
              <div className={classes.infoItem}>
                <h3 className={classes.rowTitle}>Amount:</h3>
                <p>{modalData.amount}</p>
              </div>
              <div className={classes.infoItem} data-item="description">
                <h3 className={classes.rowTitle}>Item Description:</h3>
                <p>{modalData.itemDescription}</p>
              </div>
            </div>
            <div className={classes.modalBottomImage}>
              <img width="100%" src={modalData.itemImage} alt="Uplio" />
            </div>
          </div> */}
          </div>
        )}
      </CommonModal>
    </div>
  )
}

export default OrdersModal
