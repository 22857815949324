import React, { useState, useEffect } from 'react'
import classes from './CommonModal.module.scss'
import clsx from 'clsx'

const CommonModal = ({ title, modalOpened, setModalOpened, children }) => {
  return (
    <div
      className={classes.modal}
      style={modalOpened ? { display: 'block' } : { display: 'none' }}
    >
      <div className={classes.modalWrapper}>
        <button className={classes.close} onClick={() => setModalOpened(false)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
              fill="#303030"
            />
          </svg>
        </button>
        <h2 className={clsx([`title title_item`, classes.title])}>{title}</h2>
        {children}
      </div>
    </div>
  )
}

export default CommonModal
