import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import Search from '../Search/Search'
import './m-menu.scss'
import {
  userLoggedOut,
  userDataStatus,
} from '../../../redux/actions/user.action.js'
export const MobileMenu = ({
  isSigned,
  menuOpened,
  setMenuOpened,
  searchResults,
  handleClick
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const history = useHistory()
  const user = useSelector((state) => state.user.user);
  const userLoggedInState = useSelector((state) => state.user.userLoggedIn)

  const dispatch = useDispatch()

  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }

  return (
    <div className={`m-menu ${menuOpened ? 'm-menu_opened' : null}`}>
      <div className="container">
        <div className="m-menu__wrapper">
          <div className="m-menu__search">
            {/* <Search setDropdown={setDropdownOpened} placeholder="Search" /> */}
            {
              !userLoggedInState ?
                <Search
                  dropdownClass="m-menu__dropdown"
                  dropdownData={searchResults}
                  setDropdown={setDropdownOpened}
                  handleChange={(value) => { }}
                  placeholder="Search"
                  handleClick={() => handleClick()}
                /> :
                user.userType !== 'supplier' &&
                <Search
                  dropdownClass="m-menu__dropdown"
                  dropdownData={searchResults}
                  setDropdown={setDropdownOpened}
                  handleChange={(value) => { }}
                  placeholder="Search"
                  handleClick={() => handleClick()}
                />}
            {/* <Search
              dropdownClass="m-menu__dropdown"
              dropdownData={searchResults}
              setDropdown={setDropdownOpened}
              handleChange={(value) => {}}
              placeholder="Search"
              handleClick={() => handleClick()}
            /> */}
          </div>
          <ul
            className={`m-menu-links ${isSigned ? 'm-menu-links_signed' : null
              }`}
            style={dropdownOpened ? { display: 'none' } : { display: 'block' }}
          >
            <li className="m-menu-links__link">
              <HashLink
                className="link"
                to="/#categories"
                onClick={() => setMenuOpened(false)}
              >
                Categories
              </HashLink>
            </li>
            <li className="m-menu-links__link">
              <HashLink
                className="link"
                to="/#how-it-works"
                onClick={() => setMenuOpened(false)}
              >
                How it works
              </HashLink>
            </li>
            <li className="m-menu-links__link">
              <HashLink
                className="link"
                to="/#faq"
                onClick={() => setMenuOpened(false)}
              >
                FAQ
              </HashLink>
            </li>
          </ul>
          {!isSigned && (
            <div
              className="m-menu__sign"
              style={dropdownOpened ? { display: 'none' } : { display: 'flex' }}
            >
              <Link to="/signup" className={`button signupLink`}>
                Sign up
              </Link>
              <Link to="/login" className="link link_fade">
                Sign in
              </Link>
            </div>
          )}
          {isSigned && (
            <div
              className="m-menu-signed"
              style={dropdownOpened ? { display: 'none' } : { display: 'flex' }}
            >
              <h4 className="title title_item-s m-menu-signed__title">
                {user ? `Hi ${user.firstname} ${user.lastname}!` : `Hi John!`}
              </h4>
              {
                user.userType === 'buyer' ?
                  <ul className="m-menu-signed__list">
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/buyer/inquiries"
                        onClick={() => setMenuOpened(false)}
                      >
                        Quotes
                      </Link>
                    </li>
                    <li className="m-menu-signed__item m-menu-signed__item_messages">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/message"
                        onClick={() => setMenuOpened(false)}
                      >
                        Message Center
                      </Link>
                      {user.messages && (
                        <div className="m-menu__messages">
                          {user ? user.messages : '2'}
                        </div>
                      )}
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/buyer/orders"
                        onClick={() => setMenuOpened(false)}
                      >
                        Orders
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/buyer/invoices"
                        onClick={() => setMenuOpened(false)}
                      >
                        Invoices
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/buyer/profile"
                        onClick={() => setMenuOpened(false)}
                      >
                        Account Settings
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/buyer/shipping-and-payment"
                        onClick={() => setMenuOpened(false)}
                      >
                        Shipping and Payment Information
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <button
                        className="title title_item-s m-menu-signed__link"
                        onClick={() => handleSignOut()}
                      >
                        Log Out
                      </button>
                    </li>
                  </ul>
                  :
                  <ul className="m-menu-signed__list">
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/lead"
                        onClick={() => setMenuOpened(false)}
                      >
                        Lead Opportunities
                      </Link>
                    </li>
                    <li className="m-menu-signed__item m-menu-signed__item_messages">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/message"
                        onClick={() => setMenuOpened(false)}
                      >
                        Message Center
                      </Link>
                      {user.messages && (
                        <div className="m-menu__messages">
                          {user ? user.messages : '2'}
                        </div>
                      )}
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/orders"
                        onClick={() => setMenuOpened(false)}
                      >
                        Manage Orders
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/invoices"
                        onClick={() => setMenuOpened(false)}
                      >
                        Manage Invoices
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/product-and-services"
                        onClick={() => setMenuOpened(false)}
                      >
                        Product and Services Offered

                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/profile"
                        onClick={() => setMenuOpened(false)}
                      >
                        Setup Profile
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <Link
                        className="title title_item-s m-menu-signed__link"
                        to="/dashboard/supplier/deposit-methods"
                        onClick={() => setMenuOpened(false)}
                      >
                        Deposit Methods
                      </Link>
                    </li>
                    <li className="m-menu-signed__item">
                      <button
                        className="title title_item-s m-menu-signed__link"
                        onClick={() => handleSignOut()}
                      >
                        Log Out
                      </button>
                    </li>
                  </ul>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
