import React, { useState, useEffect } from 'react'
import classes from './InputWithDropDown.module.scss'
import clsx from 'clsx'
import InputElement from '../Input/InputElement'

const InputWithDropDown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [items, setItem] = useState(props.dropdown)
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const toggleDropdown = () => {
    setOpen(!isOpen);
  }

  useEffect(() => {
    setItem(props.dropdown);
    setValue(props.value);
    setOpen(props.openDropdown);
  }, [props.dropdown]);

  const handleItemClick = (id) => {
    setValue(id);
    toggleDropdown()
    props.setDropdown(id);
    // props.onChange(id);
    setSearchText("");
  }




  return (
    <div>
      <div className={classes.dropdown}>
        {/* {!isOpen ? */}
        <div style={{ position: "relative" }}>
          <div
            className={classes.dropdownHeader}
            style={{ width: props.width }}
            onClick={toggleDropdown}
          >
            <h3>
              {props.value}
            </h3>
            <h3 className={classes.stateIcon}>
              {isOpen ? (
                <>
                  <i className={clsx(classes.arrow, classes.up)}></i>
                </>
              ) : (
                <i className={clsx(classes.arrow, classes.down)}></i>
              )}
            </h3>
          </div>
        </div>
        {
          isOpen &&
          <div className={classes.dropdownOpenContainer}>
            <div>
              <div style={{ padding: "0px 16px" }}>
                <InputElement
                  name="companyName"
                  type="text"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="Start Searching Here"
                  onChange={(e) => setSearchText(e.target.value)}
                // value={props.Formik.values.companyName}
                />
              </div>
              <div
                className={clsx({
                  [classes.dropdownBody]: true,
                  [classes.open]: isOpen,
                })}
              >
                {/* <button
                  className={classes.dropdownItem}
                  onClick={() => handleItemClick("")}
                  id={""}
                >
                  <span
                    className={`dropdown-item-dot ${"" === value && 'selected'}`}
                  >
                    {props.placeholder}
                  </span>
                </button> */}
                {items?.filter(f => f?.label?.toLowerCase().includes(searchText.toLowerCase())).map((item) => (
                  <button
                    key={item.id}
                    className={classes.dropdownItem}
                    onClick={() => handleItemClick(item.label)}
                    id={item.id}
                  >
                    <span
                      className={`dropdown-item-dot ${item.id === value && 'selected'}`}
                    >
                      {item.label}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          // }
        }
      </div>
    </div>
  )
}

export default InputWithDropDown
