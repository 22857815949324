import React from 'react'
import classes from './DeleteModal.module.scss'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import ButtonElement from '../../../../constant/public/Button/ButtonElement'

const DeleteModal = ({
    modalOpened,
    setModalOpened,
    note,
    setDeleteIndex,
    deleteMethod,
    setDeleteType,
}) => {
    const handleDelete = () => {
        setModalOpened(false)
        deleteMethod()
        setDeleteIndex(null)

        if (setDeleteType) {
            setDeleteType(null)
        }
    }

    const handleClose = () => {
        setModalOpened(false)
        setDeleteIndex(null)

        if (setDeleteType) {
            setDeleteType(null)
        }
    }

    return (
        <div className={classes.deleteModal}>
            <CommonModal
                className={classes.deleteModal}
                setModalOpened={setModalOpened}
                modalOpened={modalOpened}
            >
                <div>
                    <div className={classes.deleteModalNote}>{note}</div>
                    <div className={classes.buttonsBlock}>
                        <ButtonElement
                            className={classes.delete}
                            content={'DELETE'}
                            function={handleDelete}
                        />
                        <button className={classes.cancel} onClick={handleClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </CommonModal>
        </div>
    )
}

export default DeleteModal
