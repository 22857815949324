import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import "./Stripe.css"

const SetupForm = () => {
    const pageUrl = window.location.href;
    console.log(pageUrl)
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolderName, setCardHolderName] = useState('');


    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href,
                payment_method_data: {
                    billing_details: {
                        name: cardHolderName
                    }
                }
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const paymentElementOptions = {
        layout: "tabs",
        name: cardHolderName,
        fields: {
            billingDetails: {
                name: 'auto',
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="stripeNameInputCont">
                <label>Name</label>
                <input required onChange={(e) => setCardHolderName(e.target.value)} className="stripeNameInput" placeholder="Name" />
            </div>
            <PaymentElement options={paymentElementOptions} />
            <button disabled={!stripe}>ADD PAYMENT METHOD</button>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
};

export default SetupForm;