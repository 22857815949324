import React, { useState, useEffect } from 'react'
import classes from './LeadsItem.module.scss'
import nathan from '../../../../../assets/images/nathan.jpg'
import closeBtn from '../../../../../assets/svg/Cross.svg'
import clsx from 'clsx'
import moment from 'moment'
import { useSelector } from 'react-redux'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import { Image, Modal } from 'antd'

const LeadsItem = (props) => {
  const [moreInfoOpened, setMoreInfoOpened] = useState(false)
  const [infoModalOpened, setInfoModalOpened] = useState(false)
  const [isAnswered, setIsAnswered] = useState(false)
  const [isPassed, setIsPassed] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const user = useSelector((state) => state.user.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState({});

  const showModal = (val) => {
    setResponse(val)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(props.data)

  useEffect(() => {
    const isAnswered = props.modalsAnswered.find((item) => item.id === props.id)

    if (isAnswered) {
      setIsAnswered(true)
    }
  })

  if (isHidden) {
    return null
  } else if (props.data.supplierCanUndoResponse) {
    return (
      <li className={classes.quotesItem} data-id={props.id}>
        <div className={classes.request}>
          <p>You have declined the request</p>
          <button
            type="button"
            className={classes.requestBtn}
            onClick={() => props.passLead(0, props.id)}
          >
            Undo
          </button>
          <button
            type="button"
            className={classes.requestClose}
            onClick={() => setIsHidden(true)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="#303030"
              />
            </svg>
          </button>
        </div>
      </li>
    )
  } else {
    return (
      <li className={classes.quotesItem} data-id={props.id}>
        <div className={classes.mainInfo}>
          <p className={classes.subtitle}>
            Category: <span>{props.data.productCategory}</span>
          </p>
          <p className={classes.subtitle}>
            Launch Date: <time>{moment(props.data.projectLaunchDate).format('MM/DD/YYYY')}</time>
          </p>
          <p className={classes.subtitle}>
            MOQ: <span>{props.data.quantity}</span>
          </p>
        </div>
        <div className={classes.about}>
          <p className={classes.subtitle}>About the project:</p>
          <p className={classes.text}>{props.data.description}</p>
          <div
            className={classes.additional}
            style={moreInfoOpened ? { display: 'block' } : { display: 'none' }}
          >
            <p className={classes.subtitle}>
              Budget: <span>{props.data.budget}</span>
            </p>
            <div className={classes.photos}>
              {props.data.inspirationImages.length > 0
                ? props.data.inspirationImages.map((item) => (
                  <Image style={{ width: "100px", height: "100px" }} src={item} alt="Uplio" />
                ))
                : null}
            </div>
            <ul className={classes.docsList}>
              {props.data.inspirationDocument.length > 0
                ? props.data.inspirationDocument.map((item) => <li>
                  <a href={item} target="_blank" rel="noreferrer">For_Uplio.doc</a>
                </li>)
                : null}
            </ul>
          </div>
          <button
            type="button"
            className={classes.moreInfo}
            onClick={() => setMoreInfoOpened((prev) => !prev)}
          >
            {moreInfoOpened ? 'Less' : 'More'}
          </button>
        </div>
        <div className={classes.buttons}>
          <div className={classes.buttonsWrapper}>
            <div
              className={`${classes.buttonsInfo}`}
              onMouseOver={() => setInfoModalOpened(true)}
              onMouseLeave={() => setInfoModalOpened(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                  fill="#303030"
                />
                <path
                  d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                  fill="#303030"
                />
              </svg>
              <div
                className={classes.buttonsInfoModal}
                style={
                  infoModalOpened
                    ? { opacity: '1', pointerEvents: 'auto' }
                    : { opacity: '0', pointerEvents: 'none' }
                }
              >
                <p>
                  You can accept or pass this proposal. If you accept, you are
                  able to message customers and submit proposal pricing.
                </p>
              </div>
            </div>
            <div className={classes.mobileFlex}>
              {
                props.data?.supplierResponse?.supplier === user._id ?
                  <button
                    type="button"
                    className={clsx([`button`, classes.answerButton])}
                    onClick={() => showModal(props.data?.supplierResponse)}
                  >
                    View answer
                  </button>
                  :
                  <button
                    type="button"
                    className={clsx([`button`, classes.answerButton])}
                    onClick={() => props.answerHandler(props.id)}
                  >
                    Give an answer
                  </button>
              }
              <div
                className={`${classes.buttonsInfo} ${classes.buttonsInfoMobile}`}
                onMouseOver={() => setInfoModalOpened(true)}
                onMouseLeave={() => setInfoModalOpened(false)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                    fill="#303030"
                  />
                  <path
                    d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                    fill="#303030"
                  />
                </svg>
                <div
                  className={classes.buttonsInfoModal}
                  style={
                    infoModalOpened
                      ? { opacity: '1', pointerEvents: 'auto' }
                      : { opacity: '0', pointerEvents: 'none' }
                  }
                >
                  <p>
                    You can accept or pass this proposal. If you accept, you are
                    able to message customers and submit proposal pricing.
                  </p>
                </div>
              </div>
            </div>
            {
              props.data?.supplierResponse?.supplier !== user._id ?
                <button
                  type="button"
                  className={classes.passButton}
                  onClick={() => { setIsPassed(true); props.passLead(2, props.id) }}
                >
                  Pass
                </button>
                :
                <button
                  type="button"
                  className={classes.passButton}
                  style={{ width: "49px" }}
                ></button>
            }
            <Modal centered title="View Answer" closeIcon={<img src={closeBtn} alt="close icon" />} visible={isModalOpen} footer={false} onCancel={handleCancel}>
              <div className={classes.modalText}>
                <p className={classes.subtitle}>Min Price:</p>
                <p className={classes.text}>{props.data?.supplierResponse?.minPrice}</p>
                <p className={classes.subtitle}>Max Price:</p>
                <p className={classes.text}>{props.data?.supplierResponse?.maxPrice}</p>
                <p className={classes.subtitle}>Time:</p>
                <p className={classes.text}>{moment(props.data?.supplierResponse?.responseDatetime).format('MM/DD/YYYY')}</p>
              </div>
            </Modal>
          </div>
        </div>
      </li>
    )
  }
}

export default LeadsItem
