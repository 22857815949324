import React, { useState } from 'react'
import classes from './OrdersTable.module.scss'
import BlackArrow from '../../../assets/svg/Arrow_Right_Black.svg'
import OrdersModal from './OrdersModal/OrdersModal'
import moment from 'moment'
import StatusSelect from './StatusSelect/StatusSelect'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { OpenNotificationWithIcon } from '../../../constant/public/Notification/Notification'

const OrdersTable = ({ data, buyer, update }) => {
  const [status, setStatus] = useState('In Production')
  const [modalOpened, setModalOpened] = useState(false)
  const [modalData, setModalData] = useState(null)

  const handleModal = (item) => {
    setModalOpened(true)
    setModalData(item)
  }

  const sortOptionsData = [
    {
      label: "Pending Confirmation",
      value: "2"
    },
    {
      label: "In Progress",
      value: "6"
    },
    {
      label: "Shipped",
      value: "7"
    },
    {
      label: "Delivered",
      value: "8"
    },
    {
      label: "Cancelled",
      value: "12"
    }
  ]

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const changeOrderStatus = (id, val) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/orders/${id}/status`, { status: val },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        OpenNotificationWithIcon("success", "Status Changes Successfully", "");
        setStatus(val);
        update();
      })
      .catch((err) => {
        console.log(err.response)
      })
  }
  console.log(data);
  return (
    <>
      <div className={classes.table}>
        {data?.map((item) => {
          return (
            <div className={classes.tableRow} key={item.id}>
              <div className={classes.orderId}>
                <h3 className={classes.rowTitle}>Order ID:</h3>
                <p>{item?.shortId}</p>
              </div>
              <div className={classes.buyer}>
                {
                  buyer ?
                    <>
                      <h3 className={classes.rowTitle}>Buyer:</h3>
                      <p>{item?.buyer?.firstname} {item?.buyer?.lastname}</p>
                    </>
                    :
                    <>
                      <h3 className={classes.rowTitle}>Supplier:</h3>
                      <p>{item?.supplier?.name}</p>
                    </>
                }

              </div>
              <div className={classes.date}>
                <h3 className={classes.rowTitle}>Date:</h3>
                <p>{moment(item.orderDatetime).format('MMM D, YYYY')}</p>
              </div>
              <div className={classes.status}>
                <h3 className={classes.rowTitle}>status:</h3>
                {
                  !buyer ?
                    // <div className={classes.sortDropdownSection}>
                    <p>{item?.statusDescription}</p>
                    // </div>
                    :
                    <div className={classes.sortDropdownSection}>
                      <StatusSelect
                        value={
                          item.status === 2 ?
                            "Pending Confirmation"
                            :
                            item.status === 6 ?
                              "In Progress"
                              :
                              item.status === 7 ?
                                "Shipped"
                                :
                                item.status === 8 ?
                                  "Delivered"
                                  :
                                  item.status === 12 &&
                                  "Cancelled"
                        }
                        setValue={(val) => { changeOrderStatus(item._id, val) }} optionsData={sortOptionsData} />
                    </div>
                }
              </div>
              <div className={classes.seeMore}>
                <button
                  type="button"
                  className={classes.seeMoreBtn}
                  onClick={() => handleModal(item)}
                >
                  <div className={classes.seeMoreBtn}>
                    <p className="title title_item-s">See more</p>
                    <img className={classes.BlackArrow} src={BlackArrow} alt="Arrow" />
                  </div>
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <OrdersModal
        modalData={modalData}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        status={status}
        buyer={buyer ? true : false}
      />
      <div
        className={`${modalOpened ? 'blocker blocker_opened' : 'blocker'}`}
      ></div>
    </>
  )
}

export default OrdersTable
