import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import Respond from '../../../Buyer/MyInquiries/Respond/Respond'
import classes from "./QuoteModal.module.scss"

const QuoteModal = ({ modalData, modalOpened, setModalOpened }) => {
    const user = useSelector((state) => state.user.user)

    return (
        <div className={classes.QuoteModal}>
            <CommonModal
                className={classes.QuoteModal}
                title="The Quote info"
                modalOpened={modalOpened}
                setModalOpened={setModalOpened}
            >
                <div className={classes.QuoteModalWrapper}>
                    <div className={classes.modalMain}>
                        <div className={classes.tableRow}>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Submitted:</h3>
                                <p>{moment(modalData?.submittedDatetime).format('MMM D, YYYY')}</p>
                            </div>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Quantity:</h3>
                                <p>{modalData?.quantity}</p>
                            </div>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Category:</h3>
                                <p>{modalData?.productCategory}</p>
                            </div>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Budget:</h3>
                                <p>${modalData?.budget}</p>
                            </div>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Launch date:</h3>
                                <p>{moment(modalData?.projectLaunchDate).format('MMM D, YYYY')}</p>
                            </div>
                            <div className={classes.infoItem}>
                                <h3 className={classes.rowTitle}>Status:</h3>
                                <p>{modalData?.quote_status_description}</p>
                            </div>
                            <div className={classes.infoItem} data-item="description">
                                <h3 className={classes.rowTitle}>About the project:</h3>
                                <p>{modalData?.description}</p>
                            </div>
                        </div>
                        <div className={classes.photos}>
                            {modalData?.inspirationImages?.length > 0
                                ? modalData?.inspirationImages?.map((item) => (
                                    <img width="100" height="100" src={item} alt="Uplio" />
                                ))
                                : null}
                        </div>
                        <div>
                            <ul className={classes.docsList}>
                                {modalData?.inspirationDocument?.length > 0
                                    ? modalData?.inspirationDocument?.map((item) => <li>
                                        <a href={item} target="_blank" rel="noreferrer">For_Uplio.doc</a>
                                    </li>)
                                    : null}
                            </ul>
                        </div>
                    </div>
                    {
                        user.userType === "buyer" &&
                        modalData?.quoteSuppliers?.length > 0 && (
                            <div className={classes.modalBottom}>
                                <h2
                                    className={clsx([`title title_item`, classes.titleItem])}
                                >
                                    {modalData?.quoteSuppliers?.length === 1
                                        ? `A supplier responded you`
                                        : `${modalData?.quoteSuppliers?.length} suppliers responded you`}
                                </h2>
                                <div className={classes.respondsWrapper}>
                                    {modalData?.quoteSuppliers?.map((resp, idx) => {
                                        return <Respond qouteSupplierIndex={idx + 1} quoteSuppliers={modalData?.quoteSuppliers} modalData={modalData} data={resp} key={resp.title + idx} />
                                    })}
                                </div>
                            </div>
                        )
                    }
                </div>
            </CommonModal>
        </div>
    )
}

export default QuoteModal
