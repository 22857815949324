import React from 'react'
import classes from './NotificationModal.module.scss'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import {
  userLoggedOut,
  userDataStatus,
} from '../../../redux/actions/user.action.js'
import { useDispatch } from 'react-redux'

const NotificationModal = ({ user, modalOpened, setModalOpened }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }
  return (
    <div
      className={classes.modal}
      style={modalOpened ? { display: 'block' } : { display: 'none' }}
      id={'notification-modal'}
    >
      <div className={classes.modalWrapper}>
        <h4 className={clsx([`title`, `title_item_s`, classes.modalTitle])}>
          Hi <span>{user.firstname} {user.lastname}!</span>
        </h4>
        {
          user.userType === 'supplier'
            ?
            <>
              <ul className={classes.linkList}>
                <li className={classes.linkItem}>
                  <Link
                    className={classes.link}
                    to={`/dashboard/supplier/lead`}
                  >
                    Lead opportunities
                  </Link>
                </li>
                <li
                  className={clsx([classes.linkItem, 'm-menu-signed__item_messages'])}
                >
                  <Link className={classes.link} to="/dashboard/message">
                    Message Center
                  </Link>
                  {user.messages && (
                    <div className="m-menu__messages">
                      {user ? user.messages : '2'}
                    </div>
                  )}
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/supplier/product-and-services">
                    Products and Services Offered
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/supplier/orders">
                    Manage Orders
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/supplier/invoices">
                    Manage Invoices
                  </Link>
                </li>
              </ul>
              <ul className={classes.linkList}>
                <li className={classes.linkItem}>
                  <Link
                    className={classes.link}
                    to={`/dashboard/supplier/profile`}
                  >
                    Setup Profile
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/supplier/deposit-methods">
                    Deposit Methods
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <button className={classes.link} onClick={() => handleSignOut()}>
                    Log Out
                  </button>
                </li>
              </ul>
            </>
            :
            <>
              <ul className={classes.linkList}>
                <li className={classes.linkItem}>
                  <Link
                    className={classes.link}
                    to={`/dashboard/buyer/inquiries`}
                  >
                    Quotes
                  </Link>
                </li>
                <li
                  className={clsx([classes.linkItem, 'm-menu-signed__item_messages'])}
                >
                  <Link className={classes.link} to="/dashboard/message">
                    Message Center
                  </Link>
                  {user.messages && (
                    <div className="m-menu__messages">
                      {user ? user.messages : '2'}
                    </div>
                  )}
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/buyer/orders">
                    Orders
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/buyer/invoices">
                    Invoices
                  </Link>
                </li>
              </ul>
              <ul className={classes.linkList}>
                <li className={classes.linkItem}>
                  <Link
                    className={classes.link}
                    to={`/dashboard/buyer/profile`}
                  >
                    Account Settings
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <Link className={classes.link} to="/dashboard/buyer/shipping-and-payment">
                    Shipping and Payment Information
                  </Link>
                </li>
                <li className={classes.linkItem}>
                  <button className={classes.link} onClick={() => handleSignOut()}>
                    Log Out
                  </button>
                </li>
              </ul>
            </>
        }
      </div>
    </div>
  )
}

export default NotificationModal
