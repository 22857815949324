import React, { useEffect, useState } from 'react'
import classes from "./ProductCard.module.scss"
import heart from "../../assets/svg/Heart.svg"
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";


const ProductCard = ({ data, handleBrand }) => {
    let initialSlide = data?.images?.findIndex((f) => f.cover);
    const [swiper, setSwiper] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if (swiper) {
            swiper.activeIndex = initialSlide;
            setActiveIndex(swiper.activeIndex)
        }
    }, [swiper])

    return (
        <div className={classes.ProductCard}>
            <div style={{ position: "relative" }}>
                <Link to={`/product/${data?._id}`}>
                    <Swiper
                        onSwiper={setSwiper}
                        slidesPerView={1}
                        // spaceBetween={0}
                        loop={true}
                        dots={false} 
                        className="mySwiper">
                        { /* below commented by Prem
                            data?.images && data?.images?.length > 0 ? data?.images?.map(image => {
                                return (
                                    <SwiperSlide key={image?.id} className={classes.slide}>
                                        <img src={image.url} alt="Shirt" className={classes.productImg} />
                                    </SwiperSlide>
                                )
                            })
                                :
                                <SwiperSlide className={classes.slide}>
                                    <img src={shirt} alt="Shirt" className={classes.productImg} />
                                </SwiperSlide>
                        */ }

                        { //this block added by Prem
                            data?.images && data?.images?.length > 0 && data?.images?.filter((i) => i.cover === true).map(image => {
                                return (
                                    <SwiperSlide key={image?._id} className={classes.slide}>
                                        {/* <Link to={`/product/${data?._id}`}> */}
                                        <img src={image.url} alt="Shirt" className={classes.productImg} />
                                        {/* </Link> */}
                                    </SwiperSlide>
                                )
                            })
                        }
                        {//filter added by Prem
                            data?.images && data?.images && data?.images?.length > 0 && data?.images?.filter((i) => i.cover === false).map(image => {
                                return (
                                    <SwiperSlide key={image?._id} className={classes.slide}>
                                        {/* <Link to={`/product/${data?._id}`}> */}
                                        <img src={image.url} alt="Shirt" className={classes.productImg} />
                                        {/* </Link> */}
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <img src={heart} alt="heart" className={classes.heartIcon} />
                    {
                        data?.new &&
                        <div className={classes.tag}>
                            NEW
                        </div>
                    }
                </Link>
            </div>
            <div className={classes.BottomSection}>
                <div className={classes.titleAndPrice}>
                    <h5>{data.name} </h5>
                    <h5>${parseInt(data?.price).toFixed(2)}</h5>
                </div>
                <div className={classes.sameManufacturer}>
                    <p>Same manufacturer as:<span className={classes.brands}>
                        {
                            data?.user?.notableBrands && data?.user?.notableBrands?.map((brand, index) => {
                                return (
                                    <button onClick={() => handleBrand(brand)}>
                                        <span>{brand}</span>
                                        {
                                            index === 0 &&
                                            <span>;&nbsp;</span>
                                        }

                                    </button>
                                )
                            })
                        }
                        {/* <span>Prada; </span>
                        <span>Gucci</span> */}
                    </span>

                    </p>
                </div>
                <div>
                    <p>Lead time: {data?.user?.estimatedLeadTimeProduction} weeks</p>
                </div>
                <div>
                    <p>Min Qty: {data?.minimum_quantity}</p>
                </div>
            </div>
        </div >
    )
}

export default ProductCard
