import React from 'react'
import classes from './AddModal.module.scss'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import SetupForm from './Stripe/SetupForm';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const AddDepositModal = ({
  state,
  dispatch,
  modalOpened,
  setModalOpened,
  shippingInfo,
  setShippingInfo,
  client_secret
}) => {
  const options = {
    // passing the client secret obtained in step 3
    clientSecret: client_secret,
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };
  const handleSubmit = (e) => {
    setShippingInfo((prev) =>
      prev.concat({
        name: 'Aron',
        city: 'Calgary',
        country: 'Canada',
        state: 'Cagary',
        street: 'Green Blvd',
        zip: 90210,
        apt: '',
        phone_number: '+1 562-985-4111',
      })
    )

    setModalOpened(false)

    // dispatch({ type: 'CLEAR' })

    // console.log(depositMethods)
  }

  console.log(client_secret)

  return (
    <CommonModal
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      title={'Add payment method'}
    >
      <div className={classes.wrapper}>
        <h3 className={classes.formTitle}>Credit or debit card</h3>
        <div className={classes.form}>
          {
            client_secret &&
            <Elements stripe={stripePromise} options={options}>
              <SetupForm />
            </Elements>
          }

          {/* <InputElement
            wrapperClass={classes.inputWrapper}
            name="card_number"
            placeholder="Card Number"
            type="text"
          />
          <InputElement
            wrapperClass={classes.inputWrapper}
            name="card_name"
            placeholder="Name on Card"
            type="text"
          />
          <InputElement
            wrapperClass={clsx([
              classes.inputWrapper,
              classes.inputWrapperSmall,
            ])}
            name="mm/yy"
            placeholder="MM/YY"
            type="text"
          />
          <InputElement
            wrapperClass={clsx([
              classes.inputWrapper,
              classes.inputWrapperSmall,
            ])}
            name="cvc"
            placeholder="CVC"
            type="text"
          />
          <InputElement
            wrapperClass={classes.inputWrapper}
            name="zip"
            placeholder="ZIP CODE"
            type="text"
          />
          <div className={classes.submitWrapper}>
            <ButtonElement
              className={classes.submit}
              type="button"
              content={'Add payment method'}
              function={(evt) => handleSubmit(evt)}
            />
          </div> */}
        </div>
      </div>
    </CommonModal>
  )
}

export default AddDepositModal
