import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import './CertificateUpload.css';
import loader from "../../../../../assets/svg/eos-icons_loading.svg"
import closeIcon from "../../../../../assets/svg/Cross.svg"
import InputElement from '../../../../../constant/public/Input/InputElement';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { NotificationWithIconComp } from '../../../../../constant/Notification';
import Loading from '../../../../../constant/Loading/Loading';
import LoadingModal from '../../../../../constant/public/LoadingModal/LoadingModal';

const CertificateUpload = (props) => {
    const inputFile = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [file, setFile] = useState();
    const [uploadedResponse, setUploadedResponse] = useState();

    const openAttachment = () => {
        inputFile.current.click()
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const user = useSelector((state) => state.user.user)
    const token = sessionStorage.getItem('token')

    const handlePPI = (f) => {
        // if (f.size / 1000000 < 200) {
        if (props.userData?.profileCompleted) {

        } else {
            const newFile = new File([f], searchText + `.${f?.type?.split("/")[1]}`);
            handleCancel();
            setLoading(true);
            setShowLoadingModal(true);
            const formData = new FormData();
            const baseURL = process.env.REACT_APP_API_URL
            formData.append('file', newFile)
            formData.append('type', props.type)
            axios({
                method: "PATCH",
                url: baseURL + '/users/' + user?.id + "/media/",
                data: formData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "bearer " + token
                }
            })
                .then(function (response) {
                    setLoading(false);
                    setShowLoadingModal(false);
                    if (response.status == 200) {
                        console.log("Image Upload Successfully", response.data.data?.images.slice(-1)[0])
                        props.handleUpdate(response);
                        handleCancel();
                    }
                    else if (response.status == 400) {

                    }
                })
            // } else {
            //     NotificationWithIconComp("error", "Files less than 200MB are supported", "")
            // }
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files[0].size / 1000000 < 200) {
            showModal();
            setLoading(true);
            setFile(e.target.files[0])
            setLoading(false);
        }
        else {
            NotificationWithIconComp("error", "Files less than 200MB are supported", "")
        }
    }

    return (
        <div>
            <input
                type="file"
                style={{ display: "none" }}
                accept=".pdf,.jpeg"
                name='image'
                onClick={(e) => e.target.value = null}
                onChange={
                    handleFileChange
                }
                ref={inputFile}
            />
            <button
                disabled={props.disabled}
                // id="attachment"
                id='certUploadBtn'
                type="button"
                class="file"
                name="image"
                onClick={openAttachment}
            >
                {!props.disabled ? (
                    <PlusOutlined width={32} />
                ) : (
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                )}
            </button>
            {
                showLoadingModal &&
                <LoadingModal text={"Please wait. The certificate is being loaded."} openModal={showLoadingModal} />
            }
            <Modal footer={false} closeIcon={<img src={closeIcon} alt="Cross Icon" />} className={"certificateUploadModal"} title={false} visible={isModalOpen} onCancel={handleCancel}>
                {
                    file && file !== null &&
                    <div className='showCertSection'>
                        <p>{file.name}</p>
                        <img className='uploadedImg' src={file && URL.createObjectURL(file)} alt="Pic" />
                        <div>
                            <InputElement
                                name="companyName"
                                type="text"
                                placeholder="Enter new image name"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                        <div className='mt-4 btnContainer'>
                            {
                                props.userData?.profileCompleted ?
                                    <button style={{ pointerEvents: "none", opacity: ".5" }} disabled onClick={() => { handlePPI(file) }}>upload</button>
                                    :
                                    <button onClick={() => { handlePPI(file) }}>upload</button>
                            }
                            <button onClick={handleCancel}>cancel</button>
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default CertificateUpload
