import React, { useEffect, useState } from 'react'
import classes from './Profile.module.scss'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import Footer from '../../../../constant/public/Footer/Footer'
import { useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import CompanyInfo from '../CompanyInfo/CompanyInfo'
import Orders from '../Orders/Orders'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ref } from 'yup'
import LeadOpportunities from '../Dashboard'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import {
  userDataStatus,
  userLoggedOut,
} from '../../../../redux/actions/user.action'
import ProductAndServices from '../ProductAndServices/ProductAndServices'
import SaveModal from '../../../../constant/public/SaveModal/SaveModal'
import AddProduct from '../ProductAndServices/AddProducts/AddProduct'
import SupplierHeader from '../Header/Header'
import SupplierAside from '../SupplierAside/SupplierAside'
import ComingSoon from '../ComingSoon/ComingSoon'
import DepositMethods from '../DepositMethods/DepositMethods'
import AddInvoice from '../Invoices/CreateInvoice/AddInvoice'
import Invoices from '../Invoices/Invoices'

const Profile = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const path = useParams()
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [editing, setEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(path.profileTab);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    setCurrentTab(path.profileTab)
  }, [path])

  console.log(path);

  const Formik = useFormik({
    initialValues: {
      companyName: '',
      companyOverview: '',
      minimumOrderQuantity: '',
      estimatedLeadTimeSample: '',
      estimatedLeadTimeProduction: '',
      yearEstablished: '',
      productionCapacity: '',
      country: '',
      state: '',
      brand: '',
      brandTwo: '',
      specialization: [],
      profileImage: '',
      pastProjectImages: [],
      estimatedLeadTimeProduction: '',
      estimatedLeadTimeSample: '',
      password: '',
      oldPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email.').required('Email is Required'),
      companyName: yup.string().required("Company Name is Required"),
      companyOverview: yup.string().required("Company Overview is Required"),
      minimumOrderQuantity: yup.number().required('Order Quantity is required').min(1, 'Add value greater than 1'),
      productionCapacity: yup.number().required('Production Capacity is required').min(1, 'Add value greater than 1'),
      yearEstablished: yup.string().required("Year is Required").min(4, "Year must be four characters").max(4, "Year must be four characters"),
      estimatedLeadTimeSample: yup.number().required("Sample is required").min(1, 'Add value greater than 1'),
      estimatedLeadTimeProduction: yup.number().required("Production run is required").min(1, 'Add value greater than 1'),
      password: yup.string().required('Password is required.'),
      brand: yup.string().required('Brand is required.'),
      specialization: yup.array().required('Specialization is required.'),
      brandTwo: yup.string().required('Brand is required.'),
      country: yup.string().required('Country is required.'),
      state: yup.string().required('State is required.'),
      oldPassword: yup.string().required('Old Password is required.'),
      confirmPassword: yup
        .string()
        .required('Password is required.')
        .oneOf([ref('password')], 'Passwords do not match'),
    }),
    onSubmit: (values) => {
      // handleProfileUpdated
      alert(JSON.stringify(values, null, 2));
    },

  })

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')
  const { setFieldValue } = Formik
  useEffect(() => {
    if (user !== null && user !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/users/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // setLoading(false)
          const data = res.data.user;
          setUserData(res.data?.data)
          console.log("fetchted profile data", data)
          setFieldValue('companyName', data?.name)
          setFieldValue('companyOverview', data?.companyOverview)
          setFieldValue('minimumOrderQuantity', data?.MOQ)
          setFieldValue('country', data?.country)
          setFieldValue('state', data?.state)
          setFieldValue('brand', data?.notableBrands[0])
          setFieldValue('brandTwo', data?.notableBrands[1])
          setFieldValue('profileImage', data?.profileImage)
          setFieldValue('yearEstablished', data?.yearEstablished)
          setFieldValue('productionCapacity', data?.productionCapacity)
          setFieldValue('specialization', data?.specialization)
          setFieldValue('pastProjectImages', data?.images)
          setFieldValue(
            'estimatedLeadTimeProduction',
            data?.estimatedLeadTimeProduction
          )
          setFieldValue('estimatedLeadTimeSample', data?.estimatedLeadTimeSample)
        })
        .catch((err) => {
          console.log("fetchted profile data", err.response);
        })
    }
  }, [])

  const handleProfileUpdated = () => {
    Formik.handleSubmit();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        {
          name: Formik.values.companyName,
          companyOverview: Formik.values.companyOverview,
          MOQ: Formik.values.minimumOrderQuantity,
          country: Formik.values.country,
          state: Formik.values.state,
          notableBrands: [Formik.values.brand, Formik.values.brandTwo],
          companyLogo: Formik.values.profileImage,
          yearEstablished: Formik.values.yearEstablished,
          productionCapacity: Formik.values.productionCapacity,
          specialization: Formik.values.specialization,
          images: Formik.values.pastProjectImages,
          estimatedLeadTimeProduction:
            Formik.values.estimatedLeadTimeProduction,
          estimatedLeadTimeSample: Formik.values.estimatedLeadTimeSample,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        setLoading(false)
        setEditing(true);
        // NotificationWithIconComp("success", "Saved successfully", "")
        setIsModalOpen(!isModalOpen)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }

  const handleUpdatePassword = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${user ? user.id : null
        }/update_password`,
        {
          password: Formik.values.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <Navigation />
      {
        currentTab === "add-product" ?
          <div className={classes.headerWrapper}>
            <SupplierHeader text="BACK TO MY PRODUCTS" link="/dashboard/supplier/product-and-services" />
          </div>
          :
          currentTab === "add-invoice" &&
          <div className={classes.headerWrapper}>
            <SupplierHeader text="BACK TO MY INVOICES" link="/dashboard/supplier/orders-and-invoices" />
          </div>
      }
      <main className={clsx([classes.main, classes.supplier])}>
        <div className="container">
          <div className={classes.dashboardWrapper}>
            <SupplierAside
              currentTab={currentTab}
              handleSignOut={handleSignOut}
            />
            {currentTab === 'profile' ? (
              <CompanyInfo
                userData={userData}
                handleProfileUpdated={handleProfileUpdated}
                handleUpdatePassword={handleUpdatePassword}
                Formik={Formik}
              />
            ) : currentTab === 'lead' ? (
              <LeadOpportunities />
            ) : currentTab === 'coming-soon' ? (
              <div className={classes.ComingSoon}>
                <ComingSoon />
              </div>
            ) : currentTab === 'orders' ? (
              <Orders />
            ) :
              currentTab === 'invoices' ? (
                <Invoices />
              )
                :
                currentTab === 'add-invoice' ? (
                  <AddInvoice />
                ) :
                  currentTab === "product-and-services" ?
                    <ProductAndServices />
                    :
                    currentTab === "add-product" ?
                      <AddProduct />
                      :
                      currentTab === "deposit-methods" &&
                      <DepositMethods />
            }
          </div>
        </div>
        {
          <SaveModal openModal={isModalOpen} />
        }
      </main>
      <Footer />
    </>
  )
}

export default Profile
