import React, { useEffect, useState } from 'react'
import classes from './AddModal.module.scss'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../../constant/public/Input/InputElement'
import InputWithDropDown from '../../../../../constant/public/InputWithDropDown/InputWithDropDown'
import errorIcon from '../../../../../assets/svg/error_icon.svg'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Col, Row } from 'antd'
import { OpenNotificationWithIcon } from '../../../../../constant/public/Notification/Notification'

const AddDepositModal = ({
  modalOpened,
  setModalOpened,
  handleUpdate,
}) => {
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [statee, setStatee] = useState('- Select a state -')
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')
  const countries = [
    { name: 'United States', slug: 'United States' },
    { name: 'Canada', slug: 'Canada' },
    { name: 'Mexico', slug: 'Mexico' },
  ];

  const Formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      address2: '',
      address1: '',
      state: '',
      city: '',
      country: '',
      zip: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Invalid email.')
        .required('Email is Required'),
      firstName: yup
        .string()
        .required('First Name is Required'),
      lastName: yup
        .string()
        .required('Last Name is Required'),
      address1: yup
        .string()
        .required('Address is Required'),
      address2: yup
        .string(),
      state: yup
        .string()
        .required('State is Required'),
      city: yup
        .string()
        .required('City is Required'),
      country: yup
        .string()
        .required('Country is Required'),
      zip: yup
        .string()
        .required('Postal Code is Required'),
    }),
  })
  const { setFieldValue } = Formik;


  const filterStates = (value) => {
    let arr = [];
    data?.filter(d => d.country === value)[0]?.states.map((d, index) => arr.push({
      id: index,
      label: d,
      state: true
    }));
    setStateData(arr);
  }


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/countries/`)
      .then((res) => {
        setData(res.data.countries)
        let newArr = []
        let arr = [];
        res.data.countries.map((item, index) => {
          newArr.push({
            id: index + 1,
            label: item.country,
          })
        })
        setCountryData(newArr)
        res.data.countries?.filter(d => d.country === "USA")[0]?.states.map((d, index) => arr.push({
          id: index,
          label: d,
          state: true
        }));
        setStateData(arr);
      })
      .catch((err) => {
        console.log(err)
      })

    if (user !== null && user !== undefined) {
      setFieldValue("email", user.email);
      setFieldValue("firstName", user.name);
    }

  }, [])

  const handleSubmit = (e) => {
    Formik.handleSubmit();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${user?.id}/shipping-address`,
        {
          type: "home",
          firstName: Formik.values.firstName,
          lastName: Formik.values.lastName,
          address1: Formik.values.address1,
          address2: Formik.values.address2,
          city: Formik.values.city,
          state: Formik.values.state,
          country: Formik.values.country,
          zip: Formik.values.zip,
          default: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setModalOpened(false);
        OpenNotificationWithIcon("success", "Address Saved", "");
        handleUpdate();
        Formik.resetForm();
      })
      .catch((err) => {
        console.log(err)
      })
  }
  console.log(Formik)
  console.log(Formik)
  return (
    <CommonModal
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      title={'Add new address'}
    >
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <div>
            <div className={classes.mainContent}>
              <div className={classes.form}>
                <div className={classes.inner}>
                  <Row className={classes.row} gutter={[24, 24]}>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={Formik.values.firstName}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.firstName && Formik.touched.firstName
                            ? Formik?.errors.firstName
                            : null
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={Formik.values.lastName}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.lastName && Formik.touched.lastName
                            ? Formik?.errors.lastName
                            : null
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="address1"
                        placeholder="Address Line 1"
                        value={Formik.values.address1}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.address1 && Formik.touched.address1
                            ? Formik?.errors.address1
                            : null
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="address2"
                        placeholder="Address Line 2"
                        value={Formik.values.address2}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.address2 && Formik.touched.address2
                            ? Formik?.errors.address2
                            : null
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <div className={classes.selectWrapper}>
                        <InputWithDropDown
                          dropdown={countryData}
                          width={'100%'}
                          placeholder="- Select a country -"
                          value={Formik?.values.country ? Formik?.values.country : "- Select a country -"}
                          onChange={(value) => setFieldValue("country", value)}
                          setDropdown={(value) => { setFieldValue("country", value); filterStates(value) }}
                        />
                        <p className={classes.errorMessage}>
                          {Formik?.errors.country ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                          {Formik?.errors.country}
                        </p>
                      </div>
                    </Col>
                    <Col xs={24}>
                      <div className={`${classes.selectWrapper} ${classes.stateSelect}`}>
                        <InputWithDropDown
                          dropdown={stateData}
                          width={'100%'}
                          placeholder="- Select a state -"
                          value={statee ? statee : "- Select a state -"}
                          setDropdown={(value) => { setFieldValue("state", value); setStatee(value) }}
                          onChange={(value) => setFieldValue("state", value)}
                        />
                        <p className={classes.errorMessage}>
                          {Formik.errors.state ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                          {Formik.errors.state}
                        </p>
                      </div>
                    </Col>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="city"
                        placeholder="City"
                        value={Formik.values.city}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.city && Formik.touched.city
                            ? Formik?.errors.city
                            : null
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <InputElement
                        width="100%"
                        type="text"
                        name="zip"
                        placeholder="ZIP Code"
                        value={Formik.values.zip}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.zip && Formik.touched.zip
                            ? Formik?.errors.zip
                            : null
                        }
                      />
                    </Col>
                    {/* <Col xs={24}>
                      <InputElement
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={Formik.values.phone}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                          Formik.errors.phone && Formik.touched.phone
                            ? Formik?.errors.phone
                            : null
                        }
                        width={'100%'}
                      />
                    </Col> */}
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.submitWrapper}>
            {
              Formik.errors.firstName || Formik.errors.lastName ||
                Formik.errors.country ||
                Formik.errors.state ||
                Formik.errors.city ||
                Formik.errors.address1 ||
                // Formik.errors.address2 ||
                Formik.errors.phone ||
                Formik.errors.zip ?
                <button disabled type='button' className={`${classes.submit} ${classes.disabledBtn}`} onClick={(evt) => handleSubmit(evt)}>
                  Save and Make Default Address
                </button>
                :
                <button type='button' className={classes.submit} onClick={(evt) => handleSubmit(evt)}>
                  Save and Make Default Address
                </button>
            }
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default AddDepositModal
