import React, { useState, useEffect } from 'react'
import classes from './BuyerAside.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

const MENU_PATH_COMPARE = {
  "profile": 'Account Settings',
  "inquiries": 'Quotes',
  "message": 'Message Center',
  "product-and-services": 'Product and Services Offered',
  "shipping-and-payment": 'Shipping and Payment Information',
  "orders": 'Orders',
  "invoices": 'Invoices',
  "invoice-payment": 'Invoices',
  "payment-complete": 'Invoices',
}

const BuyerAside = ({ handleSignOut }) => {
  const history = useHistory()
  const location = useLocation()

  const [menuOpened, setMenuOpened] = useState(true);
  const [activeTab, setActiveTab] = useState(
    MENU_PATH_COMPARE[location.pathname.split('/').reverse()[0]]
  )

  // letlocation.pathname.split("/").reverse()[0]

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMenuOpened(true)
    } else {
      setMenuOpened(false)
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 992) {
        setMenuOpened(true)
      }
    })

    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth >= 992) {
          setMenuOpened(true)
        }
      })
    }
  }, [])

  // useEffect(() => {}, [])

  const handleMenuClick = (e, path) => {
    history.push(`${path}`)

    if (window.innerWidth <= 991) {
      setMenuOpened(false)
    }

    const key = e.target.innerHTML
    setActiveTab(key)
  }

  useEffect(() => {
    setActiveTab(MENU_PATH_COMPARE[location.pathname.split('/').reverse()[0]])

    return () => {

    }
  }, [window.location.pathname])


  return (
    <>
      <button
        className={
          menuOpened
            ? clsx([classes.mobileDrop, classes.mobileDropActive])
            : classes.mobileDrop
        }
        onClick={() => setMenuOpened((prev) => !prev)}
      >
        <h2>{activeTab}</h2>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.0001 4.80005L8.0001 8.80005L12.0001 4.80005L13.6001 5.60005L8.0001 11.2L2.4001 5.60005L4.0001 4.80005Z"
            fill="#303030"
          />
        </svg>
      </button>
      <div className={classes.wrapper}>
        <aside
          className={classes.actionSection}
          style={!menuOpened ? { display: 'none' } : { display: 'block' }}
        >
          <div className={classes.actionSectionTop}>
            <button
              onClick={(evt) =>
                handleMenuClick(evt, '/dashboard/buyer/inquiries')
              }
              type="primary"
              className={clsx([classes.actionButton, activeTab === "Quotes" && classes.active])}
            >
              Quotes
            </button>
            <button
              className={clsx([classes.actionButton, activeTab === "Message Center" && classes.active])}
              onClick={(evt) => {
                handleMenuClick(evt, '/dashboard/message');
                history.push('/dashboard/message')
              }}
            >
              Message Center
            </button>
            <button
              className={clsx([classes.actionButton, activeTab === "Orders" && classes.active])}
              onClick={(evt) => {
                handleMenuClick(evt, '/dashboard/buyer/orders');
                history.push('/dashboard/buyer/orders')
              }}
            >
              Orders
            </button>
            <button
              className={clsx([classes.actionButton, activeTab === "Invoices" && classes.active])}
              onClick={(evt) => {
                handleMenuClick(evt, '/dashboard/buyer/invoices');
                history.push('/dashboard/buyer/invoices')
              }}
            >
              Invoices
            </button>
          </div>
          <button
            className={clsx([classes.actionButton, activeTab === "Account Settings" && classes.active])}
            onClick={(evt) =>
              handleMenuClick(evt, '/dashboard/buyer/profile')
            }
          >
            Account Settings
          </button>
          <button
            className={clsx([classes.actionButton, activeTab === "Shipping and Payment Information" && classes.active])}
            onClick={(evt) => {
              handleMenuClick(evt, '/dashboard/buyer/profile');
              history.push('/dashboard/buyer/shipping-and-payment')
            }}
          >
            Shipping and Payment Information
          </button>
          <button
            onClick={() => handleSignOut()}
            className={clsx([classes.actionButton])}
          >
            Log Out
          </button>
        </aside>
      </div>
    </>
  )
}

export default BuyerAside