import React, { useState } from 'react'
import classes from './BrowseProducts.module.scss'
import { Radio } from 'antd'
import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import clsx from 'clsx'
import upIndicator from '../../../assets/svg/up_indicator.svg'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'
import ProductCard from '../../../constant/ProductCard/ProductCard'
import MobileFIilterBy from './MobileFIilterBy'
import SortSelect from '../../../constant/public/SortSelect/SortSelect'
import Loading from '../../../constant/Loading/Loading'

const BrowseProducts = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const [totalProducts, setTotalProducts] = useState("");
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [supplierData, setSupplierData] = useState([])
  const [categoryFilterData, setCategoryFilterData] = useState([])
  const [valueFilterData, setValueFilterData] = useState([])
  const [currentCategory, setCurrentCategory] = useState([])
  const [currentValueBasedFilter, setCurrentValueBasedFilter] = useState([])
  const [filterQuantity, setFilterQuantity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([])
  const [showCategory, setShowCategory] = useState(true);
  const [showValueFilters, setShowValueFilters] = useState(true);
  const [showMinQuantity, setShowMinQuantity] = useState(true);
  const [page, setPage] = useState(1);
  const [showLocation, setShowLocation] = useState(true)
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("createdAt:desc");
  const [notableBrand, setNotableBrand] = useState("");
  const [locationFilterData, setLocationFileterData] = useState([
    { name: 'USA', slug: 'USA' },
    { name: 'Canada', slug: 'Canada' },
    { name: 'Mexico', slug: 'Mexico' },
    // { name: 'Turkey', slug: 'Turkey' },
  ])


  useEffect(() => {
    setSearchValue(location.search.split("?")[1]);
    setPage(1)

    // axios
    //   .post(`${process.env.REACT_APP_API_URL}/products/search?limit=${limit}&page=${page}&sortBy=${sort}`, { search: searchValue })
    //   .then((res) => {
    //     setSupplierData(res.data.results)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })


  }, [location.search]);


  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories`)
      .then(async (res) => {
        setLoading(false)

        let newCategoryFilterData = []
        await res.data.categories.map((item) => {
          newCategoryFilterData.push({
            name: item.name,
            slug: item._id,
            status: true,
            _id: item._id,
            checked: true,
          })
        })
        console.log(newCategoryFilterData)

        setCategoryFilterData(newCategoryFilterData)
      })
      .catch((err) => {
        console.log(err)
      })
    axios
      .get(`${process.env.REACT_APP_API_URL}/specializations?limit=50&page=1`)
      .then(async (res) => {
        setLoading(false);
        setValueFilterData(res.data.results)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])


  const handleFilterCategory = async (e) => {
    if (currentCategory.includes(e.target.value)) {
      setCurrentCategory(currentCategory.filter((i) => i !== e.target.value));
    } else {
      setCurrentCategory([...currentCategory, e.target.value]);
    }
  };

  const handleFilterValueBased = async (e) => {
    if (currentValueBasedFilter.includes(e.target.value)) {
      setCurrentValueBasedFilter(currentValueBasedFilter.filter((i) => i !== e.target.value));
    } else {
      setCurrentValueBasedFilter([...currentValueBasedFilter, e.target.value]);
    }
  };

  const handleFilterCountry = async (e) => {
    if (selectedCountry.includes(e.target.value)) {
      setSelectedCountry(selectedCountry.filter((i) => i !== e.target.value));
    } else {
      setSelectedCountry([...selectedCountry, e.target.value]);
    }
  };


  const handleFilterQuantity = (e) => {
    setFilterQuantity(e.target.value)
  }
  useEffect(() => {
    handleFilterProducts(currentCategory, filterQuantity, selectedCountry, 1, notableBrand);

    return () => {

    }
  }, [selectedCountry, currentCategory, filterQuantity, searchValue, sort, currentValueBasedFilter, notableBrand]);

  const handleFilterProducts = (category, qty, country, page, notableBrand) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/products/search?limit=12&page=${page}&sortBy=${sort}`, {
        "search": searchValue ? searchValue : "",
        "category": category,
        "country": country,
        "minimum_quantity": qty,
        "specialization": currentValueBasedFilter,
        "notableBrands": notableBrand
      })
      .then((res) => {
        setSupplierData(res.data.results);
        setTotalProducts(res.data.totalResults);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
  }

  const handleLoadMore = (category, qty, country, page, notableBrand) => {
    setLoadMoreLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/products/search?limit=12&page=${page}&sortBy=${sort}`, {
        "search": searchValue ? searchValue : "",
        "category": category,
        "country": country,
        "minimum_quantity": qty,
        "specialization": currentValueBasedFilter,
        "notableBrands": notableBrand
      })
      .then((res) => {
        // setSupplierData(res.data.results);
        // setSupplierData(res.data.results);
        setSupplierData([...supplierData, ...res.data.results]);
        setTotalProducts(res.data.totalResults);
        setLoadMoreLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadMoreLoading(false);
      })
  }

  useEffect(() => {
    if (totalProducts > supplierData?.length) {
      handleLoadMore(currentCategory, filterQuantity, selectedCountry, page, notableBrand);
    }

    return () => {

    }
  }, [page]);


  const handleUpdate = (cat, minQ, ctry, spec) => {
    setCurrentCategory(cat);
    setFilterQuantity(minQ);
    setSelectedCountry(ctry);
    setCurrentValueBasedFilter(spec);
  }

  const sortOptionsData = [
    {
      label: "Featured",
      value: "createdAt:desc"
    },
    {
      label: "$ Low to High",
      value: "price:asc"
    },
    {
      label: "$ High to Low",
      value: "price:desc"
    },
  ]

  return (
    <>
      <Navigation />
      <main className={`${classes.container} container`}>
        <div className={classes.sectionWrapper}>
          <div className={`${classes.section} ${classes.sidebar} ${classes.desktopSidebar}`}>
            <div className={classes.resultsHeader}>
              <h2>FILTER BY:</h2>
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowCategory(!showCategory)}>
                Category
                <img
                  src={upIndicator}
                  style={
                    showCategory
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  alt="Uplio"
                />
              </button>
              <div className="egl-radio-with-check">
                <div className="eachRadio">
                  <Radio
                    className="radio-input"
                    type="radio"
                    checked={currentCategory.length === 0}
                    onClick={() => setCurrentCategory([])}
                    id={""}
                    name={""}
                    value={[]}
                  >
                    All
                  </Radio>
                  {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                </div>
              </div>
              {showCategory ? (
                <div className="egl-radio-with-check">
                  {categoryFilterData.map((item) => {
                    return (
                      <div className="eachRadio">
                        <Radio
                          className="radio-input"
                          type="radio"
                          checked={currentCategory.includes(item.slug)}
                          onClick={handleFilterCategory}
                          id={item.name}
                          name={item.name}
                          value={item.slug}
                        >
                          {item.name}
                        </Radio>
                        {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowMinQuantity(!showMinQuantity)}>
                Minimum Quantity
                <img
                  style={
                    showMinQuantity
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  src={upIndicator}
                  alt="Uplio"
                />
              </button>
              {showMinQuantity ? (
                <Radio.Group
                  onChange={handleFilterQuantity}
                  value={filterQuantity}
                >
                  <div className={classes.qtyEachRadio}>
                    <Radio value="">All</Radio>
                    <div >0</div>
                  </div>
                  <div className={classes.qtyEachRadio}>
                    <Radio value={500}>500 units or less</Radio>
                    <div className={classes.totalProductsCount}>0</div>
                  </div>
                  <div className={classes.qtyEachRadio}>
                    <Radio value={1000}>
                      1,000 units or less
                    </Radio>
                    <div className={classes.totalProductsCount}>0</div>
                  </div>
                  <div className={classes.qtyEachRadio}>
                    <Radio value={10000}>
                      10,000 units or less
                    </Radio>
                    <div className={classes.totalProductsCount}>0</div>
                  </div>
                  <div className={classes.qtyEachRadio}>
                    <Radio value={10001}>
                      10,000 units or more
                    </Radio>
                    <div className={classes.totalProductsCount}>0</div>
                  </div>
                </Radio.Group>
              ) : null}
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowLocation(!showLocation)}>
                Location
                <img
                  style={
                    showLocation
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  src={upIndicator}
                  alt="Uplio"
                />
              </button>
              <div className="egl-radio-with-check">
                <div className="eachRadio">
                  <Radio
                    className="radio-input"
                    type="radio"
                    checked={selectedCountry.length === 0}
                    onClick={() => setSelectedCountry([])}
                    id={""}
                    name={""}
                    value={[]}
                  >
                    All
                  </Radio>
                  {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                </div>
              </div>
              {showLocation ? (
                <div className="egl-radio-with-check">
                  {locationFilterData.map((item) => {
                    return (
                      <div className="eachRadio">
                        <Radio
                          className="radio-input"
                          type="radio"
                          checked={selectedCountry.includes(item.slug)}
                          onClick={handleFilterCountry}
                          id={item.name}
                          name={item.name}
                          value={item.slug}
                        >
                          {item.name}
                        </Radio>
                        {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                      </div>
                    )
                  })}
                </div>
              ) : null}
              {/* {showLocation ? (
                <RadioGroup
                  radioTags={locationFilterData}
                  onChange={(evt) => setSelectedCountry(evt.target.value)}
                  value={selectedCountry} />
              ) : null} */}
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowValueFilters(!showValueFilters)}>
                Value-Based Filters
                <img
                  src={upIndicator}
                  style={
                    showValueFilters
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  alt="Uplio"
                />
              </button>
              <div className="egl-radio-with-check">
                <div className="eachRadio">
                  <Radio
                    className="radio-input"
                    type="radio"
                    checked={currentValueBasedFilter.length === 0}
                    onClick={() => setCurrentValueBasedFilter([])}
                    id={""}
                    name={""}
                    value={[]}
                  >
                    All
                  </Radio>
                  {/* <div className='totalProductsCount'>
                          0
                        </div> */}
                </div>
              </div>
              {showValueFilters ? (
                <div className={classes.valueBasedFilterSection}>
                  <div className="egl-radio-with-check">
                    {
                      valueFilterData.map((item) => {
                        return (
                          <div className="eachRadio">
                            <Radio
                              className="radio-input"
                              type="radio"
                              checked={currentValueBasedFilter.includes(item._id)}
                              onClick={handleFilterValueBased}
                              id={item.name}
                              name={item.name}
                              value={item._id}
                            >
                              {item.name}
                            </Radio>
                            {/* <div className='totalProductsCount'>
                              0
                            </div> */}
                          </div>
                        )
                      })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className={`${classes.section} ${classes.rightSection}`}>
            <p>{searchValue && `${searchValue}:`} {totalProducts} Products</p>
            <div className={`${classes.sortSection} ${classes.mobileSortSection}`}>
              <MobileFIilterBy handleUpdate={handleUpdate} sort={sort} handleSort={(val) => setSort(val)} />
            </div>
            <div className={`${classes.sortSection} ${classes.desktopSortSection}`}>
              <h5>Products:</h5>
              <h5>Filter By:</h5>
              <div className={classes.sortDropdownSection}>
                <SortSelect setValue={(val) => setSort(val)} optionsData={sortOptionsData} />
              </div>
            </div>
            <div className={classes.productCardBottom}>
              {
                supplierData.length > 0
                  ?
                  supplierData.map((item, idx) => {
                    return (
                      <div style={{ minWidth: "max-content" }}>
                        <ProductCard data={item} key={item} handleBrand={(val) => setNotableBrand(val)} />
                      </div>
                    )
                  })
                  : null
              }
            </div>
            <div className={classes.loadMore}>
              {
                loadMoreLoading ?
                  <Loading />
                  :
                  <button disabled={supplierData.length === totalProducts} onClick={async () => setPage(page + 1)}>
                    <h3>Load More Products</h3>
                  </button>
              }
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default BrowseProducts;
