import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import classes from "./RecentlyViewed.module.scss"
import { SwiperSlide, Swiper } from 'swiper/react';
import ProductCard from '../../ProductCard/ProductCard';
import "swiper/css";
import LeftArrowSlider from "../../../assets/images/left.png"
import RightArrow from "../../../assets/images/right.png"

const RecentlyViewed = (props) => {
    const [recentlyViewedProductsList, setRecentlyViewedProductsList] = useState([]);
    const [swiperTwo, setSwiperTwo] = useState(null);
    const [current, setCurrent] = useState(0);
    const user = useSelector((state) => state.user.user)
    const token = sessionStorage.getItem('token');


    const getRecentlyViewedProducts = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/users/${user.id}/products/recently-viewed?limit=12&page=1&sortBy=createdAt:desc`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
            .then((res) => {
                // setSupplierData(res.data.results);
                
                setRecentlyViewedProductsList(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    useEffect(() => {
        if (user !== null && user !== undefined && user) {
            getRecentlyViewedProducts();
        } else {
            setRecentlyViewedProductsList(JSON.parse(sessionStorage.getItem('products')));
        }
        return () => {

        }
    }, [])


    return (
        recentlyViewedProductsList && recentlyViewedProductsList?.length > 0 &&
        <div className={`${classes.similarProducts} similarProducts`}>
            <div className={classes.topBar}>
                <div className={classes.title}>Recently Viewed</div>
                <div className={classes.btnContainer}>
                    <button className='leftArrow' >
                        <img src={LeftArrowSlider} alt="Left arrow" onClick={() => swiperTwo?.slidePrev()} />
                    </button>
                    <button className='rightArrow'>
                        <img src={RightArrow} alt="Right arrow" onClick={() => swiperTwo?.slideNext()} />
                    </button>
                </div>
            </div>
            <div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    loop={false}
                    // loop={props.cartPage ? recentlyViewedProductsList?.length > 3 : recentlyViewedProductsList?.length > 4 ? true : false}
                    loopAdditionalSlides={false}
                    dots={false}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 60,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 60,

                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                            loop: false
                            // loop: props.cartPage ? recentlyViewedProductsList?.length > 3 : recentlyViewedProductsList.length > 4 ? true : false
                        },
                    }}
                    slideClass={classes.slide}
                    onSwiper={setSwiperTwo}
                    onSlideChange={(e) => setCurrent(e.realIndex)}
                >
                    {
                        recentlyViewedProductsList && recentlyViewedProductsList?.length > 0 && recentlyViewedProductsList?.map((item) => {
                            return (
                                <SwiperSlide key={item?._id} className={classes.slide}>
                                    <div>
                                        <ProductCard data={item} key={item} handleBrand={(val) => console.log(val)} />
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div >
    )
}

export default RecentlyViewed
