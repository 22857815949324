import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { Col, Row, notification } from 'antd'

import classes from './ForgotPassword.module.scss'
import * as yup from 'yup'
import { ref } from 'yup'
import {
  userLoggedIn,
  userDataStatus
} from '../../../redux/actions/user.action.js'
import { connect } from 'react-redux'
import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import InputElement from '../../../constant/public/Input/InputElement'
import ButtonWithRightArrow from '../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'

const ForgotPassword = () => {
  const location = useLocation()
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  })

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])

  const history = useHistory()
  const Formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      password: yup
        .string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
        //   'Invalid Format'
        // )
        .required('Password is required.'),
      confirmPassword: yup
        .string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
        //   'Invalid Format'
        // )
        .oneOf([ref('password')], 'Passwords do not match')
        .required('Password is required.'),
    }),
  })

  const [loader, setLoader] = useState(false)

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  const handleError = (data) => {
    if (data.message === 'You are not registered with us') {
      history.push({ pathname: '/signup' })
    }
  }

  const handleForgotPassword = (password) => {
    setLoader(true)
    Formik.handleSubmit();
    // dispatch(postUserLogin(user))
    if (Formik.values.password && Formik.values.confirmPassword)
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/reset-password${location.search}`, {
          password: password
        })
        .then((res) => {
          setLoader(false)
          console.log(res);
          if (!res.data.error) {
            openNotificationWithIcon('success', 'Password changed successfully', "");
            setTimeout(() => {
              history.push({ pathname: '/login' })
            }, 2000);
          } else {
            openNotificationWithIcon('warning', 'Oops', res.data.message)
            handleError(res.data)
          }
        })
        .catch((err) => {
          setLoader(false)
          openNotificationWithIcon('error', err.response?.data?.message, '')
        })
  }

  return (
    <>
      <Navigation />
      <div className={classes.sideTitle}>
        <h2>CHANGE PASSWORD</h2>
        <h4>CHANGE PASSWORD</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <h3>New Password*</h3>
          <InputElement
            width="100%"
            name="password"
            type="password"
            value={Formik.values.password}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            placeholder="Password"
            helperText={
              Formik.errors.password && Formik.touched.password
                ? Formik?.errors.password
                : null
            }
          />
          <h3>Confirm New Password*</h3>
          <InputElement
            name="confirmPassword"
            type="password"
            placeholder="Password"
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            helperText={
              Formik.errors.confirmPassword &&
                Formik.touched.confirmPassword
                ? Formik?.errors.confirmPassword
                : null
            }
            value={Formik.values.confirmPassword}
            width={'100%'}
          />

          <Row>
            <Col lg={12} md={12} sm={0} xs={0}></Col>
            <Col lg={12} md={12} sm={24} xs={24} align="right">
              <div className={classes.actionButton}>
                {
                  Formik.errors.password ||
                    Formik.errors.confirmPassword ?
                    <ButtonWithRightArrow
                      disabled
                      type="button"
                      content="SET PASSWORD"
                    />
                    :
                    <ButtonWithRightArrow
                      type="button"
                      content="SET PASSWORD"
                      function={() =>
                        handleForgotPassword(Formik.values.password)
                      }
                    />
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedIn: () => dispatch(userLoggedIn()),

    userDataStatus: () => dispatch(userDataStatus()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
