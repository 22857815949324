import React, { useState } from 'react'
import classes from './InputElement.module.scss'
import eye from '../../../assets/svg/akar-icons_eye.svg'
import clsx from 'clsx'
import errorIcon from '../../../assets/svg/error_icon.svg'
const InputElement = (props) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {props.type !== 'password' ? (
        <div className={clsx(props.wrapperClass, classes.inputWrapper)}>
          <input
            name={props.name}
            type={props.type !== undefined ? props.type : 'text'}
            style={{ width: `${props.width}` }}
            className={clsx(props.className, classes.input)}
            onBlur={props.onBlur}
            value={props.value}
            onClick={props.onClick}
            onChange={props.onChange}
            placeholder={props.placeholder}
            required={props.required ? true : false}
          />
          {
            props.bottomText &&
            <p className={classes.bottomText}>{props?.bottomText}</p>
          }
          <p className={classes.errorMessage}>
            {props.helperText ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
            {props.helperText}
          </p>
        </div>
      ) : (
        <div className={classes.inputWrapper}>
          <div className={classes.inputContainer}>
            <input
              name={props.name}
              className={classes.inputPassword}
              type={visible ? 'text' : 'password'}
              style={{ width: `${props.width}` }}
              onBlur={props.onBlur}
              value={props.value}
              onChange={props.onChange}
              placeholder={props.placeholder}
            />
            <button type='button' onClick={() => setVisible(!visible)}>
              <img
                className={visible ? classes.icon : classes.iconVisible}
                src={eye}
                alt="Uplio"
              />
            </button>
          </div>
          <p className={classes.errorMessage}>
            {props.helperText ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
            {props.helperText}
          </p>
        </div>
      )}
    </>
  )
}
export default InputElement