import React, { useState, useEffect } from 'react'
import classes from './Orders.module.scss'
import clsx from 'clsx'
import axios from 'axios'
import { useSelector } from 'react-redux'
import OrdersTable from '../../../common/OrdersTable/OrdersTable'
import Loading from '../../../../constant/Loading/Loading'

const Orders = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [originalOrders, setOriginalOrders] = useState([]);
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    if (totalOrders > orders.length) {
      setLoadMoreLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/supplier/${user?.id}/orders?limit=10&page=${page}&status=${status === 6 ? "6, 2" : status}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          
          setOrders(prevArray => [...prevArray, ...res.data?.results]);
          // setOriginalOrders(res.data?.results);
          setOriginalOrders(prevArray => [...prevArray, ...res.data?.results]);
          setTotalOrders(res.data?.totalResults);
          setLoadMoreLoading(false);
        })
        .catch((err) => {
          setLoadMoreLoading(false);
          console.log(err.response)
        })
    }

    return () => {

    }
  }, [page]);

  const getAllOrders = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supplier/${user?.id}/orders?limit=10&page=${1}&status=${status === "6" ? "6,2" : status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        
        setOrders(res.data?.results);
        setOriginalOrders(res.data?.results);
        setTotalOrders(res.data?.totalResults);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response)
      })
  }


  useEffect(() => {
    getAllOrders();

    return () => {

    }
  }, [status]);

  console.log(orders);


  return (
    <div className={classes.wrapper}>
      <div className={classes.tabs}>
        <button
          type="button"
          className={clsx([
            classes.tab,
            `${activeTab === 'all' ? classes.activeTab : null}`,
          ])}
          onClick={() => { setActiveTab('all'); setStatus("") }}
        >
          All
        </button>
        <button
          type="button"
          className={clsx([
            classes.tab,
            `${activeTab === 'pending' ? classes.activeTab : null}`,
          ])}
          onClick={() => { setActiveTab('pending'); setStatus("6") }}
        >
          Pending
        </button>
        <button
          type="button"
          className={clsx([
            classes.tab,
            `${activeTab === 'shipped' ? classes.activeTab : null}`,
          ])}
          onClick={() => { setActiveTab('shipped'); setStatus("7") }}
        >
          Shipped
        </button>
        <button
          type="button"
          className={clsx([
            classes.tab,
            `${activeTab === 'cancelled' ? classes.activeTab : null}`,
          ])}
          onClick={() => { setActiveTab('cancelled'); setStatus("12") }}
        >
          Cancelled
        </button>
      </div>
      {
        loading ?
          <Loading />
          :
          (
            activeTab === 'all' ? (
              <OrdersTable update={getAllOrders} buyer={true} data={orders} />
            )
              :
              activeTab === 'pending' ? (
                <OrdersTable update={getAllOrders} buyer={true} data={orders} />
              )
                : activeTab === 'shipped' ? (
                  <OrdersTable update={getAllOrders} buyer={true} data={orders} />
                ) : activeTab === 'cancelled' ? (
                  <OrdersTable update={getAllOrders} buyer={true} data={orders} />
                ) : null
          )
      }
      <div className={classes.loadMore}>
        {
          loadMoreLoading ?
            <Loading />
            :
            <button onClick={async () => setPage(page + 1)}>
              Load Older
            </button>
        }
      </div>
    </div>
  )
}
export default Orders
