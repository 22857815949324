import React, { useEffect, useState } from 'react'
import classes from "./CartItem.module.scss"
import downArrow from "../../../assets/images/downArrow.svg"
import { Modal } from 'antd';
import TextInput from '../TextInput/TextInput';
import clsx from 'clsx';
import "./ViewComment.css"
import closeIcon from "../../../assets/svg/Cross.svg"


const CartItem = ({ product, handleUpdateQty, handleRemoveProduct, handleUpdateComment, summary }) => {
    const [qty, setQty] = useState(product.quantity);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [commentValue, setCommentValue] = useState(product.comments);
    const [isMobile, setIsMobile] = useState(
        window.innerWidth > 767 ? false : true
    )

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            } else {
                setIsMobile(true)
            }
        })

        return () => {
            window.removeEventListener('resize', () => {
                if (window.innerWidth > 991) {
                    setIsMobile(false)
                } else {
                    setIsMobile(true)
                }
            })
        }
    }, [])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (
        <div className={classes.itemContainer}>
            <div>
                <div className={classes.item}>
                    <img src={product?.images[0]?.url} alt="Cross" />
                    <div style={{ flexGrow: "1" }}>
                        <div className={classes.priceContainer}>
                            <h4>{product?.name}</h4>
                        </div>
                        {
                            product?.modifications && product?.modifications.length > 0 && product?.modifications.map(modification => {
                                return (<p> {modification.name}: {modification?.options[0].value}</p>)
                            }
                            )
                        }
                        {
                            summary ?
                                <p>QTY: {product?.quantity}</p>
                                :
                                <p>Price: ${product?.price}</p>
                        }
                        {
                            summary && isMobile &&
                            <h4 style={{ textAlign: "start", marginTop: "11px" }} className={classes.bold}>${Math.round(product.price * product.quantity * 100 / 100).toFixed(2)}</h4>
                        }
                        {
                            !summary &&
                            <div className={`${classes.right} ${classes.rightMobile}`}>
                                <div style={{ textAlign: "end" }}>
                                    <h4 className={classes.bold}>${Math.round(product.price * product.quantity * 100 / 100).toFixed(2)}</h4>
                                </div>
                                <div>
                                    <div className={classes.qtyCon}>
                                        <div className={classes.qtyInner}>
                                            <div className={classes.numbersArrow}>
                                                <img src={downArrow} alt="arrow" onClick={() => { qty > product.minimum_quantity && setQty((parseInt(qty) - 1)); handleUpdateQty(product.uuid, (qty > product.minimum_quantity ? parseInt(qty) - 1 : qty)) }} className={classes.smallLeftArrow} />
                                                <p>{qty}</p>
                                                <img src={downArrow} alt="arrow" onClick={() => { setQty((parseInt(qty) + 1)); handleUpdateQty(product.uuid, (parseInt(qty) + 1)) }} className={classes.smallRightArrow} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={!summary && classes.right}>
                    {
                        !summary ?
                            <div>
                                <p>Quantity</p>
                                <div className={classes.qtyCon}>
                                    <div className={classes.qtyInner}>
                                        <p>{qty}</p>
                                        <div className={classes.numbersArrow}>
                                            <img src={downArrow} alt="arrow" onClick={() => { qty > product.minimum_quantity && setQty((parseInt(qty) - 1)); handleUpdateQty(product.uuid, (qty > product.minimum_quantity ? parseInt(qty) - 1 : qty)) }} className={classes.smallLeftArrow} />
                                            <img src={downArrow} alt="arrow" onClick={() => { setQty((parseInt(qty) + 1)); handleUpdateQty(product.uuid, (parseInt(qty) + 1)) }} className={classes.smallRightArrow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        summary ? (!isMobile ?
                            <div style={{ textAlign: "end" }}>
                                <p>Cost</p>
                                <h4 className={classes.bold}>${Math.round(product.price * product.quantity * 100 / 100).toFixed(2)}</h4>
                            </div>
                            :
                            null
                        )
                            :
                            <div style={{ textAlign: "end" }}>
                                <p>Cost</p>
                                <h4 className={classes.bold}>${Math.round(product.price * product.quantity * 100 / 100).toFixed(2)}</h4>
                            </div>
                    }
                </div>
            </div>



            {
                !summary &&
                <div className={classes.footer}>
                    {
                        product?.customization_option === "Allowed" && <button onClick={showModal}>VIEW COMMENT</button>
                    }
                    {
                        product?.customization_option === "Not allowed" && <button>  </button>
                    }
                    <Modal centered className='viewCommentModal' closeIcon={<img src={closeIcon} alt="Cross Icon" />} title={false} footer={false} visible={isModalOpen} onCancel={handleCancel}>
                        <h4>Your comment for the order</h4>
                        <TextInput
                            name="comment"
                            type="text"
                            className={clsx([`input`, classes.companyInput])}
                            wrapperClass={classes.companyInput}
                            placeholder="Write your wishes for the order"
                            onChange={(e) => { setCommentValue(e.target.value); handleUpdateComment(product.uuid, e.target.value) }}
                            value={commentValue}
                        />
                    </Modal>
                    <button onClick={() => handleRemoveProduct(product.uuid)}>REMOVE</button>
                </div>

            }
        </div>
    )
}

export default CartItem
