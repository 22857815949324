import { Modal } from 'antd';
import React, { useState } from 'react'
import pencil from "../../../../../assets/svg/pencil.svg"
import cross from "../../../../../assets/svg/Cross.svg"
import "./EditModal.css"
import EditProductsModalComp from './EditProductsModel';

const EditIt = ({ productId, handleUpdate, product }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getProductId, setGetProductId] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
        setGetProductId(productId)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            {
                product.status === 1 ?
                    <button disabled className='productEditBtnDisabled' onClick={showModal}>
                        <img src={pencil} alt="pencil" />
                    </button>
                    :
                    <button onClick={showModal}>
                        <img src={pencil} alt="pencil" />
                    </button>
            }
            <Modal destroyOnClose title={false} footer={false} className="productEditModal" closeIcon={<img src={cross} alt="Cross" />} visible={isModalOpen} onCancel={handleCancel}>
                <EditProductsModalComp productId={getProductId} handleUpdate={handleUpdate} closeModal={handleCancel} />
            </Modal>
        </div >
    )
}

export default EditIt
