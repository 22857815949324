import React, { useEffect, useState } from 'react'
import classes from './EditModal.module.scss'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../../constant/public/Input/InputElement'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Col, Row } from 'antd'
import { OpenNotificationWithIcon } from '../../../../../constant/public/Notification/Notification'
import Loading from '../../../../../constant/Loading/Loading'

const EditAddressModal = ({
  modalOpened,
  setModalOpened,
  handleUpdate,
  methodObj
}) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token');


  const Formik = useFormik({
    initialValues: {
      accountNumber: '',
      routingNumber: '',
      accountHolderName: '',
      bankName: '',
      bankLocation: ''
    },
    validationSchema: yup.object({
      accountNumber: yup
        .string()
        .required('Account Number is Required'),
      routingNumber: yup
        .string()
        .required('Routing Number is Required'),
      accountHolderName: yup
        .string()
        .required('Name is Required'),
      bankName: yup
        .string()
        .required('Bank Name is Required'),
      bankLocation: yup
        .string()
        .required('Bank Location is Required'),
    }),
  })
  const { setFieldValue } = Formik;


  useEffect(() => {
    getMethodById()
    return () => {

    }
  }, [methodObj]);

  const getMethodById = () => {
    setLoading(true);
    console.log(methodObj)
    if (methodObj) {
      setFieldValue('accountNumber', `********${methodObj?.last4}`)
      setFieldValue('routingNumber', methodObj?.routing_number)
      setFieldValue('accountHolderName', methodObj?.account_holder_name)
      setFieldValue('bankName', methodObj?.bank_name)
      setFieldValue('bankLocation', methodObj?.country);
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
  }

  const handleSubmit = (id) => {
    Formik.handleSubmit();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${user?.id}/stripe/connect-account-bank`,
        {
          accountNumber: Formik.values.accountNumber,
          routingNumber: Formik.values.routingNumber,
          accountHolderName: Formik.values.accountHolderName,
          // bankName: Formik.values.bankName,
          // bankLocation: Formik.values.bankLocation
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setModalOpened(false);
        OpenNotificationWithIcon("success", "Deposit Method Saved", "");
        handleUpdate();
        Formik.resetForm();
      })
      .catch((err) => {
        console.log(err.response);
        OpenNotificationWithIcon("error", err.response?.data?.message, "Error");
      })
  }


  return (
    <CommonModal
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      title={'EDIT method №1'}
    >
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <div>
            <div className={classes.mainContent}>
              {
                loading ?
                  <Loading />
                  :
                  <div className={classes.form}>
                    <div className={classes.inner}>
                      <h6>Bank Information</h6>
                      <Row className={classes.row} gutter={[24, 24]}>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="accountNumber"
                            placeholder="Account Number"
                            value={Formik.values.accountNumber}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.accountNumber && Formik.touched.accountNumber
                                ? Formik?.errors.accountNumber
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="routingNumber"
                            placeholder="Routing Number "
                            value={Formik.values.routingNumber}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.routingNumber && Formik.touched.routingNumber
                                ? Formik?.errors.routingNumber
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="accountHolderName"
                            placeholder="Account Holder Name "
                            value={Formik.values.accountHolderName}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.accountHolderName && Formik.touched.accountHolderName
                                ? Formik?.errors.accountHolderName
                                : null
                            }
                          />
                        </Col>
                        {/* <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="bankName"
                            placeholder="Bank Name"
                            value={Formik.values.bankName}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.bankName && Formik.touched.bankName
                                ? Formik?.errors.bankName
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="bankLocation"
                            placeholder="Bank Location"
                            value={Formik.values.bankLocation}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.bankLocation && Formik.touched.bankLocation
                                ? Formik?.errors.bankLocation
                                : null
                            }
                          />
                        </Col> */}
                      </Row>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className={classes.submitWrapper}>
            {
              Formik.errors.accountNumber ||
                Formik.errors.routingNumber ||
                Formik.errors.accountHolderName
                ?
                <button disabled type='button' className={`${classes.submit} ${classes.disabledBtn}`} onClick={(evt) => handleSubmit(evt)}>
                  EDIT DEPOSIT METHOD
                </button>
                :
                <button type='button' className={classes.submit} onClick={(evt) => handleSubmit(evt)}>
                  EDIT DEPOSIT METHOD
                </button>
            }
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default EditAddressModal
