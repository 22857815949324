import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userDataStatus, userLoggedOut } from '../../redux/actions/user.action';
import PublicRoutes from './PublicRoutes'
//TODO Switch to render protected and unprotected routes.
export default function MainLayout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      handleSignOut();
    }

    return () => {

    }
  }, [])
  return (
    <>
      <PublicRoutes />
    </>
  )
}
