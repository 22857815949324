import React from 'react';
import './popup.scss';

const Popup = ({ isOpened, setOpened, title, text, btnText }) => {
  const handlePopupClose = () => {
    document.documentElement.classList.remove('is-locked');

    setOpened(false);
  };

  return (
    <div className={`popup ${isOpened ? 'popup_opened' : null}`}>
      {title && <h4 className="title title_item-s popup__title">{title}</h4>}
      {text && (
        <div className="popup__text">
          <p>{text}</p>
        </div>
      )}
      <div className="popup__btn-wrapper">
        <button
          type="button"
          className="button popup__btn"
          onClick={handlePopupClose}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default Popup;
