import React, { useState, useEffect } from 'react'
import classes from './OrderItem.module.scss'
import closeBtn from '../../../../../../assets/svg/Cross.svg'
import clsx from 'clsx'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'

const OrderItem = (props) => {
  const history = useHistory();
  const [moreInfoOpened, setMoreInfoOpened] = useState(false)
  const [infoModalOpened, setInfoModalOpened] = useState(false)
  const [isAnswered, setIsAnswered] = useState(false)
  const [isPassed, setIsPassed] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const user = useSelector((state) => state.user.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState({});

  const showModal = (val) => {
    setResponse(val)
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(props.data)

  useEffect(() => {
    const isAnswered = props.modalsAnswered.find((item) => item.id === props.id)

    if (isAnswered) {
      setIsAnswered(true)
    }
  })

  if (isHidden) {
    return null
  } else if (props.data.supplierCanUndoResponse) {
    return (
      <li className={classes.quotesItem} data-id={props.id}>
        <div className={classes.request}>
          <p>You have declined the request</p>
          <button
            type="button"
            className={classes.requestBtn}
            onClick={() => props.passLead(props._id)}
          >
            Undo
          </button>
          <button
            type="button"
            className={classes.requestClose}
            onClick={() => setIsHidden(true)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="#303030"
              />
            </svg>
          </button>
        </div>
      </li>
    )
  } else {
    return (
      <li className={classes.quotesItem} data-id={props.id}>
        <div className={classes.mainInfo}>
          <p className={classes.subtitle}>
            Product: <span>{props.data?.productsString?.name}</span>
          </p>
          {/* <p className={classes.subtitle}>
            Launch Date: <time>{moment(props.data.projectLaunchDate).format('DD-MM-YYYY')}</time>
          </p>
          <p className={classes.subtitle}>
            MOQ: <span>{props.data.quantity}</span>
          </p> */}
        </div>
        <div className={classes.about}>
          <p className={classes.subtitle}>Customization Request:</p>
          <p className={classes.text}>{props.data?.productsString?.comments}</p>
        </div>
        <div className={classes.buttons}>
          <div className={classes.buttonsWrapper}>
            <button
              type="button"
              className={clsx([`button`, classes.answerButton])}
              onClick={() => history.push({ pathname: `/dashboard/message`, state: { targetUser: props.data?.buyer?._id, contextId: props.data?._id, contextType: 2 } })}
            >
              MESSAGE BUYER
            </button>
            {
              // props.data?.supplierResponse?.supplier !== user._id &&
              <button
                type="button"
                className={classes.passButton}
                onClick={() => { setIsPassed(true); props.passLead(props.data?._id) }}
              >
                Pass
              </button>
            }
            <Modal centered title="View Answer" closeIcon={<img src={closeBtn} alt="close icon" />} visible={isModalOpen} footer={false} onCancel={handleCancel}>
              <div className={classes.modalText}>
                <p className={classes.subtitle}>Min Price:</p>
                <p className={classes.text}>{props.data?.supplierResponse?.minPrice}</p>
                <p className={classes.subtitle}>Max Price:</p>
                <p className={classes.text}>{props.data?.supplierResponse?.maxPrice}</p>
                <p className={classes.subtitle}>Time:</p>
                <p className={classes.text}>{moment(props.data?.supplierResponse?.responseDatetime).format('DD-MM-YYYY')}</p>
              </div>
            </Modal>
          </div>
        </div>
      </li>
    )
  }
}

export default OrderItem
