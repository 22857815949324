import React, { useState, useEffect } from 'react'
import classes from './BuyerName.module.scss'
import clsx from 'clsx'

const BuyerName = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [items, setItem] = useState(props.dropdown)
  const [value, setValue] = useState("");
  const toggleDropdown = () => {
    setOpen(!isOpen);
  }

  useEffect(() => {
    setItem(props?.dropdown);
    setValue(props.value);
  }, [props.dropdown]);

  const handleItemClick = (label, id, item) => {
    console.log(item);
    setValue(label);
    // props.onClick();
    toggleDropdown();
    props.setDropdown(label);
    props.onChange({ quoteNumber: item?.quoteNumber, address: item?.address, quoteId: item.quoteId });
  }

  console.log("items", items)

  return (
    <div>
      <div className={classes.dropdown} onClick={props.onClick}>
        <div
          className={classes.dropdownHeader}
          style={{ width: props.width }}
          onClick={toggleDropdown}
        >
          {
            value === undefined || value === null || value === "" ?
              <h3 className={classes.placeholder}>
                {props?.placeholder}
              </h3>
              :
              <h3>
                {value}
              </h3>
          }
          <h3 className={classes.stateIcon}>
            {isOpen ? (
              <>
                <i className={clsx(classes.arrow, classes.up)}></i>
              </>
            ) : (
              <i className={clsx(classes.arrow, classes.down)}></i>
            )}
          </h3>
        </div>
        {/* } */}
        {
          !props.noDropdown &&
          <div
            style={props.categories && { marginLeft: "35.1%" }}
            className={clsx({
              [`${classes.dropdownBody} BuyerNameBody`]: true,
              [classes.open]: isOpen,
            })}
          // id="inputElement"
          >
            {items?.map((item) => (
              <button
                key={item.id}
                className={classes.dropdownItem}
                onClick={() => handleItemClick(item.label, item.id, item)}
                id={item.id}
              >
                <span
                  className={`dropdown-item-dot ${item.id === value && 'selected'}}`}
                >
                  <span>
                    {item?.label}
                  </span>
                  <span>
                    ID #{item?.quoteNumber}
                  </span>
                </span>
              </button>
            ))}
          </div>
        }
      </div>
    </div >
  )
}

export default BuyerName
