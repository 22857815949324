import React from 'react'

import Leads from './Leads/Leads'
import Navigation from '../../../constant/public/Navigation/Navigation'

const Dashboard = () => {
  return (
    <>
      <Navigation />
      <Leads />
      {/* <Messages /> */}
      {/* <Services /> */}
      {/* <Orders /> */}
      {/* <Profile/> */}
    </>
  )
}
export default Dashboard
