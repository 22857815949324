import { Select } from 'antd';
import React, { useState } from 'react'
import classes from "./SortSelect.module.scss"
import downArrow from "../../../../../assets/images/downArrow.svg"
import "./SortSelect.css"


const { Option } = Select;

const SortSelect = ({ setValue, optionsData, value }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.sortDropdownSection}>
            <Select
                className={`${classes.sortSelect} sortSelect invoicesSelect`}
                // defaultValue={optionsData[0]?.value}
                bordered={false}
                placement="bottomLeft"
                onClick={() => setOpen(!open)}
                onBlur={() => setOpen(false)}
                onSelect={() => setOpen(!open)}
                dropdownClassName="sortSelect invoicesSort"
                onChange={setValue}
                suffixIcon={!open ? <img src={downArrow} alt="Down Arrow" /> : <img src={downArrow} className={classes.downArrow} alt="Down Arrow" />}
            >
                {
                    optionsData && optionsData?.length > 0 && optionsData?.map(opt => {
                        return (
                            <>
                                <Option value={opt.value}>
                                    <span className={
                                        opt?.label === "Fully paid" ?
                                            classes.statusBox1
                                            :
                                            opt?.label === "Partially paid" ?
                                                classes.statusBox2
                                                :
                                                opt?.label === "Pending payment" ?
                                                    classes.statusBox3
                                                    :
                                                    opt?.label === "All" &&
                                                    null
                                    }></span>
                                    {opt.label}
                                </Option>
                            </>
                        )
                    })
                }
                {/* <Option value="price:asc">$ Low to High</Option>
                <Option value="price:desc">$ High to Low</Option> */}
            </Select>
        </div >
    )
}

export default SortSelect
