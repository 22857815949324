import { Select } from 'antd';
import React, { useState } from 'react'
import classes from "./StatusSelect.module.scss"
import downArrow from "../../../../assets/images/downArrow.svg"
import "./StatusSelect.css"


const { Option } = Select;

const StatusSelect = ({ setValue, optionsData, value }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.sortDropdownSection}>
            <Select
                className={`${classes.sortSelect} sortSelect statusSelect`}
                // defaultValue={optionsData[0]?.value}
                bordered={false}
                placement="bottomLeft"
                onClick={() => setOpen(!open)}
                onBlur={() => setOpen(false)}
                onSelect={() => setOpen(!open)}
                dropdownClassName={"sortSelect statusSelect"}
                onChange={setValue}
                value={value}
                placeholder="Select Status"
                defaultValue={"Select Status"}
                suffixIcon={!open ? <img src={downArrow} alt="Down Arrow" /> : <img src={downArrow} className={classes.downArrow} alt="Down Arrow" />}
            >
                {
                    optionsData && optionsData?.length > 0 && optionsData?.map(opt => {
                        return (
                            <Option value={opt.value}>
                                <button className='redBox'></button>{opt.label}
                            </Option>
                        )
                    })
                }
            </Select>
        </div>
    )
}

export default StatusSelect
