import React from 'react'

import classes from './TermsAndConditions.module.scss'

import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'

const TermsAndConditions = () => {
  return (
    <>
      <Navigation />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>Terms of Service</h1>
          <h3>
            Last Updated: Feb 23, 2022
            <br />
            Uplio, Inc. (“Uplio”) is an online marketplace connecting buyers
            with manufacturers, accessible through its websites (collectively,
            the “Site”). Throughout these Terms of Service (“Terms”), the terms
            “Uplio”, “our”, “us”, and/or “we” refer to Uplio. The terms “you”
            and/or “your” refer to any visitor of the Site and any user of the
            Services, including any Member (as defined below). These Terms
            govern your access to and use of the Services and constitute a
            binding legal agreement between you and Uplio.
            <br />
            The Services are offered and available solely to users who are 18 or
            older. By using the Services, you represent and warrant that you are
            of legal age to form a binding contract with Uplio and meet the
            foregoing eligibility requirements. If you do not meet these
            requirements, you must not access or use the Services. If you are
            entering into these Terms on behalf of a company or other legal
            entity, you represent and warrant that you have the authority to
            bind that party to these Terms and, in such event, “you” and “your”
            will refer and apply to that party.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>1.Accessing the service.</h2>
          <h3>
            We grant you permission to access and use the Services subject to
            the obligations, conditions, and restrictions set out in these
            Terms. It is a condition of your use of the Services that the
            information you provide is correct, current, and complete. Your use
            of the Services is at your own risk, including the risk that you may
            be exposed to content that is inaccurate, objectionable, or
            otherwise inappropriate. You are responsible for making all
            arrangements necessary for you to access Services.
            <br />
            <br />
            a) Account Registration and Account Security.
            <br />
            <br />
            Some or all of the Website, and certain features or functionalities,
            may require you to register an account with us. When you do, we may
            ask you to provide certain registration details or other information
            about yourself, such as your name, email address, password, and
            other pieces of information. All such information is subject to our
            privacy practices detailed in our Privacy Policy. <br />
            <br />
            Once you have an account on the Website, you are a "Member" of our
            website portal. As a member, you are responsible for all activities
            that occur in connection with your account. You will treat as
            confidential your account access credentials and will not to
            disclose it to any third-party. You agree to immediately notify
            Uplio if you have any reason to believe that your account
            credentials have been compromised or if there is any unauthorized
            use of your account or password, or any other breach of security.
            <br />
            <br />
            You may not impersonate someone else to create an account, create or
            use an account for anyone other than yourself, permit anyone else to
            use your account, or provide personal information for purposes of
            account registration other than your own.
            <br />
            <br />
            In order to ensure we can protect and properly administer the
            website or Services and our community of users, we have the right to
            disable or close any user account at any time and for any reason or
            for no reason.
            <br />
            Buyers and suppliers Specific Terms For Buyers: A buyer who uses the
            Services to get inspiration for consumer goods or purchases,
            requests design services from suppliers, makes purchases from
            suppliers, or otherwise uses the website portal to browse, review,
            facilitate, receive, of pay for Services through the website is a
            "Buyer." If you are a buyer, you agree to comply with the rules and
            additional terms and conditions available at www.uplio.com or as
            otherwise made available to you, which are incorporated by reference
            into these Terms.
            <br />
            <br />
            For Suppliers: A Member who uses the Services to provide design or
            finished products to Buyers, promote their services, and any related
            activities. If you are a supplier, you agree to comply with the
            additional terms and conditions mutually agreed upon in a separate
            agreement between you and Uplio.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>2.Prohibited Uses and Conduct.</h2>
          <h3>
            You agree not to do any of the following <br />
            1. Post, upload, publish, submit or transmit any Content that: (a)
            infringes, misappropriates or violates a third party’s patent,
            copyright, trademark, trade secret, moral rights or other
            intellectual property rights, or rights of publicity or privacy; (b)
            violates, or encourages any conduct that would violate, any
            applicable law or regulation or would give rise to civil liability;
            (c) is fraudulent, false, misleading or deceptive; (d) is
            defamatory, obscene, pornographic, vulgar or offensive; (e) promotes
            discrimination, bigotry, racism, hatred, harassment or harm against
            any individual or group; (f) is violent or threatening or promotes
            violence or actions that are threatening to any person or entity; or
            (g) promotes illegal or harmful activities or substances.
            <br />
            2. Use, embed, display, mirror or frame the Site or Application, or
            any individual element within the Services, Uplio’s name, any Uplio
            trademark, logo or other proprietary information, or the layout and
            design of any page or form contained on a page, without Uplio’s
            express written consent or as enabled by features made available by
            Uplio (e.g., widgets) (you may link to Uplio’s homepage, so long as
            you do not imply or suggest any form of association, approval or
            endorsement on our behalf without our express written consent but
            you may not link to the Site in a libelous, misleading or otherwise
            unlawful manner, or in any manner that violates these Terms);
            <br />
            3. Access, tamper with or use non-public areas of the Site or
            Application, Uplio’s computer systems or the technical delivery
            systems of Uplio’s providers;
            <br /> 4. Attempt to probe, scan, or test the vulnerability of any
            Uplio system or network or breach any security or authentication
            measures;
            <br /> 5. Avoid, bypass, remove, deactivate, impair, descramble or
            otherwise circumvent any technological measure implemented by Uplio
            or any of Uplio’s providers or any other third party (including
            another user) to protect the Services or Collective Content;
            <br />
            6. Attempt to access or search the Services or Collective Content or
            download Collective Content from the Services through the use of any
            engine, software, tool, agent, device or mechanism (including
            spiders, robots, crawlers, data mining tools or the like) other than
            the software and/or search agents provided by Uplio or other
            generally available third party web browsers;
            <br /> 7. Send any unsolicited or unauthorized advertising,
            promotional materials, email, junk mail, spam, chain letters or
            other form of solicitation to, through, or with any assistance from,
            the Services;
            <br /> 8. Use any meta tags or other hidden text or metadata
            utilizing a Uplio trademark, logo URL or product name without
            Uplio’s express written consent;
            <br /> 9. Use the Services or Collective Content in any manner not
            permitted by these Terms; <br />
            10. Forge any TCP/IP packet header or any part of the header
            information in any email or newsgroup posting, or in any way use the
            Services or Collective Content to send altered, deceptive or false
            source-identifying information;
            <br />
            11. Attempt to decipher, decompile, disassemble or reverse engineer
            any of the software or algorithms used to provide the Services or
            Collective Content;
            <br />
            12. Interfere with, or attempt to interfere with, the access of any
            user, host or network, including sending a virus, overloading,
            flooding, spamming or mail- bombing the Services;
            <br />
            13. Collect or store any personally identifiable information from
            the Services from other users of the Site, Services or Application
            without their express permission and in a manner that does not
            violate Uplio’s Privacy Policy;
            <br />
            14. Impersonate or misrepresent your affiliation with any person or
            entity;
            <br />
            15. Violate any applicable law or regulation; or <br />
            16. Encourage or enable any other third party to do any of the
            foregoing. Uplio will have the right to investigate and prosecute
            violations of any of the above to the fullest extent of the law.
            Uplio may involve and cooperate with law enforcement authorities in
            prosecuting users who violate these Terms. You acknowledge that
            Uplio has no obligation to monitor your access to or use of the
            Services or Collective Content, or to review or edit any Collective
            Content, but has the right to do so for the purpose of operating the
            Services, to ensure your compliance with these Terms, to investigate
            a complaint or to comply with applicable law or the order or
            requirement of a court, administrative agency or other governmental
            body. Uplio reserves the right, at any time and without prior
            notice, to remove or disable access to any Member Content or
            Collective Content that Uplio, in its sole discretion, considers to
            be in violation of these Terms or otherwise harmful to the Services.
            Uplio reserves the right to cooperate fully with law enforcement,
            and to involve and share information with law enforcement,
            governmental agencies or other oversight bodies if Uplio suspects
            illegal activity may be taking place
          </h3>
        </div>
        <div className={classes.content}>
          <h2>3.Links to Third-Party Websites and Services. </h2>
          <h3>
            This Site, or Services and User posts may contain hyperlinks to [or
            integrate with] third-party or other websites, applications,
            products or services ("Third-Party Services"). If there are
            Third-Party Services or other resources linked to on this Site, App,
            or Services, either by Uplio or by you, these links are provided
            only for the convenience of our Users. We have no control over the
            contents of those Third-Party Services or resources, and therefore
            cannot accept responsibility for them or for any loss or damage that
            may arise from your use of them. If you decide to access any
            Third-Party Services linked to the Site, or Services, you do so
            entirely at your own risk and subject to the terms and conditions of
            use for that Third-Party Service.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>3.Links to Third-Party Websites and Services. </h2>
          <h3>
            The Services and Collective Content are each protected by copyright,
            trademark and other laws of the United States and foreign countries.
            You acknowledge and agree that the Services and Collective Content,
            excluding your specific Member Content, including all associated
            intellectual property rights, are the exclusive property of Uplio,
            its licensors and/or other providers of such material. Other than
            expressly stated herein, there are no implied licenses granted under
            these Terms. You will not remove, alter or obscure any copyright,
            trademark, service mark or other proprietary rights notices
            incorporated in or accompanying the Services or Collective Content.
            Additionally, Uplio is the owner of pending, registered and/or
            unregistered trademarks, trade dress and trade name appearing on the
            Services, including the Uplio name and logo, and all related names,
            logos, product and service names, designs and slogans. You agree to
            not use such marks without Uplio’s prior written permission. Any
            other trademarks, service marks, logos, trade names and any other
            proprietary designations are the trademarks or registered trademarks
            of their respective parties.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>4. Ownership.</h2>

          <h3>
            The Services and Collective Content are each protected by copyright,
            trademark and other laws of the United States and foreign countries.
            You acknowledge and agree that the Services and Collective Content,
            excluding your specific Member Content, including all associated
            intellectual property rights, are the exclusive property of Uplio,
            its licensors and/or other providers of such material. Other than
            expressly stated herein, there are no implied licenses granted under
            these Terms. You will not remove, alter or obscure any copyright,
            trademark, service mark or other proprietary rights notices
            incorporated in or accompanying the Services or Collective Content.
            Additionally, Uplio is the owner of pending, registered and/or
            unregistered trademarks, trade dress and trade name appearing on the
            Services, including the Uplio name and logo, and all related names,
            logos, product and service names, designs and slogans. You agree to
            not use such marks without Uplio’s prior written permission. Any
            other trademarks, service marks, logos, trade names and any other
            proprietary designations are the trademarks or registered trademarks
            of their respective parties.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>5.Feedback.</h2>

          <h3>
            By sending us any feedback, ideas, comments, suggestions, documents,
            proposals, or other materials or information ("Feedback"), you agree
            that (i) your Feedback does not contain the confidential or
            proprietary information of any third party, (ii) we are under no
            obligation of confidentiality, express or implied, with respect to
            the Feedback, (iii) we may have something similar to the Feedback
            already under consideration or in development, and (iv) you grant us
            an irrevocable, non- exclusive, royalty-free, perpetual, worldwide
            license to use, modify, prepare derivative works, publish,
            distribute and sublicense the Feedback, and you irrevocably waive,
            and cause to be waived, against Uplio and its users any claims and
            assertions of any moral rights contained in such Feedback.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>6.Service Level Agreements.</h2>
          <h3>
            Uplio Inc. serves as a marketplace that connects suppliers,
            manufacturers (collectively, the "Suppliers") looking to sell their
            product and services with Buyers (sometimes referred to as
            "Customers") looking to create products through our Site. All
            services that are made available in the buyer portal are made
            available at Uplio.com, act as a connecting party between suppliers
            and buyers. The software and services allow Users to conduct their
            business through software. Uplio does not claim responsibility or
            ownership over, or intellectual property right for, the business
            that is created as a result of using the services offered by Uplio
            Inc. All attempts to help a buyer connect with suppliers are done in
            good faith. The Site reserves the right to turn off services to
            buyers if they choose, for example, when in violation of community
            guidelines or in violation of any of the terms in this document.
            <br />
            <br />
            The software platform itself provides basic information to both
            suppliers and buyers using the marketplace. Costs associated with
            production are on a per piece and are always to be made known to the
            buyer prior to the start of sale. Each Buyer takes it upon
            themselves to review all items and costs. Any changes to the
            manufacturing cost will be made available to the buyer immediately
            and by their manufacturing partner. Each Buyer will be allowed to
            terminate their relationship with their selected supply partner at
            any time. It is up to the Buyer, or Seller to understand the fees
            associated with operating their business.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>7.No Guarantee of service </h2>
          <h3>
            Although we hope to make the Site, or Services available at all
            times in the future, there may be times when we need to disable the
            Site either temporarily or permanently. The Site or Services may be
            modified, updated, interrupted, suspended, or discontinued at any
            time without notice or liability. Keep this in mind as Uplio will
            not be liable if all or any part of the Site or Services is
            unavailable at any time, for any period of time. Also, from time to
            time, we may restrict access to some parts of the Site or Services,
            or the entire Site or Services, to Users, including Members. We
            cannot and do not make any representations or warranties with
            respect to the devices you use to access or use the Site or
            Services, including with respect to device compatibility.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>8.Disclaimer of Warranties.</h2>
          <h3>
            UPLIO HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A
            PARTICULAR PURPOSE. THE SITE AND ANY SERVICES OR ITEMS OBTAINED
            THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
            BASIS. NEITHER UPLIO NOR ANY PERSON ASSOCIATED WITH UPLIO MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SITE OR SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER UPLIO NOR
            ANYONE ASSOCIATED with UPLIO REPRESENTS OR WARRANTS THAT THE SITE OR
            SERVICES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SITE OR SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
            SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES
            OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR
            ITEMS OBTAINED THROUGH THE SITE OR SERVICES WILL OTHERWISE MEET YOUR
            NEEDS OR EXPECTATIONS. YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR
            ALL OF YOUR COMMUNICATIONS, CONTENT AND INTERACTIONS WITH OTHER
            USERS OF THE SITE OR SERVICES. YOU FURTHER WAIVE AND HOLD HARMLESS
            UPLIO FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY UPLIO
            DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS
            TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER UPLIO OR LAW
            ENFORCEMENT AUTHORITIES. IF YOU ARE A RESIDENT OF A JURISDICTION
            THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE, THEN THE
            FOLLOWING APPLIES. FOR EXAMPLE, (1) CALIFORNIA RESIDENTS MUST, AS A
            CONDITION OF THESE TERMS, WAIVE THE APPLICABILITY OF CALIFORNIA
            CIVIL CODE SECTION 1542, WHICH STATES, "A GENERAL RELEASE DOES NOT
            EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
            EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
            WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
            HER SETTLEMENT WITH THE DEBTOR" AND (2) NEW JERSEY RESIDENTS MUST,
            AS A CONDITION OF THIS AGREEMENT, WAIVE THE APPLICABILITY OF THE
            TRUTH IN CONSUMER CONTRACT, WARRANTY, AND NOTICE ACT (X) SECTIONS
            15, WHICH STATES, AMONG OTHER THINGS, THAT "NO SELLERS...SHALL IN
            THE COURSE OF HIS BUSINESS OFFER TO ANY CONSUMER OR PROSPECTIVE
            CONSUMER OR ENTER INTO ANY WRITTEN CONSUMER CONTRACT OR GIVE OR
            DISPLAY ANY WRITTEN CONSUMER WARRANTY, NOTICE OR SIGN...WHICH
            INCLUDES ANY PROVISION THAT VIOLATES ANY CLEARLY ESTABLISHED LEGAL
            RIGHT OF A CONSUMER OR RESPONSIBILITY OF A SELLER..." AND (X)
            SECTION 16, WHICH STATES, AMONG OTHER THINGS, THAT "...NO CONSUMER
            CONTRACT, NOTICE OR SIGN SHALL STATE THAT ANY OF ITS PROVISIONS IS
            OR MAY BE VOID, UNENFORCEABLE OR INAPPLICABLE IN SOME JURISDICTIONS
            WITHOUT SPECIFYING WHICH PROVISIONS ARE OR ARE NOT VOID,
            UNENFORCEABLE OR INAPPLICABLE WITHIN THE STATE OF NEW JERSEY...".
            YOU HEREBY WAIVE, AS APPLICABLE, THESE SECTIONS OF THE CALIFORNIA
            CIVIL CODE AND NEW JERSEY TRUTH IN CONSUMER CONTRACT, WARRANTY, AND
            NOTICE ACT. YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW,
            REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE
            AFOREMENTIONED PROVISIONS.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>9.Limitation of Liability.</h2>
          <h3>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE
            THAT IN NO EVENT SHALL UPLIO BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
            CONNECTION WITH THE SITE OR SERVICES, WHETHER OR NOT UPLIO HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR FROM ANY
            COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE
            SITE OR SERVICES, ON ANY THEORY OF LIABILITY (INCLUDING CONTRACT,
            TORT INCLUDING NEGLIGENCE, OR HOWSOEVER OTHERWISE) ARISING OUT OF,
            IN CONNECTION WITH, OR RESULTING FROM THE SITE OR SERVICES. TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES WILL UPLIO
            BE LIABLE TO YOU, (REGARDLESS OF WHETHER THE CLAIM IS BASED IN
            CONTRACT, TORT INCLUDING NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE)
            FOR MORE THAN $1,000 AS A RESULT OF A USER'S USE OF THE SITE OR
            SERVICES. THE LIMITATIONS OF THIS SECTION WILL NOT APPLY TO ANY
            LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY LAW.
          </h3>
        </div>
        <div className={classes.content}>
          <h2> 10.Indemnification.</h2>
          <h3>
            As a necessary condition of your use of the Site and Services, you
            agree to be responsible for the consequences flowing from your use
            of the Site or Services and any violation of these Terms. Therefore,
            you hereby agree to defend, indemnify, and hold harmless Uplio, its
            affiliates and licensors and their respective officers, directors,
            employees, contractors, agents, licensors and suppliers from and
            against any claims, liabilities, damages, judgments, awards, losses,
            costs, expenses or fees (including reasonable attorneys' fees)
            resulting from your violation of these Terms or your use of the Site
            or Services, including, without limitation, (i) any use of the Site
            , Site Content, Services, and any related products, services, or
            materials other than as expressly authorized in these Terms or your
            use of any information obtained from the Site or Services, (ii) your
            violation of any term of these Terms, (iii) your violation of any
            third party rights, including without limitation intellectual
            property or privacy rights, (iv) your or your users' violation of
            law, (v) your use of any services provided by third party service
            providers, or (vi) any breach of any of your representations and
            warranties.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>11.Governing Law.</h2>
          <h3>
            You agree that: (i) the Site, and Services will be deemed solely
            based in California; and (ii) the Site, and Services will be deemed
            a passive one that does not give rise to personal jurisdiction over
            us, either specific or general, in jurisdictions other than
            California. These Terms will be governed by the internal substantive
            laws of the State of California, without respect to its conflict of
            laws principles. You agree to submit to the personal jurisdiction of
            the federal and state courts located in California for any actions
            for which we retain the right to seek injunctive or other equitable
            relief in a court of competent jurisdiction to prevent the actual or
            threatened infringement, misappropriation or violation of a our
            copyrights, trademarks, trade secrets, patents, or other
            intellectual property or proprietary rights, as set forth in the
            Arbitration provision below, including any provisional relief
            required to prevent irreparable harm. You agree that California is
            the proper forum for any appeals of an arbitration award or for
            trial court proceedings in the event that the arbitration provision
            below is found to be unenforceable.
            <br />
            <br />
            A) Arbitration and Class Action Waiver.
            <br />
            <br />
            You and Uplio agree that any dispute, claim or controversy arising
            out of or relating to these Terms or the breach, termination,
            enforcement, interpretation or validity thereof or the use of the
            Services (collectively, “Disputes”) will be settled by binding
            arbitration, except that each party retains the right to: (a) bring
            an individual action in small claims court; (b) the right to seek
            injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation or violation of a party’s copyrights, trademarks,
            trade secrets, patents or other intellectual property rights; (c)
            pursue an enforcement action through the applicable federal, state
            or local agency if that action is available; and (d) seek injunctive
            relief in a court of law in aid of arbitration. YOU ACKNOWLEDGE AND
            AGREE THAT YOU AND UPLIO ARE EACH WAIVING THE RIGHT TO A TRIAL BY
            JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. Further, unless
            both you and Uplio otherwise agree in writing, the arbitrator may
            not consolidate more than one person's claims, and may not otherwise
            preside over any form of any class or representative proceeding. If
            this specific paragraph is held unenforceable, then the entirety of
            this “Dispute Resolution” section will be deemed void. Except as
            provided in the preceding sentence, this “Dispute Resolution”
            section will survive any termination of these Terms.
            <br />
            <br /> Arbitration Rules and Governing Law. The arbitration will be
            administered by the American Arbitration Association (“AAA”) in
            accordance with the Commercial Arbitration Rules (the “AAA Rules”)
            then in effect, except as modified by this “Dispute Resolution”
            section. The parties agree that the Federal Arbitration Act applies
            and will govern the interpretation and enforcement of this
            Arbitration Agreement.
            <br />
            <br /> Arbitration Process. A party who desires to initiate
            arbitration must provide the other party with a written Demand for
            Arbitration as specified in the AAA Rules. The arbitrator will be
            either a retired judge or an experienced (15+ years practicing)
            attorney licensed to practice law in the state of California.
            <br />
            <br />
            Arbitration Location and Procedure. Unless you and Uplio otherwise
            agree, the arbitration will be conducted in the county where you
            reside. If your claim does not exceed US$10,000, then the
            arbitration will be conducted solely on the basis of documents you
            and Uplio submit to the arbitrator, unless you request a hearing, or
            the arbitrator determines that a hearing is necessary. If your claim
            exceeds US$10,000, your right to a hearing will be determined by the
            AAA Rules. Subject to the AAA Rules, the arbitrator will have the
            discretion to direct a reasonable exchange of information by the
            parties. Arbitrator’s Decision. The arbitrator will render an award
            within the time frame specified in the AAA Rules. The arbitrator’s
            decision will include the essential findings and conclusions upon
            which the arbitrator based the award. Judgment on the arbitration
            award may be entered in any court having jurisdiction thereof. The
            arbitrator’s award damages must be consistent, to the extent
            permitted by law, with the terms of the “Limitation of Liability”
            section above as to the types and the amounts of damages for which a
            party may be held liable. Fees. Your responsibility to pay any AAA
            filing, administrative and arbitrator fees will be solely as set
            forth in the AAA Rules. If applicable arbitration rules or laws
            require us to pay a greater portion or all of such fees and costs in
            order for this Dispute Resolution provision to be enforceable, then
            we will have the right to elect to pay the fees and costs and
            proceed to arbitration. Changes. Notwithstanding the provisions of
            the “Modification” section above, if Uplio changes this “Dispute
            Resolution” section after the date you first accepted these Terms
            (or accepted any subsequent changes to these Terms), you may reject
            any such change by sending us written notice (including by email
            hello@Uplio.com) within 30 days of the date such change became
            effective, as indicated in the “Last Updated Date” above or in the
            date of Uplio’s email to you notifying you of such change. By
            rejecting any change, you are agreeing that you will arbitrate any
            Dispute between you and Uplio in accordance with the arbitration
            provisions of this “Dispute Resolution” section (however entitled)
            as of the date you first accepted these Terms or accepted any
            subsequent changes to these Terms.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>12.Termination.</h2>
          <h3>
            -You may terminate the Terms at any time by closing your account and
            discontinuing your use of the Site, or Services. Please review our
            Privacy Policy for information about our privacy practices with
            respect to your personal information when you terminate your
            account. -We may close your account, suspend your ability to use
            certain portions of the Site or Services, or ban you altogether from
            the Site or Services for any or no reason, and without notice or
            liability of any kind. Any such action could prevent you from
            accessing your account, the Site or Services, Your Content, or any
            other related information.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>13.General Terms.</h2>
          <h3>
            These Terms constitute the sole and entire agreement between you and
            Uplio with respect to the Site or Services and supersede all prior
            and contemporaneous understandings, agreements, representations and
            warranties, both written and oral, with respect to the Site or
            Services. No waiver of these Terms by Uplio shall be deemed a
            further or continuing waiver of such term or condition or any other
            term or condition, and any failure of Uplio to assert a right or
            provision under these Terms shall not constitute a waiver of such
            right or provision. If any provision of these Terms is held by a
            court of competent jurisdiction to be invalid, illegal, or
            unenforceable for any reason, such provision shall be modified to
            reflect the parties' intention or eliminated to the minimum extent
            such that the remaining provisions of the Terms will continue in
            full force and effect. The Terms, and any rights or obligations
            hereunder, are not assignable, transferable, or sublicensable by you
            except with Uplio's prior written consent but may be assigned or
            transferred by us without restriction. Any attempted assignment by
            you shall violate these Terms and be void. The section titles in the
            Terms are for convenience only and have no legal or contractual
            effect.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>14.Contact.</h2>
          <h3>
            Any questions, requests for technical support, complaints, claims or
            other communications relating to the Site, App, or Services should
            be directed to: <a href="hello@uplio.com">hello@uplio.com</a>.
          </h3>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default TermsAndConditions
