import React from 'react'
import './home-main.scss'
import { Link, useHistory } from 'react-router-dom'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'

// This are correct versions (not forget to delete .jpg images from ./images folder)
import fashion from '../../../../assets/png/fashion.png'
import fashion2x from '../../../../assets/png/fashion@2x.png'
import cosmetics from '../../../../assets/png/cosmetics.png'
import cosmetics2x from '../../../../assets/png/cosmetics@2x.png'
import jewelry from '../../../../assets/png/jewelry.png'
import jewelry2x from '../../../../assets/png/jewelry@2x.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Autoplay } from 'swiper'
import 'swiper/scss'
import 'swiper/css/effect-fade'

const HomeMain = () => {
  const history = useHistory()

  return (
    <section className="home-main">
      <div className="home-main-wrapper">
        <div className="container">
          <div className="home-main__content">
            <div className="home-main__content-text">
              <h1 className="title title_size-l home-main__title">
                Convert your&nbsp;dream into a&nbsp;product
              </h1>
              <div className="title title_item home-main__subtitle">
                Find a trusted supplier
              </div>
              <div className="home-main__btn">
                <ButtonWithRightArrow
                  content={'Get a quote'}
                  function={() =>
                    history.push({ pathname: '/quote/generic_quotation' })
                  }
                />
                <div className='browseBtn'>
                  <Link to="/browse">PRODUCT BROWSE</Link>
                </div>
              </div>
            </div>
            <div className="home-main__content-img">
              <Swiper
                className="home-main__content-swiper"
                slidesPerView={1}
                spaceBetween={10}
                effect={'fade'}
                modules={[EffectFade, Autoplay]}
                loop={true}
                speed={1000}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <img
                    width="602"
                    height="650"
                    src={fashion}
                    srcSet={`${fashion} 1x, ${fashion2x} 2x`}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    width="518"
                    height="650"
                    src={cosmetics}
                    srcSet={`${cosmetics} 1x, ${cosmetics2x} 2x`}
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    width="632"
                    height="650"
                    src={jewelry}
                    srcSet={`${jewelry} 1x, ${jewelry2x} 2x`}
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeMain
