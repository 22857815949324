import React, { useState, useEffect } from 'react'
import classes from './SupplierProfile.module.scss'
import verified_tag from '../../../assets/svg/verified_tag.svg'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import rightArrow from '../../../assets/svg/Arrow_indicator_left_black.svg'
import leftArrow from '../../../assets/svg/Arrow_indicator_right_black.svg'
import ButtonWithRightArrow from '../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

import { Spin } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'

import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import { useDispatch } from 'react-redux'
import { singleQuoteData } from '../../../redux/actions/singleQuote.action'

const SupplierProfile = () => {
  const history = useHistory()
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [supplierData, setSupplierData] = useState([])
  const [swiper, setSwiper] = useState(null)
  const [swiperThumbs, setSwiperThumbs] = useState(null)
  const [realIndex, setRealIndex] = useState(0)

  useEffect(() => {
    if (swiper) {
      setRealIndex(swiper.realIndex)
    }
  }, [swiper])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/supplier_profile/${id}`)
      .then((res) => {
        setSupplierData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleSlideChange = (swiper) => {
    const lastMainSlide = swiper.slides.length - 1

    if (swiperThumbs !== null) {
      const additionalSwiper = swiperThumbs

      const activeIndex = swiper.activeIndex
      const prevIndex = swiper.previousIndex
      if (prevIndex === 0 || prevIndex === lastMainSlide) {
        return
      }

      if (activeIndex > prevIndex) {
        additionalSwiper.slideNext()
      } else {
        additionalSwiper.slidePrev()
      }
    }
  }
  const dispatch = useDispatch()
  const handleQuote = () => {
    dispatch(
      singleQuoteData({
        productCategory: supplierData.uCategory[0].name,
      })
    )

    history.push({
      pathname: `/quote/${id}`,
    })
  }

  return (
    <>
      <Navigation />
      {!loading ? (
        <>
          <div className={clsx(classes.container, classes.main)}>
            <div className={classes.companyDetails}>
              <img
                width={48}
                height={48}
                className={classes.logoImg}
                src={supplierData.companyLogo}
                alt="uplio"
              />
              <div className={classes.detailsWrapper}>
                <h1>{supplierData.companyName}</h1>
                <h4>
                  <img src={verified_tag} alt="Uplio" />
                  Verified Supplier
                </h4>
              </div>
            </div>

            {!loading ? (
              <div className={classes.mainSection}>
                <div className={classes.slider}>
                  <div className={classes.sliderMain}>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={24}
                      onSwiper={(swiper) => setSwiper(swiper)}
                      onSlideChange={(swiper) => {
                        setRealIndex(swiper.realIndex)
                        handleSlideChange(swiper)
                      }}
                      loop={true}
                    >
                      {supplierData.images.map((item) => {
                        return (
                          <SwiperSlide key={item}>
                            <img
                              width={560}
                              height={420}
                              src={item !== undefined ? item : null}
                              alt="uplio"
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                  <div className={classes.sliderThumbs}>
                    <Swiper
                      direction={'vertical'}
                      slidesPerView={2}
                      spaceBetween={24}
                      loop={true}
                      onSwiper={(swiper) => setSwiperThumbs(swiper)}
                      initialSlide={1}
                      slideToClickedSlide={false}
                      allowTouchMove={false}
                      breakpoints={{}}
                    >
                      {supplierData.images.map((item) => {
                        return (
                          <SwiperSlide key={item}>
                            <img
                              width={264}
                              src={item !== undefined ? item : null}
                              alt="uplio"
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
                <div className={classes.quoteButton}>
                  <ButtonWithRightArrow
                    function={() => handleQuote()}
                    content="GET A QUOTE"
                  />
                  <button type="button" className={classes.contactBtn}>
                    Contact supplier
                  </button>
                </div>
                <div className={classes.actionButton}>
                  <div className={classes.slidesCount}>
                    <span>{realIndex + 1}</span>/
                    <span>{swiper && swiper.slides.length - 2}</span>
                  </div>
                  <div className={classes.actionButtonBlock}>
                    <button
                      onClick={() => {
                        swiperThumbs.slidePrev()
                        swiper.slidePrev()
                      }}
                    >
                      <img src={leftArrow} alt="Uplio" />
                    </button>
                    <button
                      onClick={() => {
                        swiperThumbs.slideNext()
                        swiper.slideNext()
                      }}
                    >
                      <img src={rightArrow} alt="Uplio" />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={clsx(
                classes.supplierSection,
                classes.supplierSectionTopBorder
              )}
            >
              <h2>About Supplier</h2>
              <h3>
                Categories:{' '}
                <strong>
                  {supplierData.uCategory.map((item) => item.name)}
                </strong>
              </h3>
              <h3>
                Production capacity:{' '}
                <strong>{supplierData.productionCapacity}</strong>
              </h3>
              <h3>
                Minimum Order: <strong>{supplierData.MOQ} units</strong>
              </h3>
              <h3>
                Est. Leadtime: <strong>{supplierData.averageLeadtime}</strong>
              </h3>
              <h3>
                Established: <strong>{supplierData.yearEstablished}</strong>
              </h3>
              <h3>
                Location:{' '}
                <strong>
                  {' '}
                  {supplierData.city}, {supplierData.country}
                </strong>{' '}
              </h3>
              {/* <h3>Response Rate:</h3> */}
            </div>

            <div
              className={clsx(
                classes.supplierSection,

                classes.supplierSectionTopBorder
              )}
            >
              <h2>Overview</h2>
              <p>{supplierData.companyOverview}</p>
            </div>
            <div
              className={clsx(
                classes.supplierSection,
                classes.supplierSectionTopBorder,
                classes.supplierSectionBottomBorder
              )}
            >
              <h2>Specialization</h2>
              {/* <ul>
                {!loading
                  ? supplierData.specialization.split(',').map((item) => (
                      <li key={item}>
                        <Link to={`/products/${item}`}>{item}</Link>
                      </li>
                    ))
                  : null}
              </ul> */}
              <ul className={classes.spezializationList}>
                {!loading
                  ? supplierData.specialization.split(',').map((spez) => (
                    <li key={spez}>
                      <Link to={`/products/${spez}`}>{spez}</Link>
                    </li>
                  ))
                  : null}
              </ul>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <div className={classes.spin}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default SupplierProfile
