import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

const TooltipMessage = ({ text }) => {
    return (
        <Tooltip title={text}>
            <InfoCircleOutlined />
        </Tooltip>
    )
}

export default TooltipMessage
