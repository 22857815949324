import React, { useEffect } from 'react'
import classes from './AddInvoice.module.scss'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik';
import clsx from 'clsx'
import * as Yup from "yup"
import { useState } from 'react'
import { useSelector } from 'react-redux'
import SaveModal from '../../../../../constant/public/SaveModal/SaveModal';
import TooltipMessage from '../../../../../constant/TooltipMessage';
import InputElement from './Input/InputElement';
import cross from "../../../../../assets/svg/Cross.svg"
import errorIcon from '../../../../../assets/svg/error_icon.svg'
import InputWithDropDownWithoutSearch from '../../../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch';
import moment from 'moment';
import { DatePicker, Input, Radio } from 'antd';
import BuyerName from '../BuyerName/BuyerName';
import { OpenNotificationWithIcon } from '../../../../../constant/public/Notification/Notification';
import ImageUpload from '../../ProductAndServices/ImageUpload/ImageUpload';


const AddInvoice = (props) => {
    const token = sessionStorage.getItem('token')
    const user = useSelector((state) => state.user.user)
    const history = useHistory()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buyerNamesArray, setBuyerNamesArray] = useState([]);
    const [buyerDetails, setBuyerDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [quoteId, setQuoteId] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [paymentTerms, setPaymentTerms] = useState(1);
    const [payment1, setPayment1] = useState("");
    const [payment2, setPayment2] = useState("");
    const [payBefore1, setPayBefore1] = useState("");
    const [payBefore2, setPayBefore2] = useState("");
    const [modificationOpen, setModificationOpen] = useState(false);
    const [modifications, setModifications] = useState([
        {
            quantity: "",
            price: "",
            totalPrice: "",
            description: "",
            images: []
        },
    ]);

    console.log(modifications)
    const invoiceReasonsArray = [
        {
            id: "Custom Production Run",
            label: "Custom Production Run"
        },
        {
            id: "Custom Samples",
            label: "Custom Samples"
        },
        {
            id: "Shipping Fee",
            label: "Shipping Fee"
        },
        {
            id: "Other Fees",
            label: "Other Fees"
        }
    ];

    const handleAddModification = () => {
        if (modifications?.length < 20) {
            setModifications([...modifications, {
                quantity: "",
                price: "",
                totalPrice: "",
                description: ""
            }]);
        } else {
            OpenNotificationWithIcon("Error", "You cannot add more than 20 products", "")
        }
    };


    const handleInputChange = (event, name, index) => {
        if (name === "images") {
            const newModifications = [...modifications];
            if (newModifications[index].images?.length > 2) {
                // OpenNotificationWithIcon("error", "Limit Reached. Only 3 images are allowed", "")
            } else {
                newModifications[index].images = event;
                setModifications(newModifications);
            }
        } else {
            const newModifications = [...modifications];
            newModifications[index][event.target.name] = event.target.value;
            setModifications(newModifications);
        }
        handlePriceChange();
    };

    // const products = [{ quantity: "10", price: "10", totalPrice: "" }, { quantity: "5", price: "20", totalPrice: "" }];

    modifications.forEach(product => {
        const quantity = parseInt(product.quantity);
        const price = parseInt(product.price);
        product.totalPrice = quantity * price;
    });

    // console.log("products", modifications);
    // {
    //     "sequence": paymentTerms,
    //     "type": paymentTerms == 1 ? "Pay Now" : paymentTerms === 2 && "Pay Deposit",
    //     "amount": paymentTerms === 1 ? modifications?.reduce(((a, b) => a + b.quantity * b.price), 0) : paymentTerms === 2 && payment2,
    //     "dueDate": paymentTerms === 1 ? moment(payBefore1).format("YYYY-MM-DD") : paymentTerms === 2 && moment(payBefore2).format("YYYY-MM-DD"),
    // },
    const handleSubmitForm = (d) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/invoice/supplier/${user?.id}`,
            {
                quote: buyerDetails?.quoteId,
                invoiceNumber,
                reason: formik.values.invoiceReason,
                products: modifications,
                paymentTerms: parseInt(paymentTerms),
                issueDate: moment().format("YYYY-MM-DD"),
                payments: paymentTerms === 2 ? [
                    {
                        "sequence": 1,
                        "type": "Pay Deposit",
                        "amount": payment1,
                        "dueDate": moment(payBefore1).format("YYYY-MM-DD"),
                    },
                    {
                        "sequence": 2,
                        "type": "Pay Deposit",
                        "amount": payment2,
                        "dueDate": moment(payBefore2).format("YYYY-MM-DD"),
                    },
                ]
                    :
                    [
                        {
                            "sequence": 1,
                            "type": "Pay Now",
                            "amount": modifications?.reduce(((a, b) => a + b.quantity * b.price), 0),
                            "dueDate": moment(payBefore1).format("YYYY-MM-DD"),
                        },
                    ],
                invoiceTotal: parseInt(modifications?.reduce(((a, b) => a + b.quantity * b.price), 0))
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        )
            .then((res) => {
                setIsModalOpen(!isModalOpen);
                setTimeout(() => {
                    history.push("/dashboard/supplier/invoices")
                }, 2000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const formik = useFormik({
        initialValues: {
            otherFees: '',
            invoiceReason: '',
            buyerName: '',
            payment1: "",
            payment2: "",
            payBefore1: "",
            payBefore2: "",
        },
        validationSchema: Yup.object({
            otherFees: Yup.string().required("Other Fee is required"),
            invoiceReason: Yup.string().required("Invoice Reason Id is required"),
            payment1: Yup.number().required("Payment is required"),
            payment2: Yup.number().required("Payment is required"),
            payBefore1: Yup.number().required("Pay Before is required"),
            payBefore2: Yup.number().required("Pay Before is required"),
            buyerName: Yup.string().required("Customization Option is required")
        }),
        onSubmit: (values) => {
            // handleProfileUpdated
            alert(JSON.stringify(values, null, 2));
        },

    });

    const { setFieldValue } = formik;
    console.log(modifications?.reduce(((a, b) => a + b.quantity * b.price), 0));


    const handleRemoveModification = (index) => {
        const newModifications = [...modifications];
        newModifications.splice(index, 1);
        setModifications(newModifications);
    };

    const getBuyerNameAndQoutes = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/quotes/supplier-leads/${user?.id}/answered`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                
                let newArr = []
                res.data.map((item, index) => (
                    newArr.push({
                        id: item.user?.firstname + item.user?.lastname,
                        label: item?.user?.firstname !== "" && item?.user?.firstname !== null ? (item.user?.firstname + " " + item.user?.lastname) : item?.user?.name,
                        address: item.user?.shippingAddresses[0]?.address1,
                        quoteNumber: item.quoteNumber,
                        quoteId: item._id,

                    })
                ))
                setBuyerNamesArray(newArr)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response)
            })
    }


    useEffect(() => {
        getBuyerNameAndQoutes();

        return () => {

        }
    }, []);

    const handlePriceChange = async () => {
        let total = modifications.forEach(product => {
            const quantity = parseInt(product.quantity);
            const price = parseInt(product.price);
            product.totalPrice = quantity * price;
        });
        console.log("total", total)
        setFieldValue("payment1", total);
    }

    const handleInvoiceGeneration = async () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/invoice/generate-number`, { data: "" },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                ;
                setInvoiceNumber(res.data.invoiceNumber)
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response)
            })
    }

    useEffect(() => {
        handleInvoiceGeneration();

        return () => {

        }
    }, [])

    const handlePayment1Change = (e) => {
        if (modifications?.reduce(((a, b) => a + b.quantity * b.price), 0) > e.target.value) {
            setPayment1(e.target.value);
            setPayment2(modifications?.reduce(((a, b) => a + b.quantity * b.price), 0) - Number(e.target.value))
        } else {
            setPayment1(modifications?.reduce(((a, b) => a + b.quantity * b.price), 0));
            setPayment2(0);
        }
    }

    const handleKeyPress = (e) => {
        const pattern = /^\d+$/;
        const inputChar = String.fromCharCode(e.charCode);
        if (!pattern.test(inputChar)) {
            e.preventDefault();
        }
    };

    return (
        <div className={classes.AddInvoice}>
            {/* <div className={classes.header}>
                <SupplierHeader />
            </div>˝ */}
            {/* <ProductModification /> */}
            <form onSubmit={(e) => { e.preventDefault() }}>
                <div className="row">
                    <div style={{ display: "flex" }}>
                        <div className='col-6' style={{ flexGrow: 1 }}>
                            <h3>Invoice #{invoiceNumber}</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <SaveModal openModal={isModalOpen} />
                </div>
                <div className={classes.container}>
                    <div className={`${classes.wrapper} ${classes.mainWrapper}`}>
                        <div className={classes.mainContent}>
                            {/* <Form /> */}
                            <div className='row'>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Invoice reasons <TooltipMessage text="Please enter Invoice reasons" /></p>
                                        <div className={classes.selectWrapper}>
                                            <InputWithDropDownWithoutSearch
                                                dropdown={invoiceReasonsArray}
                                                width={'100%'}
                                                value={formik.values.invoiceReason ? formik.values.invoiceReason : "- Select a reason -"}
                                                // onChange={(value) => setFieldValue("invoiceReason", value)}
                                                setDropdown={(value) => setFieldValue("invoiceReason", value)}
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                {
                                    formik.values.invoiceReason === "Other Fees" &&
                                    <div className={classes.col12}>
                                        <div className={classes.wrapper}>
                                            <p>Other Fees <TooltipMessage text="Please enter Other Fees" /></p>
                                            <InputElement
                                                name="otherFees"
                                                type="text"
                                                className={clsx([`input`, classes.companyInput])}
                                                wrapperClass={classes.companyInput}
                                                placeholder="Specify the reason"
                                                onChange={formik.handleChange}
                                                value={formik.values.otherFees}
                                                helperText={formik.errors.otherFees
                                                    // formik.touched.otherFees
                                                    ? formik?.errors.otherFees
                                                    : null}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Invoice ID</p>
                                        <p className={classes.valueLabel}>#{invoiceNumber}</p>
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Issue Date</p>
                                        <p className={classes.valueLabel}>{moment().format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <div classes="col-12">
                                    <>
                                        {
                                            <div>
                                                {
                                                    modifications?.map((modification, index) => (
                                                        <div style={{ marginTop: "23px" }} key={index}>
                                                            <div className={classes.typeOfModification}>
                                                                <div className={classes.right}>
                                                                    <div>
                                                                        {
                                                                            index > 0 &&
                                                                            <button className={classes.wholeCloseBtn} onClick={() => { handleRemoveModification(index); index === 0 && setModificationOpen(false) }}>
                                                                                <img src={cross} alt="Cross" />
                                                                            </button>
                                                                        }
                                                                        <div key={index} style={{ marginBottom: "36px" }}>
                                                                            <div className={classes.itemsContainer}>
                                                                                <div>
                                                                                    <p>QTY <TooltipMessage text="Please enter QTY" /></p>
                                                                                    <div className={classes.textInnerWrapper}>
                                                                                        <InputElement
                                                                                            name="quantity"
                                                                                            onChange={(event) => { handleInputChange(event, "quantity", index); handleInputChange(event, "totalPrice", index) }}
                                                                                            type="number"
                                                                                            onKeyPress={handleKeyPress}
                                                                                            className={clsx([`input`, classes.productInput])}
                                                                                            wrapperClass={classes.productInput}
                                                                                            value={modification.quantity}
                                                                                            placeholder="50"
                                                                                            clearIcon={true}
                                                                                            helperText={modification.quantity === ""
                                                                                                ? "Please enter QTY"
                                                                                                : null}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <p>Unit Price <TooltipMessage text="Please enter Unit Price" /></p>
                                                                                    <div className={`${classes.textInnerWrapper} ${classes.unitPriceInput}`}>
                                                                                        <InputElement
                                                                                            name="price"
                                                                                            onChange={(event) => handleInputChange(event, "price", index)}
                                                                                            type="number"
                                                                                            onKeyPress={handleKeyPress}
                                                                                            className={clsx([`input`, classes.productInput])}
                                                                                            wrapperClass={classes.productInput}
                                                                                            value={modification.price}
                                                                                            placeholder="50"
                                                                                            clearIcon={true}
                                                                                            helperText={modification.price === ""
                                                                                                ? "Please enter Unit Price"
                                                                                                : null}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <p>Total Price</p>
                                                                                    <div className={classes.textInnerWrapper}>
                                                                                        <p className={classes.valueLabel}>$ {modification.price * modification.quantity}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={classes.col12}>
                                                                                    <div className={classes.textareaWrapper}>
                                                                                        <p style={{ marginBottom: "21px" }}>Custom Product Description <TooltipMessage text="Provide specific Custom Product Description" /></p>
                                                                                        <div className={classes.textAreaContainer}>
                                                                                            <textarea
                                                                                                name="description"
                                                                                                value={modification.description}
                                                                                                maxLength={1000}
                                                                                                onChange={(event) => handleInputChange(event, "description", index)}
                                                                                                style={{ width: props.width }}
                                                                                                className={classes.textarea}
                                                                                                placeholder="As an example, Arcus A.G. was founded by a group of people coming from very diverse backgrounds in many different professional fields ranging from management and entrepreneurship to fashion and graphic design."
                                                                                                rows={props.rows}
                                                                                                cols={props.cols}
                                                                                            />
                                                                                            <div className={classes.wordCount}>
                                                                                                {modification?.description?.length} / 1000
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            <div className={classes.errorMessage}>
                                                                                                {modification?.description === "" &&
                                                                                                    <>
                                                                                                        <img src={errorIcon} alt="Uplio" />
                                                                                                        {modification?.description === "" ? "Please enter Custom Product Description" : null}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className={classes.imagesUpload} style={{ marginTop: "28px" }}>
                                                                                    <ImageUpload
                                                                                        title={'Product Images'}
                                                                                        text={'Images (.JPEG, .PDF). Max size: 200 MB'}
                                                                                        data={modification.images}
                                                                                        handleImage={(arr) => handleInputChange(arr, "images", index)}
                                                                                        type="PI"
                                                                                    />
                                                                                    {
                                                                                        modification?.images?.length === 0 &&
                                                                                        <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {""}{
                                                                                            modification?.images?.length === 0 ? "Product Images are required" : null
                                                                                        }</div>
                                                                                    }
                                                                                    {/* {
                                                                                        modification?.images?.length > 0 && modification?.images?.filter(f => f?.cover)?.length === 0 &&
                                                                                        <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {""}{
                                                                                            "Please choose cover image"
                                                                                        }</div>
                                                                                    } */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <div className={classes.addItemBtn}>
                                                    {
                                                        modifications?.length === 20 ?
                                                            <button disabled className={`${classes.addModificationBtn} ${classes.disableAddModificationBtn}`} onClick={handleAddModification}>
                                                                <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD item
                                                            </button>
                                                            :
                                                            <button className={classes.addModificationBtn} onClick={handleAddModification}>
                                                                <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD item
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Total Order Value</p>
                                        <p className={classes.valueLabel}>$ {modifications?.reduce(((a, b) => a + b.quantity * b.price), 0)}</p>
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Payment Terms <TooltipMessage text="Please enter Payment Terms" /></p>
                                        <div style={{ width: "100%", maxWidth: "325px" }}>
                                            <Radio.Group className='paymentTerms' onChange={(e) => setPaymentTerms(e.target.value)} value={paymentTerms}>
                                                <Radio className={classes.radioInner} value={1}>Pay Now</Radio>
                                                <Radio className={classes.radioInner} value={2}>Pay Deposit</Radio>
                                            </Radio.Group>
                                            {
                                                paymentTerms === 1 ?
                                                    <div className={classes.paymentTerms}>
                                                        <p>Payment <TooltipMessage text="Please enter Payment" /></p>
                                                        <Input className={classes.paymentInput} type="number" name="payment1" value={modifications?.reduce(((a, b) => a + b.quantity * b.price), 0)} onChange={formik.handleChange} placeholder="50" prefix={<span className={classes.prefix}>$</span>} />
                                                        <DatePicker
                                                            format={"MM/DD/YYYY"}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format('MM/DD/YYYY');
                                                                return current && current < moment(customDate, 'MM/DD/YYYY');
                                                            }}
                                                            placeholder="01-01-2023"
                                                            suffixIcon={false}
                                                            name="payBefore1"
                                                            onChange={(d, ds) => setPayBefore1(ds)}
                                                            className={classes.datePicker}
                                                        />
                                                    </div>
                                                    :
                                                    paymentTerms === 2 &&
                                                    <div className={classes.paymentTerms}>
                                                        <p>Payment <TooltipMessage text="Please enter Payment" /></p>
                                                        {/* <input type="number" name="payment1" value={formik.values.payment1} onChange={(e) => e.target.value < modifications?.reduce(((a, b) => a + b.quantity * b.price), 0) ? formik.handleChange(e) : formik.handleChange(e)} /> */}
                                                        <Input className={classes.paymentInput} type="number" name="payment1" value={payment1} onKeyPress={handleKeyPress} onChange={handlePayment1Change} placeholder="50" prefix={<span className={classes.prefix}>$</span>} />
                                                        <DatePicker
                                                            format={"MM/DD/YYYY"}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format('MM/DD/YYYY');
                                                                return current && current < moment(customDate, 'MM/DD/YYYY');
                                                            }}
                                                            placeholder="01-01-2023"
                                                            suffixIcon={false}
                                                            name="payBefore1"
                                                            onChange={(d, ds) => setPayBefore1(ds)}
                                                            className={classes.datePicker}
                                                        />
                                                        <div className={classes.uponFulfillment}>
                                                            <p>Upon Fulfillment <TooltipMessage text="Please enter Payment" /></p>
                                                            <Input className={classes.paymentInput} type="number" value={payment2} name="payment2" placeholder="50" prefix={<span className={classes.prefix}>$</span>} />
                                                            <DatePicker
                                                                suffixIcon={false}
                                                                format={"MM/DD/YYYY"}
                                                                disabledDate={(current) => {
                                                                    let customDate = payBefore1 ? moment(payBefore1).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
                                                                    return current && current < moment(customDate, 'MM/DD/YYYY');
                                                                }}
                                                                placeholder="01-01-2023"
                                                                name="payBefore2"
                                                                onChange={(d, ds) => setPayBefore2(ds)}
                                                                className={classes.datePicker}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Supplier Name</p>
                                        <p className={classes.valueLabel}>{user?.firstname} {user.lastname}</p>
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p> <span className={classes.smallWidth}>Buyer Name</span> <TooltipMessage text="Please enter Buyer Name" /></p>
                                        <div className={classes.selectWrapper}>
                                            <BuyerName
                                                dropdown={buyerNamesArray}
                                                name="buyerName"
                                                width={'100%'}
                                                value={formik.values.buyerName ? formik.values.buyerName : "- Select a buyer name -"}
                                                onChange={(val) => setBuyerDetails(val)}
                                                setDropdown={(val) => setFieldValue("buyerName", val)}
                                            />
                                            {
                                                formik.values.buyerName == "" &&
                                                <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {""}{
                                                    formik.values.buyerName == "" ? "Please select buyer name" : null
                                                }</div>
                                            }

                                        </div>
                                    </div>
                                    {
                                        formik.values.buyerName &&
                                        <div className={classes.col12}>
                                            <div className={classes.wrapper}>
                                                <p></p>
                                                <div className={classes.buyerNameWrapper}>
                                                    <p className={classes.title}> Quote ID<TooltipMessage text="Please enter Quote ID" /></p>
                                                    <p className={classes.valueLabel}>#{buyerDetails?.quoteNumber}</p>
                                                    <p className={classes.title}>Shipping Address</p>
                                                    <p className={classes.valueLabel}>{buyerDetails?.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <hr />
                                <div className="col-12">
                                    <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "start", marginTop: "8px" }} className={`${classes.wrapper}`}>
                                        {
                                            formik.values.invoiceReason === '' ||
                                                formik.values.buyerName == "" ||
                                                modifications?.length === 0 ||
                                                payBefore1 === "" ||
                                                paymentTerms === 2 && (payBefore2 === "" || payment2 === "") ||
                                                modifications?.some(obj => {
                                                    for (const key in obj) {
                                                        if (!obj[key] || obj[key].length === 0) {
                                                            return true;
                                                        }
                                                    }
                                                    if (obj.images.length === 0) {
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                ?
                                                <button
                                                    disabled
                                                    className={clsx(['button', classes.saveButton])}
                                                >
                                                    SUBMIT FOR PAYMENT
                                                </button>
                                                :
                                                <button
                                                    onClick={() => handleSubmitForm("button")}
                                                    // type='submit'
                                                    className={clsx(['button', classes.saveButton])}
                                                >
                                                    SUBMIT FOR PAYMENT
                                                </button>
                                        }
                                        <button
                                            onClick={() => history.push("/dashboard/supplier/invoices")}
                                            style={{ maxWidth: "160px", background: "transparent", color: "#303030" }}
                                            className={clsx(['button', classes.saveButton])}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </form >
        </div >
    )
}

export default AddInvoice;

