import React, { useEffect, useState } from 'react'
import classes from './ShippingAndPayment.module.scss'
import clsx from 'clsx'
import ButtonElement from '../../../../constant/public/Button/ButtonElement'
import AddAddressModal from './AddAddressModal/AddModal'
import EditAddressModal from './EditAddressModal/EditModal'
import AddPaymentModal from './AddPaymentModal/AddModal'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import pinkCard from '../../../../assets/png/pink_card.png'
import gradientCard from '../../../../assets/png/gradient_card.png'
import cardPoints from '../../../../assets/svg/card-points.svg'
import DeleteModal from '../DepositMethods/DeleteModal'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { OpenNotificationWithIcon } from '../../../../constant/public/Notification/Notification'
import { PlusOutlined } from '@ant-design/icons'
import Loading from '../../../../constant/Loading/Loading'

const ShippingAndPayment = () => {
  const [shippingInfo, setShippingInfo] = useState([])
  const [paymentInfo, setPaymentInfo] = useState([])

  const [addShippingModal, setAddShippingModal] = useState(false)
  const [editShippingModal, setEditShippingModal] = useState(false)
  const [addPaymentModal, setAddPaymentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [client_secret, setClient_secret] = useState("")
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [addressObj, setAddressObj] = useState({});
  const [deleteType, setDeleteType] = useState(null)

  const [defaultAddress, setDefaultAddress] = useState(1)
  const [defaultPayment, setDefaultPayment] = useState(1)
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const deleteMethod = () => {
    switch (deleteType) {
      case 'address': {
        deleteAddress(deleteIndex);
        break
      }
      case 'payment': {
        deletePaymentMethod(deleteIndex)
        break
      }
      default:
        break
    }
  }

  const getShippingAddresses = () => {
    if (user !== null && user !== undefined) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${user._id}/shipping-address`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setShippingInfo(res.data)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        })
    }
  }

  const getPaymentMethods = () => {
    if (user !== null && user !== undefined) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${user._id}/stripe/payment-methods`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          setPaymentInfo(res.data)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        })
    }
  }


  useEffect(() => {
    getShippingAddresses();
    getPaymentMethods();

    return () => {

    }
  }, []);



  const handleDeleteBtn = (idx, type) => {
    setDeleteType(type)
    setDeleteIndex(idx)
    setDeleteModal(true)
  }


  const handleUpdate = () => {
    getShippingAddresses();
    setAddressObj({});
  }


  const deleteAddress = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/users/${user._id}/shipping-address/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleUpdate();
        OpenNotificationWithIcon("success", "Address deleted", "");
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      })
  }

  const deletePaymentMethod = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/users/${user._id}/stripe/payment-methods/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        getPaymentMethods();
        OpenNotificationWithIcon("success", "Card deleted", "");
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      })
  }


  const createPaymentIntent = () => {
    window.scrollTo(0, 0)
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/${user._id}/stripe/setup-intent`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setClient_secret(res.data.client_secret);
        // OpenNotificationWithIcon("success", "Payment intent created", "");
        setAddPaymentModal(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      })
  }

  const makeDefaultShipping = (shipp) => {
    window.scrollTo(0, 0)
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${user?.id}/shipping-address/${shipp._id}`,
        {
          type: "home",
          firstName: shipp.firstName,
          lastName: shipp.lastName,
          address1: shipp.address1,
          address2: shipp.address2,
          city: shipp.city,
          state: shipp.state,
          country: shipp.country,
          zip: shipp.zip,
          phone: shipp.phone,
          default: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        OpenNotificationWithIcon("success", "Address Saved", "");
        setLoading(false);
        getShippingAddresses();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response)
      })
  }

  return (
    <>
      <Navigation />
      <div>
        {
          loading ?
            <div>
              <Loading />
            </div>
            :
            <div className={classes.containerWrapper}>
              {/* <div className={classes.actionSectionWrapper}>
            <BuyerAside />
          </div> */}
              <div className={classes.mainSectionWrapper}>
                <div className={classes.deposit}>
                  <div className={classes.wrapper}>
                    {shippingInfo[0] ? (
                      <div className={classes.depositsWrapper}>
                        <h2 className={classes.title}>Shipping Info</h2>
                        {
                          loading ?
                            <Loading />
                            :
                            <ul className={classes.depositList}>
                              {shippingInfo.map((address, idx) => {
                                const id = idx + 1
                                return (
                                  <li className={classes.depositItem} key={id}>
                                    <h4 className={classes.depositTitle}>
                                      {address.type}
                                      <button
                                        type="button"
                                        className={classes.depositEditBtn}
                                        onClick={() => { setAddressObj(address); setEditShippingModal(true) }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M15.0524 3.69766L12.4435 1.07543C12.2711 0.903907 12.0378 0.807617 11.7946 0.807617C11.5514 0.807617 11.3181 0.903907 11.1457 1.07543L1.89683 10.311L1.05238 13.9554C1.02325 14.0887 1.02425 14.2267 1.0553 14.3595C1.08636 14.4923 1.14669 14.6165 1.23188 14.7229C1.31707 14.8294 1.42497 14.9155 1.5477 14.975C1.67044 15.0344 1.8049 15.0657 1.94127 15.0665C2.00481 15.073 2.06884 15.073 2.13238 15.0665L5.81683 14.2221L15.0524 4.99543C15.2239 4.82304 15.3202 4.58974 15.3202 4.34654C15.3202 4.10335 15.2239 3.87005 15.0524 3.69766ZM5.37238 13.4221L1.91905 14.1465L2.70572 10.7599L9.62572 3.86654L12.2924 6.53321L5.37238 13.4221ZM12.8879 5.88877L10.2213 3.2221L11.7679 1.68432L14.3902 4.35099L12.8879 5.88877Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </button>
                                    </h4>
                                    <ul className={classes.depositInfo}>
                                      <li className={classes.depositInfoItem}>
                                        <p>Name</p>
                                        <span>{address.firstName} {address.lastName}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>City</p>
                                        <span>{address.city}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>Country</p>
                                        <span>{address.country}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>
                                          State/ <br />
                                          Province
                                        </p>
                                        <span>{address.state}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>Street</p>
                                        <span>{address.address1}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>ZIP</p>
                                        <span>{address.zip}</span>
                                      </li>
                                      <li className={classes.depositInfoItem}>
                                        <p>Apt, Suite <br /> (optional)</p>
                                        <span>{address.address2}</span>
                                      </li>
                                      {/* <li className={classes.depositInfoItem}>
                                    <p>Phone Number</p>
                                    <span>+1 562-985-4111</span>
                                  </li> */}
                                    </ul>
                                    <div className={classes.handleMethod}>
                                      <label
                                        className={clsx({
                                          [classes.defaultMethod]: true,
                                          [classes.defaultMethod_checked]:
                                            +address?.default,
                                        })}
                                      >
                                        <input
                                          style={{ display: 'none' }}
                                          type="radio"
                                          checked={address?.default}
                                          value={address?.default}
                                          name="default_method"
                                          onChange={(evt) => { setDefaultAddress(+evt.target.value); evt.target.checked && makeDefaultShipping(address) }
                                          }
                                        />
                                        Use as a default method
                                      </label>
                                      <div className={classes.deleteMethod}>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleDeleteBtn(address._id, 'address')
                                          }
                                        >
                                          Delete Address
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}
                            </ul>
                        }
                        <div className={classes.anotherMethod}>
                          <p>Add another address</p>
                          <button
                            className={classes.addBtn}
                            type="button"
                            onClick={() => setAddShippingModal(true)}
                          >
                            <PlusOutlined />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.depositWrapper}>
                        <h2 className={classes.title}>Shipping info</h2>
                        <p className={classes.depositText}>
                          No addresses added yet
                        </p>
                        <ButtonElement
                          className={classes.setMethod}
                          content={'Add new address'}
                          function={() => setAddShippingModal(true)}
                        />
                      </div>
                    )}
                    {paymentInfo[0] ? (
                      <div className={classes.depositsWrapper}>
                        <h2 className={classes.title}>Payment Info</h2>
                        <ul className={classes.depositList}>
                          {paymentInfo.map((method, idx) => {
                            const id = idx + 1

                            return (
                              <li className={classes.cardDepositItem} key={id}>
                                <div className={classes.depositCard}>
                                  <img
                                    src={id % 2 ? pinkCard : gradientCard}
                                    alt="bank card"
                                  />
                                  <div className={classes.cardInfo}>
                                    <span className={classes.cardNumber}>
                                      <img
                                        width="72"
                                        src={cardPoints}
                                        alt="Uplio"
                                      />
                                      <img
                                        width="72"
                                        src={cardPoints}
                                        alt="Uplio"
                                      />
                                      <img
                                        width="72"
                                        src={cardPoints}
                                        alt="Uplio"
                                      />
                                      {method?.card?.last4}
                                    </span>
                                    <span className={classes.ownerName}>
                                      {method?.billing_details?.name}
                                    </span>
                                    <span className={classes.cardDate}>{method?.card?.exp_month}/{method?.card?.exp_year}</span>
                                  </div>
                                </div>
                                <div className={classes.handleCardMethod}>
                                  {/* <label
                                    className={clsx({
                                      [classes.defaultMethod]: true,
                                      [classes.defaultMethod_checked]:
                                        +defaultPayment === id,
                                    })}
                                  >
                                    <input
                                      style={{ display: 'none' }}
                                      type="radio"
                                      value={id}
                                      name="default_method"
                                      onChange={(evt) =>
                                        setDefaultPayment(+evt.target.value)
                                      }
                                    />
                                    Use aÏs a default method
                                  </label> */}
                                  <div className={classes.deleteMethod}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDeleteBtn(method.id, 'payment')
                                      }
                                    >
                                      Delete Card
                                    </button>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                        <div className={classes.anotherMethod}>
                          <p>Add another payment method</p>
                          <button
                            className={classes.addBtn}
                            type="button"
                            onClick={() => createPaymentIntent()}
                          >
                            <PlusOutlined />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.depositWrapper}>
                        <h2 className={classes.title}>Payment info</h2>
                        <p className={classes.depositText}>
                          No credit card added yet
                        </p>
                        <ButtonElement
                          className={classes.setMethod}
                          content={'Add payment method'}
                          function={() => createPaymentIntent()}
                        />
                      </div>
                    )}
                  </div>
                  <AddAddressModal
                    modalOpened={addShippingModal}
                    setModalOpened={setAddShippingModal}
                    handleUpdate={handleUpdate}
                    setShippingInfo={setShippingInfo}
                  />
                  <EditAddressModal
                    addressObj={addressObj}
                    modalOpened={editShippingModal}
                    handleUpdate={handleUpdate}
                    setModalOpened={setEditShippingModal}
                    setShippingInfo={setShippingInfo}
                  />
                  <AddPaymentModal
                    client_secret={client_secret}
                    modalOpened={addPaymentModal}
                    setModalOpened={setAddPaymentModal}
                    // shippingInfo={shippingInfo}
                    setShippingInfo={setPaymentInfo}
                  />
                  {
                    <DeleteModal
                      note={
                        deleteType === 'payment'
                          ? 'Are you sure you want to delete this payment method?'
                          : deleteType === 'address'
                            ? 'Are you sure you want to delete this address?'
                            : null
                      }
                      modalOpened={deleteModal}
                      setModalOpened={setDeleteModal}
                      deleteIndex={deleteIndex}
                      setDeleteIndex={setDeleteIndex}
                      deleteMethod={deleteMethod}
                      setDeleteType={setDeleteType}
                    />
                  }
                  <div
                    className={`blocker ${addShippingModal ||
                      editShippingModal ||
                      addPaymentModal ||
                      deleteModal
                      ? 'blocker_opened'
                      : null
                      }`}
                  ></div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default ShippingAndPayment
