import React, { useState, useEffect, memo, lazy } from 'react'
import classes from './BuyerInfo.module.scss'
import clsx from 'clsx'
import CompanyRow from '../CompanyRow/CompanyRow'
import axios from 'axios'
import { Avatar, Col, Row, Select, Spin } from 'antd'
import { useSelector } from 'react-redux'
import errorIcon from '../../../../assets/svg/error_icon.svg'
import {  UserOutlined } from '@ant-design/icons'
import cam from '../../../../assets/svg/camera.svg'
import { useHistory } from 'react-router-dom'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../constant/public/Input/InputElement'
import { supplierUserDataStatus } from '../../../../redux/actions/supplierProfile.action'
import { useDispatch } from 'react-redux'
import InputWithDropDownWithoutSearch from '../../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch'
import MultiSelect from '../../../../constant/public/MultiSelect/MultiSelect'
import Loading from '../../../../constant/Loading/Loading'

const BuyerInfo = memo((props) => {
  const [imageUploadingInProgress, setImageUploadingInProgress] = useState(false)
  const hiddenFileInput = React.useRef(null)
  const [userImage, setUserImage] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [passwordModalOpened, setPasswordModalOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState([]);
  const userData = useSelector((state) => state.user.user)

  const annualSalesArray = [
    {
      id: "$0-50K",
      label: "$0-50K",
    },
    {
      id: "$50K-$100K",
      label: "$50K-$100K",
    },
    {
      id: "$100K-$200K",
      label: "$100K-$200K",
    },
    {
      id: "$200K-$500K",
      label: "$200K-$500K",
    },
    {
      id: "$500K- $1M",
      label: "$500K- $1M",
    },
    {
      id: "$1M- $3M",
      label: "$1M- $3M",
    },
    {
      id: "> $3M",
      label: "> $3M",
    },
  ]



  const history = useHistory()

  const { setFieldValue } = props.Formik;


  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const dispatch = useDispatch()

  const handleViewProfile = () => {
    dispatch(supplierUserDataStatus(props.Formik.values))
    history.push({ pathname: '/dashboard/supplier/profile/overview' })
  }

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories`)
      .then((res) => {
        let newArr = []
        res.data.categories.map((item, index) => (
          newArr.push({
            value: item._id,
            label: item.name,
          })
        ))
        setCategoryData(newArr)
      })
      .catch((err) => {
        console.log(err.response);
      });

    setFieldValue("category", props.Formik.values.category);
  }, []);

  useEffect(() => {
    setUserImage(props.Formik.values.profileImage);

    return () => {

    }
  }, [props.Formik])




  const handleProfileImage = (event) => {
    const formData = new FormData();
    const baseURL = process.env.REACT_APP_API_URL
    formData.append('image', event.currentTarget.files[0])
    axios({
      method: "PATCH",
      url: baseURL + '/users/' + user.id + "/profile_image/",
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "bearer " + token
      }
    })
      .then(function (response) {
        if (response.status == 200) {
          setFieldValue('profileImage', response.data.data.profileImage)
          setImageUploading(false)
        }
        else if (response.status == 400) {

        }
      })
  }

  const handleUpdatePassword = () => {
    setPasswordModalOpened(false)
    props.handleUpdatePassword()
    setFieldValue('password', '')
    setFieldValue('oldPassword', '')
    setFieldValue('confirmPassword', '')
  }

  const handleSubmit = async () => {
    await props.handleProfileUpdated();
  }
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    const data = new FormData()
    data.append('file', fileUploaded)
    let url = `${process.env.REACT_APP_API_URL}/users/${userData._id}/profile_image`
    axios
      .patch(url, data, {})
      .then((res) => {
        setUserImage(res.data.data.profileImage);
        setFieldValue("profileImage", res.data.data.profileImage)
        setImageUploading(false)
        // handleEditing(true, res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function NameAbbreviation({ name }) {
    const abbreviation = name
      ?.split(' ')
      ?.map(part => part.charAt(0))
      ?.join('')
      ?.toUpperCase();

    return <div>{abbreviation}</div>;
  }

  return (
    loading ?
      <div>
        <Loading />
      </div>
      :
      <div className={classes.companyInfoContainer}>
        <h1 className={clsx([`title title_item`, classes.title])}>
          Personal info
        </h1>
        <Row>
          <Col order={1} sm={{ order: 0 }} xs={24} md={20}>
            <div className={classes.companyInfo}>
              <div className={classes.companyInfoTop}>
                <div className={classes.content}>
                  <div>
                    <CompanyRow>
                      <p>First Name</p>
                      <InputElement
                        name="firstName"
                        type="text"
                        className={clsx([`input`, classes.companyInput])}
                        wrapperClass={classes.companyInput}
                        placeholder="First Name"
                        onChange={props.Formik.handleChange}
                        value={props.Formik.values.firstName}
                        helperText={props.Formik.errors.firstName
                          ? props.Formik?.errors.firstName
                          : null}
                      />
                    </CompanyRow>
                    <CompanyRow>
                      <p>Last Name</p>
                      <InputElement
                        name="lastName"
                        type="text"
                        value={props.Formik.values.lastName}
                        onChange={props.Formik.handleChange}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        pattern="[0-9]*"
                        className={clsx([`input`, classes.companyInput])}
                        wrapperClass={classes.companyInput}
                        placeholder="Last Name"
                        helperText={props.Formik.errors.lastName
                          ? props.Formik?.errors.lastName
                          : null}
                      />
                    </CompanyRow>
                    <CompanyRow>
                      <div className={classes.line}>
                        <p>Email</p>
                        <InputElement
                          name="email"
                          onChange={props.Formik.handleChange}
                          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                          pattern="[0-9]*"
                          type="email"
                          className={clsx([`input`, classes.companyInput])}
                          wrapperClass={classes.companyInput}
                          value={props.Formik.values.email}
                          placeholder="Email"
                          helperText={props.Formik.errors.email
                            ? props.Formik?.errors.email
                            : null}

                        />
                      </div>
                    </CompanyRow>
                    <CompanyRow>
                      <div className={classes.line}>
                        <p>Phone Number</p>
                        <InputElement
                          type="text"
                          name="phone"
                          placeholder="+1 1231231234"
                          value={props.Formik.values.phone}
                          wrapperClass={classes.companyInput}
                          className={clsx([`input`, classes.companyInput])}
                          onChange={props.Formik.handleChange}
                          onBlur={props.Formik.handleBlur}
                          helperText={
                            props.Formik.errors.phone && props.Formik.touched.phone
                              ? props.Formik?.errors.phone
                              : null
                          }
                          width={'100%'}
                        />
                      </div>
                    </CompanyRow>
                    <div className={classes.lastSection}>
                      <CompanyRow>
                        <p className={classes.passwordLabel}>Password</p>
                        <div className={classes.changePassword}>
                          <button type="button" className={classes.changePasswordBtn} onClick={() => setPasswordModalOpened(true)}>
                            Change Password
                          </button>
                          <CommonModal
                            title={'Change password'}
                            modalOpened={passwordModalOpened}
                            setModalOpened={setPasswordModalOpened}
                          >
                            <div className={classes.changePasswordForm}>
                              <label>
                                Old Password*
                                <div className={classes.passwordChange}>
                                  <InputElement
                                    name="oldPassword"
                                    placeholder="Password"
                                    type="password"
                                    value={props.Formik.values.oldPassword}
                                    onChange={props.Formik.handleChange}
                                    onBlur={props.Formik.handleBlur}
                                    width="100%"
                                    helperText={
                                      props.Formik.errors.oldPassword &&
                                        props.Formik.touched.oldPassword
                                        ? props.Formik?.errors.oldPassword
                                        : null
                                    }
                                  />
                                </div>
                              </label>
                              <label>
                                New Password*
                                <div className={classes.passwordChange}>
                                  <InputElement
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    value={props.Formik.values.password}
                                    onChange={props.Formik.handleChange}
                                    onBlur={props.Formik.handleBlur}
                                    width="100%"
                                    helperText={
                                      props.Formik.errors.password &&
                                        props.Formik.touched.password
                                        ? props.Formik?.errors.password
                                        : null
                                    }
                                  />
                                </div>
                              </label>
                              <label>
                                Confirm New Password*
                                <div className={classes.passwordChange}>
                                  <InputElement
                                    name="confirmPassword"
                                    placeholder="Password"
                                    type="password"
                                    value={props.Formik.values.handleChange}
                                    onChange={props.Formik.handleChange}
                                    onBlur={props.Formik.handleBlur}
                                    width="100%"
                                    helperText={
                                      props.Formik.errors.confirmPassword &&
                                        props.Formik.touched.confirmPassword
                                        ? props.Formik?.errors.confirmPassword
                                        : null
                                    }
                                  />
                                </div>
                              </label>
                              <div className={classes.submitWrapper}>
                                {
                                  props.Formik.errors?.oldPassword ||
                                    props.Formik.errors?.password ||
                                    props.Formik.errors?.confirmPassword ?
                                    <button
                                      disabled
                                      className={clsx([`button`, classes.submit])}
                                      aria-label="close"
                                    >
                                      CHANGE PASSWORD
                                    </button>
                                    :
                                    <button
                                      onClick={() => handleUpdatePassword()}
                                      className={clsx([`button`, classes.submit])}
                                      aria-label="close"
                                    >
                                      CHANGE PASSWORD
                                    </button>
                                }
                              </div>
                            </div>
                          </CommonModal>
                        </div>
                      </CompanyRow>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col order={0} sm={{ order: 1 }} xs={24} md={4} className={classes.avatarCont}>
            {
              userImage === '' ?
                <div className={classes.imageContainer}>
                  {NameAbbreviation(props.Formik.values.firstName)}
                </div>
                :
                <div className={classes.uploadedImage}>
                  <img src={userImage} alt="User" />
                </div>
            }
            <div className={classes.space}>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <button
                className={classes.editButton}
                onClick={() => handleClick('inspiration')}
              >
                <img src={cam} alt="Uplio" />
              </button>
            </div>
          </Col>
        </Row>
        <div className={classes.companyInfo}>
          <div className={classes.companyInfoTop}>
            <div className={classes.content}>
              <h1 className={clsx([`title title_item`, classes.title])}>
                Business info
              </h1>
              <div>
                <CompanyRow>
                  <p>Brand Name</p>
                  <InputElement
                    name="brandName"
                    type="text"
                    className={clsx([`input`, classes.companyInput])}
                    wrapperClass={classes.companyInput}
                    placeholder="Brand Name"
                    onChange={props.Formik.handleChange}
                    value={props.Formik.values.brandName}
                    helperText={props.Formik.errors.brandName
                      ? props.Formik?.errors.brandName
                      : null}
                  />
                </CompanyRow>
                <CompanyRow>
                  <p>Category you sell</p>
                  <div className={classes.selectWrapper}>
                    {/* <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      className={classes.antSelect}
                      placeholder="Please select"
                      onChange={handleChange}
                      options={categoryData}
                    /> */}
                    <MultiSelect
                      dropdown={categoryData}
                      categories={true}
                      width={'100%'}
                      placeholder="- Select a category -"
                      value={props.Formik.values.category}
                      setDropdown={(value) => setFieldValue("category", value)}
                    />
                    {/* <InputWithDropDownWithoutSearch
                      dropdown={categoryData}
                      categories={true}
                      width={'100%'}
                      value={props.Formik.values.categoryName ? props.Formik.values.categoryName : "- Select a category -"}
                      setDropdown={(value) => setFieldValue("category", value)}
                    /> */}
                    {
                      props.Formik.values.category?.length === 0 &&
                      <p className={classes.errorMessage}>
                        <img src={errorIcon} alt="Uplio" /> Category is required
                      </p>
                    }
                  </div>
                </CompanyRow>
                <CompanyRow>
                  <p>Annual sales revenue</p>
                  <div className={classes.selectWrapper}>
                    <InputWithDropDownWithoutSearch
                      dropdown={annualSalesArray}
                      // categories={true}
                      width={'100%'}
                      value={props.Formik.values.annualSales ? props.Formik.values.annualSales : "- Select a value -"}
                      setDropdown={(value) => setFieldValue("annualSales", value)}
                    />
                    {
                      props.Formik.errors.annualSales &&
                      <p className={classes.errorMessage}>
                        <img src={errorIcon} alt="Uplio" /> Annual Sales is required
                      </p>
                    }
                  </div>
                </CompanyRow>
                <CompanyRow>
                  <div className={classes.line}>
                    <p>Website URL</p>
                    <InputElement
                      name="websiteUrl"
                      onChange={props.Formik.handleChange}
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      type="text"
                      className={clsx([`input`, classes.companyInput])}
                      wrapperClass={classes.companyInput}
                      value={props.Formik.values.websiteUrl}
                      placeholder="uplio.com"
                      helperText={props.Formik.errors.websiteUrl
                        ? props.Formik?.errors.websiteUrl
                        : null}

                    />
                  </div>
                </CompanyRow>
                <CompanyRow>
                  <div className={classes.line}>
                    <p className={classes.instagramTitle}>Instagram (optional)</p>
                    <InputElement
                      type="text"
                      name="instagramUrl"
                      placeholder="@uplio_marketplace"
                      value={props.Formik.values.instagramUrl}
                      wrapperClass={classes.companyInput}
                      className={clsx([`input`, classes.companyInput])}
                      onChange={props.Formik.handleChange}
                      onBlur={props.Formik.handleBlur}
                      helperText={
                        props.Formik.errors.instagramUrl && props.Formik.touched.instagramUrl
                          ? props.Formik?.errors.instagramUrl
                          : null
                      }
                      width={'100%'}
                    />
                  </div>
                </CompanyRow>
              </div>
            </div>
            <div className={classes.avatarBlock}>
              <div className={classes.avatarBlockWrapper}>
                <h1 className={clsx([`title title_item`, classes.mobileTitle])}>
                  Business info
                </h1>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleProfileImage}

                  style={{ display: 'none' }}
                />
                <div hidden className={classes.avatarWrapper}>
                  {imageUploading ? (
                    !imageUploadingInProgress ? (
                      <button
                        className={classes.uploadButton}
                        onClick={(evt) => handleClick()}
                      >
                        +
                      </button>
                    ) : (
                      <Spin size="large" />
                    )
                  ) : props.Formik.values.profileImage === '' ? (
                    <Avatar
                      className={classes.avatar}
                      size={209}
                      icon={<UserOutlined />}
                    />
                  ) : (
                    <Avatar
                      className={classes.avatar}
                      size={209}
                      src={props.Formik.values.profileImage}
                    />
                  )}
                  <div className={classes.upload}>
                    <input hidden
                      type="file"
                      accept="image/*"
                      onChange={
                        handleProfileImage
                      }
                      name='image'
                    />

                    <button
                      className={classes.editButton}
                      onClick={() => setImageUploading(true)}
                    >
                      <img src={cam} alt="Uplio" />
                    </button>
                  </div>
                </div>
                <div hidden className={classes.viewProfile}>
                  <button onClick={() => handleViewProfile()}>
                    VIEW MY PROFILE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          props.Formik.errors.firstName || props.Formik.errors.lastName || props.Formik.errors.email
            || props.Formik.errors.phone || props.Formik.errors.brandName ||
            props.Formik.values.category?.length === 0 ||
            props.Formik.errors.annualSales ||
            props.Formik.errors.websiteUrl ||
            props.Formik.errors.instagramUrl
            ?
            <div className={classes.disableButton}>
              <button
                disabled
              >
                Save
              </button>
            </div>
            :
            <div className={classes.save}>
              <button
                className={clsx(['button', classes.saveButton])}
                onClick={() => handleSubmit()}
              >
                Save
              </button>
            </div>
        }
      </div>
  )
})

export default BuyerInfo
