import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import QuillToolbar, { formats, modules } from './Toolbar'
import "./RichTextEditor.css";
import { decode } from 'html-entities';


const RichTextEditor = ({ value, onChange }) => {
    const [decodedValue, setDecodedValue] = useState("");

    useEffect(() => {
        setDecodedValue(decode(value))

        return () => {

        }
    }, [])


    return (
        <div className="RichTextEditor">
            <QuillToolbar />
            <ReactQuill value={decode(value)} onChange={onChange} formats={formats} modules={modules} theme="snow" />
        </div>
    )
}

export default RichTextEditor