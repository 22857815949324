import React, { useState, useEffect } from 'react'
import classes from './ProjectImages.module.scss'
import CompanyRow from '../CompanyRow/CompanyRow'
import UploadFiles from '../../../../constant/public/UploadFiles/UploadFiles'
import axios from 'axios'
import { useSelector } from 'react-redux'
import TooltipMessage from '../../../../constant/TooltipMessage'
import errorIcon from '../../../../assets/svg/error_icon.svg'
import LoadingModal from '../../../../constant/public/LoadingModal/LoadingModal'


const ProjectImages = (props) => {
  const [loadingImage, setLoadingImage] = useState(false)
  const [documentValue, setDocumentValue] = useState(null)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [type, setType] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState("")
  const [imageArray, setImageArray] = useState([])
  const data = props.data
  const title = props.title
  const text = props.text;

  useEffect(() => {
    handleChange(documentValue, false)
  }, [documentValue])

  useEffect(() => {
    setType(props.type)

    return () => {

    }
  }, [])


  useEffect(() => {
    setImageArray(data)
  }, [data])
  const baseURL = process.env.REACT_APP_API_URL
  const user = useSelector((state) => state.user.user);

  const token = sessionStorage.getItem('token')

  useEffect(() => {
    handleChange(value, true)
  }, [value]);

  const DeletePPI = (id) => {
    localStorage.setItem("idPPI", id)
    if (props?.userData?.profileCompleted) {

    }
    else {
      axios({
        method: "DELETE",
        url: baseURL + '/users/' + user?.id + "/media/",
        data: {
          mediaId: localStorage.getItem("idPPI")
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "bearer " + token
        }
      }).then((res) => {
        console.log(res.data.data)
        setLoadingImage(false)
        let imageFilter = imageArray.filter(f => f._id !== id)
        setImageArray(imageFilter);
        props.handleImage(imageFilter)
        console.log(imageFilter)
      })
    }
  }

  const handleChange = (event, document) => {
    if (props?.userData?.profileCompleted) {

    } else {
      if (event !== null) {
        if (document) {
          setLoadingDocument(true)
        } else {
          setLoadingImage(true)
        }

        const fileUploaded = event.target.files[0]

        const data = new FormData()

        data.append('file', fileUploaded)

        let url = `${process.env.REACT_APP_API_URL}/quotes/uploadFile`

        axios
          .post(url, data, {
            // receive two parameter endpoint url ,form data
          })
          .then((res) => {
            // setFieldValue('pastProjectImages', res.data.images)
            setLoadingImage(false)
            if (imageArray.length > 0) {
              let arr = imageArray
              arr.push(res.data.data)
              setImageArray(arr)
              props.handleImage(arr)
            } else {
              const arr = []
              arr.push(res.data.data)
              setImageArray(arr)
              props.handleImage(arr)
            }

            // then print response status
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const handleUpdate = (data) => {
    setImageArray([])
    setImageArray(data.data.data.images);
    props.handleImage(data.data.data.images)
  }

  return (
    <div>
      <CompanyRow>
        <div className={classes.companyTitleWrapper}>
          {
            title &&
            <p style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>{title}</span>
              {
                props.tooltip &&
                <TooltipMessage text="Ensure to add up to two relevant industry licenses and/or certificates. This information will help increase customer trust on your business. " />
              }
            </p>
          }
          {!title && <p></p>}
          <p className={classes.imageUploadText}>{text}</p>
        </div>
        <div>
          {
            isModalOpen &&
            <LoadingModal text={"Please wait. Thewdwd"} openModal={isModalOpen} />
          }
        </div>
        <div className={`${classes.imagesWrapper} ${!title && classes.document} ${props.type === "LCD" && classes.documentSection}`}>
          <UploadFiles userData={props?.userData} loadingModal={() => setIsModalOpen(!isModalOpen)} title={title} type={props.type} handleUpdate={handleUpdate} disabled={loadingImage} onChange={setDocumentValue} />
          {
            imageArray && imageArray.length > 0 && imageArray.filter(f => f.type === props.type).map((item) => {
              return (
                <>
                  <label className={classes.cover} key={item}>
                    {
                      item.type === "LCD" ?
                        <div className={classes.documentName}
                          onMouseEnter={() => setShowCloseIcon(item._id)}
                          onMouseLeave={() => setShowCloseIcon("")}>
                          <p style={{ border: "1 px solid black" }}>
                            {item.name}
                          </p>
                          {
                            showCloseIcon === item._id &&
                            <button onMouseEnter={() => setShowCloseIcon(item.id)} type='button' className={classes.documentDeleteBtn}>
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="#C3C3C3" xmlns="http://www.w3.org/2000/svg" onClick={() => DeletePPI(item.id)}>
                                <path d="M11.7786 0.716247C12.1203 1.05796 12.1203 1.61198 11.7786 1.95368L7.73731 5.99492L11.7786 10.0361C12.1203 10.3779 12.1203 10.9319 11.7786 11.2736C11.4369 11.6153 10.8829 11.6153 10.5412 11.2736L6.49986 7.23235L2.45856 11.2736C2.11685 11.6153 1.56283 11.6153 1.22112 11.2736C0.879415 10.9319 0.879418 10.3779 1.22113 10.0361L5.26242 5.99492L1.22113 1.95368C0.879418 1.61198 0.879415 1.05796 1.22112 0.716247C1.56283 0.374536 2.11685 0.374532 2.45856 0.716238L6.49986 4.75749L10.5412 0.716238C10.8829 0.374532 11.4369 0.374536 11.7786 0.716247Z" fill="#C3C3C3" />
                              </svg>
                            </button>
                          }
                        </div>
                        :
                        <>
                          <img
                            onMouseEnter={() => setShowCloseIcon(item._id)}
                            onMouseLeave={() => setShowCloseIcon("")}
                            width="100px"
                            height="100px"
                            className={classes.imageLoaded}
                            src={item.url}
                            alt="Uplio"
                          // onChange={handleChange}
                          />
                          {
                            showCloseIcon === item._id &&
                            <button onMouseEnter={() => setShowCloseIcon(item.id)} type='button' className={classes.deleteBtn}>
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="#C3C3C3" xmlns="http://www.w3.org/2000/svg" onClick={() => DeletePPI(item.id)}>
                                <path d="M11.7786 0.716247C12.1203 1.05796 12.1203 1.61198 11.7786 1.95368L7.73731 5.99492L11.7786 10.0361C12.1203 10.3779 12.1203 10.9319 11.7786 11.2736C11.4369 11.6153 10.8829 11.6153 10.5412 11.2736L6.49986 7.23235L2.45856 11.2736C2.11685 11.6153 1.56283 11.6153 1.22112 11.2736C0.879415 10.9319 0.879418 10.3779 1.22113 10.0361L5.26242 5.99492L1.22113 1.95368C0.879418 1.61198 0.879415 1.05796 1.22112 0.716247C1.56283 0.374536 2.11685 0.374532 2.45856 0.716238L6.49986 4.75749L10.5412 0.716238C10.8829 0.374532 11.4369 0.374536 11.7786 0.716247Z" fill="#C3C3C3" />
                              </svg>
                            </button>
                          }
                        </>
                    }
                  </label>
                </>
              )
            }
            )
          }
          <p className={classes.errorMessage}>
            {props.helperText ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
            {props.helperText}
          </p>
        </div>
      </CompanyRow>
    </div>
  )
}

export default ProjectImages
