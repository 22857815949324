import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../constant/Loading/Loading';
import SortSelect from './SortSelect/SortSelect';
import classes from "./Invoices.module.scss";
import InvoiceTable from './InvoiceTable/InvoiceTable';

const BuyerInvoices = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [invoices, setInvoices] = useState([]);
    const [totalInvoices, setTotalInvoices] = useState();
    const token = sessionStorage.getItem('token')
    const [sort, setSort] = useState(0);
    const [page, setPage] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const user = useSelector((state) => state.user.user)

    const getInvoices = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/invoice/buyer/${user?.id}?limit=10&page=${page}&sortBy=createdAt:desc&status=${sort === 0 ? "" : sort}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setInvoices(res.data?.results);
                setTotalInvoices(res.data?.totalResults);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response)
            })
    }


    useEffect(() => {
        getInvoices();

        return () => {

        }
    }, [sort]);


    useEffect(() => {
        if (totalInvoices > invoices.length) {
            setLoadMoreLoading(true)
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/invoice/buyer/${user.id}?limit=10&page=${page}&sortBy=createdAt:desc&status=${sort === 0 ? "" : sort}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setInvoices(prevArray => [...prevArray, ...res.data?.results]);
                    setLoadMoreLoading(false);
                })
                .catch((err) => {
                    setLoadMoreLoading(false);
                    console.log(err.response)
                })
        }

        return () => {

        }
    }, [page]);



    const sortOptionsData = [
        {
            label: "All",
            value: "0"
        },
        {
            label: "Fully paid",
            value: "3"
        },
        {
            label: "Partially paid",
            value: "2"
        },
        {
            label: "Pending payment",
            value: "1"
        },
    ]



    return (
        <div className={classes.invoices}>
            <div className={classes.topBar}>
                <h2>invoices info</h2>
                <div className={classes.sortDropdownSection}>
                    <SortSelect setValue={(val) => setSort(parseInt(val))} optionsData={sortOptionsData} />
                </div>
            </div>
            {
                loading ?
                    <Loading />
                    :
                    invoices?.length === 0 ?
                        <div className={classes.noInvoicesCont}>
                            {/* <h2>invoices info</h2> */}
                            <p>No invoice found</p>
                        </div>
                        :
                        <div className={classes.main}>
                            {/* <div className={classes.topBar}>
                                <h2>invoices info</h2>
                                <div className={classes.sortDropdownSection}>
                                    <SortSelect setValue={(val) => setSort(val)} optionsData={sortOptionsData} />
                                </div>
                            </div> */}
                            <InvoiceTable update={getInvoices} data={invoices} />
                            <div className={classes.loadMore}>
                                {
                                    loadMoreLoading ?
                                        <Loading />
                                        :
                                        <button onClick={async () => setPage(page + 1)}>
                                            Load Older
                                        </button>
                                }
                            </div>
                        </div>
            }
        </div>
    )
}

export default BuyerInvoices
