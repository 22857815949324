import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'

import classes from './SupplierDetails.module.scss'

import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import axios from 'axios'
import InputElement from '../../../../constant/public/Input/InputElement'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import {
  userLoggedIn,
  userDataStatus
} from '../../../../redux/actions/user.action'
import { NotificationWithIconComp } from '../../../../constant/Notification'

const SupplierDetails = (props) => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)

  const handleSignUp = () => {
    props.Formik.handleSubmit();
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        name: props.Formik.values.businessName,
        phone: props.Formik.values.phone,
        email: props.Formik.values.email,
        password: props.Formik.values.password,
        userType: 'supplier',
      })
      .then((res) => {
        console.log(res);
        setLoader(false)
        dispatch(userDataStatus(res.data.user))
        dispatch(userLoggedIn())
        // props.userDataStatus(userData)
        sessionStorage.setItem('token', res.data.tokens.access.token)
        sessionStorage.setItem('refresh', res.data.tokens.refresh.token)
        props.setCurrentView('postSignUp')
      })
      .catch(err => {
        NotificationWithIconComp("error", "Oops", err.response?.data?.message)
        setLoader(false)
        console.log(err.response)
      })
  }

  // const handlePhoneChange = (e) => {
  //   const { value } = e.target;
  //   let phone = value.replace(/\D/g, "");
  //  let phone = value.substring(0, 10);

  //   let phoneFormatted = phone;
  //   if (phone.length > 6) {
  //     phoneFormatted = `${phone.substring(0, 2)} ${phone.substring(2, 6)}-${phone.substring(6, 10)}-${phone.substring(
  //       10,
  //       14
  //     )}`;
  //   } else if (phone.length > 3) {
  //     phoneFormatted = `${phone.substring(0, 3)}-${phone.substring(3, 6)}`;
  //   }
  //   console.log(phoneFormatted);
  //   setFieldValue("phone", phoneFormatted)
  // };



  return (
    <>
      <div className={classes.sideTitle}>
        <h2>SIGN UP</h2>
        <h4>SIGN UP</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <h3>Business Name*</h3>
          <InputElement
            type="text"
            placeholder="Business Name"
            name="businessName"
            value={props.Formik.values.businessName}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.businessName &&
                props.Formik.touched.businessName
                ? props.Formik?.errors.businessName
                : null
            }
            width={'100%'}
          />
          <h3>Email*</h3>
          <InputElement
            type="text"
            name="email"
            placeholder="Email*"
            value={props.Formik.values.email}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.email && props.Formik.touched.email
                ? props.Formik?.errors.email
                : null
            }
            width={'100%'}
          />
          <h3>Phone Number (Optional)</h3>
          <InputElement
            type="text"
            name="phone"
            placeholder="+1 1231231234"
            value={props.Formik.values.phone}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.phone && props.Formik.touched.phone
                ? props.Formik?.errors.phone
                : null
            }
            width={'100%'}
          />
          <h3>Password*</h3>
          <InputElement
            type="password"
            name="password"
            value={props.Formik.values.password}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            placeholder="Password"
            helperText={
              props.Formik.errors.password && props.Formik.touched.password
                ? props.Formik?.errors.password
                : null
            }
            width={'100%'}
          />
          <h3>Confirm the Password*</h3>
          <InputElement
            name="confirmPassword"
            type="password"
            placeholder="Password"
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.confirmPassword &&
                props.Formik.touched.confirmPassword
                ? props.Formik?.errors.confirmPassword
                : null
            }
            value={props.Formik.values.confirmPassword}
            width={'100%'}
          />
        </div>
        <Row>
          <Col lg={12} md={12} sm={0} xs={0}></Col>
          <Col lg={12} md={12} sm={24} xs={24} align="right">
            <div className={classes.actionButton}>
              {
                props.Formik.errors.businessName ||
                  props.Formik.errors.phone ||
                  props.Formik.errors.email ||
                  props.Formik.errors.password ||
                  props.Formik.errors.confirmPassword
                  ?
                  <div className={classes.disableButton}>
                    <ButtonWithRightArrow
                      disabled={true}
                      // function={() => handleSignUp()}
                      content="SIGN UP"
                      width={'325px'}
                    />
                  </div>
                  :
                  <ButtonWithRightArrow
                    function={() => handleSignUp()}
                    content="SIGN UP"
                    width={'325px'}
                  />
              }
              <h4>
                By submitting this form, you acknowledge that you have read and
                agree to our{' '}
                <Link to="/terms-conditions">Terms of Service </Link>
                and <Link to="/privacy-policy">Privacy Policy</Link>.
              </h4>
              <h4>
                Already have an account? <Link to="/login">Sign In</Link>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    singleQuote: state,
  }
}

export default connect(mapStateToProps)(SupplierDetails)
