import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import { useFormik } from 'formik'
import * as yup from 'yup'
import GetStarted from './GetStarted/GetStarted'
import SelectCategory from './SelectCategory/SelectCategory'
import ProjectDescription from './ProjectDescription/ProjectDescription'
import SelectQuantity from './SelectQuantity/SelectQuantity'
import LaunchDate from './LaunchDate/LaunchDate'
import UploadDocument from './UploadDocument/UploadDocument'
import PreSignUp from './PreSignUp/PreSignUp'
import PostQuoteSubmit from './PostQuoteSubmit/PostQuoteSubmit'
import PostSignUp from './PostSignUp/PostSignUp'
import { useParams } from 'react-router-dom'
import { singleQuoteData } from '../../../redux/actions/singleQuote.action'

const Quote = () => {
  const Formik = useFormik({
    initialValues: {
      description: '',
      quantity: '',
      budget: '',
      imageUploaded: '',
      documentUploaded: '',
      name: '',
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email.').required('Email is Required'),
      name: yup.string().required('Name is Required'),
      budget: yup
        .number()
        .moreThan(0, 'Budget is not allowed to be zero.')
        .required('Budget is required'),
      quantity: yup
        .number()
        .moreThan(0, 'Quantity is not allowed to be zero.')
        .required('Quantity is required'),
    }),
  })
  const token = sessionStorage.getItem('token')
  const [quoteView, setQuoteView] = useState('getStarted')
  const [category, setCategory] = useState('')
  const [projectDescription, setProjectDescription] = useState('')

  const [launchDate, setLaunchDate] = useState('')
  const [imageUploaded, setImageUploaded] = useState([])
  const [documentUploaded, setDocumentUploaded] = useState([])
  const [supplierIdExist, setSupplierIdExist] = useState(false)
  const [supplierId, setSupplierId] = useState('')
  const [userExist, setUserExist] = useState(false)
  let { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (id === 'generic_quote') {
      setSupplierIdExist(false)
      setSupplierId('')
    } else {
      setSupplierIdExist(true)
      setSupplierId(id)
    }
  }, [])

  const handleSubmit = () => {
    dispatch(
      singleQuoteData({
        projectName: '',
        productCategory: category,
        color: '',
        description: projectDescription,
        projectStartDate: '',
        projectLaunchDate: launchDate,
        quantity: Formik.values.quantity,
        budget: Formik.values.budget,
        inspirationImages: imageUploaded,
        inspirationDocument: documentUploaded,
        referenceImages: [],
        supplierIdExist: supplierIdExist,
        supplierId: supplierId,
        email: Formik.values.email,
        name: Formik.values.name,
      })
    )
    // history.push({ pathname: '/signup' })

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/quotes/save_quote`,
        {
          projectName: 'Uplio',
          productCategory: category,
          color: '',
          inspirationImages: imageUploaded,
          inspirationDocument: documentUploaded,
          description: projectDescription,
          projectStartDate: '',
          projectLaunchDate: launchDate,
          quantity: Formik.values.quantity,
          budget: Formik.values.budget,
          name: Formik.values.name,
          email: Formik.values.email,
          password: '123123',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        setQuoteView('postQuoteSubmit')
        setUserExist(res.data.userExist)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  console.log(quoteView)
  const handleQuotes = () => {
    if (quoteView === 'getStarted') {
      return <GetStarted setQuoteView={setQuoteView} Formik={Formik} />
    } else if (quoteView === 'selectCategory') {
      return (
        <SelectCategory
          setQuoteView={setQuoteView}
          setCategory={setCategory}
          Formik={Formik}
        />
      )
    } else if (quoteView === 'projectDescription') {
      return (
        <ProjectDescription
          setQuoteView={setQuoteView}
          setProjectDescription={setProjectDescription}
          Formik={Formik}
        />
      )
    } else if (quoteView === 'selectQuantity') {
      return <SelectQuantity setQuoteView={setQuoteView} Formik={Formik} />
    } else if (quoteView === 'launchDate') {
      return (
        <LaunchDate setQuoteView={setQuoteView} setLaunchDate={setLaunchDate} />
      )
    } else if (quoteView === 'uploadDocument') {
      return (
        <UploadDocument
          setQuoteView={setQuoteView}
          setImageUploaded={setImageUploaded}
          setDocumentUploaded={setDocumentUploaded}
        />
      )
    } else if (quoteView === 'preSignUp') {
      return <PreSignUp setQuoteView={setQuoteView} Formik={Formik} />
    } else if (quoteView === 'postSignUp') {
      return <PostSignUp setQuoteView={setQuoteView} handleSubmit={handleSubmit} />
    } else if (quoteView === 'postQuoteSubmit') {
      return (
        <PostQuoteSubmit userExist={userExist} setQuoteView={setQuoteView} />
      )
    } else {
      return <GetStarted setQuoteView={setQuoteView} />
    }
  }
  return (
    <>
      <Navigation />
      {handleQuotes()}
      <Footer />
    </>
  )
}
export default Quote
