import React from 'react'
import classes from "./CartPopup.module.scss"
import cross from "../../../assets/svg/Cross.svg"
import { useHistory } from 'react-router-dom';

const CartPopup = ({ handlePopup }) => {
    let products = JSON.parse(localStorage.getItem("products"));
    const history = useHistory();
    return (
        <div className={classes.CartPopup}>
            <div>
                <header>
                    <h5>Added to bag</h5>
                    <button onClick={handlePopup}>
                        <img src={cross} alt="Cross" />
                    </button>
                </header>
                <div className={classes.main}>
                    <div className={classes.itemsContainer}>
                        {
                            products && products?.length > 0 && products?.map(product => {
                                return (
                                    <div className={classes.item}>
                                        <img src={product?.images[0]?.url} alt="Cross" />
                                        <div style={{ flexGrow: "1" }}>
                                            <div className={classes.priceContainer}>
                                                <h4>{product?.name}</h4>
                                                <h4>${Math.round(product?.price * product.quantity * 100 / 100).toFixed(2)}</h4>
                                            </div>
                                            {
                                                product?.modifications && product?.modifications.length > 0 && product?.modifications.map(modification => {
                                                    return (<p> {modification.name}: {modification?.options[0].value}</p>)
                                                }
                                                )
                                            }
                                            <p>QTY: {product?.quantity}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={classes.priceContainer}>
                        <h5>Subtotal:</h5>
                        <h4 className={classes.bold}>${(products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2))}</h4>
                    </div>
                    <button onClick={() => history.push("/cart")}>CHECKOUT</button>
                </div>
            </div>
        </div>
    )
}

export default CartPopup
