import moment from 'moment'
import React from 'react'
import classes from './MyInquiriesTable.module.scss'

const MyInquiriesTable = ({ data, setModalOpened, selectQuote }) => {
  const handleSeeMore = (item) => {
    selectQuote(item)
    setModalOpened(true)
  }

  return (
    <div className={classes.table}>
      {data?.map((item) => {
        return (
          <div className={classes.tableRow} key={item.quoteId}>
            <div className={classes.orderId}>
              <h3 className={classes.rowTitle}>Quote ID:</h3>
              <p>{item?.quoteNumber}</p>
            </div>
            <div className={classes.buyer}>
              <h3 className={classes.rowTitle}>Supplier:</h3>
              <p>{item?.quoteSuppliers?.length} Suppliers</p>
            </div>
            <div className={classes.date}>
              <h3 className={classes.rowTitle}>Date:</h3>
              <p>{moment(item?.submittedDatetime).format('MMM D, YYYY')}</p>
            </div>
            <div className={classes.status}>
              <h3 className={classes.rowTitle}>Status:</h3>
              <p>{item?.quote_status_description}</p>
            </div>
            <div className={classes.seeMore}>
              <button
                type="button"
                className={classes.seeMoreBtn}
                onClick={() => handleSeeMore(item)}
              >
                See more
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MyInquiriesTable
