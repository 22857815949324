import React, { useState, useEffect, memo, lazy } from 'react'
import classes from './CompanyInfo.module.scss'
import clsx from 'clsx'
import CompanyRow from '../CompanyRow/CompanyRow'
import axios from 'axios'
import ProjectImages from '../ProjectImages/ProjectImages'
import { Avatar, Radio, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import cam from '../../../../assets/svg/camera.svg'
import { useHistory } from 'react-router-dom'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../constant/public/Input/InputElement'
import { supplierUserDataStatus } from '../../../../redux/actions/supplierProfile.action'
import { useDispatch } from 'react-redux'
import TooltipMessage from '../../../../constant/TooltipMessage'
import errorIcon from '../../../../assets/svg/error_icon.svg'
import Loading from '../../../../constant/Loading/Loading'
const InputWithDropDown = lazy(() => import("../../../../constant/public/InputWithDropDown/InputWithDropDown"))

const MAX_OVERVIEW_LENGTH = 1000

const CompanyInfo = memo((props) => {
  const [overviewValue, setOverviewValue] = useState('')
  const [imageUploadingInProgress, setImageUploadingInProgress] = useState(false)
  const hiddenFileInput = React.useRef(null)
  const [closeCountryDropDown, setCloseCountryDropDown] = useState(false);
  const [overviewLength, setOverviewLength] = useState(0)
  const [imagesArray, setImagesArray] = useState([])
  const [country, setCountry] = useState('- Select a country -')
  const [statee, setStatee] = useState('- Select a state -')
  const [userImage, setUserImage] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [countryData, setCountryData] = useState([])
  const [stateData, setStateData] = useState([])
  const [isSpezModalOpened, setIsSpezModalOpened] = useState(false)
  const [passwordModalOpened, setPasswordModalOpened] = useState(false)
  const [data, setData] = useState([])
  const [specialization, setSpecialization] = useState([])
  const [selectedSpecialisations, setSelectedSpecialization] = useState([])
  const [loading, setLoading] = useState(false)
  const [newProfileImage, setNewProfileImage] = useState('')
  const [openDropdown, setOpenDropdown] = useState(false);
  const [pastProjectImages, setPastProjectImages] = useState([])

  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/countries/`)
      .then((res) => {
        setData(res.data.countries)
        let newArr = []
        res.data.countries.map((item, index) => {
          newArr.push({
            id: index + 1,
            label: item.country,
          })
        })
        setCountryData(newArr)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/specializations?limit=50&page=1`)
      .then(async (res) => {
        setLoading(false);
        setSpecialization(res.data.results)
      })
      .catch((err) => {
        console.log(err.response)
      })
    setCountry(props?.Formik?.values.country);
  }, [])
  const { setFieldValue } = props.Formik


  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleImage = (arr) => {
    console.log(arr);
    setImagesArray(arr);
    setFieldValue('pastProjectImages', arr)
  }
  const dispatch = useDispatch()

  const handleViewProfile = () => {
    dispatch(supplierUserDataStatus(props?.Formik?.values))
    history.push({ pathname: '/dashboard/supplier/profile/overview' })
  }

  useEffect(() => {
    setUserImage(props?.Formik?.values.profileImage);
    setCountry(props?.Formik?.values.country);
    setStatee(props?.Formik?.values.state);
    filterStates(props?.Formik?.values.country);
    setPastProjectImages(props?.Formik?.values.pastProjectImages)
    setSelectedSpecialization(props?.Formik?.values.specialization);

  }, [props.Formik])

  const handleOverview = (e) => {
    const value = e.target.value
    const valueLength = value.length

    if (valueLength <= MAX_OVERVIEW_LENGTH) {
      setOverviewValue(value)
      setOverviewLength(valueLength)
    }
  }
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const handleProfileImage = (event) => {
    setNewProfileImage(event.currentTarget.files[0])
    const formData = new FormData();
    const baseURL = process.env.REACT_APP_API_URL
    formData.append('image', event.currentTarget.files[0])
    axios({
      method: "PATCH",
      url: baseURL + '/users/' + user.id + "/profile_image/",
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "bearer " + token
      }
    })
      .then(function (response) {
        if (response.status == 200) {
          setFieldValue('profileImage', response.data.data.profileImage)
          setImageUploading(false)

          // setUserImage('http://localhost:3000/static/media/logo_rectangle_black.18247de46f494c22398a0cfc9753bb3b.svg')
          // userImage = 'http://localhost:3000/static/media/logo_rectangle_black.18247de46f494c22398a0cfc9753bb3b.svg'
          // history.push({ pathname: '/dashboard/supplier/profile' })
        }
        else if (response.status == 400) {

        }
      })
  }
  const handleChange = (event) => {
    if (event !== null) {
      setImageUploadingInProgress(true)
      const fileUploaded = event.target.files[0]

      const data = new FormData()

      data.append('file', fileUploaded)

      let url = `${process.env.REACT_APP_API_URL}/quotes/uploadFile`

      axios
        .post(url, data, {
          // receive two parameter endpoint url ,form data
        })
        .then((res) => {
          // setProfileImage(res.data.data)
          setFieldValue('profileImage', res.data.data)
          setImageUploading(false)
          setImageUploading(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const filterStates = (value) => {
    let arr = [];
    data?.filter(d => d.country === value)[0]?.states.map((d, index) => arr.push({
      id: index,
      label: d,
      state: true
    }));
    setStateData(arr);
  }


  const handleUpdatePassword = () => {
    setPasswordModalOpened(false)
    props.handleUpdatePassword()
    setFieldValue('password', '')
    setFieldValue('oldPassword', '')
    setFieldValue('confirmPassword', '')
  }

  const handleSelectedSpecialisations = async (e) => {
    if (selectedSpecialisations.includes(e.target.value)) {
      let specializations = selectedSpecialisations.filter((i) => i !== e.target.value);
      setFieldValue("specialization", specializations);
    } else {
      let specializations = [...selectedSpecialisations, e.target.value];
      // setSelectedSpecialization([...selectedSpecialisations, e.target.value]);
      // setSelectedSpecialization([...selectedSpecialisations, e.target.value]);
      setFieldValue("specialization", specializations);
    }
    // setFieldValue("specialization", selectedSpecialisations);
  };

  const handleSubmit = async () => {
    // await props.Formik.handleSubmit();
    await props.handleProfileUpdated();
  }

  console.log(props.Formik)

  return (
    loading ?
      <div><Loading /></div>
      :
      <div className={classes.companyInfo}>
        <div className={classes.companyInfoTop}>
          <div className={classes.content}>
            {
              props?.userData?.profileCompleted &&
              <h5 className={classes.lockedErrorMessage}>
                Profile is locked for making any changes. Please contact Uplio Admin - <a href='mailto:Hello@uplio.com'> Hello@uplio.com</a>
              </h5>
            }
            <h1 className={clsx([`title title_item`, classes.title])}>
              Company info
            </h1>
            <div>
              <CompanyRow>
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}><span>Company Name </span><TooltipMessage text="Enter your Business Legal Name here.  For Example, AAA LLC or XYZ Inc. " /></p>
                <InputElement
                  name="companyName"
                  type="text"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="Company Name"
                  onChange={props?.Formik?.handleChange}
                  value={props?.Formik?.values.companyName}
                  helperText={props?.Formik?.errors.companyName
                    ? props.Formik?.errors.companyName
                    : null}
                />
              </CompanyRow>
              <CompanyRow>
                <p>Minimum Order Quantity</p>
                <InputElement
                  name="minimumOrderQuantity"
                  type="number"
                  value={props?.Formik?.values.minimumOrderQuantity}
                  onChange={props?.Formik?.handleChange}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  pattern="[0-9]*"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="500"
                  helperText={props?.Formik?.errors.minimumOrderQuantity
                    ? props.Formik?.errors.minimumOrderQuantity
                    : null}
                />
              </CompanyRow>
              <CompanyRow title={'Estimated Lead Time:'}>
                <div className={classes.line}>
                  <p>Sample</p>
                  <InputElement
                    name="estimatedLeadTimeSample"
                    onChange={props?.Formik?.handleChange}
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    pattern="[0-9]*"
                    type="number"
                    className={clsx([`input`, classes.companyInput])}
                    wrapperClass={classes.companyInput}
                    value={props?.Formik?.values.estimatedLeadTimeSample}
                    placeholder="15"
                    bottomText="*in weeks"
                    helperText={props?.Formik?.errors.estimatedLeadTimeSample
                      ? props.Formik?.errors.estimatedLeadTimeSample
                      : null}

                  />
                </div>
                <div className={classes.line}>
                  <p>Production Run</p>
                  <InputElement
                    name="estimatedLeadTimeProduction"
                    onChange={props?.Formik?.handleChange}
                    type="number"
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    pattern="[0-9]*"
                    className={clsx([`input`, classes.companyInput])}
                    value={props?.Formik?.values.estimatedLeadTimeProduction}
                    wrapperClass={classes.companyInput}
                    placeholder="35"
                    bottomText="*in weeks"
                    helperText={props?.Formik?.errors.estimatedLeadTimeProduction
                      ? props.Formik?.errors.estimatedLeadTimeProduction
                      : null}
                  />
                </div>
              </CompanyRow>
              <CompanyRow>
                <p>Year Established</p>
                <InputElement
                  name="yearEstablished"
                  type="number"
                  value={props?.Formik?.values.yearEstablished}
                  onChange={props?.Formik?.handleChange}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  pattern="[0-9]*"
                  maxlength="4"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="2010"
                  helperText={props?.Formik?.errors.yearEstablished
                    ? props.Formik?.errors.yearEstablished
                    : null}
                />
              </CompanyRow>
              <CompanyRow>
                <p>Production Capacity</p>
                <InputElement
                  name="productionCapacity"
                  onChange={props?.Formik?.handleChange}
                  type="number"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  pattern="[0-9]*"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="57"
                  bottomText="*units per month"
                  value={props?.Formik?.values.productionCapacity}
                  helperText={props?.Formik?.errors.productionCapacity
                    ? props.Formik?.errors.productionCapacity
                    : null}
                />
              </CompanyRow>
              {
                <CompanyRow title={'Location:'}>
                  <div className={classes.line}>
                    <p>Country</p>
                    <div className={classes.selectWrapper}>
                      <InputWithDropDown
                        dropdown={countryData}
                        width={'100%'}
                        closeCountryDropDown={closeCountryDropDown}
                        placeholder="- Select a country -"
                        value={country ? country : "- Select a country -"}
                        onChange={(value) => { setFieldValue("country", value) }}
                        setDropdown={(value) => { filterStates(value); setFieldValue("country", value); setOpenDropdown(false) }}
                      />
                      <p className={classes.errorMessage}>
                        {props.Formik?.errors.country ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                        {props.Formik?.errors.country}
                      </p>
                    </div>
                  </div>
                  <div className={classes.line}>
                    <p>State</p>
                    <div className={`${classes.selectWrapper} ${classes.stateSelect}`}>
                      <InputWithDropDown
                        dropdown={stateData}
                        width={'100%'}
                        closeCountryDropDownFun={() => setCloseCountryDropDown(true)}
                        placeholder="- Select a state -"
                        value={statee ? statee : "- Select a state -"}
                        setDropdown={(value) => { setStatee(value); setOpenDropdown(false); setFieldValue("state", value) }}
                        onChange={(value) => setFieldValue("state", value)}
                      />
                      <p className={classes.errorMessage}>
                        {props?.Formik?.errors.state ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                        {props?.Formik?.errors.state}
                      </p>
                    </div>
                  </div>
                </CompanyRow>
              }
              <div className={classes.brandsSection}>
                <CompanyRow>
                  <div className={`${classes.line}`}>
                    <div className={classes.brandTitle}>Brands that you <br /> worked with</div>
                    <div className={classes.brandTitleMobile}>Brands that you worked with</div>
                    <InputElement
                      name="brand"
                      type="text"
                      value={props?.Formik?.values.brand}
                      onChange={props?.Formik?.handleChange}
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      pattern="[0-9]*"
                      className={clsx([`input`, classes.companyInput])}
                      wrapperClass={classes.companyInput}
                      placeholder="Prada"
                      helperText={props?.Formik?.errors.brand
                        ? props.Formik?.errors.brand
                        : null}
                    />
                  </div>
                  <div className={classes.line}>
                    <p></p>
                    <InputElement
                      name="brandTwo"
                      type="text"
                      value={props?.Formik?.values.brandTwo}
                      onChange={props?.Formik?.handleChange}
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      pattern="[0-9]*"
                      className={clsx([`input`, classes.companyInput])}
                      wrapperClass={classes.companyInput}
                      placeholder="Gucci"
                      helperText={props?.Formik?.errors.brandTwo
                        ? props.Formik?.errors.brandTwo
                        : null}
                    />
                  </div>
                </CompanyRow>
              </div>
            </div>
          </div>
          <div className={classes.avatarBlock}>
            <div className={classes.avatarBlockWrapper}>
              <h1 className={clsx([`title title_item`, classes.mobileTitle])}>
                Company info
              </h1>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleProfileImage}

                style={{ display: 'none' }}
              />
              <div hidden className={classes.avatarWrapper}>
                {imageUploading ? (
                  !imageUploadingInProgress ? (
                    <button
                      className={classes.uploadButton}
                      onClick={(evt) => handleClick()}
                    >
                      +
                    </button>
                  ) : (
                    <Spin size="large" />
                  )
                ) : props?.Formik?.values.profileImage === '' ? (
                  <Avatar
                    className={classes.avatar}
                    size={209}
                    icon={<UserOutlined />}
                  />
                ) : (
                  <Avatar
                    className={classes.avatar}
                    size={209}
                    src={props?.Formik?.values.profileImage}
                  />
                )}
                <div className={classes.upload}>
                  <input hidden
                    type="file"
                    accept="image/*"
                    onChange={
                      handleProfileImage
                    }
                    name='image'
                  />

                  <button
                    className={classes.editButton}
                    onClick={() => setImageUploading(true)}
                  >
                    <img src={cam} alt="Uplio" />
                  </button>
                </div>
              </div>
              <div hidden className={classes.viewProfile}>
                <button onClick={() => handleViewProfile()}>
                  VIEW MY PROFILE
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.companyInfoBottom}>
          <CompanyRow>
            <div className={classes.spezialization}>
              <div>
                <p>Specialization</p>
                <TooltipMessage text="Check all applicable skills and specialization, this will allow customers to find your business when searching based on the specific keywords. " />
              </div>
              <h6>Click on the appropriate specializations</h6>
            </div>
            <div className='searchTabAside'>
              <div className={`egl-radio-with-check ${classes.spezializationList}`}>
                {specialization.map((item) => {
                  return (
                    <div className={`eachRadio ${classes.eachRadio}`}>
                      <Radio
                        className="radio-input"
                        type="radio"
                        checked={selectedSpecialisations.includes(item._id)}
                        onClick={handleSelectedSpecialisations}
                        id={item.name}
                        name={item.name}
                        value={item._id}
                      >
                        {item.name}
                      </Radio>
                    </div>
                  )
                })}
              </div>
            </div>
            {
              props?.Formik?.values.specialization.length === 0 &&
              <p className={classes.errorMessage}>
                {<img src={errorIcon} alt="Uplio" />}{' '}
                {"Specialization is required"}
              </p>
            }
            {/* <ul className={classes.spezializationList}>
              {props?.Formik?.values.specialization !== undefined &&
                props?.Formik?.values.specialization !== ''
                ? props?.Formik?.values.specialization === ''
                  ? ''
                  : props?.Formik?.values.specialization
                    .split(',')
                    .map((spez) => <li key={spez}>{spez}</li>)
                : null}
            </ul> */}
            {/* <SpezializationModal
              spezializations={
                props?.Formik?.values.specialization !== undefined &&
                  props?.Formik?.values.specialization !== ''
                  ? props?.Formik?.values.specialization.split(',')
                  : []
              }
              Formik={props.Formik}
              isOpened={isSpezModalOpened}
              setIsOpened={setIsSpezModalOpened}
            ></SpezializationModal> */}
            <div
              className={
                isSpezModalOpened || passwordModalOpened
                  ? 'blocker blocker_opened'
                  : 'blocker'
              }
            ></div>
          </CompanyRow>
          <div style={{ marginTop: "16px" }}>
            <ProjectImages
              accept="image/*"
              tooltip={true}
              title={'Add License or Certificate'}
              text={'Images (.JPEG, .PDF). Max size: 200 MB'}
              type="LCI"
              data={pastProjectImages}
              userData={props.userData}
              handleImage={handleImage}
              helperText={
                props?.Formik?.values?.pastProjectImages?.filter(f => f.type === "LCI")?.length === 0 && "Image is required"
              }
            />
            <div className={classes.docUploadSection}>
              <ProjectImages
                accept="application/pdf,application/vnd.ms-excel"
                text={'Documents (.DOC) Max size: 200 MB'}
                type="LCD"
                userData={props.userData}
                data={props?.Formik?.values.pastProjectImages}
                handleImage={handleImage}
                helperText={
                  props?.Formik?.values?.pastProjectImages?.filter(f => f.type === "LCD")?.length === 0 && "Document is required"
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.changePassword}>
          <button type="button" className={classes.changePasswordBtn} onClick={() => setPasswordModalOpened(true)}>
            Change Password
          </button>
          <CommonModal
            title={'Change password'}
            modalOpened={passwordModalOpened}
            setModalOpened={setPasswordModalOpened}
          >
            <div className={classes.changePasswordForm}>
              <label>
                Old Password*
                <div className={classes.passwordChange}>
                  <InputElement
                    name="oldPassword"
                    placeholder="Password"
                    type="password"
                    value={props?.Formik?.values.oldPassword}
                    onChange={props?.Formik?.handleChange}
                    onBlur={props.Formik.handleBlur}
                    width="100%"
                    helperText={
                      props?.Formik?.errors.oldPassword &&
                        props.Formik.touched.oldPassword
                        ? props.Formik?.errors.oldPassword
                        : null
                    }
                  />
                </div>
              </label>
              <label>
                New Password*
                <div className={classes.passwordChange}>
                  <InputElement
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={props?.Formik?.values.password}
                    onChange={props?.Formik?.handleChange}
                    onBlur={props.Formik.handleBlur}
                    width="100%"
                    helperText={
                      props?.Formik?.errors.password &&
                        props.Formik.touched.password
                        ? props.Formik?.errors.password
                        : null
                    }
                  />
                </div>
              </label>
              <label>
                Confirm New Password*
                <div className={classes.passwordChange}>
                  <InputElement
                    name="confirmPassword"
                    placeholder="Password"
                    type="password"
                    value={props?.Formik?.values.handleChange}
                    onChange={props?.Formik?.handleChange}
                    onBlur={props.Formik.handleBlur}
                    width="100%"
                    helperText={
                      props?.Formik?.errors.confirmPassword &&
                        props.Formik.touched.confirmPassword
                        ? props.Formik?.errors.confirmPassword
                        : null
                    }
                  />
                </div>
              </label>
              <div className={classes.submitWrapper}>
                <button
                  onClick={() => handleUpdatePassword()}
                  className={clsx([`button`, classes.submit])}
                  aria-label="close"
                >
                  Submit
                </button>
              </div>
            </div>
          </CommonModal>
        </div>

        {
          props?.Formik?.errors.companyName || props?.Formik?.errors.yearEstablished || props?.Formik?.errors.minimumOrderQuantity || props?.Formik?.errors.estimatedLeadTimeSample
            || props?.Formik?.errors.estimatedLeadTimeProduction || props?.Formik?.errors.productionCapacity || props?.Formik?.values.specialization?.length === 0 ||
            props?.Formik?.values?.pastProjectImages?.filter(f => f.type === "LCI")?.length === 0 ||
            props?.Formik?.values?.pastProjectImages?.filter(f => f.type === "LCD")?.length === 0 ||
            props?.Formik?.errors.country ||
            props?.Formik?.errors.state ||
            props?.Formik?.errors.brand ||
            props?.Formik?.errors.brandTwo ||
            props?.userData?.profileCompleted === true
            ?
            <div className={classes.disableButton}>
              <button
                disabled
              >
                Save
              </button>
            </div>
            :
            <div className={classes.save}>
              <button
                className={clsx(['button', classes.saveButton])}
                onClick={() => handleSubmit()}
              >
                Save
              </button>
            </div>
        }

      </div>

  )
})

export default CompanyInfo
