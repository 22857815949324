import { combineReducers } from 'redux'

import userReducer from './reducers/user.reducer'
import singleQuoteReducer from './reducers/singleQuote.reducer'
import supplierProfileReducer from './reducers/supplierProfile.reducer'

const rootReducer = combineReducers({
  user: userReducer,
  singleQuote: singleQuoteReducer,
  supplierProfile: supplierProfileReducer,
})

export default rootReducer
