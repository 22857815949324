import { Select } from 'antd'
import React from 'react'
import "./MultiSelect.css"
import downArrow from '../../../assets/svg/arrow-black-down.svg'

const MultiSelect = (props) => {
    return (
        <div>
            <Select
                mode="multiple"
                // allowClear
                style={{
                    width: '100%',
                }}
                suffixIcon={<img src={downArrow} alt="Down Arrow" />}
                value={props.value}
                placeholder={props.placeholder}
                className='antSelect'
                onChange={(val) => props.setDropdown(val)}
                options={props.dropdown}
            />
        </div>
    )
}

export default MultiSelect
