import React from 'react'
import classes from './InputElement.module.scss'
import clsx from 'clsx'
import errorIcon from '../../../../../../assets/svg/error_icon.svg'
const InputElement = (props) => {

  return (
    <>
      <div className={clsx(props.wrapperClass, classes.inputWrapper)}>
        <input
          name={props.name}
          type={props.type !== undefined ? props.type : 'text'}
          style={{ width: `${props.width}` }}
          className={clsx(props.className, classes.input)}
          onBlur={props.onBlur}
          value={props.value}
          onClick={props.onClick}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          required={props.required ? true : false}
        />
        {
          props.bottomText &&
          <p className={classes.bottomText}>{props?.bottomText}</p>
        }
        <p className={classes.errorMessage}>
          {props.helperText ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
          {props.helperText}
        </p>
      </div>
    </>
  )
}
export default InputElement