import React from 'react'
import { Radio } from 'antd'
import "./RadioGroup.css"

const RadioGroup = (props) => {
  return (
    <>
      <div className="egl-radio-with-check">
        {props.radioTags.map((item) => {
          return (
            <div className="eachRadio">
              <Radio.Group
                value={props.value}
                key={item.name}
                onChange={props.onChange}
              >
                <Radio
                  className="radio-input"
                  type="radio"
                  id={item.name}
                  name={props.name}
                  value={item.slug}
                >
                  {item.name}
                </Radio>
                {/* <label for={item.name}>{item.name}</label> */}
              </Radio.Group>
              <div className='totalProductsCount'>
                0
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
export default RadioGroup
