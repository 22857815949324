import React from 'react'
import classes from "./OrderSteps.module.scss"
import completed from "../../../assets/svg/completed.svg"

const OrderSteps = ({ active }) => {
    return (
        <div className={classes.OrderSteps}>
            <div className={classes.item}>
                {
                    active > 1 ?
                        <button className={classes.completed}>
                            <img src={completed} alt="completed" />
                        </button>
                        :
                        active === 1 ?
                            <button className={active === 1 && classes.active}>1</button>
                            :
                            <button>1</button>
                }

                <p>Items</p>
            </div>
            <div className={classes.item}>
                {
                    active > 2 ?
                        <button className={classes.completed}>
                            <img src={completed} alt="completed" />
                        </button>
                        :
                        active === 2 ?
                            <button className={active === 2 && classes.active}>2</button>
                            :
                            <button>2</button>
                }
                <p>Information</p>
            </div>
            <div className={classes.item}>
                {
                    active > 3 ?
                        <button className={classes.completed}>
                            <img src={completed} alt="completed" />
                        </button>
                        :
                        active === 3 ?
                            <button className={active === 3 && classes.active}>3</button>
                            :
                            <button>3</button>
                }
                <p>Summary</p>
            </div>
            <div className={classes.item}>
                {
                    active > 4 ?
                        <button className={classes.completed}>
                            <img src={completed} alt="completed" />
                        </button>
                        :
                        active === 4 ?
                            <button className={active === 4 && classes.active}>4</button>
                            :
                            <button>4</button>
                }
                <p>Payment</p>
            </div>
        </div>
    )
}

export default OrderSteps
