import React, { useState, useEffect } from 'react'
import classes from './ImageUpload.module.scss'
// import CompanyRow from '../CompanyRow/CompanyRow'
import axios from 'axios'
import cross from "../../../../../assets/svg/Cross.svg"
import { useSelector } from 'react-redux'
import UploadFiles from '../UploadFiles/UploadFiles'
import { Modal, Radio } from 'antd'

const ImageUpload = (props) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [newImage, setNewImage] = useState(false);
  const [documentValue, setDocumentValue] = useState(null)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [hoveredImage, setHoveredImage] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState("");
  const [imageArray, setImageArray] = useState([])
  const data = props.data
  const title = props.title
  const text = props.text;
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id) => {
    setHoveredImage(id)
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  const baseURL = process.env.REACT_APP_API_URL
  const user = useSelector((state) => state.user.user);
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    handleChange(documentValue, false)
  }, [documentValue])


  useEffect(() => {
    props.handleImage(imageArray);
  }, [newImage])


  useEffect(() => {
    setImageArray(data);
  }, [data]);


  const handleChange = (event, document) => {
    if (event !== null) {
      if (document) {
        setLoadingDocument(true)
      } else {
        setLoadingImage(true)
      }

      const fileUploaded = event.target?.files[0]

      const data = new FormData()

      data.append('file', fileUploaded)

      let url = `${process.env.REACT_APP_API_URL}/quotes/uploadFile`

      axios
        .post(url, data, {
          // receive two parameter endpoint url ,form data
        })
        .then((res) => {
          console.log(res);
          setLoadingImage(false)
          if (imageArray.length > 0) {
            let arr = imageArray
            arr.push(res.data.data)
            setImageArray(arr)
            props.handleImage(arr)
          } else {
            const arr = []
            arr.push(res.data.data)
            setImageArray(arr)
            props.handleImage(arr)
          }
          // then print response status
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleUpdate = async (data) => {
    console.log(data);
    setNewImage(false);
    setImageArray(prev => [...prev || [], { url: data?.data?.url, cover: false }]);
    setNewImage(true);
  }

  const handleCoverImage = (e) => {
    if (imageArray.filter(f => f.cover).length > 0) {
      imageArray[e.target.value].cover = false;
      setImageArray(imageArray);
    } else {
      imageArray[e.target.value].cover = true;
      setImageArray(imageArray);
    }
    props.handleImage(imageArray)
  }

  console.log(isMobile)


  const DeletePPI = (id) => {
    // localStorage.setItem("idPPI", id)
    // axios({
    //   method: "DELETE",
    //   url: baseURL + '/users/' + user?.id + "/media/",
    //   data: {
    //     mediaId: localStorage.getItem("idPPI")
    //   },
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     'Authorization': "bearer " + token
    //   }
    // }).then((res) => {
    //   setLoadingImage(false)
    console.log(imageArray)
    let imageFilter = imageArray.filter(f => f.url !== id)
    setImageArray(imageFilter);
    props.handleImage(imageFilter)
    // })
  }


  return (
    <>
      <div className={classes.companyTitleWrapper}>
        <p>{title}</p>
        {/* <p className={classes.imageUploadText}>{text}</p> */}
      </div>
      <div className={classes.imagesWrapper} onMouseLeave={() => setHoveredImage("")}>
        {
          hoveredImage && !isMobile &&
          <img className={classes.hoveredImage} src={hoveredImage} alt="Hovered Img" />
        }
        <Modal centered className='mobileImageModal' closeIcon={<img src={cross} alt="Cross" />} title={false} visible={isModalOpen} footer={false} onCancel={handleCancel}>
          <img
            className={classes.imageLoaded}
            src={hoveredImage}
            alt="Uplio"
          />
        </Modal>
        <UploadFiles accept={"images/*"} type={props.type} handleUpdate={handleUpdate} disabled={loadingImage} onChange={setDocumentValue} />
        {
          imageArray && imageArray.length > 0 && imageArray?.map((item, index) => {
            let disabled = !item?.cover && imageArray.filter(f => f?.cover).length > 0;
            return (
              <div>
                {
                  isMobile ?
                    <label
                      onClick={() => showModal(item.url)}
                      onMouseOver={() => setHoveredImage(item.url)}
                      onMouseLeave={() => setHoveredImage("")}
                      className={classes.cover}
                      key={item}
                    >
                      <img
                        style={{ height: "100px" }}
                        className={classes.imageLoaded}
                        src={item?.url}
                        alt="Uplio"
                      />
                      {
                        hoveredImage === item.url &&
                        <button onMouseEnter={() => setShowCloseIcon(item.url)} type='button' className={classes.deleteBtn}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="#C3C3C3" xmlns="http://www.w3.org/2000/svg" onClick={() => DeletePPI(item.url)}>
                            <path d="M11.7786 0.716247C12.1203 1.05796 12.1203 1.61198 11.7786 1.95368L7.73731 5.99492L11.7786 10.0361C12.1203 10.3779 12.1203 10.9319 11.7786 11.2736C11.4369 11.6153 10.8829 11.6153 10.5412 11.2736L6.49986 7.23235L2.45856 11.2736C2.11685 11.6153 1.56283 11.6153 1.22112 11.2736C0.879415 10.9319 0.879418 10.3779 1.22113 10.0361L5.26242 5.99492L1.22113 1.95368C0.879418 1.61198 0.879415 1.05796 1.22112 0.716247C1.56283 0.374536 2.11685 0.374532 2.45856 0.716238L6.49986 4.75749L10.5412 0.716238C10.8829 0.374532 11.4369 0.374536 11.7786 0.716247Z" fill="#C3C3C3" />
                          </svg>
                        </button>
                      }
                      <div className="egl-radio-with-check">
                        <Radio
                          className={`radio-input ${classes.radioInput} productRadioInput`}
                          type="radio"
                          disabled={disabled}
                          checked={item?.cover}
                          onClick={handleCoverImage}
                          id={""}
                          name={""}
                          value={index}
                        >
                          <span className={`${classes.coverText} ${disabled && classes.disabled}`}>Cover image</span>
                        </Radio>
                      </div>
                    </label>
                    :
                    <label
                      onMouseOver={() => setHoveredImage(item?.url)}
                      onMouseLeave={() => setHoveredImage("")}
                      className={classes.cover}
                      key={item}
                    >
                      <img
                        style={{ height: "100px" }}
                        className={classes.imageLoaded}
                        src={item?.url}
                        alt="Uplio"
                      />
                      {
                        hoveredImage === item?.url &&
                        <button onMouseEnter={() => setShowCloseIcon(item?.url)} type='button' className={classes.deleteBtn}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="#C3C3C3" xmlns="http://www.w3.org/2000/svg" onClick={() => DeletePPI(item?.url)}>
                            <path d="M11.7786 0.716247C12.1203 1.05796 12.1203 1.61198 11.7786 1.95368L7.73731 5.99492L11.7786 10.0361C12.1203 10.3779 12.1203 10.9319 11.7786 11.2736C11.4369 11.6153 10.8829 11.6153 10.5412 11.2736L6.49986 7.23235L2.45856 11.2736C2.11685 11.6153 1.56283 11.6153 1.22112 11.2736C0.879415 10.9319 0.879418 10.3779 1.22113 10.0361L5.26242 5.99492L1.22113 1.95368C0.879418 1.61198 0.879415 1.05796 1.22112 0.716247C1.56283 0.374536 2.11685 0.374532 2.45856 0.716238L6.49986 4.75749L10.5412 0.716238C10.8829 0.374532 11.4369 0.374536 11.7786 0.716247Z" fill="#C3C3C3" />
                          </svg>
                        </button>
                      }
                      <div className="egl-radio-with-check">
                        <Radio
                          className={`radio-input ${classes.radioInput} productRadioInput`}
                          type="radio"
                          disabled={disabled}
                          checked={item?.cover}
                          onClick={handleCoverImage}
                          id={""}
                          name={""}
                          value={index}
                        >
                          <span className={`${classes.coverText} ${disabled && classes.disabled}`}>Cover image</span>
                        </Radio>
                      </div>
                    </label>
                }
              </div>
            )
          }
          )
        }
      </div>
    </ >
  )
}

export default ImageUpload
