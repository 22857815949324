import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classes from "./Payment.module.scss"
import Navigation from '../../../../../../constant/public/Navigation/Navigation'
import { Col, Row } from 'antd'
import axios from 'axios'
import payImg from "../../../../../../assets/svg/payment.svg"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm/CheckoutForm"
import Loading from '../../../../../../constant/Loading/Loading'


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const InvoicePayment = () => {
  const history = useHistory();
  const params = useLocation();
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [activeStep, setActiveStep] = useState(4);
  const [paymentId, setPaymentId] = useState("");
  const [data, setData] = useState([]);
  const token = sessionStorage.getItem('token');

  const getInvoice = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/invoice/${params?.state}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setInvoice(res.data);
        stripeIntentFunction(res.data?.payments[0]._id);
        // setPaymentId(res.data?.payments[0]._id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      })
  }

  const stripeIntentFunction = (id) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/invoice/${params?.state}/payments/intent`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      .then((res) => {
        
        setClientSecret(res.data?.paymentIntent?.client_secret);
        setPaymentId(res.data._id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response)
        setLoading(false);
      })
  }

  useEffect(() => {
    getInvoice();

  }, []);


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance
  }


  const handleSubmit = async () => {
    history.push("/summary")
    // await handleProfileUpdated();
  }


  return (
    <>
      {/* <Navigation /> */}
      {
        loading ?
          <div>
            <Loading />
          </div>
          :
          <div className={`${classes.Payment}`}>
            <div>
              <div className={classes.mainContent}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={12} className={classes.leftImg}>
                    <img src={payImg} alt="Payment" />
                  </Col>
                  <Col xs={24} md={12}>
                    <div className={classes.modalBottom}>
                      <div className={classes.modalBottomInfo}>
                        <h2>Invoice Summary</h2>
                        <h4 className={classes.invoiceId}>Invoice #{invoice?.invoiceNumber}</h4>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Payment Term</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>{invoice?.paymentTermsDescription}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Invoice Date</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>{invoice?.issueDate}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Supplier Name</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            {
                              invoice?.supplier?.firstname ?
                                <p>{invoice?.supplier?.firstname} {invoice?.supplier?.lastname}</p>
                                :
                                <p>{invoice?.supplier?.name}</p>
                            }
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Invoice Total Amount</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>${invoice?.invoiceTotal}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Invoice Due Amount</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>${invoice?.payments?.filter(p => p?.paymentStatusDescription === "Unpaid")[0]?.amount}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Payment Due Date</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>{invoice?.payments?.filter(p => p?.paymentStatusDescription === "Unpaid")[0]?.dueDate}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem} justify="end">
                          {/* <Col xs={24} md={12}>
                            <h3 className={classes.rowTitle}>Shipping Address</h3>
                          </Col>
                          <Col xs={24} md={12}>
                            <p style={{ textAlign: "start" }}>{invoice?.buyer?.shippingAddresses[0]?.address1}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem}>
                          <Col xs={12} md={12}>
                            <h3 className={classes.rowTitle}>Quote ID</h3>
                          </Col>
                          <Col xs={12} md={12}>
                            <p>#{invoice?.quote?.quoteNumber}</p>
                          </Col>
                        </Row>
                        <Row className={classes.infoItem} gutter={[16, 16]}>
                          <Col xs={15} md={12}>
                            <h3 className={classes.rowTitle}>Total Products Quantity</h3>
                          </Col> */}
                          {/* <Col xs={9} md={4}>
                            <p>{invoice?.products?.reduce((a, b) => a + b.quantity, 0)}</p>
                          </Col> */}
                          {/* <Col xs={24} md={8} className={classes.InvoiceBgCont}>
                            <div classNam="footer footer2">
                              <h6>TOTAL </h6>
                              <span>${invoice?.invoiceTotal}</span>
                            </div>
                            <div classNam="footer">
                              <h6>TOTAL DUE </h6>
                              <span>${invoice?.payments?.filter(p => p?.paymentStatusDescription === "Unpaid")[0]?.amount}</span>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    {clientSecret !== "" && (
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm onChangeStep={(val) => setActiveStep(val)} clientSecret={clientSecret} paymentId={paymentId} invoiceId={params.state} />
                      </Elements>
                    )}
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={24} md={12} className={classes.leftImg}>
                    <img src={payImg} alt="Payment" />
                  </Col>
                  <Col className={classes.paymentCont} xs={24} md={12}>
                    {clientSecret !== "" && (
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm onChangeStep={(val) => setActiveStep(val)} clientSecret={clientSecret} paymentId={paymentId} invoiceId={params.state} />
                      </Elements>
                    )}
                  </Col>
                </Row> */}
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default InvoicePayment;
