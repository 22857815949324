import React, { useState } from 'react'
import './footer.scss'
import axios from 'axios'
import * as yup from 'yup'
import { Formik, useFormik } from 'formik'
import { Link } from 'react-router-dom'
import logo from '../../../assets/svg/logo_rectangle_black.svg'
import { Row, Col, Space } from 'antd'
import Popup from '../Popup/Popup'
import InputElement from '../Input/InputElement'
import top from '../../../assets/svg/back_to_top.svg'
import FormList from 'antd/lib/form/FormList'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
  const Formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email.').required('Email is Required'),
    }),
    onSubmit: (values) => {},
  })

  const [popupOpened, setPopupOpened] = useState(false)

  const handleSubscribe = (e) => {
    // e.preventDefault();

    document.documentElement.classList.add('is-locked')

    setPopupOpened(true)

    handleSubmit()
  }

  const { setFieldValue } = Formik

  const handleSubmit = () => {
    axios
      .post(`https://uplio-newsletter.herokuapp.com/api/newsletter/register`, {
        email: Formik.values.email,
      })
      .then((res) => {
        setFieldValue('email', '')
      })
      .catch((err) => {
        console.log(err.response)
      })
    setFieldValue('email', '')
  }

  return (
    <>
      <footer className="footer">
        <div className="footer-wrapper">
          <div className="container">
            <div className="footer-top">
              <div className="footer-top-arrow">
                <Link to="/#header" className="footer-top-arrow__link">
                  <img src={top} alt="arrow top" />
                </Link>
              </div>
              <div className="footer-logo">
                <Link to="/#header" className="footer-logo__logo">
                  <img height="48" src={logo} alt="" />
                </Link>
                <ul className="footer-socials">
                  <li className="footer-socials__item">
                    <a
                      className="link footer-socials__link"
                      target="_blank"
                      href="https://www.instagram.com/uplio_marketplace/"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li className="footer-socials__item">
                    <a
                      className="link footer-socials__link"
                      target="_blank"
                      href="https://twitter.com/Uplio11"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li className="footer-socials__item">
                    <a
                      className="link footer-socials__link"
                      target="_blank"
                      href="https://www.facebook.com/Uplio-103062572308513"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="footer-socials footer-socials_m">
                <li className="footer-socials__item">
                  <a
                    className="link footer-socials__link"
                    target="_blank"
                    href="https://www.instagram.com/uplio_marketplace/"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li className="footer-socials__item">
                  <a
                    className="link footer-socials__link"
                    target="_blank"
                    href="https://twitter.com/Uplio11"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li className="footer-socials__item">
                  <a
                    className="link footer-socials__link"
                    target="_blank"
                    href="https://www.facebook.com/Uplio-103062572308513"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
              <div className="footer-categories">
                <p className="footer-categories__title">Categories</p>
                <ul className="footer-categories__list">
                  <li className="footer-categories__item">
                    <Link
                      to="/browse"
                      className="title_item-s footer-categories__link"
                    >
                      [FASHION]
                    </Link>
                  </li>
                  <li className="footer-categories__item">
                    <Link
                      to="/browse"
                      className="title_item-s footer-categories__link"
                    >
                      [JEWELRY]
                    </Link>
                  </li>
                  <li className="footer-categories__item">
                    <Link
                      to="/browse"
                      className="title_item-s footer-categories__link"
                    >
                      [COSMETICS]
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="footer-navigation">
                <li className="footer-navigation__item">
                  <HashLink to="/#why" className="link footer-navigation__link">
                    About us
                  </HashLink>
                </li>
                <li className="footer-navigation__item">
                  <HashLink
                    to="/#how-it-works"
                    className="link footer-navigation__link"
                  >
                    How it works
                  </HashLink>
                </li>
                <li className="footer-navigation__item">
                  <HashLink to="/#faq" className="link footer-navigation__link">
                    FAQ
                  </HashLink>
                </li>
                <li className="footer-navigation__item">
                  <HashLink
                    to="/#categories"
                    className="link footer-navigation__link"
                  >
                    Categories
                  </HashLink>
                </li>
              </ul>
              <div className="footer-contact">
                <p className="footer-contact__title">Contact:</p>
                <div className="footer-contact__contact">
                  <a href="mailto:hello@uplio.com">hello@uplio.com</a>
                </div>
                <div className="footer-contact__contact">
                  <a href="tel:+17146046709">+1 714 604 6709</a>
                </div>
              </div>
              <div className="footer-subscribtion">
                <p className="footer-subscribtion__title">
                  SUBSCRIBE TO OUR NEWSLETTER:
                </p>
                <form className="footer-subscribtion__form">
                  <div className="footer-subscribtion__input-wrapper">
                    <InputElement
                      className="footer-subscribtion__input"
                      type="text"
                      name="email"
                      width="100%"
                      placeholder="Email"
                      required="true"
                      value={Formik.values.email}
                      onChange={Formik.handleChange}
                    />
                  </div>
                  <div className="footer-subscribtion__submit-wrapper">
                    <button
                      type="submit"
                      className="button footer-subscribtion__submit"
                      onClick={(e) => handleSubscribe(e)}
                    >
                      Subscribe
                      <svg
                        width="56"
                        height="16"
                        viewBox="0 0 56 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M55.7071 8.70711C56.0976 8.31658 56.0976 7.68342 55.7071 7.29289L49.3431 0.928932C48.9526 0.538408 48.3195 0.538408 47.9289 0.928932C47.5384 1.31946 47.5384 1.95262 47.9289 2.34315L53.5858 8L47.9289 13.6569C47.5384 14.0474 47.5384 14.6805 47.9289 15.0711C48.3195 15.4616 48.9526 15.4616 49.3431 15.0711L55.7071 8.70711ZM0 9H55V7H0V9Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="footer-bottom__rights">
                <p>@2023 Uplio, Inc. All rights reserved</p>
              </div>
              <div className="footer-bottom__terms">
                <Link to="/terms-conditions">TERMS & CONDITIONS</Link>
              </div>
              <div className="footer-bottom__policy">
                <Link to="/privacy-policy">PRIVACY POLICY</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={`blocker ${popupOpened ? 'blocker_opened' : null}`}></div>
      <Popup
        isOpened={popupOpened}
        setOpened={setPopupOpened}
        text={'You have successfully subscribed to our newsletter'}
        btnText={'Great!'}
      />
    </>
  )
}
export default Footer
