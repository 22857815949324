import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import SuccessMessageIcon from "../../../assets/images/Success.png"


const SaveModal = ({ openModal }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setIsModalOpen(openModal);

        return () => {

        }
    }, [openModal])


    return (
        <>
            <Modal centered className='successModal' width={304} style={{ width: "304px", height: "304px" }} title={false} footer={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ padding: "80px 100px 80px 100px", textAlign: "center" }}>
                    <img src={SuccessMessageIcon} alt="Success Message" style={{ width: "104px", height: "104px" }} />
                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#6FCF97", lineHeight: "24px", marginTop: "16px", marginBottom: "0px" }}>Saved</p>
                </div>
            </Modal>
        </>
    )
}

export default SaveModal
