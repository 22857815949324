import React, { useEffect, useState } from 'react'
import classes from './PreSignUp.module.scss'
import ProgressBar from '../ProgressBar/ProgressBar'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import InputElement from '../../../../constant/public/Input/InputElement'
import { useSelector } from 'react-redux'

const PreSignUp = (props) => {
  const [disable, setDisable] = useState(true)
  const user = useSelector((state) => state.user.user)
  const { setFieldValue } = props.Formik;
  useEffect(() => {
    if (user !== null && user !== undefined && user) {
      setFieldValue('email', user.email)
      setFieldValue('name', user.name)
      // props.setQuoteView('postSignUp')
    }
  }, [])

  useEffect(() => {
    if (props.Formik.values.name !== '' && props.Formik.values.email !== '') {
      setDisable(false)
    }
  }, [props])

  console.log(props.Formik)
  return (
    <>
      <div className={classes.sideTitle}>
        <h2>Get your Quote</h2>
        <h4>Get your Quote</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.heading}>
            <ProgressBar width={'70%'} />
            <div className={classes.mainSection}>
              <h2>Tell us your name and email address so we can contact you</h2>
              <p>We won’t share your contact info with suppliers</p>

              <h4>Name</h4>
              <InputElement
                name="name"
                value={props.Formik.values.name}
                onChange={props.Formik.handleChange}
                onBlur={props.Formik.handleBlur}
                placeholder="Name"
                width="100%"
                helperText={
                  props.Formik.errors.name && props.Formik.touched.name
                    ? props.Formik?.errors.name
                    : null
                }
              />

              <h4 className={classes.emailLabel}>Email</h4>
              <InputElement
                name="email"
                value={props.Formik.values.email}
                onChange={props.Formik.handleChange}
                onBlur={props.Formik.handleBlur}
                helperText={
                  props.Formik.errors.email && props.Formik.touched.email
                    ? props.Formik?.errors.email
                    : null
                }
                placeholder="Email"
                width="100%"
              />
              <div className={classes.actionButtonSection}>
                <button onClick={() => props.setQuoteView('uploadDocument')}>
                  PREVIOUS
                </button>
                {
                  props.Formik.values.email === '' ||
                    props.Formik.errors.email === "Invalid email." ||
                    props.Formik.values.name === '' ?
                    <ButtonWithRightArrow
                      disabled={true}
                      content="NEXT"
                    />
                    :
                    <ButtonWithRightArrow
                      disabled={disable}
                      content="NEXT"
                      function={() => props.setQuoteView('postSignUp')}
                    />
                }
              </div>
            </div>
            {/* <Row>
              <Col lg={12} md={12} sm={0} xs={0}></Col>
              <Col lg={12} md={12} sm={0} xs={0}>
                
              </Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <div className={classes.actionButtonSection}>
                  <ButtonWithRightArrow
                    disabled={disable}
                    content="NEXT"
                    function={() => props.setQuoteView('postSignUp')}
                  />
                  <button onClick={() => props.setQuoteView('uploadDocument')}>
                    PREVIOUS
                  </button>
                </div>
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default PreSignUp
