import classes from './OrdersWithCustomisation.module.scss'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import OrderItem from './OrderItem/OrderItem'
import Loading from '../../../../../constant/Loading/Loading'

const OrdersWithCustomisation = (props) => {
    const [quotes, setQuotes] = useState("")
    const [minPrice, setMinPrice] = useState('')
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [maxPrice, setMaxPrice] = useState('')
    const [qouteId, setQouteId] = useState('')
    const [activeTab, setActiveTab] = useState('orders')
    const [costModalOpened, setCostModalOpened] = useState(false)
    const [modalsAnswered, setModalsAnswered] = useState([])

    const token = sessionStorage.getItem('token')
    const user = useSelector((state) => state.user.user)
    const [loading, setLoading] = useState(false)

    const passLead = (val) => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL}/orders/${val}/status`, {
                status: 12
            },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((res) => {
                setLoading(false);
                props.update();
                // setLeads(res.data.results)
                // setQuotes(res.data.totalResults)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    // const loadMoreProducts = () => {
    //     setLoadMoreLoading(true);
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_API_URL}/supplier/${user._id}/orders-with-customizations?limit=10&page=${page}`,
    //             {
    //                 headers: { Authorization: `Bearer ${token}` },
    //             }
    //         )
    //         .then((res) => {
    //             setLoadMoreLoading(true);
    //             setLeads(res.data.results)
    //         })
    //         .catch((err) => {
    //             setLoadMoreLoading(true);
    //             console.log(err)
    //         })
    // }


    return (
        <div className={classes.wrapper}>
            {/* <div className={classes.menu}>
                <button onClick={() => { setActiveTab("orders"); }} className={activeTab === "orders" && classes.active}>Orders with Customization (4)</button>
                <button onClick={() => { setActiveTab("qoutes"); }} className={activeTab === "qoutes" && classes.active}>Quotes ({quotes})</button>
            </div> */}
            {
                loading ?
                    <Loading />
                    :
                    <ul className={classes.quotesList}>
                        {props.orders?.length > 0
                            ? props.orders?.map((item) => (
                                <OrderItem
                                    data={item}
                                    answerHandler={(val) => { setQouteId(val); setCostModalOpened(true); }}
                                    passLead={passLead}
                                    id={item._id}
                                    modalsAnswered={modalsAnswered}
                                    setModalsAnswered={setModalsAnswered}
                                    maxPrice={maxPrice}
                                    minPrice={minPrice}
                                    key={item.projectLaunchDate}
                                />
                            ))
                            : null}
                        <div
                            className="blocker"
                            style={costModalOpened ? { opacity: '1' } : { opacity: '0' }}
                        ></div>
                        {/* <div className={classes.loadMore}>
                            {
                                loadMoreLoading ?
                                    <Loading />
                                    :
                                    <button disabled={quotes === props.orders.length} onClick={async () => { setPage(page + 1); loadMoreProducts() }}>
                                        Load Older
                                    </button>
                            }
                        </div> */}
                    </ul>
            }
        </div>
    )
}

export default OrdersWithCustomisation
