import React from 'react'
import classes from './TextInput.module.scss'
const TextInput = (props) => {
  return (
    <>
      <textarea
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        style={{ width: props.width }}
        className={classes.textarea}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
      />
    </>
  )
}
export default TextInput
