import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import InputElement from '../../../../constant/public/Input/InputElement';
import "./ResetModal.css"
import * as yup from 'yup'
import axios from 'axios';
import { Navigate, useHistory } from 'react-router-dom';

const ResetModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const Formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: yup.object({
            email: yup.string().email('Invalid email.').required('Email is Required')
        }),
    });

    const { setFieldError } = Formik;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        })
    }

    const history = useHistory()

    const handleForgotPassword = () => {
        Formik.handleSubmit();
        // dispatch(postUserLogin(user))
        if (Formik.values.email) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
                    email: Formik.values.email,
                })
                .then((res) => {
                    if (!res.data.error) {
                        openNotificationWithIcon('success', 'Request submitted successfully', "Check Inbox");
                        handleCancel();
                    } else {
                        openNotificationWithIcon('warning', 'Oops', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    setFieldError("email", err.response?.data?.message);
                    setTimeout(() => {
                        history.push({ pathname: '/signup' })

                    }, 2000);
                })
        }
    }

    return (
        <div>
            <button onClick={showModal}>
                <h4> Forgot Password?</h4>
            </button>
            <Modal centered destroyOnClose className='resetModal' title={false} footer={false} visible={isModalOpen} onCancel={handleCancel}>
                <h3 className='title'>RESET YOUR PASSWORD</h3>
                <p className='subTitle'>Enter the email address associated with your account and we will send instructions for reseting your password</p>
                <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
                    {/* <h3>Email</h3> */}
                    <InputElement
                        width="100%"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={Formik.values.email}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        helperText={
                            Formik.errors.email && Formik.touched.email
                                ? Formik?.errors.email
                                : null
                        }
                    />
                    <div className='submitBtn' style={{ textAlign: "center", marginTop: "30px" }}>
                        {
                            Formik.errors.email ?
                                <button type='submit' disabled className='submitBtn disabledBtn'>
                                    SEND
                                </button>
                                :
                                <button type='submit' className='submitBtn' onClick={handleForgotPassword}>
                                    SEND
                                </button>
                        }
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default ResetModal
