import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OrderSteps from '../../../constant/public/OrderSteps/OrderSteps'
import classes from "./Payment.module.scss"
import * as yup from 'yup'
import InputElement from '../../../constant/public/Input/InputElement'
import Navigation from '../../../constant/public/Navigation/Navigation'
import { Col, Divider, Radio, Row } from 'antd'
import InputWithDropDown from '../../../constant/public/InputWithDropDown/InputWithDropDown'
import axios from 'axios'
import payImg from "../../../assets/svg/payment.svg"
import Footer from '../../../constant/public/Footer/Footer'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm/CheckoutForm"


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const Payment = () => {
  const history = useHistory();
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [country, setCountry] = useState('Please select a country')
  const [statee, setStatee] = useState('Please select a region or state')
  const [clientSecret, setClientSecret] = useState("");
  const [activeStep, setActiveStep] = useState(4);
  const [paymentId, setPaymentId] = useState("");
  const [data, setData] = useState([])

  const Formik = useFormik({
    initialValues: {
      email: '',
      addressTwo: '',
      addressOne: '',
      city: '',
      country: '',
      postalCode: '',
      cardNumber: '',
      ExpDate: '',
      CVV: '',
      cardName: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Invalid email.')
        .required('Email is Required'),
      addressOne: yup
        .string()
        .required('Address is Required'),
      addressTwo: yup
        .string(),
      city: yup
        .string()
        .required('City is Required'),
      country: yup
        .string()
        .required('Country is Required'),
      postalCode: yup
        .string()
        .required('Postal Code is Required'),
      cardNumber: yup
        .string()
        .required('Card Number is Required'),
      ExpDate: yup
        .string()
        .required('Expiry date is Required'),
      CVV: yup
        .string()
        .required('CVV is Required'),
      cardName: yup
        .string()
        .required('Card Name is Required'),

    }),
  })
  const { setFieldValue } = Formik;


  const filterStates = (value) => {
    let arr = [];
    data?.filter(d => d.country === value)[0]?.states.map((d, index) => arr.push({
      id: index,
      label: d,
      state: true
    }));
    setStateData(arr);
  }

  useEffect(() => {
    JSON.parse(localStorage.getItem("products")) === null && history.push("/browse");
    // setProducts(JSON.parse(localStorage.getItem("products")))
    axios
      .get(`${process.env.REACT_APP_API_URL}/countries/`)
      .then((res) => {
        setData(res.data.countries)
        let newArr = []
        res.data.countries.map((item, index) => {
          newArr.push({
            id: index + 1,
            label: item.country,
          })
        })
        setCountryData(newArr)
      })
      .catch((err) => {
        console.log(err)
      })
    axios
      .post(`${process.env.REACT_APP_API_URL}/orders/stripe/payment-intent`, { amount: JSON.parse(localStorage.getItem("products"))?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2) })
      .then((res) => {
        
        setClientSecret(res.data.client_secret);
        setPaymentId(res.data.id);
      })
      .catch((err) => {
        console.log(err)
      })

  }, []);


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance
  }


  const handleSubmit = async () => {
    Formik.handleSubmit();
    history.push("/summary")
    // await handleProfileUpdated();
  }


  return (
    <>
      <Navigation />
      <div className={`${classes.Payment} container`}>
        <div>
          <h2>Shipping</h2>
          <OrderSteps active={activeStep} />
          <div className={classes.mainContent}>
            <Row>
              <Col xs={24} md={12} className={classes.leftImg}>
                <img src={payImg} alt="Payment" />
              </Col>
              <Col className={classes.paymentCont} xs={24} md={12}>
                {/* <div className={classes.top}>
                  <button>
                    <img src={gpay} alt="G Pay" />
                  </button>
                  <Divider className={classes.divider}>or pay by card</Divider>
                </div> */}
                {/* <div className={classes.emailContainer}>
                  <h3>E-mail</h3>
                  <InputElement
                    width="100%"
                    type="text"
                    name="email"
                    placeholder="uplioclient@gmail.com"
                    value={Formik.values.email}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    helperText={
                      Formik.errors.email && Formik.touched.email
                        ? Formik?.errors.email
                        : null
                    }
                  />
                </div> */}
                {clientSecret !== "" && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm onChangeStep={(val) => setActiveStep(val)} clientSecret={clientSecret} paymentId={paymentId} />
                  </Elements>
                )}
                {/* <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <h3>Card Information</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="cardNumber"
                      placeholder="1234 1234 1234 1234"
                      value={Formik.values.cardNumber}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.cardNumber && Formik.touched.cardNumber
                          ? Formik?.errors.cardNumber
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}>
                        <InputElement
                          width="100%"
                          type="text"
                          name="ExpDate"
                          placeholder="MM/YY"
                          value={Formik.values.ExpDate}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.ExpDate && Formik.touched.ExpDate
                              ? Formik?.errors.ExpDate
                              : null
                          }
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <InputElement
                          width="100%"
                          type="text"
                          name="CVV"
                          placeholder="CVV/CVC"
                          value={Formik.values.CVV}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.CVV && Formik.touched.CVV
                              ? Formik?.errors.CVV
                              : null
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <h3>Card Name & Surname</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="cardName"
                      placeholder="John Black"
                      value={Formik.values.cardName}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.cardName && Formik.touched.cardName
                          ? Formik?.errors.cardName
                          : null
                      }
                    />
                  </Col>
                </Row> */}
                {/* <div className={classes.form}>
                  <h5>Shipping Address</h5>
                  <div className={classes.defaultCheck}>
                    <div className="egl-radio-with-check">
                      <div className="eachRadio">
                        <Radio
                          className="radio-input"
                          type="radio"
                          checked={defaultAddress}
                          onClick={() => setDefaultAddress(!defaultAddress)}
                          id={""}
                          name={""}
                          value={defaultAddress}
                        >
                          Billing Address same as Shipping Address
                        </Radio>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inner}>
                    <Row className={classes.row} gutter={[25, 16]}>
                      <Col xs={24} md={24}>
                        <h3>Country*</h3>
                        <div className={classes.selectWrapper}>
                          <InputWithDropDown
                            dropdown={countryData}
                            width={'100%'}
                            placeholder="Please select a country"
                            value={Formik?.values.country ? Formik?.values.country : "Please select a country"}
                            onChange={(value) => setFieldValue("country", value)}
                            setDropdown={(value) => { filterStates(value) }}
                          />
                          <p className={classes.errorMessage}>
                            {Formik?.errors.country ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                            {Formik?.errors.country}
                          </p>
                        </div>
                      </Col>
                      <Col xs={24}>
                        <h3>Address Line 1*</h3>
                        <InputElement
                          width="100%"
                          type="text"
                          name="addressOne"
                          placeholder="Please enter the address 1"
                          value={Formik.values.addressOne}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.addressOne && Formik.touched.addressOne
                              ? Formik?.errors.addressOne
                              : null
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <h3>Address Line 2*</h3>
                        <InputElement
                          width="100%"
                          type="text"
                          name="addressTwo"
                          placeholder="Please enter the address 2"
                          value={Formik.values.addressTwo}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.addressTwo && Formik.touched.addressTwo
                              ? Formik?.errors.addressTwo
                              : null
                          }
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <h3>Zip/Postal Code*</h3>
                        <InputElement
                          width="100%"
                          type="text"
                          name="postalCode"
                          placeholder="90210"
                          value={Formik.values.postalCode}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.postalCode && Formik.touched.postalCode
                              ? Formik?.errors.postalCode
                              : null
                          }
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <h3>City*</h3>
                        <InputElement
                          width="100%"
                          type="text"
                          name="city"
                          placeholder="Please select a city"
                          value={Formik.values.city}
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          helperText={
                            Formik.errors.city && Formik.touched.city
                              ? Formik?.errors.city
                              : null
                          }
                        />
                      </Col>
                    </Row>
                    <div className={classes.default}>
                      <div className="egl-radio-with-check">
                        <div className="eachRadio">
                          <Radio
                            disabled
                            className="radio-input"
                            type="radio"
                            checked={defaultAddress}
                            onClick={() => setDefaultAddress(!defaultAddress)}
                            id={""}
                            name={""}
                            value={defaultAddress}
                          >
                            Make this my default payment method
                            <p>Accelerated payment on Uplio and thousands of other platforms</p>
                          </Radio>
                        </div>
                      </div>
                    </div>
                    <div className={classes.btnContainer}>
                      <button onClick={() => history.push("/payment")}>
                        <img src={arrow} alt="Arrow" />
                        <span>PREVIOUS</span>
                      </button>
                      {
                        Formik.values.email === "" ||
                          Formik.values.city === "" ||
                          Formik.values.country === "" ||
                          Formik.values.addressOne === "" ||
                          Formik.values.postalCode === "" ?
                          <button
                            disabled
                            className={classes.disableButton}
                          >
                            Place Order
                          </button>
                          :
                          <button
                            className={clsx(['button', classes.saveButton])}
                            onClick={() => handleSubmit()}
                          >
                            Place Order
                          </button>
                      }
                    </div>
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
      </div >
      <Footer />
    </>
  )
}

export default Payment
