import React, { useEffect, useState } from "react";
import classes from "./ProductDetails.module.scss"
import Navigation from '../../../constant/public/Navigation/Navigation'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import axios from "axios";
import Footer from '../../../constant/public/Footer/Footer'
import { HeartOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "swiper/css";
import LeftArrowSlider from "../../../assets/images/left.png"
import RightArrow from "../../../assets/images/right.png"
import downArrow from "../../../assets/images/downArrow.svg"
import errorIcon from '../../../assets/svg/error_icon.svg'
import { Link, useParams } from "react-router-dom";
import InputWithDropDownWithoutSearch from "../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch";
import ColorDropdown from "../../../constant/public/ColorDropdown/ColorDropdown";
import ProductSlider from "../../../constant/public/ProductDetailsSlider/ProductSlider";
import CertificateShow from "../../../constant/public/CertificateShow/CertificateShow";
import ProductCard from "../../../constant/ProductCard/ProductCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { RecentlyViewedProducts } from "../../../constant/public/RecentlyViewedProducts";
import { useSelector } from "react-redux";
import ProductDetailsTextarea from "../../../constant/public/ProductDetailsTextarea/ProductDetailsTextarea";
import CartPopup from "../../../constant/public/CartPopup/CartPopup";
import RecentlyViewed from "../../../constant/public/RecentlyViewed/RecentlyViewed";
import uuid from 'react-uuid';
import { decode } from 'html-entities';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="h5">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      textColorPrimary
      style={{ fontSize: "13px", paddingLeft: "10px", fontWeight: "bold" }}
      selected
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
const ProductDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [color, setColor] = useState("");
  const [notableBrand, setNotableBrand] = useState("");
  const [product, setProduct] = useState({});
  const [qty, setQty] = useState();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [recentlyViewedProductsList, setRecentlyViewedProductsList] = useState([]);
  const [selectValues, setSelectValues] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [current, setCurrent] = useState(0);
  const [comment, setComment] = useState("");
  const userLoggedInState = useSelector((state) => state.user.userLoggedIn)
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token');


  useEffect(() => {
    setCurrent(0)

    return () => {

    }
  }, []);

  const handleSelectChange = (id, name, type) => {
    const selectedOption = id;
    let updatedOptions = [];
    const index = selectValues.findIndex(item => item.name === name);
    if (index === -1) {
      updatedOptions = [...selectValues, { name, type, variation: true, options: [{ key: "value", value: selectedOption }] }];
    } else {
      updatedOptions = [
        ...selectValues.slice(0, index),
        { name, type, variation: true, options: [{ key: "value", value: selectedOption }] },
        ...selectValues.slice(index + 1)
      ];
    }
    setSelectValues(updatedOptions);
  }

  console.log("selected", selectValues)

  const length = 0;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    if (userLoggedInState && token && params !== null && params !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/products/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
        )
        .then((res) => {
          const data = res.data
          setProduct(data);
          setQty(res.data?.minimum_quantity);
          getSimilarProducts(res.data?.category?._id)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false)
        });
    }
    else if (params !== null && params !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/products/${params.id}`
        )
        .then((res) => {
          const data = res.data
          setProduct(data);
          setQty(res.data?.minimum_quantity)
          getSimilarProducts(res.data?.category?._id)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false)
        });
    }
    setRecentlyViewedProductsList(JSON.parse(sessionStorage.getItem('products')));
  }, [params])

  const getSimilarProducts = (category) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/products/search?limit=12&page=1&sortBy=createdAt:desc`, {
        "category": [category],
        "country": "",
        "minimum_quantity": "",
        "specialization": "",
        "notableBrands": ""
      })
      .then((res) => {
        // setSupplierData(res.data.results);
        setSimilarProducts(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      })
  }

  const getRecentlyViewedProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/${user.id}/products/recently-viewed?limit=12&page=1&sortBy=createdAt:desc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      .then((res) => {
        // setSupplierData(res.data.results);
        setRecentlyViewedProductsList(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      })
  }

  const handleAddToCart = () => {
    let products = localStorage.getItem('products');
    if (products) {
      products = JSON.parse(products);
    } else {
      products = [];
    }
    // check if product already exists in array
    const existingProduct = products.find((p) => p._id === product._id && JSON.stringify(p.modifications) === JSON.stringify(selectValues));
    // const existingProduct = products.find((p) => p._id === product._id);
    if (!existingProduct) {
      let newProd = { ...product };
      // newProd.selectedValues = selectValues;
      newProd.uuid = uuid();
      newProd.comments = comment;
      newProd.modifications = selectValues;
      newProd.quantity = qty;
      newProd.productId = newProd._id;
      products.push(newProd);
      products = products.filter(p => Object.keys(p).length !== 0); // remove empty object
      localStorage.setItem('products', JSON.stringify(products));
    } else {
      // existingProduct.selectedValues = selectValues;
      existingProduct.comments = comment;
      existingProduct.modifications = selectValues;
      existingProduct.quantity = qty;
      let newProductsArray = products.filter(p => p._id !== existingProduct._id)
      newProductsArray.push(existingProduct);
      newProductsArray = newProductsArray.filter(p => Object.keys(p).length !== 0); // remove empty object
      localStorage.setItem('products', JSON.stringify(newProductsArray));
    }
    setColor("");
    setQty(product.minimum_quantity);
    setComment("");
    setSelectValues([]);
    setShow(true);
  }

  console.log(product)



  return (
    <>
      <div>
        <Navigation />
        {
          loading ?
            <div style={{ marginTop: "160px", marginLeft: "30px" }}>Loading...</div>
            :
            <div className={`${classes.container} container ProductDetail`}>
              <div className={classes.mainContent}>
                <div className="row mt-5">
                  <div className={classes.imageSetting}>
                    {
                      // product?.images && product?.images?.length > 0 &&
                      <div className={classes.leftSlider}>
                        <ProductSlider initialSlide={product?.images?.findIndex(f => f.cover) || 0} images={product?.images} />
                      </div>
                    }
                    <div className={classes.right}>
                      <div className="row">
                        <div className="col-12" style={{ marginTop: "10px" }}>
                          <div>
                            <h2>{product?.name}</h2>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className={classes.wrapper}>
                            <h5>${product?.price} <small>/per item</small></h5>
                          </div>
                        </div>
                        {
                          product?.modifications && product?.modifications?.map((prod, index) => {
                            let newArr = []
                            prod?.options?.map((item, index) => (
                              newArr.push({
                                id: item.key,
                                label: item.value,
                              })
                            ))
                            return (
                              prod.type === 'color' ?
                                <div classes="col-12" key={prod._id}>
                                  <div className={classes.wrapper}>
                                    <p className="mb-0">{prod.name}</p>
                                    <div className={classes.selectWrapper}>
                                      <ColorDropdown
                                        dropdown={newArr}
                                        width={'100%'}
                                        placeholder="-Select an Option-" //Prem
                                        // value={color}
                                        value={selectValues.find(item => item.name === prod.name)?.options[0].value}
                                        // onChange={(event) => console.log(event, index)}
                                        onChange={(id) => handleSelectChange(id, prod.name, "color")}
                                        //onChange={(val) => setColor(val)}
                                        setDropdown={(event) => setColor(event)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className={classes.wrapper} key={prod._id}>
                                  <p className="mb-0">{prod.name}</p>
                                  <div className={classes.selectWrapper}>
                                    <InputWithDropDownWithoutSearch
                                      dropdown={newArr}
                                      width={'100%'}
                                      // placeholder={prod.name}
                                      value={selectValues.find(item => item.name === prod.name)?.options[0].value}
                                      placeholder="-Select an Option-" //Prem
                                      onChange={(event) => handleSelectChange(event, prod.name, "text")}
                                      //Commented by Prem onChange={(value) => setSize(value)}
                                      setDropdown={(event) => console.log(event, index)}
                                    />
                                  </div>
                                </div>
                            )
                          })
                        }
                        <div classes="col-12">
                          <div className={classes.wrapper}>
                            <p>Quantity (Min {product.minimum_quantity} items)</p>
                            <input className={classes.input} value={qty} onChange={(e) => setQty(e.target.value)} type="number" style={{ width: "100%" }} />
                            {
                              qty < product.minimum_quantity &&
                              <p className={classes.errorMessage}>
                                <img src={errorIcon} alt="Uplio" />{' '}
                                Quantity must be greater than or equal to {product.minimum_quantity}
                              </p>
                            }
                            <div className={classes.numbersArrow}>
                              <img src={downArrow} alt="arrow" onClick={() => qty > product.minimum_quantity && setQty((parseInt(qty) - 1))} className={classes.smallLeftArrow} />
                              <img src={downArrow} alt="arrow" onClick={() => setQty((parseInt(qty) + 1))} className={classes.smallRightArrow} />
                            </div>
                          </div>
                        </div>
                        {
                          product?.customization_option === "Allowed" &&
                          <div classes="col-12">
                            <div className={`${classes.wrapper} ${classes.last}`}>
                              <p style={{ display: "flex", alignItems: "center", gap: "6px" }}><span>Leave a Comment</span> <InfoCircleOutlined className="productIcon" /></p>
                              <ProductDetailsTextarea value={comment} width={"100%"} onChange={(val) => setComment(val)} />
                            </div>
                          </div>
                        }
                        <div className="col-12">
                          <div>
                            <div className={classes.totalContainer}>
                              <p>Total Items Amount: <strong>{qty > 0 ? qty : 0}</strong></p>
                              <p>Total Price: <strong>${qty > 0 ? Math.round((parseInt(qty) * parseInt(product.price)) * 100 / 100).toFixed(2) : "0.00"}</strong></p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className={`${classes.wrapper} ${classes.buttonContainer}`}>
                            {
                              qty < product.minimum_quantity || selectValues.length < product?.modifications?.length ?
                                <button
                                  className={clsx(['button', classes.saveButton])}
                                  onClick={handleAddToCart}
                                  disabled
                                >
                                  ADD TO CART
                                </button>
                                :
                                <button
                                  className={clsx(['button', classes.saveButton])}
                                  onClick={handleAddToCart}
                                >
                                  ADD TO CART
                                </button>
                            }
                            <button
                              className={clsx(['button', classes.saveButton])}
                            >
                              <HeartOutlined />
                            </button>
                          </div>
                          {
                            show &&
                            <CartPopup handlePopup={() => setShow(!show)} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  params.id &&
                  <RecentlyViewedProducts product={product} />
                }
                <hr style={{ color: "rgba(135, 135, 135, 0.5)" }} />
                <div className={`row mt-5 ${classes.details}`}>
                  <div className="col-6">
                    <AppBar position="static" className={classes.appBar} style={{ color: "black", fontSize: "20px !important" }}>
                      <Tabs
                        // style={{ backgroundColor: "white", fontSize: "20px" }}
                        variant="centered"
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        <LinkTab label="Product Description" href="/drafts" {...a11yProps(0)} />
                        <LinkTab label="About Manufacturer" href="/trash" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                      <div className={classes.catTitle}>Categories: <span>{product?.category?.name}</span></div>
                      <div style={{ marginTop: "16px" }} className="quill ql-editor">
                        <div dangerouslySetInnerHTML={{ __html: decode(product?.description) }}></div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className={classes.eachItem}>
                        Production Capacity: <strong>{product?.user?.productionCapacity} units per month</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Location: <strong>{product?.user?.country}</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Year Established: <strong>{product?.user?.yearEstablished}</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Same manufacturer as: {product?.user?.notableBrands && product?.user?.notableBrands.map(function (item, i) {
                          return (
                            <strong>{item + ", "}</strong>
                          )
                        })}
                      </div>
                      <div className={classes.eachItem}>
                        Certifications: {product?.user?.images && product?.user?.images?.filter(f => f.type === "LCI").map(function (item, i) {
                          return (
                            <CertificateShow index={i} name={item.name?.split(".")[0]} imagesArray={product?.user?.images?.filter(f => f.type === "LCI")} images={item.url} />
                          )
                        })}
                      </div>
                      {/* </div> */}
                    </TabPanel>
                  </div>
                </div>
                <hr style={{ color: "red", marginTop: "14px" }} />
                <div className={`${classes.similarProducts} similarProducts`}>
                  <div className={classes.topBar}>
                    <div className={classes.title}>Similar Products</div>
                    <div className={classes.btnContainer}>
                      <button className='leftArrow' >
                        <img src={LeftArrowSlider} alt="Left arrow" onClick={() => swiper?.slidePrev()} />
                      </button>
                      <button className='rightArrow'>
                        <img src={RightArrow} alt="Right arrow" onClick={() => swiper?.slideNext()} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <Swiper
                      slidesPerView={6}
                      spaceBetween={10}
                      loop={false}
                      dots={false}
                      breakpoints={{
                        200: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 60,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 60,
                        },
                      }}
                      slideActiveClass={classes.slideActive}
                      slideNextClass={classes.slideActive}
                      slideClass={classes.slide}
                      onSwiper={setSwiper}
                      onSlideChange={(e) => setCurrent(e.realIndex)}
                      className="mySwiper"
                    >
                      {
                        similarProducts.length > 0
                        &&
                        similarProducts.map((item, idx) => {
                          return (
                            <SwiperSlide key={item?._id} className={classes.slide}>
                              <div>
                                <ProductCard data={item} key={item} handleBrand={(val) => setNotableBrand(val)} />
                              </div>
                            </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                  </div>
                </div>
                <div className={`${classes.similarProducts} similarProducts`}>
                  <RecentlyViewed />
                </div>
                {/* <div className={`${classes.similarProducts} similarProducts`}>
                  <div className={classes.topBar}>
                    <div className={classes.title}>Recently Viewed</div>
                    <div className={classes.btnContainer}>
                      <button className='leftArrow' >
                        <img src={LeftArrowSlider} alt="Left arrow" onClick={() => swiperTwo?.slidePrev()} />
                      </button>
                      <button className='rightArrow'>
                        <img src={RightArrow} alt="Right arrow" onClick={() => swiperTwo?.slideNext()} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <Swiper
                      slidesPerView={6}
                      // spaceBetween={60}
                      loop={false}
                      loopAdditionalSlides={false}
                      dots={false}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                          loop: true
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 60
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 60
                        },
                      }}
                      slideActiveClass={classes.slideActive}
                      slideNextClass={classes.slideActive}
                      slideClass={classes.slide}
                      onSwiper={setSwiperTwo}
                      onSlideChange={(e) => setCurrent(e.realIndex)}
                      className="mySwiper"
                    >
                      {
                        userLoggedInState ?
                          (
                            recentlyViewedProductsList.length > 0
                            &&
                            recentlyViewedProductsList.map((item, idx) => {
                              return (
                                <SwiperSlide key={item?._id}>
                                  <div>
                                    <ProductCard data={item} key={item} handleBrand={(val) => setNotableBrand(val)} />
                                  </div>
                                </SwiperSlide>
                              )
                            })
                          )
                          :
                          (
                            recentlyViewedProductsList && recentlyViewedProductsList.length > 0 && recentlyViewedProductsList?.map((item) => {
                              return (
                                <SwiperSlide key={item?._id} className={classes.slide}>
                                  <div>
                                    <ProductCard data={item} key={item} handleBrand={(val) => setNotableBrand(val)} />
                                  </div>
                                </SwiperSlide>
                              )
                            })
                          )
                      }
                    </Swiper>
                  </div>
                </div> */}
                <hr style={{ color: "red", marginTop: "14px" }} />
                <div className={classes.returnAndRefundLink}>
                  <Link>Return and Refund Policies</Link>
                </div>
              </div>
            </div>
        }

      </div >
      <Footer></Footer>
    </>
  )
}


export default ProductDetail;
