import React, { useEffect } from 'react'
import classes from './AddProduct.module.scss'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik';
import clsx from 'clsx'
import * as Yup from "yup"
import { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import ImageUpload from '../ImageUpload/ImageUpload';
import SaveModal from '../../../../../constant/public/SaveModal/SaveModal';
import TooltipMessage from '../../../../../constant/TooltipMessage';
import { Radio } from 'antd';
import InputElement from '../../../../../constant/public/Input/InputElement';
import cross from "../../../../../assets/svg/Cross.svg"
import errorIcon from '../../../../../assets/svg/error_icon.svg'
import ColoModal from './ColorModal/ColorModal';
import InputWithDropDownWithoutSearch from '../../../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch';
import { RGBAtoHEX } from '../../../../../constant/RgbaToHex';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RichTextEditor from './RichTextEditor/RichTextEditor';


const AddProduct = (props) => {
    const token = sessionStorage.getItem('token')
    const user = useSelector((state) => state.user.user)
    const history = useHistory()
    const [categoryData, setCategoryData] = useState([])
    const [getproductFeatures, setProductFeatures] = useState([]);
    const [bg, setBg] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modificationOpen, setModificationOpen] = useState(false);
    const [finalColor, setFinalColor] = useState("#FFFFFF");
    const [showInput, setShowInput] = useState(false);
    const [textOpen, setTextOpen] = useState(false);
    const [modifications, setModifications] = useState([
        {
            name: "",
            type: "text",
            variation: true,
            options: [{ key: "", value: "" }]
        },
    ]);

    const handleAddModification = () => {
        setModifications([...modifications, { name: "", type: "text", variation: true, options: [{ key: "", value: "" }] }]);
    };

    const handleAddMultipleInput = (index) => {
        const newModifications = [...modifications];
        newModifications[index].options.push({ key: "", value: "" });
        setModifications(newModifications);
    };

    const handleRemoveOption = (index, optionIndex) => {
        const newModifications = [...modifications];
        newModifications[index].options.splice(optionIndex, 1);
        setModifications(newModifications);
    };

    const handleInputChange = (event, index) => {
        const newModifications = [...modifications];
        newModifications[index][event.target.name] = event.target.value;
        setModifications(newModifications);
    };

    const handleMultipleInputChange = (event, index, optionIndex, keyType) => {
        const newModifications = [...modifications];
        newModifications[index].options[optionIndex][keyType] = event.target.value;
        setModifications(newModifications);
    };

    const handleModTypeChange = (event, index) => {
        // if (event === "color") {
        //     setColorOpen(true);
        // } else {
        const newModifications = [...modifications];
        newModifications[index].type = event;
        setModifications(newModifications);
        // }
    };


    const addTask = React.useCallback(
        (newTask) => {
            setProductFeatures([...getproductFeatures, newTask]);
        },
        [getproductFeatures]
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/categories`)
            .then((res) => {
                let newArr = []
                res.data.categories.map((item, index) => (
                    newArr.push({
                        id: item._id,
                        label: item.name,
                    })
                ))
                console.log(newArr)
                setCategoryData(newArr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const handleSubmitForm = (d) => {
        var updatedData = [];
        modifications.map((item, index) => {
            console.log("<index>: " + index + " <modifications.leng> : " + modifications.length + " <modifications.name> : " + modifications[index].name)
            if ((modifications[index].name != "") && modifications[index].type == "text") { //Prem: Check if the Name set, then only send to the API. Dont send NULL
                console.log({ ...item, type: "text" })
                updatedData.push({ ...item, type: "text" });
            }

        });
        console.log(updatedData)
        if (formik.values.modification2Name) { //Prem. Check if the color modification is set, then add the color. If not dont pass Color
            console.log("Prem: " + formik.values.modification2Name)
            updatedData.push({ "type": "color", name: formik.values.modification2Name, variation: true, options: colorInputs });
        }
        axios.post(
            `${process.env.REACT_APP_API_URL}/users/${user?.id}/products`,
            {
                price: formik.values.pricePerItem,
                name: formik.values.productName,
                modifications: updatedData,
                measuring_unit: { "unit": "size", value: formik.values.size },
                minimum_quantity: formik.values.minQuantity,
                customization_option: formik.values.customzationOption,
                features: formik.values.productFeatures,
                images: formik.values.productImages,
                description: formik.values.description,
                categoryName: formik.values.category_id === "620e246a43c520220316c069" ? formik.values.productCategory : "",
                category: formik.values.category_id,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((res) => {
                // if (d === "button") {
                setIsModalOpen(!isModalOpen);
                setTimeout(() => {
                    history.push("/dashboard/supplier/product-and-services")
                }, 2000);
                // }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const formik = useFormik({
        initialValues: {
            pricePerItem: "",
            productName: '',
            // modification: '',
            productCategory: '',
            category_id: '',
            // measuringUnits: '',
            customzationOption: '',
            minQuantity: '',
            // color: [],
            // size: [],
            // productFeatures: [],
            description: "",
            modification1Name: "",
            modification2Name: "",
            // modificationOption: "",
            productImages: [],
        },
        validationSchema: Yup.object({
            pricePerItem: Yup.number().required("Price per Item is required").min(1, 'Add value greater than 1'),
            productName: Yup.string().required("Product Name is required"),
            // modification: Yup.string().required("Modification Unit is required"),
            // productCategory: Yup.string().required("Product Category is required"),
            category_id: Yup.string().required("Product Category Id is required"),
            // measuringUnits: Yup.string().required("Measuring Units is required"),
            description: Yup.string().required("Description is required"),
            customzationOption: Yup.string().required("Customization Option is required"),
            minQuantity: Yup.number().required("Minimum Quantity is required").min(1, 'Add value greater than 1'),
            // color: Yup.array().required("Color is required"),
            // size: Yup.array().required("Size is required"),
            modification1Name: Yup.string().required("Name is required"),
            modification2Name: Yup.string(),
            // modificationOption: Yup.string().required("Option is required"),
            productImages: Yup.array().required("Images are required"),
            // productFeatures: Yup.array().required("Features are required")
        }),
        onSubmit: (values) => {
            // handleProfileUpdated
            alert(JSON.stringify(values, null, 2));
        },

    });

    const { setFieldValue } = formik;

    const [textInputs, setTextInputs] = useState([{ key: "", value: "" }]);
    const [colorInputs, setColorInputs] = useState([{ key: "", value: "" }]);

    const handleColorClick = () => {
        setColorInputs([...colorInputs, { key: "", value: "" }]);
        setShowInput(!showInput);
    }

    const handleColorChange = (e, index1, key) => {
        console.log(e, index1, key);
        if (key === "key") {
            const newColorInputs = [...colorInputs];
            newColorInputs[index1].key = e.target.value;
            setColorInputs(newColorInputs);
        } else {
            const newColorInputs = [...colorInputs];
            newColorInputs[index1].value = e;
            setColorInputs(newColorInputs);
        }
    }

    const handleRemoveModification = (index) => {
        const newModifications = [...modifications];
        newModifications.splice(index, 1);
        setModifications(newModifications);
    };

    console.log(formik.values);


    return (
        <div className={classes.AddProduct}>
            {/* <div className={classes.header}>
                <SupplierHeader />
            </div>˝ */}
            {/* <ProductModification /> */}
            <form onSubmit={(e) => { e.preventDefault() }}>
                <div className="row">
                    <div style={{ display: "flex" }}>
                        <div className='col-6' style={{ flexGrow: 1 }}>
                            <h3>PRODUCT INFO</h3>
                        </div>
                        <div className={classes.viewProductInfoBtn}>
                            <h3 className={classes.productInfo} onClick={() => {
                                history.push('/dashboard/supplier/profile/productDetails/')
                            }}><Link to="/product" style={{ display: "flex", gap: "10px", alignItems: "center" }}><span>VIEW THE PRODUCT PAGE</span><InfoCircleOutlined /></Link> </h3>
                        </div>
                    </div>
                </div>
                <div>
                    <SaveModal openModal={isModalOpen} />
                </div>
                <div className={classes.container}>
                    <div className={`${classes.wrapper} ${classes.mainWrapper}`}>
                        <div className={classes.mainContent}>
                            {/* <Form /> */}
                            <div className='row'>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Product Category </p>
                                        <div className={classes.selectWrapper}>
                                            <InputWithDropDownWithoutSearch
                                                dropdown={categoryData}
                                                categories={true}
                                                width={'100%'}
                                                value={formik.values.category_id ? formik.values.category_id : "- Select a category -"}
                                                // onChange={(value) => setFieldValue("category_id", value)}
                                                setDropdown={(value) => setFieldValue("category_id", value)}
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                {
                                    formik.values.category_id === "620e246a43c520220316c069" &&
                                    <div className={classes.col12}>
                                        <div className={classes.wrapper}>
                                            <p>Enter Category Name <TooltipMessage text="Enter a descriptive category name. This will allow customers find your products easier." /></p>
                                            <InputElement
                                                name="productCategory"
                                                type="text"
                                                className={clsx([`input`, classes.companyInput])}
                                                wrapperClass={classes.companyInput}
                                                placeholder="Furniture"
                                                onChange={formik.handleChange}
                                                value={formik.values.productCategory}
                                                helperText={formik.errors.productCategory
                                                    // formik.touched.productCategory
                                                    ? formik?.errors.productCategory
                                                    : null}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Product Name <TooltipMessage text="Enter a descriptive product name. This will allow customers find your products easier." /></p>
                                        <InputElement
                                            name="productName"
                                            type="text"
                                            className={clsx([`input`, classes.companyInput])}
                                            wrapperClass={classes.companyInput}
                                            placeholder="Product Name"
                                            onChange={formik.handleChange}
                                            value={formik.values.productName}
                                            helperText={formik.errors.productName
                                                // formik.touched.productName
                                                ? formik?.errors.productName
                                                : null}
                                        />
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p>Price per item</p>
                                        <span className={classes.inputHelp}>
                                            <span className={classes.dollarSign}>$</span>
                                            <input
                                                name="pricePerItem"
                                                type='number'
                                                style={{ width: "325px" }}
                                                className={`${classes.input} ${classes.priceInput}`}
                                                value={formik.values.pricePerItem}
                                                onChange={formik.handleChange}
                                                placeholder="50"
                                                helperText={formik.errors.pricePerItem
                                                    // formik.touched.input
                                                    ? formik.errors.pricePerItem
                                                    : null}
                                            />
                                            {
                                                formik.errors.pricePerItem &&
                                                <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                                    {formik.errors.pricePerItem}</div>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div classes="col-12">
                                    <div className={`${classes.wrapper} ${classes.modWrapper}`}>
                                        <p>Type of Modification</p>
                                        <div className={classes.selectWrapper}>
                                            {
                                                modifications.length > 0
                                                    ?
                                                    <>
                                                        <InputWithDropDownWithoutSearch
                                                            // dropdown={[]}
                                                            name="modificationName"
                                                            placeholder="Material"
                                                            onClick={() => { setModificationOpen(!modificationOpen) }}
                                                            noDropdown={true}
                                                            value={formik.values.modification1Name !== "" || formik.values.modification2Name !== "" ? `${formik.values.modification1Name}; ${formik.values.modification2Name}` : "- Select Modification -"}
                                                            onChange={(value) => console.log("")}
                                                            setDropdown={(value) => { setModificationOpen(!modificationOpen) }}
                                                        />
                                                        {
                                                            modifications.length === 0 ?
                                                                <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                                                    {"Atleast one modification is required"}</div>
                                                                :
                                                                modifications[0].name === '' ?
                                                                    <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                                                        {"Atleast one modification is required"} </div>
                                                                    :
                                                                    null
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <InputWithDropDownWithoutSearch
                                                            dropdown={[{
                                                                label: "+ ADD MODIFICATION",
                                                                id: "+ ADD MODIFICATION",
                                                            }]}
                                                            name="modificationName"
                                                            placeholder="Cotton"
                                                            // onClick={() => setModificationOpen(!modificationOpen)}
                                                            // value={formik.values.modification1Name}
                                                            value={formik.values.modification1Name !== "" || formik.values.modification2Name !== "" ? `${formik.values.modification1Name}; ${formik.values.modification2Name}` : "- Select Modification -"}
                                                            onChange={(value) => {
                                                                setModifications([{
                                                                    name: "",
                                                                    type: "text",
                                                                    options: [{ key: "", value: "" }]
                                                                }]);
                                                                setModificationOpen(!modificationOpen); setTextOpen(!textOpen)
                                                            }}
                                                            setDropdown={(value) => { setModificationOpen(!modificationOpen) }}
                                                        />
                                                        {
                                                            modifications.length === 0 ?
                                                                <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                                                    {"Atleast one modification is required"}</div>
                                                                :
                                                                modifications[0].name === '' ?
                                                                    <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                                                        {"Atleast one modification is required"} </div>
                                                                    :
                                                                    null
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <>
                                        {
                                            modificationOpen ?
                                                <div>
                                                    {
                                                        modifications?.map((modification, index) => (
                                                            <div style={{ marginTop: "23px" }}>
                                                                <div className={classes.typeOfModification}>
                                                                    <div className={classes.left}>
                                                                        <div>
                                                                            <h4>SELECT TYPE <TooltipMessage text="Use this section to add product variations such as size, fabrics, scents, flavors, etc. This will help customers find your products easier and faster based on their specific preferences and filters." /></h4>
                                                                            <div className={classes.radioSection}>
                                                                                <Radio.Group value={modification.type} onChange={(e) => handleModTypeChange(e.target.value, index)}>
                                                                                    <div className={`${classes.eachRadio} pureRadioBox`}>
                                                                                        <Radio className={classes.radio} value="text">Text</Radio>
                                                                                    </div>
                                                                                    <div className={`${classes.eachRadio} pureRadioBox`}>
                                                                                        {/*removed  disabled={index > 0}  by prem in the below line */}
                                                                                        <Radio value="color">Color</Radio>
                                                                                    </div>
                                                                                </Radio.Group>
                                                                            </div>
                                                                        </div>
                                                                        {/* <button onClick={() => { setTextInputs([{ key: "", value: "" }]); setTextOpen(false) }}>
                                                                            <img src={cross} alt="Cross" />
                                                                        </button> */}
                                                                    </div>
                                                                    <div className={classes.right}>
                                                                        <div>
                                                                            {
                                                                                modification.type === "text" ?
                                                                                    <div key={index} style={{ marginBottom: "36px" }}>
                                                                                        <button className={classes.wholeCloseBtn} onClick={() => { handleRemoveModification(index); index === 0 && setModificationOpen(false) }}>
                                                                                            <img src={cross} alt="Cross" />
                                                                                        </button>
                                                                                        <p>Name <TooltipMessage text="Name your product variant. For example, Size." /></p>
                                                                                        <div className={classes.textInnerWrapper}>
                                                                                            <InputElement
                                                                                                name="name"
                                                                                                onChange={(event) => handleInputChange(event, index)}
                                                                                                type="text"
                                                                                                className={clsx([`input`, classes.productInput])}
                                                                                                wrapperClass={classes.productInput}
                                                                                                value={modification.name}
                                                                                                placeholder="Material"
                                                                                                clearIcon={true}
                                                                                            // helperText={formik?.errors?.modification1Name
                                                                                            //     ? formik?.errors?.modification1Name
                                                                                            //     : null}
                                                                                            />
                                                                                        </div>
                                                                                        {
                                                                                            modification.options.map((option, optionIndex) => (
                                                                                                <div key={optionIndex}>
                                                                                                    <p>Option <TooltipMessage text="Add specific variation value one by one. For example, if your product has five different sizes XS, S, M, L, XL, you would need to add a row for each." /></p>
                                                                                                    <div className={classes.textInnerWrapper}>
                                                                                                        <InputElement
                                                                                                            name="value"
                                                                                                            onChange={(event) => handleMultipleInputChange(event, index, optionIndex, 'value')}
                                                                                                            type="text"
                                                                                                            className={clsx([`input`, classes.productInput])}
                                                                                                            wrapperClass={classes.productInput}
                                                                                                            data-index={optionIndex}
                                                                                                            value={option.value}
                                                                                                            placeholder="Cotton"
                                                                                                            clearIcon={true}
                                                                                                        // helperText={formik?.errors?.modification1Name
                                                                                                        //     ? formik?.errors?.modification1Name
                                                                                                        //     : null}
                                                                                                        />
                                                                                                        <button className={classes.inputCloseBtn} onClick={() => handleRemoveOption(index, optionIndex)}>
                                                                                                            <img src={cross} alt="Cross" />
                                                                                                        </button>
                                                                                                        {/* <button onClick={() => handleRemoveOption(index, optionIndex)}>Remove Option</button> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                        <button className={classes.addOption} onClick={() => handleAddMultipleInput(index)}>
                                                                                            <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD OPTION
                                                                                        </button>
                                                                                    </div>
                                                                                    :
                                                                                    // modification.type === "color" &&
                                                                                    <div>
                                                                                        {/* Below button added by Prem */}
                                                                                        <button className={classes.wholeCloseBtn} onClick={() => { handleRemoveModification(index); setColorInputs([]); formik.values.modification2Name = ""; index === 0 && setModificationOpen(false) }}>
                                                                                            <img src={cross} alt="Cross" />
                                                                                        </button>
                                                                                        <p>Name <TooltipMessage text="Name your product variant. For example, Size." /></p>
                                                                                        <div className={classes.textInnerWrapper}>
                                                                                            <InputElement
                                                                                                name="modification2Name"
                                                                                                onChange={(e) => setFieldValue("modification2Name", e.target.value)}
                                                                                                type="text"
                                                                                                className={clsx([`input`, classes.productInput])}
                                                                                                wrapperClass={classes.productInput}
                                                                                                value={`${formik.values.modification2Name}`}
                                                                                                placeholder="Color"
                                                                                                clearIcon={true}
                                                                                                helperText={formik?.errors?.modification2Name
                                                                                                    ? formik?.errors?.modification2Name
                                                                                                    : null}
                                                                                            />
                                                                                        </div>
                                                                                        {colorInputs?.length > 0 && colorInputs?.map((input, index) => (
                                                                                            <div key={index}>
                                                                                                <div key={index}>
                                                                                                    <p>Option <TooltipMessage text="Add specific variation value one by one. For example, if your product has five different sizes XS, S, M, L, XL, you would need to add a row for each." /></p>
                                                                                                    <div className={classes.colorWrapper}>
                                                                                                        <div className={classes.innerWrapper}>
                                                                                                            <InputElement
                                                                                                                name="modificationOption"
                                                                                                                onChange={e => handleColorChange(e, index, "key")}
                                                                                                                type="text"
                                                                                                                className={clsx([`input`, classes.productInput])}
                                                                                                                wrapperClass={classes.productInput}
                                                                                                                value={input.key}
                                                                                                                placeholder="White"
                                                                                                                clearIcon={true}
                                                                                                            />
                                                                                                            <button className={classes.inputCloseBtn} onClick={() => setColorInputs(colorInputs.filter((f, indx) => indx !== index))}>
                                                                                                                <img src={cross} alt="Cross" />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        <div className={classes.colorRight}>
                                                                                                            <div className={classes.colorBox}>
                                                                                                            </div>
                                                                                                            <div className={classes.colorBox}>
                                                                                                                <ColoModal value={input.value} hexColor={input.value.includes("rgb") ? RGBAtoHEX(input.value) : input.value} handleSave={(val, bg) => { setFinalColor(val); setBg(bg); handleColorChange(val, index, "value") }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                        <button className={classes.addOption} onClick={handleColorClick}>
                                                                                            <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD OPTION
                                                                                        </button>
                                                                                    </div>
                                                                                // </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        modificationOpen &&
                                                        <div style={{ textAlign: "center" }}>
                                                            <button className={classes.addModificationBtn} onClick={handleAddModification}>
                                                                <img src={cross} alt="Cross" style={{ transform: "rotate(45deg)" }} /> ADD modification
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </>
                                </div>
                                <div classes="col-12 mb-5">
                                    <div className={classes.wrapper}>
                                        <p><span className={classes.smallWidth}>Minimum Order Quantity</span> <TooltipMessage text="Input the minimum order quantity that you accept for this specific product. This number should be greater than “1”" /></p>
                                        <InputElement
                                            name="minQuantity"
                                            type="number"
                                            placeholder="50"
                                            value={formik.values.minQuantity}
                                            onChange={formik.handleChange}
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            pattern="[0-9]*"
                                            className={clsx([`input`, classes.companyInput])}
                                            wrapperClass={classes.companyInput}
                                            helperText={formik.errors.minQuantity
                                                ? formik?.errors.minQuantity
                                                : null}
                                        />
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.wrapper}>
                                        <p> <span className={classes.smallWidth}>Customization Option</span> <TooltipMessage text="By selecting customization option you allow customers to modify this specific product. Customization orders are marked as pending until you approve the requested customization requests from customers." /></p>
                                        <div className={classes.selectWrapper}>
                                            <InputWithDropDownWithoutSearch
                                                dropdown={[
                                                    {
                                                        id: "Allowed",
                                                        label: "Allowed"
                                                    },
                                                    {
                                                        id: "Not allowed",
                                                        label: "Not allowed"
                                                    },
                                                ]}
                                                name="customzationOption"
                                                width={'100%'}
                                                value={formik.values.customzationOption ? formik.values.customzationOption : "- Select an option -"}
                                                // onChange={(val) => setFieldValue("customzationOption", val)}
                                                setDropdown={(val) => setFieldValue("customzationOption", val)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.col12}>
                                    <div className={classes.textareaWrapper}>
                                        <p style={{ marginBottom: "21px" }}>Product Description <TooltipMessage text="Provide specific information about the item for example the size and material of this item." /></p>
                                        <div className={classes.textAreaContainer}>
                                            {/* <textarea
                                                name={props.name}
                                                value={formik.values.description}
                                                maxLength={1000}
                                                onChange={(e) => setFieldValue("description", e.target.value)}
                                                style={{ width: props.width }}
                                                className={classes.textarea}
                                                placeholder="As an example, Arcus A.G. was founded by a group of people coming from very diverse backgrounds in many different professional fields ranging from management and entrepreneurship to fashion and graphic design."
                                                rows={props.rows}
                                                cols={props.cols}
                                            /> */}
                                            <RichTextEditor value={formik.values.description} onChange={(value) => setFieldValue("description", value)} />
                                            {/* <ReactQuill theme="snow" value={formik.values.description} onChange={(e) => setFieldValue("description", e.target.value)} /> */}
                                            <div className={classes.wordCount}>
                                                {formik.values?.description?.length} / 1000
                                            </div>
                                        </div>
                                        {
                                            formik.errors.description &&
                                            <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {formik.errors.description ? formik.errors.description : null}</div>
                                        }
                                    </div>
                                </div>
                                {/* <div className='col-12'>
                                <div className={classes.spezialization} style={{ marginTop: "48px" }}>
                                    <p style={{ paddingRight: "0px" }}>Product Features</p>
                                    <button style={{ padding: "0px" }} onClick={() => setIsSpezModalOpened(true)}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M22.5805 5.54673L18.6672 1.61339C18.4086 1.3561 18.0586 1.21167 17.6939 1.21167C17.3291 1.21167 16.9791 1.3561 16.7205 1.61339L2.84719 15.4667L1.58053 20.9334C1.53683 21.1332 1.53833 21.3403 1.58491 21.5395C1.63149 21.7387 1.72198 21.9249 1.84977 22.0847C1.97755 22.2444 2.13941 22.3736 2.32351 22.4627C2.50761 22.5519 2.70931 22.5988 2.91386 22.6001C3.00917 22.6097 3.10521 22.6097 3.20053 22.6001L8.72719 21.3334L22.5805 7.49339C22.8378 7.2348 22.9823 6.88485 22.9823 6.52006C22.9823 6.15527 22.8378 5.80532 22.5805 5.54673ZM8.06053 20.1334L2.88053 21.2201L4.06053 16.1401L14.4405 5.80006L18.4405 9.80006L8.06053 20.1334ZM19.3339 8.8334L15.3339 4.83339L17.6539 2.52673L21.5872 6.52673L19.3339 8.8334Z"
                                                fill="#ADADAD"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div style={{ color: "red", fontSize: "14px", marginTop: "4px" }}>{
                                    formik.values?.productFeatures?.length === 0 ? "Product Features are required" : null
                                }</div>
                                <ul className={classes.spezializationList}>
                                    {
                                        formik.values.productFeatures && formik.values.productFeatures.length > 0 ?
                                            formik.values?.productFeatures?.map((spez) =>
                                                <li key={spez}>{spez}</li>
                                            )
                                            : null
                                    }
                                </ul>
                                <FeaturesModal
                                    productFeat={
                                        formik.values.productFeatures && formik.values.productFeatures.length > 0
                                            ? formik.values.productFeatures
                                            : []
                                    }
                                    setFieldValue={setFieldValue}
                                    isOpened={isSpezModalOpened}
                                    setIsOpened={setIsSpezModalOpened}
                                ></FeaturesModal>
                                <div
                                    className={
                                        isSpezModalOpened
                                            ? 'blocker blocker_opened'
                                            : 'blocker'
                                    }
                                ></div>
                            </div> */}
                                {/* <ProjectImages
                                accept="image/*"
                                title={'Product Images'}
                                tooltip={false}
                                // text={'Images (.JPEG, .PDF). Max size: 200 MB'}
                                type="LCI"
                                productImages
                                data={formik.values.productImages}
                                handleImage={(arr) => setFieldValue('productImages', arr)}
                                helperText={
                                    formik.values?.productImages.length === 0 && "Image is required"
                                }
                            /> */}
                                <div className="col-12" style={{ marginTop: "28px" }}>
                                    <ImageUpload
                                        title={'Product Images'}
                                        text={'Images (.JPEG, .PDF). Max size: 200 MB'}
                                        data={formik.values.productImages}
                                        handleImage={(arr) => setFieldValue('productImages', arr)}
                                        type="PI"
                                    />
                                    {
                                        formik.values?.productImages?.length === 0 &&
                                        <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {""}{
                                            formik.values?.productImages?.length === 0 ? "Product Images are required" : null
                                        }</div>
                                    }
                                    {
                                        formik.values?.productImages?.length > 0 && formik.values?.productImages?.filter(f => f.cover)?.length === 0 &&
                                        <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" /> {""}{
                                            "Please choose cover image"
                                        }</div>
                                    }
                                </div>
                                <hr />
                                <div className={classes.viewProductInfoBtn2}>
                                    <h3 className={classes.productInfo} onClick={() => {
                                        history.push('/dashboard/supplier/profile/productDetails/')
                                    }}><Link to="/product" style={{ display: "flex", gap: "10px", alignItems: "center" }}><span>VIEW THE PRODUCT PAGE</span><InfoCircleOutlined /></Link> </h3>
                                </div>
                                <div className="col-12">
                                    <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "start", marginTop: "0px" }} className={`${classes.wrapper}`}>
                                        {
                                            formik.values.productName === '' || formik.values.category_id == '' || formik.values.pricePerItem == "" ||
                                                formik.values.minQuantity == "" ||
                                                formik.values.customzationOption == "" || formik.values.description == "" || formik.values?.productImages?.length === 0 ||
                                                modifications.length === 0 || modifications[0].name === "" || formik.values?.productImages?.filter(f => f.cover)?.length === 0
                                                ?
                                                <button
                                                    disabled
                                                    className={clsx(['button', classes.saveButton])}
                                                >
                                                    Save
                                                </button>
                                                :
                                                <button
                                                    onClick={() => handleSubmitForm("button")}
                                                    // type='submit'
                                                    className={clsx(['button', classes.saveButton])}
                                                >
                                                    Save
                                                </button>
                                        }
                                        <button
                                            onClick={() => history.push("/dashboard/supplier/product-and-services")}
                                            style={{ maxWidth: "160px", background: "transparent", color: "#303030" }}
                                            className={clsx(['button', classes.saveButton])}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </form >
        </div >
    )
}

export default AddProduct;

