import React, { useRef, useState } from 'react'
import "./ProductSlider.css"
import LeftArrow from "../../../assets/images/left.png"
import RightArrow from "../../../assets/images/right.png"
import "./ProductSlider.css"
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const ProductSlider = ({ images, initialSlide }) => {
    const sliderRef = useRef();
    const [swiper, setSwiper] = useState(null);
    const [current, setCurrent] = useState(0);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        afterChange: (index) => index < images?.length && setCurrent(index),
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };


    return (
        <div className='productImages productSlider'>
            <div className='sliderContainer'>
                <Slider className={images?.length === 1 && "displayFlex"} ref={sliderRef} {...settings}>
                    {
                        images?.length === 1 ? [images[0], images[0], images[0], images[0]]?.map((image, index) => {
                            return (
                                <div className="slideSlick" key={index}>
                                    <img src={image?.url} alt="images" />
                                </div>
                            )
                        })
                            :
                            images?.length === 2 ? [images[0], images[1], images[0], images[1]]?.map((image, index) => {
                                return (
                                    <div className="slideSlick" key={index}>
                                        <img src={image?.url} alt="images" />
                                    </div>
                                )
                            })
                                :
                                images?.length > 2 && images?.map((image, index) => {
                                    return (
                                        <div className="slideSlick" key={index}>
                                            <img src={image.url} alt="images" />
                                        </div>
                                    )
                                })
                    }
                </Slider>
            </div>
            <div className="count">{current + 1}/{images?.length === 1 ? 1 : images?.length}</div>
            {/* <div className="count">{images?.length <= 2 ? (current + 1) : current + 1}/{images?.length === 1 ? 1 : images?.length}</div> */}
            <div className='buttonContainer'>
                {/* <button className='leftArrow' onClick={() => sliderRef?.current.slickPrev()}> */}
                {/* <button className='leftArrow' onClick={async () => { await current > 1 ? setCurrent(current - 1) : setCurrent(4); sliderRef?.current.slickPrev(); }}> */}
                <button className='leftArrow' onClick={async () => { sliderRef?.current.slickPrev(); }}>
                    <img src={LeftArrow} alt="Left arrow" />
                </button>
                <button className='rightArrow' onClick={async () => { sliderRef?.current.slickNext(); }}>
                    <img src={RightArrow} alt="Right arrow" />
                </button>
            </div>
        </div >
    )
}

export default ProductSlider
