import React, { useState, useEffect } from 'react'
import classes from './ComingSoon.module.scss'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import { useHistory } from 'react-router-dom'
import image from '../../../../assets/svg/comingsoon.svg'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import Footer from '../../../../constant/public/Footer/Footer'

const ComingSoon = () => {
  const history = useHistory()

  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 767 ? false : true
  )

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 767) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    })

    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth > 991) {
          setIsMobile(false)
        } else {
          setIsMobile(true)
        }
      })
    }
  }, [])

  return (
    <>
      <Navigation />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.imageWrapper}>
            <img src={image} alt="Uplio" className={classes.image} />
          </div>
          <div className={classes.mainContent}>
            <h1>WE’re COMING SOON{!isMobile && '!'}</h1>
            <h2>WE’re working hard to give you the best experience</h2>
            <ButtonWithRightArrow
              content="GO TO HOMEPAGE"
              function={() => history.push({ pathname: '/' })}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default ComingSoon
