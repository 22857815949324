import React, { useState, useEffect } from 'react'
import classes from './Orders.module.scss'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Loading from '../../../../constant/Loading/Loading'
import bannerImg from "../../../../assets/svg/g10.svg"
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'antd'
import OrdersTable from '../../../common/OrdersTable/OrdersTable'

const Orders = () => {
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState("");
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const getAllOrders = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/buyer/${user?.id}/orders?limit=10&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrders(res.data?.results);
        setTotalOrders(res.data?.total);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response)
      })
  }


  useEffect(() => {
    getAllOrders("");

    return () => {

    }
  }, []);

  useEffect(() => {
    totalOrders > orders.length && axios
      .get(
        `${process.env.REACT_APP_API_URL}/buyer/${user?.id}/orders?limit=10&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrders(prevArray => [...prevArray, ...res.data?.results]);
        setTotalOrders(res.data?.total);
        setLoadMoreLoading(false);
      })
      .catch((err) => {
        setLoadMoreLoading(false);
        console.log(err.response)
      })

    return () => {

    }
  }, [page]);


  return (
    <>
      <Navigation />
      <div className={classes.ordersWrapper}>
        <div className={`container ${classes.ordersWrapperInner}`}>
          <div className={classes.wrapper}>
            {
              loading ?
                <Loading />
                :
                orders?.length > 0 ?
                  <div>
                    <OrdersTable setModalOpened={setModalOpened} data={orders} />
                    <div className={classes.loadMore}>
                      {
                        loadMoreLoading ?
                          <Loading />
                          :
                          <button disabled={orders.length === totalOrders} onClick={async () => setPage(page + 1)}>
                            Load Older
                          </button>
                      }
                    </div>
                  </div>
                  :
                  <Row className={classes.noOrdersContainer}>
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 9, order: 1 }}>
                      <h2>
                        {user.firstname} {user.lastname}, You are a step away from realizing your dream.
                      </h2>
                      <p>Let's Accelerate that!</p>
                      <button onClick={() => history.push("/browse")}>BROWSE PRODUCT SAMPLES</button>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 15, order: 2 }}>
                      <img src={bannerImg} alt="Banner Img" />
                    </Col>
                  </Row>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default Orders
