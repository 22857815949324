import React, { useState } from 'react'
import classes from './Products.module.scss'
import {
  Row,
  Col,
  Input,
  Pagination,
  Radio,
  Space,
  Rate,
  Button,
  Empty,
  Divider,
  Image,
  Skeleton,
  Carousel,
} from 'antd'

import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import loadingImage from '../../../assets/gif/Pulse-1.5s-201px.gif'
import companyLogo from '../../../assets/images/profile_image.png'
import defaultImage from '../../../assets/images/sample_logo_img.png'
import Rating from '../../common/Rating/Rating'
import RadioGroup from '../../../constant/public/RadioGroup/RadioGroup'
import clsx from 'clsx'
import {
  SearchOutlined,
  HeartOutlined,
  SecurityScanTwoTone,
} from '@ant-design/icons'
import Tags from '../../common/Tags/Tags'
import upIndicator from '../../../assets/svg/up_indicator.svg'

import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil'
import ProductCard from './ProductCard/ProductCard'
import { string } from 'yup'

const Products = () => {
  let { id } = useParams()
  const searchString = id
  const history = useHistory()
  const path = useParams()

  const [loading, setLoading] = useState(false)
  const [supplierData, setSupplierData] = useState([])
  const [pageinate, setPaginate] = useState(1)
  const [search, setSearch] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [categoryFilterData, setCategoryFilterData] = useState([])
  const [currentCategory, setCurrentCategory] = useState('')
  const [filterQuantity, setFilterQuantity] = useState('')
  const [selectedCateory, setSelectedCategory] = useState('')
  const [showCategory, setShowCategory] = useState(true)
  const [filterMobileOpened, setFilterMobileOpened] = useState(false)
  const [showMinQuantity, setShowMinQuantity] = useState(true)
  const [handleFilterQuantityArray] = useState([
    { name: 'All' },
    { name: '500 units or less' },
    { name: '1,000 units or less' },
    { name: '10,000 units or less' },
    { name: '10,000 units or more' },
  ])
  const [showLocation, setShowLocation] = useState(true)
  const [locationFilterData, setLocationFileterData] = useState([
    { name: 'All' },
    { name: 'USA' },
    { name: 'Canada' },
    { name: 'Mexico' },
    { name: 'Turkey' },
  ])

  const [searchResults, setSearchResults] = useState([])
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/suppliers`)
      .then((res) => {
        setSearchData(res.data.data)
        handleFilterSearchData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleFilterSearchData = (searchDataApi) => {
    let arr = []

    const handleSearchString = () => {
      if (searchString.includes('-')) {
        return searchString.toLowerCase().replace(/-/g, ' ')
      } else {
        return searchString
      }
    }

    const searchLower = handleSearchString()

    searchDataApi.map((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (
          value !== undefined &&
          value !== null &&
          key !== 'images' &&
          key !== 'workEmail'
        ) {
          if (key === 'supplier_category_data') {
            if (value.slug === searchLower) {
              arr.push(item)
            }
          } else if (value.toString().includes(searchLower)) {
            arr.push(item)
            break
          }
        }
      }
    })

    setSupplierData(arr)

    // const newData = searchDataApi.filter((string) => {
    //   return nameLower.includes(searchLower) ? true : false
    // })

    // if (newData[0] && search) {
    //   isDropdownOpened = true
    // }

    // const newObj = {
    //   title: obj.title,
    //   data: newData,
    // }

    // return newObj

    // setSearchData(filteredDropdown)
  }

  useEffect(() => {
    const initialCategory = `${path.id[0].toUpperCase()}${path.id.slice(1)}`

    setCurrentCategory(initialCategory)
  }, [path])

  useEffect(() => {
    setLoading(true)
    setSearch(id)
    setCurrentCategory(id)
    setSelectedCategory(id)
    handleFilterSearchData(searchData)
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_API_URL}/users/suppliers?category=${
    //         id === undefined ? searchInput : id === 'all' ? '' : id
    //       }`
    //     )
    //     .then((res) => {
    //       console.log(res)

    //       setSupplierData(res.data.data)
    //       setPaginate(res.data.data.length)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })

    axios
      .get(`${process.env.REACT_APP_API_URL}/categories`)
      .then((res) => {
        console.log(res)
        setLoading(false)

        let newCategoryFilterData = []
        res.data.categories.map((item) => {
          if (id.toLowerCase() === item.name.toLowerCase()) {
            newCategoryFilterData.push({
              name: item.name,
              slug: item.slug,
              status: true,
              _id: item._id,
              checked: true,
            })
          } else {
            newCategoryFilterData.push(item)
          }
        })

        setCategoryFilterData(newCategoryFilterData)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  useEffect(() => {
    console.log('search', id)
  }, [id])

  const handleFilterCategory = (e) => {
    console.log('a')

    history.push({ pathname: `/products/${e.target.value}` })
    setCurrentCategory(e.target.value)
  }

  const handleFilterQuantity = (e) => {
    setFilterQuantity(e.target.value)
    if (e.target.value === '500 units or less') {
      const newSupplierData = []
      supplierData.map((item) => {
        if (item.MOQ <= 500) {
          newSupplierData.push(item)
        }
      })
      setSupplierData(newSupplierData)
    } else if (e.target.value === '1,000 units or less') {
      const newSupplierData = []
      supplierData.map((item) => {
        if (item.MOQ <= 1000) {
          newSupplierData.push(item)
        }
      })
      setSupplierData(newSupplierData)
    } else if (e.target.value === '10,000 units or less') {
      const newSupplierData = []
      supplierData.map((item) => {
        if (item.MOQ <= 10000) {
          newSupplierData.push(item)
        }
      })
      setSupplierData(newSupplierData)
    } else if (e.target.value === '10,000 units or more') {
      const newSupplierData = []
      supplierData.map((item) => {
        if (item.MOQ >= 10000) {
          newSupplierData.push(item)
        }
      })
      setSupplierData(newSupplierData)
    }
  }

  return (
    <>
      <Navigation />
      <main className={classes.container}>
        <div className={classes.sectionWrapper}>
          <div className={classes.section}>
            <div className={classes.resultsHeader}>
              <h2>FILTER BY:</h2>
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowCategory(!showCategory)}>
                Category
                <img
                  src={upIndicator}
                  style={
                    showCategory
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  alt="Uplio"
                />
              </button>
              {showCategory ? (
                <RadioGroup
                  radioTags={categoryFilterData}
                  onChange={(evt) => handleFilterCategory(evt)}
                  value={currentCategory}
                />
              ) : null}
              {/* <Radio.Group
                  onChange={handleFilterCategory}
                  value={currentCategory}
                >
                  <Space direction="vertical">
                    <Radio value="all">All</Radio>
                    {categoryFilterData.map((item) => (
                      <Radio value={item.name}>{item.name}</Radio>
                    ))}
                  </Space>
                </Radio.Group> */}
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowMinQuantity(!showMinQuantity)}>
                Minimum Quantity
                <img
                  style={
                    showMinQuantity
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  src={upIndicator}
                  alt="Uplio"
                />
              </button>
              {showMinQuantity ? (
                <Radio.Group
                  onChange={handleFilterQuantity}
                  value={filterQuantity}
                >
                  <Space direction="vertical">
                    <Radio value="">All</Radio>
                    <Radio value="500 units or less">500 units or less</Radio>
                    <Radio value="1,000 units or less">
                      1,000 units or less
                    </Radio>
                    <Radio value="10,000 units or less">
                      10,000 units or less
                    </Radio>
                    <Radio value="10,000 units or more">
                      10,000 units or more
                    </Radio>
                  </Space>
                </Radio.Group>
              ) : null}
            </div>
            <div className={clsx([`searchTabAside`, classes.filterOption])}>
              <button onClick={() => setShowLocation(!showLocation)}>
                Location
                <img
                  style={
                    showLocation
                      ? { transform: 'none' }
                      : { transform: 'rotate(180deg)' }
                  }
                  src={upIndicator}
                  alt="Uplio"
                />
              </button>
              {showLocation ? (
                <RadioGroup radioTags={locationFilterData} />
              ) : null}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.resultsHeader}>
              <h2>
                {id}: {pageinate} suppliers found
              </h2>
            </div>
            <button
              className={classes.resultsFilter}
              onClick={() => setFilterMobileOpened(true)}
            >
              <h2>FILTER</h2>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.0001 4.80005L8.0001 8.80005L12.0001 4.80005L13.6001 5.60005L8.0001 11.2L2.4001 5.60005L4.0001 4.80005Z"
                  fill="#303030"
                />
              </svg>
            </button>
            <div
              className={classes.filterOptionWrapper}
              style={
                filterMobileOpened ? { display: 'block' } : { display: 'none' }
              }
            >
              <div className={classes.container}>
                <div className={clsx([`searchTabAside`, classes.filterOption])}>
                  <button
                    className={classes.filterClose}
                    onClick={() => setFilterMobileOpened(false)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                        fill="#303030"
                      />
                    </svg>
                  </button>
                  <div className={classes.resultsHeader}>
                    <h2>FILTER BY:</h2>
                  </div>
                  <button onClick={() => setShowCategory(!showCategory)}>
                    Category
                    <img
                      src={upIndicator}
                      style={
                        showCategory
                          ? { transform: 'none' }
                          : { transform: 'rotate(180deg)' }
                      }
                      alt="Uplio"
                    />
                  </button>
                  {showCategory ? (
                    <Radio.Group
                      onChange={handleFilterQuantity}
                      value={filterQuantity}
                    >
                      <Space direction="vertical">
                        <Radio value="">All</Radio>
                        <Radio value="USA">USA</Radio>
                        <Radio value="Canada">Canada</Radio>
                        <Radio value="Mexico">Mexico</Radio>
                        <Radio value="Turkey">Turkey</Radio>
                      </Space>
                    </Radio.Group>
                  ) : null}
                </div>
                <div className={clsx([`searchTabAside`, classes.filterOption])}>
                  <button onClick={() => setShowMinQuantity(!showMinQuantity)}>
                    Minimum Quantity
                    <img
                      style={
                        showMinQuantity
                          ? { transform: 'none' }
                          : { transform: 'rotate(180deg)' }
                      }
                      src={upIndicator}
                      alt="Uplio"
                    />
                  </button>
                  {showMinQuantity ? (
                    <RadioGroup
                      radioTags={handleFilterQuantityArray}
                      onChange={(evt) => handleFilterQuantity(evt)}
                      value={filterQuantity}
                    />
                  ) : null}
                </div>
                <div className={clsx([`searchTabAside`, classes.filterOption])}>
                  <button onClick={() => setShowMinQuantity(!showMinQuantity)}>
                    Location
                    <img
                      style={
                        showMinQuantity
                          ? { transform: 'none' }
                          : { transform: 'rotate(180deg)' }
                      }
                      src={upIndicator}
                      alt="Uplio"
                    />
                  </button>
                  {showMinQuantity ? (
                    <RadioGroup
                      radioTags={handleFilterQuantityArray}
                      onChange={(evt) => handleFilterQuantity(evt)}
                      value={filterQuantity}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.productCardBottom}>
              {supplierData.length > 0
                ? supplierData.map((item, idx) => (
                    <>
                      <ProductCard data={item} category={id} key={item} />
                    </>
                  ))
                : null}
            </div>
            <div className={classes.loadMore}>
              <button>
                <h3>Load More</h3>
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Products
