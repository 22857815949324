import React from 'react'
import classes from "./Header.module.scss"
import arrow from "../../../../assets/svg/Arrow_Indicator_Left.svg"
import { useHistory } from 'react-router-dom'


const SupplierHeader = ({ text, link }) => {
    const history = useHistory();
    return (
        <div className={`${classes.wrapper}`}>
            <div className={`${classes.header} ${classes.container}`}>
                <div className='container'>
                    <button onClick={() => history.push(link)}>
                        <img src={arrow} alt="Arrow" />
                        <h5>{text}</h5>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SupplierHeader
