import { Image } from 'antd';
import { useState } from 'react';
import classes from "./ViewImage.module.scss"


export const ViewImage = ({ imagesArray }) => {
    const [visible, setVisible] = useState(false);
    return (
        <div className={classes.wrapper}>
            <Image
                preview={{
                    visible: false,
                }}
                width={200}
                src={imagesArray[0]?.url}
                onClick={() => setVisible(true)}
            />
            <button onClick={() => setVisible(true)}>
                View Image
            </button>
            <div
                style={{
                    display: 'none',
                }}
            >
                <Image.PreviewGroup
                    preview={{
                        visible,
                        onVisibleChange: (vis) => setVisible(vis),
                    }}
                >
                    {
                        imagesArray?.map(img => {
                            return (
                                <Image key={img?.url} src={img?.url} />
                            )
                        })
                    }
                </Image.PreviewGroup>
            </div>
        </div>
    );
};