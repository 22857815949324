import React, { useEffect, useState } from 'react'
import classes from './MyInquiries.module.scss'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import MyInquiriesTable from './MyInquiriesTable/MyInquiriesTable'
import axios from 'axios'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import Respond from './Respond/Respond'
import Placeholder from '../../../common/Placeholder/Placeholder'
import { useSelector } from 'react-redux'
import Loading from '../../../../constant/Loading/Loading'

const MyInquiries = () => {
  const history = useHistory()
  const token = sessionStorage.getItem('token')
  const userData = useSelector((state) => state.user.user)
  const [quoteData, setQuoteData] = useState([])
  const [selectedQuote, setSelectedQuote] = useState(null)
  const [quoteModalOpened, setQuoteModalOpened] = useState(false)
  const [loading, setLoading] = useState(true);
  const [totalQuotes, setTotalQuotes] = useState();
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handleGetQuote = () => {
    history.push('/quote/generic_quotation')
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotes/buyer/${userData._id}?limit=10&page=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {

        setLoading(false)
        setQuoteData(res.data.results);
        setTotalQuotes(res.data.totalResults);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [])

  const getLoadMoreData = () => {
    setLoadMoreLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotes/buyer/${userData._id}?limit=10&page=${page}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setLoadMoreLoading(false)
        setTotalQuotes(res.data.totalResults);
        setQuoteData(prevArray => [...prevArray, res.data.results]);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (totalQuotes > quoteData.length) {
      getLoadMoreData();
    }
  }, [page])

  return (
    <>
      <Navigation />
      <div className={classes.inquiriesWrapper}>
        <div>
          {/* <div className={classes.responsiveHeader}>
          <div className={classes.mainSectionHeading}>
            <h3>Quotes</h3>
          </div>
        </div> */}
          <div className={classes.wrapper}>
            {/* <div className={classes.actionWrapper}>
              <BuyerAside />
            </div> */}
            <div className={classes.mainSection}>
              {loading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  {quoteData?.length > 0 ? (
                    <MyInquiriesTable
                      data={quoteData}
                      setModalOpened={setQuoteModalOpened}
                      selectQuote={setSelectedQuote}
                    />
                  ) : (
                    <Placeholder
                      btnText={'GET A QUOTE'}
                      btnFunction={handleGetQuote}
                    />
                  )}
                </>
              )}
              <div className={classes.loadMore}>
                {
                  loadMoreLoading ?
                    <Loading />
                    :
                    <button onClick={async () => setPage(page + 1)}>
                      Load Older
                    </button>
                }
              </div>
            </div>
          </div>
          <div className={classes.ordersModal}>
            <CommonModal
              className={classes.ordersModal}
              title="Your inquiry info"
              modalOpened={quoteModalOpened}
              setModalOpened={setQuoteModalOpened}
            >
              {selectedQuote && (
                <div className={classes.ordersModalWrapper}>
                  <div className={classes.modalMain}>
                    <div className={classes.tableRow}>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Submitted:</h3>
                        <p>{moment(selectedQuote?.submittedDatetime).format('MMM D, YYYY')}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Quantity:</h3>
                        <p>{selectedQuote?.quantity}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Category:</h3>
                        <p>{selectedQuote?.productCategory}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Budget:</h3>
                        <p>${selectedQuote?.budget}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Launch date:</h3>
                        <p>{moment(selectedQuote?.projectLaunchDate).format('MMM D, YYYY')}</p>
                      </div>
                      <div className={classes.infoItem}>
                        <h3 className={classes.rowTitle}>Status:</h3>
                        <p>{selectedQuote?.quote_status_description}</p>
                      </div>
                      <div className={classes.infoItem} data-item="description">
                        <h3 className={classes.rowTitle}>About the project:</h3>
                        <p>{selectedQuote?.description}</p>
                      </div>
                    </div>
                    <div className={classes.photos}>
                      {selectedQuote?.inspirationImages?.length > 0
                        ? selectedQuote?.inspirationImages?.map((item) => (
                          <img width="100" height="100" src={item} alt="Uplio" />
                        ))
                        : null}
                    </div>
                    <div>
                      <ul className={classes.docsList}>
                        {selectedQuote?.inspirationDocument?.length > 0
                          ? selectedQuote?.inspirationDocument?.map((item) => <li>
                            <a href={item} target="_blank" rel="noreferrer">For_Uplio.doc</a>
                          </li>)
                          : null}
                      </ul>
                    </div>
                  </div>
                  {selectedQuote?.quoteSuppliers?.length > 0 && (
                    <div className={classes.modalBottom}>
                      <h2
                        className={clsx([`title title_item`, classes.titleItem])}
                      >
                        {selectedQuote?.quoteSuppliers?.length === 1
                          ? `A supplier responded you`
                          : `${selectedQuote?.quoteSuppliers?.length} suppliers responded you`}
                      </h2>
                      <div className={classes.respondsWrapper}>
                        {selectedQuote?.quoteSuppliers?.map((resp, idx) => {
                          return <Respond qouteSupplierIndex={idx + 1} quoteSuppliers={selectedQuote?.quoteSuppliers} selectedQuote={selectedQuote} data={resp} key={resp.title + idx} />
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CommonModal>
          </div>
          <div
            className={quoteModalOpened ? 'blocker blocker_opened' : 'blocker'}
          ></div>
        </div>
      </div>
    </>
  )
}
export default MyInquiries
