import React from 'react'
import { Link } from 'react-router-dom'
import classes from './PrivacyPolicy.module.scss'

import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'

const PrivacyPolicy = () => {
  return (
    <>
      <Navigation />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>Privacy Policy</h1>
          <h3>
            Last Updated: Feb 23, 2022
            <br />
            Uplio understands you care about how your personal information is
            used and shared, and we take your privacy seriously. Please read the
            following to learn more about our privacy practices. By using or
            accessing the Services in any manner, you acknowledge that you
            accept the practices and policies outlined in this Privacy Policy,
            and that we will collect, use, and share your information in the
            following ways. Remember that your use of Uplio’s Services is
            subject to the <Link to="/terms-conditions">
              Terms of Service
            </Link>{' '}
            , which incorporates this Privacy Policy. Any terms we use in this
            Policy without defining them have the definitions given to them in
            the <Link to="/terms-conditions">Terms of Service</Link> .
          </h3>
        </div>
        <div className={classes.content}>
          <h2>What does this Privacy Policy cover?</h2>
          <h3>
            This Privacy Policy applies to uplio.com, and all other websites,
            applications, widgets, products, services, and other offerings
            (collectively, the “Services”) owned and operated by Uplio, Inc. and
            our affiliates and subsidiaries (collectively, “Uplio”) that link to
            this Privacy Policy. This Policy covers our treatment of the
            personal information we gather through use of our Services. Uplio
            Inc. (325 Canal Way, Costa Mesa, CA 92627) is the data controller
            for the personal information collected in connection with use of
            Uplio’s services.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>What Information does Uplio Collect?</h2>
          <h3>
            We collect information about your use of our Services. We also
            gather various types of personal information, as explained in more
            detail below.
            <br />
            Information You Provide to Us:
            <br />
            We receive and store any information you knowingly provide to us. If
            you create an account or request for quote, place an order with us,
            we may collect personal information such as:
            <br />
            Buyers (i.e., Brands):
            <br />
            When you register as a Buyer with Uplio to request for quote or
            order from Supplier (also known as Manufacturers), we will collect
            your first and last name, email address, images, designs, technical
            document such as tech pack, drawing to better understand your
            project requirements. On some occasion we may collect additional
            information such as phone number, business address, website, years
            you have been in business, and projected sales figures.
            <br />
            If you place an order to purchase goods from Suppliers through
            Uplio, we will also collect information to facilitate that order,
            such as your shipping address, Tax Identification Number, and
            payment information.
            <br />
            In some cases, we may request additional information to validate
            your business, such as photos of your brand or your social media
            profiles. In some cases, we may request information such as your tax
            identifier or other government issued numbers or financial or lease
            records to establish your account limits.
            <br />
            Suppliers (i.e., Manufacturers):
            <br />
            When you register as a Supplier with Uplio, we will collect your
            first and last name, business name, website, address, phone number,
            and information about your manufacturing business such as the
            primary category of products you provide, the countries you operate
            in, your past portfolio, years you have been in business, notable
            past projects and clients, and your social media profiles.
            <br />
            If you are accepted as a Supplier for Uplio, we may ask for
            additional information to build out your profile such as your
            profile photo, pricing, minimum order (MOQ), production capacity,
            and order requirements, and years you have been established. When
            you receive an order through Uplio, we will collect information to
            facilitate that order, such as your Tax Identification Number, tax
            class, and bank account information so we can pay you.
            <br />
            Optionally, you may choose to provide information about your
            existing customers to refer to Uplio, such as their name, store
            name, and email address.
            <br />
            <br />
            Using the Services When you browse and use our Services, we collect
            information about the items you browse, show interest in, and buy.
            Some of our Services allow you to create audio- visual content,
            which will be streamed live to other Uplio users. These Services
            will access the camera and/or microphone on your device and record
            your image and voice.
            <br />
            <br />
            Communication with Us We collect personal information from you when
            you communicate with us, for example when you request information
            about our services, contact our Support team, apply for a job, or
            register for our newsletter. Surveys, Sweepstakes, and Contests
            <br />
            <br />
            We may ask you to participate in optional surveys, where you may be
            asked to provide personal information. Additionally, we may conduct
            sweepstakes or contests where you may be asked to provide personal
            information. This information may be used to contact you about the
            sweepstakes or contest, and for other promotional and marketing
            purposes, as permitted by law. Additionally, note that some
            jurisdictions require us to publicly share information about
            sweepstakes and contest winners. Forums and Social Media
            <br />
            <br />
            Our Services may provide forums, blogs, social media pages, or other
            channels where individuals may review purchases, talk about their
            experience using our Services, or “like” or “share” content to
            social media. Note that content provided for and posted in these
            channels, including by you, is public and that the processing of
            your information might be subject to the policies and notices of
            third parties that make such channels available.
            <br />
            <br />
            Information Collected Automatically Whenever you interact with our
            Services, we automatically receive and record information on our
            server logs from your browser or device, which may include your IP
            address, geolocation data, device identification, the type of
            browser and/or device you’re using to access our Services, and the
            page or feature you requested. Additionally, we, as well as third
            parties that provide content, advertising, or other functionality on
            our Services, may use cookies, pixel tags, local storage, and other
            technologies to collect information automatically through our
            Services. Analytics
            <br />
            <br />
            We may use analytics service providers, including Google Analytics,
            to collect information regarding visitors to our Services, such as
            their behavior on our Services or information about their
            demographic. For more information about Google Analytics, see
            <a href="https://www.google.com/policies/privacy/partners/">
              [ Link ]
            </a>
            . To opt out of Google Analytics, visit
            <a href="https://tools.google.com/dlpage/gaoptout">[ Link ]</a>
            .
            <br />
            Do-Not-Track Your browser may offer you a “Do Not Track” option,
            which allows you to signal to operators of websites and web
            applications and services (including behavioral advertising
            services) that you do not wish such operators to track certain of
            your online activities over time and across different websites. At
            this time, we do not respond to nor honor Do-Not-Track signals from
            web browsers. Information Collected from Third Parties We may
            collect information about you through a referral program, such as
            when a Uplio Buyer or Supplier refers one of their existing
            customers, leads, or partners to Uplio. If you have been referred to
            Uplio but wish to opt out of emails from us, please contact us as
            explained below. If you access our Services through a third party or
            social networking site, we may collect information about you from
            that third-party application if that information has been made
            public via your privacy settings with that third party. For example,
            if you register for Uplio with Facebook, we may collect your name,
            profile ID, location, gender, profile picture, and list of your
            friends.
          </h3>
        </div>
        <div className={classes.content}>
          <h2>How Does Uplio Use the Personal Information It Collects?</h2>
          <h3>
            We use the information we collect about you for various business
            purposes as described below. <br />
            To provide you with our Services
            <br />
          </h3>
          <ul>
            <li>Enter into a contract with you</li>
            <li>Allow you to set up a user account and profile</li>
            <li>Allow you to submit a request for quote(s)</li>
            <li>Fulfi l your requests for products and services</li>
            <li>Allow you to create and share content with others</li>
            <li>
              Communicate with you about your account and updates to our
              Services
            </li>
            <li>Process applications, transactions, and payments</li>
            <li>Allow you to register for events</li>
            <li>Facilitate communications between you and other users</li>
          </ul>
          <h3>For our legitimate business interests</h3>
          <ul>
            <li>Analyze how you use the Services</li>
            <li>Understand user interest and engagement on the Services</li>
            <li>Engage in marketing or sales outreach</li>
            <li>
              Provide customized content,offers, or Services, including
              marketing content via email, postal mail, social media, SMS, or
              other channels, subject to applicable laws
            </li>
            <li>
              Research and develop Services oImprove and streamline our Services
            </li>
            <li>Verify your identity and prevent fraud</li>
            <li>
              Detect security incidents; protect against malicious, deceptive,
              fraudulent, or illegal activity oEnsure quality control
            </li>
            <li>
              Debug to identify and repair errors oEnforce our Terms and
              policies
            </li>
            <li>Audit or other compliance activities</li>
          </ul>
          <h3>
            To comply with our legal obligations
            <br />
            We retain and use your information in connection with potential
            legal claims, and for compliance, regulatory and auditing purposes.
            For example, we retain information where we are required by law, or
            if we are compelled to do so by a court order or regulatory body.
            Also, when you exercise any applicable legal rights you have to
            access, amend or delete your personal information, we may request
            identification and verification documents from you for the purpose
            of confirming your identity. With your consent
            <br />
            Uplio may collect and use personal information based with your
            consent. You can revoke your consent at any time (either through our
            Services directly or by contacting us via hello@uplio.com), though
            note that you might not be able to use any service or feature that
            requires collection or use of that personal information.
            <br />
            <br />
            De-Identified and Aggregated Information
            <br />
            We use personal information to create de-identified, aggregated
            information such as: information about demographics, de-identified
            location information, information about devices used to access our
            Services, and de-identified, aggregate information on transactions
            on our Services that help our users understand and optimize usage.
            <br />
            <br />
            Automatic Collection Technologies Our use of automatic collection
            technologies such as cookies, pixel tags, and local storage objects
            (together “Cookies”) fall into the following general categories:
          </h3>
          <ul>
            <li>
              Strictly Necessary: Technologies that allow you to access our
              Services and use our features, and tools that help us identify
              irregular site behavior and prevent fraudulent activity or improve
              security.
            </li>
            <li>
              Personalization: Technologies that help us personalize advertising
              and content for you, such as by making inferences about your
              interests based on the content you interact with on our Services,
              which informs future selections of content or advertising.
            </li>
            <li>
              Measurement: Technologies that collect information about your use
              of our Services, including measuring, understanding, and reporting
              on your usage of the Services.
            </li>
            <li>
              Information Storage and Access: Technologies that store
              information or provide access to information that is already
              stored on your device, such as your shopping cart.
            </li>
            <li>
              Content Selection, Delivery, and Reporting: Technologies that
              select and deliver content for you, and measure the eff ectiveness
              of that content, including information about what content was
              shown, how often or how long it was shown, when and where it was
              shown, and what actions, if any, you took on the content.
            </li>
            <li>
              Ad Selection, Delivery, and Reporting: Technologies that select
              and deliver advertisements for you, and measure the eff ectiveness
              of those advertisements, including information about what
              advertisements were shown, how often or how long they were shown,
              when and where they were shown, and what actions, if any, you took
              on the advertisements.
            </li>
          </ul>
          <h2>Third-Party Websites</h2>
          <h3>
            We may offer links to third-party websites or Services, which are
            not controlled by us and not subject to the protections laid out in
            this Privacy Policy. We do not endorse nor approve any third-party
            website and we encourage our users to read the Privacy Policy of
            each service they interact with. APIs and SDKs We may use
            third-party APIs and software development kits (“SDKs”), which may
            allow third parties to collect personal information about you for
            various purposes, such as to conduct analytics, verify your business
            when you register with Uplio as a Buyer or Supplier, provide
            customized content, or otherwise streamline the Services. For more
            information about our use of APIs and SDKs, contact us as described
            below.
          </h3>
          <h2>Will Uplio Share Any of the Personal Information It Receives?</h2>
          <h3>
            We do not rent nor sell your personal information in personally
            identifiable form to anyone, except as expressly provided below.
            <br /> We may share your personal information with third parties as
            described in this section: When You Request That We Share Your
            Information: We may offer Services, features, or promotions that
            involve sharing your information with a third party or with other
            Uplio users. If you request or agree to have your information shared
            with a third party as part of a feature or Service, we will share
            that information at your request. If we extend you payment terms, we
            may share aggregated payment performance information with
            third-party service providers that report on payment performance to
            Uplio and other businesses.
            <br /> De-Identified Information: We may provide de-identified or
            aggregate information to our partners, including usage information
            to help our partners understand how often and in what ways people
            use our Services, so that they, too, can provide you with an optimal
            online experience. Before we share this information, we take
            reasonable efforts aligned with industry best practices to ensure
            that this information cannot identify you.
            <br /> Advertising outside the Uplio Platform: We may use personal
            information to help us deliver targeted advertising and to measure
            the effectiveness of advertising. Affiliates: We may share your
            information with our affiliated companies. Service Providers: We
            employ service providers to perform tasks on our behalf, and we need
            to share your information with them in order to provide products or
            services to you; for example, we may use a payment processing
            company to receive and process any credit card transactions for us.
            <br />
            User Profiles and Submissions: Certain user profile information,
            including your name, location, and any video or image content and
            document that such user has uploaded to the Services, may be
            displayed to other users to facilitate user interaction within the
            Services or address your request for our services. Please remember
            that any content you upload to your public user profile, along with
            any personal information or content that you voluntarily disclose
            online in a manner other users can view (on discussion boards, in
            messages and chat areas, etc.) becomes publicly available, and can
            be collected and used by anyone. Your user name may also be
            displayed to other users if you send messages or comments or upload
            images or videos through the Services and other users can contact
            you through messages and comments. Business Transfers: We may choose
            to buy or sell assets, and may share and/or transfer customer
            information in connection with the evaluation of and entry into such
            transactions. Also, if we (or our assets) are acquired, or if we go
            out of business, enter bankruptcy, or go through some other change
            of control, personal information could be one of the assets
            transferred to or acquired by a third party.
            <br /> Protection of Company and Others: We reserve the right to
            access, read, preserve, and disclose any information that we
            reasonably believe is necessary to comply with law or court order;
            enforce or apply our Terms of Use and other agreements; or protect
            the rights, property, or safety of Company, our employees, our
            users, or others.
            <br /> International Data Transfers: We may transfer information
            about you anywhere in the world, including transferring information
            we collect about you to recipients outside of the United States
            where our global headquarters is located. These countries may not
            have the same data protection laws as the country in which you
            initially provided the information. When we transfer or disclose
            your personal information to other countries, we will protect that
            information as described in this Policy.
            <br /> We comply with applicable legal requirements providing
            adequate safeguards for the transfer of personal information to
            countries other than the country where you are located.
          </h3>
          <h2>Children’s Information</h2>
          <h3>
            As noted in the <Link to="/terms-conditions">Terms of Service</Link>{' '}
            , we do not knowingly collect nor solicit personal information from
            anyone under the age of 18. If you are under 18, please do not
            attempt to register for the Services or send any personal
            information about yourself to us. If we learn that we have collected
            personal information from a child under age 18, we will delete that
            information as quickly as possible. If you believe that a child
            under 18 may have provided us with personal information, please
            contact us as described below.
          </h3>
          <h2>Is Personal Information About Me Secure?</h2>
          <h3>
            You must prevent unauthorized access to your account and personal
            information by selecting and protecting your password appropriately
            and limiting access to your computer or device and browser by
            signing off after you have finished accessing your account.
            <br />
            We endeavor to protect the privacy of your account and other
            personal information we hold in our records, but unfortunately, we
            cannot guarantee complete security. Unauthorized entry or use,
            hardware or software failure, and other factors, may compromise the
            security of user information at any time.
          </h3>
          <h2>What Are My Privacy Rights and Choices?</h2>
          <h3>
            You can always opt not to disclose information to us, but keep in
            mind some information may be needed to register with us, use our
            Services or to take advantage of some of our features.
            <br />
            Opting Out of Communications: If you no longer wish to receive
            electronic communications from us, click the unsubscribe link at the
            bottom of the email or follow opt-out instructions provided in other
            communication channels. Note you may still receive transactional
            emails from us. We process requests to be placed on do-not- mail and
            do-not-call lists as required by applicable law.
            <br />
            Opting Out of Cookies and Advertising: You may stop or restrict the
            placement of cookies on your device or remove them as your browser
            or device permits. <br />
            Visit
            <a href="www.networkadvertising.org/managing/opt_out.asp">
              {' '}
              networkadvertising
            </a>
            , <a href="www.youronlinechoices.eu ">youronlinechoices</a>,
            <a href="www.youradchoices.ca/choices">youradchoices.ca</a> , to
            learn more about the choices available to opt out of cookies and
            advertising.
            <br />
            Updating Your Account Information: Through your account settings,
            you may access, and, in some cases, edit or delete the following
            information you have provided to us:
          </h3>
          <ul>
            <li>name</li>
            <li>email address</li>
            <li>location</li>
            <li>payment information</li>
          </ul>
          <h3>
            The information you can view, update, and delete may change as the
            Services change. If you have any questions about viewing or updating
            information we have on file about you, please contact us as
            described below. Subject to the requirements of applicable law, you
            may also have privacy rights including
          </h3>
          <ul>
            <li>
              Access Data and Portability: You may have the right to receive a
              copy of the data and related information we have about you and
              request us to transfer all or some of this data to a third party.
            </li>
            <li>
              Request Correction of Data: You may request that we update or
              rectify information we have about you.
            </li>
            <li>
              Request Deletion of Data: You may request that we delete
              information we have about you.
            </li>
            <li>
              Request Restriction of or Object to Processing: You may have the
              right to opt in or opt out of certain uses of your data or to
              object to all processing of your data.
            </li>
          </ul>
          <h3>
            If you would like to exercise any of these rights, please contact us
            as described below. We will process your request in the time and
            manner required by law. To protect your privacy, we may take steps
            to verify your identity before fulfilling your request, such as by
            asking you to log in to your Uplio account or confirm your email
            address. You have the right not to receive discriminatory treatment
            for the exercise of your privacy rights. The rights above may be
            limited in some circumstances, for example if fulfilling your
            request would reveal personal information about another person,
            where they would infringe the rights of a third party (including our
            rights) or if you ask us to delete information which we are required
            by law to keep or have compelling legitimate interests in keeping.
            We will inform you of relevant exemptions we rely upon when
            responding to your request.
            <br />
            If you wish to designate an authorized agent to make a privacy
            request for you, please email us from the email address associated
            with your Uplio account and inform us of this designation, along
            with the designated agent’s name and email address. If you have
            questions about your data rights, contact us as described below. In
            the event you have unresolved concerns, you also have the right to
            contact your local data protection authority.
            <br />
            Data Retention <br />
            <br />
            We store personal information for as long as you use our Services or
            as may be necessary to fulfill the purposes for which the
            information was collected, provide our Services, resolve disputes or
            establish legal defenses, enforce our Terms or other agreements,
            engage in audits, protect our Services, prevent fraud, comply with
            the law, or for legitimate business purposes. <br />
            Accessibility
            <br />
            <br />
            This Privacy Policy webpage uses industry-standard technologies and
            was developed inline with the World Wide Web Consortium’s Web
            Content Accessibility Guidelines (v. 2.1). If you wish to print this
            Policy, you may do so from your web browser or by saving the page as
            a PDF.
            <br />
            Will Uplio Change this Privacy Policy?
            <br />
            <br />
            We’re constantly trying to improve our Services, so we may need to
            change this Privacy Policy from time to time as well, but we will
            alert you to material changes by placing a notice on the
            https://www.uplio.com website, by sending you an email, and/or by
            some other means. By using the Services after any changes to the
            Privacy Policy have been posted, you agree to the new Privacy
            Policy.
          </h3>
          <h2>What if I have questions about this policy?</h2>
          <h3>
            If you have any questions or concerns regarding our privacy
            policies, please contact us at hello@uplio.com or by regular mail
            via:
            <br />
            For the US, Canada and Rest of the World Uplio, Inc.
            <br />
            325 Canal Way <br /> Costa Mesa, CA 92627
            <br /> USA
          </h3>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default PrivacyPolicy
