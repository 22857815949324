import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import classes from './SelectCategory.module.scss'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import ProgressBar from '../ProgressBar/ProgressBar'
import InputWithDropDownWithoutSearch from '../../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch'
import InputElement from '../../../../constant/public/Input/InputElement'

const SelectQuantity = (props) => {
  const category = useSelector((state) => state.singleQuote.quote)
  const [dropdown, setDropdown] = useState('')

  useEffect(() => {
    if (category !== null) {
      props.setQuoteView('projectDescription')
      props.setCategory(category.productCategory)
    }
  }, [])

  const [disable, setDisable] = useState(true)

  const Formik = useFormik({
    initialValues: {
      otherCategoryData: '',
    },
    validationSchema: yup.object({
      otherCategory: yup.string().required('Category is required'),
    }),
  })

  const data = [
    { id: 1, label: 'Apparel' },
    { id: 2, label: 'Cosmetics' },
    { id: 3, label: 'Jewelry' },
    { id: 4, label: 'Home Décor' },
    { id: 5, label: 'Textile' },
    { id: 6, label: 'Others' },
  ]

  useEffect(() => {
    if (dropdown !== '') {
      if (dropdown === 'Others') {
        if (Formik.values.otherCategoryData !== '') {
          setDisable(false)
          props.setCategory(Formik.values.otherCategoryData)
        }
      } else {
        setDisable(false)
        props.setCategory(dropdown)
      }
    }
  }, [dropdown, Formik])

  return (
    <>
      <div className={classes.sideTitle}>
        <h2>Get your Quote</h2>
        <h4>Get your Quote</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.heading}>
            <ProgressBar width={'20%'} />

            <div className={classes.selectField}>
              <h2>Select a category that fits your request</h2>
              <div style={{ marginBottom: '32px' }}>
                <InputWithDropDownWithoutSearch
                  dropdown={data}
                  width={'100%'}
                  value={dropdown !== '' ? dropdown : "- Select a category -"}
                  setDropdown={(val) => console.log(val)}
                  onChange={setDropdown}
                />
                {/* <InputWithDropDown
                  dropdown={data}
                  placeholder="- Select a category -"
                  width={'100%'}
                  value={dropdown !== '' ? dropdown : "- Select a category -"}
                  setDropdown={setDropdown}
                /> */}
              </div>
              {dropdown === 'Others' ? (
                <InputElement
                  name="otherCategoryData"
                  value={Formik.values.otherCategoryData}
                  type="text"
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  placeholder="Enter the name of your category"
                  width={'100%'}
                />
              ) : null}
              <div className={classes.actionButtonSection}>
                <button
                  className={classes.actionButton}
                  onClick={() => props.setQuoteView('getStarted')}
                >
                  PREVIOUS
                </button>
                <ButtonWithRightArrow
                  disabled={disable}
                  content="NEXT"
                  function={() => props.setQuoteView('projectDescription')}
                />
              </div>
            </div>
            {/* <Row
              gutter={
                ({ xs: 0, sm: 0, md: 0, lg: 0 },
                { xs: 24, sm: 24, md: 24, lg: 24 })
              }
            >
              <Col lg={12} md={12} sm={0} xs={0}></Col>
              <Col lg={12} md={12} sm={0} xs={0}></Col>
              <Col lg={0} md={0} sm={24} xs={24}>
                <div className={classes.actionButtonSection}>
                  <Space direction="vertical" size={48}>
                    <ButtonWithRightArrow
                      disabled={disable}
                      content="NEXT"
                      function={() => props.setQuoteView('projectDescription')}
                    />
                    <button
                      className={classes.actionButton}
                      onClick={() => props.setQuoteView('getStarted')}
                    >
                      PREVIOUS
                    </button>
                  </Space>
                </div>
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default SelectQuantity
