import React from 'react'
import { Link } from 'react-router-dom'
export default function Helpcenter() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Help Center</h1>
      <Link to="/">Back to Homepage</Link>
    </div>
  )
}
