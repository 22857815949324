import React, { useState, useEffect } from 'react'
import classes from './ColorDropdown.module.scss'
import clsx from 'clsx'
import { RGBAtoHEX } from '../../../constant/RgbaToHex';

const ColorDropdown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [items, setItem] = useState([])
  const [value, setValue] = useState("");
  const toggleDropdown = () => setOpen(!isOpen)

  useEffect(() => {
    setItem(props?.dropdown);
    setValue(props.value);
  }, [props.dropdown]);

  const handleItemClick = (label, id) => {
    setValue(id); //Prem
    toggleDropdown();
    // props.setDropdown(id);
    props.onChange(id); //Prem
  }
  return (
    <div>
      <div className={classes.dropdown} onClick={props.onClick}>
        <div
          className={classes.dropdownHeader}
          style={{ width: props.width }}
          onClick={toggleDropdown}
        >
          {
            value === "" || value === null || value === undefined ?
              <h3 className={classes.placeholder}>
                {props?.placeholder}
              </h3>
              :
              <h3>
                {value}
              </h3>
          }
          <h3 className={classes.stateIcon}>
            {isOpen ? (
              <>
                <i className={clsx(classes.arrow, classes.up)}></i>
              </>
            ) : (
              <i className={clsx(classes.arrow, classes.down)}></i>
            )}
          </h3>
        </div>
        {/* } */}
        {
          !props.noDropdown &&
          <div
            style={props.categories && { marginLeft: "35.1%" }}
            className={clsx({
              [classes.dropdownBody]: true,
              [classes.open]: isOpen,
            })}
          // id="inputElement"
          >
            {items?.map((item, index) => (
              <button
                key={index}
                className={classes.dropdownItem}
                onClick={() => handleItemClick(RGBAtoHEX(item.label), item.id)}
                id={item.id}
              >
                <span
                  className={`dropdown-item-dot ${item.id === value && 'selected'}`}
                >
                  <div className={classes.colorDropdownItem}>
                    <span>{item.id}</span>
                    <div>
                      <div>{item?.label && item.label.includes("rgba") && RGBAtoHEX(item?.label)}</div>
                      <div style={{ backgroundColor: item?.label }} className={classes.colorBox}></div>
                    </div>
                  </div>
                </span>
              </button>
            ))}
          </div>
        }
      </div>
    </div >
  )
}

export default ColorDropdown
