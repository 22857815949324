import React, { useEffect } from 'react';

export const RecentlyViewedProducts = ({ product }) => {
    // useEffect(() => {
    //     // get existing products array or create a new empty array
    //     let products = localStorage.getItem('products');
    //     if (products && JSON.parse(products)?.length > 0) {
    //         products = JSON.parse(products);
    //     } else {
    //         products = [];
    //     }

    //     // check if product already exists in array
    //     const existingProduct = products.find((p) => p._id === product._id);
    //     if (!existingProduct && product !== {}) {
    //         products.push(product);
    //         products = products.filter(p => Object.keys(p).length !== 0); // remove empty object
    //         localStorage.setItem('products', JSON.stringify(products));
    //     }

    //     // Remove session when user closes the browser
    //     window.addEventListener('beforeunload', () => {
    //         localStorage.removeItem('products');
    //     });
    // }, [product]);
    useEffect(() => {
        // get existing products array or create a new empty array
        let products = sessionStorage.getItem('products');
        if (products) {
            products = JSON.parse(products);
        } else {
            products = [];
        }

        // check if product already exists in array
        const existingProduct = products.find((p) => p._id === product._id);
        if (!existingProduct) {
            products.push(product);
            products = products.filter(p => Object.keys(p).length !== 0); // remove empty object
            sessionStorage.setItem('products', JSON.stringify(products));
        }
    }, [product]);
    return (
        <div></div>
    )
}