import { Col, Modal, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import "./AuthPopup.css";
import closeIcon from "../../../../assets/svg/Cross.svg"
import { userDataStatus, userLoggedIn } from '../../../../redux/actions/user.action';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup'
import classes from './AuthPopup.module.scss'
import InputElement from '../../../../constant/public/Input/InputElement';


const AuthPopup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = useSelector((state) => state.user.user);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    })
    const userLoggedInState = useSelector((state) => state.user.userLoggedIn)

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension)

        return () => {
            window.removeEventListener('resize', setDimension)
        }
    }, [screenSize])

    const quote = useSelector((state) => state.singleQuote)

    const history = useHistory()
    const Formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .email('Invalid email.')
                .required('Email is Required'),
            password: yup
                .string()
                // .matches(
                //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
                //   'Invalid Format'
                // )
                .required('Password is required.'),
        }),
    })
    const { setFieldValue } = Formik;

    useEffect(() => {
        if (quote.quote !== null) {
            setFieldValue('email', quote.quote.email)
        }

        // axios.get("https://countriesnow.space/api/v0.1/countries/states").then(res => console.log(res))
    }, [])

    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        })
    }

    const handleError = (data) => {
        if (data.message === 'You are not registered with us') {
            history.push({ pathname: '/signup' })
        }
    }

    const handleSignIn = (email, password) => {
        setLoader(true)
        //Formik.handleSubmit();
        // dispatch(postUserLogin(user))
        if (Formik.values.email && Formik.values.password) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                    email: email,
                    password: password,
                })
                .then((res) => {
                    setLoader(false)
                    if (!res.data.error) {
                        dispatch(userDataStatus(res.data.user))
                        dispatch(userLoggedIn())
                        // props.userDataStatus(userData)

                        sessionStorage.setItem('token', res.data.tokens.access.token)
                        sessionStorage.setItem('refresh', res.data.tokens.refresh.token)

                        if (res.data.user.userType === 'supplier') {
                            openNotificationWithIcon('error', "Your cannot buy products as a supplier", '');
                            setIsModalOpen(false)
                        } else {
                            history.push({ pathname: '/shipping' })
                        }
                    } else {
                        openNotificationWithIcon('warning', 'Oops', res.data.message)
                        handleError(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    setLoader(false)
                    openNotificationWithIcon('error', err.response?.data?.message, '')
                })
        }
    }

    const showModal = () => {
        if (userLoggedInState) {
            user.role === "supplier" ?
                openNotificationWithIcon('error', "Your cannot buy products as a supplier", '')
                :
                history.push({ pathname: '/shipping' })
        }
        else {
            setIsModalOpen(true);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className={`${AuthPopup} ${classes.AuthPopup}`}>
            <button onClick={showModal}>CHECKOUT</button>
            <Modal centered className='AuthPopupModal' closeIcon={<img src={closeIcon} alt="Cross Icon" />} title={false} footer={false} visible={isModalOpen} onCancel={handleCancel}>
                <h2>Login or Register</h2>
                <form className={classes.AuthPopupForm}>
                    <div className={classes.container}>
                        <div className={classes.mainContent}>
                            <h3>Email</h3>
                            <InputElement
                                width="100%"
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={Formik.values.email}
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                helperText={
                                    Formik.errors.email && Formik.touched.email
                                        ? Formik?.errors.email
                                        : null
                                }
                            />
                            <div className={classes.passwordContainer}>
                                <h3>Password</h3>
                                <InputElement
                                    width="100%"
                                    name="password"
                                    type="password"
                                    value={Formik.values.password}
                                    onChange={Formik.handleChange}
                                    onBlur={Formik.handleBlur}
                                    placeholder="Password"
                                    helperText={
                                        Formik.errors.password && Formik.touched.password
                                            ? Formik?.errors.password
                                            : null
                                    }
                                />
                            </div>
                            <div>
                                <div className={classes.actionButton}>
                                    {
                                        Formik.errors.email ||
                                            Formik.errors.password ?
                                            <button
                                                disabled
                                                onClick={() =>
                                                    handleSignIn(Formik.values.email, Formik.values.password)
                                                }
                                            >
                                                SIGN IN
                                            </button>
                                            :
                                            <button
                                                type='button'
                                                onClick={() =>
                                                    handleSignIn(Formik.values.email, Formik.values.password)
                                                }
                                            >SIGN IN</button>
                                    }
                                    <button type='button' onClick={() => history.push("/signup")}>sign up</button>
                                    <p>By signing up, you agree to Italic’s <Link to="/">Terms of Use</Link> and <Link to="/">Privacy Policy</Link>. Italic may send you communications; you may change your preferences in your account settings.</p>
                                    <button type='button' onClick={() => history.push("/shipping")}>CONTINUE WITHOUT REGISTRATION</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div >
    )
}

export default AuthPopup
