import React, { useRef, useState } from 'react'
import axios from 'axios'

import classes from './UploadFiles.module.scss'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import LoadingModal from '../../../../../constant/public/LoadingModal/LoadingModal'

const UploadFiles = (props) => {
  const inputFile = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openAttachment = () => {
    inputFile.current.click()
  }

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')
  const handlePPI = (event) => {
    setIsModalOpen(!isModalOpen);
    // setUploadPPI(event.currentTarget.files[0])
    // console.log(inputFile)
    // return false
    const formData = new FormData();
    const baseURL = process.env.REACT_APP_API_URL
    formData.append('file', event.currentTarget.files[0])
    formData.append('type', props.type)
    axios({
      method: "PATCH",
      url: baseURL + '/users/' + user?.id + "/products/image",
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "bearer " + token
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          console.log("Image Upload Successfully")
          props.handleUpdate(response);
          setIsModalOpen(false)
        }
        else if (response.status === 400) {
          setIsModalOpen(false)
        }
      }).catch(err => {
        setIsModalOpen(false);
        console.log(err);
      })
  }

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept={"image/*"}
        name='image'
        onClick={(e) => e.target.value = null}
        onChange={
          handlePPI
        }
        ref={inputFile}
      />
      <div>
        <div className={`${classes.input} ${classes.document}`}>
          <button
            disabled={props.disabled}
            id="attachment"
            type="button"
            class="file"
            name="image"
            onClick={() => openAttachment()}
          >
            {!props.disabled ? (
              <PlusOutlined width={32} />
            ) : (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            )}
          </button>
          <LoadingModal text={"Please wait. The image is being loaded."} openModal={isModalOpen} />
        </div>
      </div>
    </>
  )
}
export default UploadFiles
