import React, { useState } from 'react'
import classes from './MessageSearch.module.scss'
import close from '../../../../../assets/svg/clear.svg'
import InputElement from '../Chatbox/Input/InputElement'
// import InputElement from '../../../../../constant/public/Input/InputElement'

const MessageSearch = (props) => {

  const [search, setSearch] = useState('')

  const handleClear = () => {
    setSearch('');
    props.handleSearch("");
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
    props.handleSearch(e.target.value);
  }

  return (
    <div
      className={
        props.containerClass ? props.containerClass : classes.inputContainer
      }
    >
      <div className={classes.searchPic}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3915 18.1253 11.2956 17.9805 9.1739C17.8357 7.0522 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54845 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42285 1.47479 9.54894C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.05171 17.8361 9.17341 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74997C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88984 5.93296 4.02467 7.16637 3.51378C8.39977 3.00289 9.75697 2.86921 11.0663 3.12966C12.3757 3.39012 13.5784 4.03299 14.5225 4.977C15.4665 5.921 16.1093 7.12374 16.3698 8.43311C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95988 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74997Z"
            fill="#303030"
          />
        </svg>
      </div>
      <InputElement
        type="text"
        style={{ width: `${props.width}` }}
        className={classes.searchInput}
        value={search}
        onChange={handleSearch}
        placeholder={props.placeholder}
        onKeyPress={(e) => e.code === "Enter" && handleSearch(e)}
      />
      <button
        onClick={() => handleClear()}
        className={search ? classes.iconVisible : classes.icon}
      >
        <img src={close} alt="Uplio" />
      </button>
    </div>
  )
}

export default MessageSearch
