import React, { useState, useEffect } from 'react'
import classes from './ProductDetailsTextarea.module.scss'
import clsx from 'clsx'
import InputElement from '../Input/InputElement'
import TextInput from '../TextInput/TextInput'

const ProductDetailsTextarea = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [value, setValue] = useState("");

  const toggleDropdown = () => {
    setOpen(!isOpen);
  }

  useEffect(() => {
    setOpen(props.openDropdown);
    setValue(props.value)
  }, []);

  const handleItemClick = () => {
    setValue(value);
    toggleDropdown()
    props.onChange(value);
  }


  return (
    <div>
      <div className={classes.dropdown}>
        {/* {!isOpen ? */}
        <div style={{ position: "relative" }}>
          <div
            className={classes.dropdownHeader}
            style={{ width: "100%" }}
            onClick={toggleDropdown}
          >
            <InputElement
              name="comment"
              type="text"
              className={clsx([`input`, classes.companyInput])}
              wrapperClass={classes.companyInput}
              placeholder="Write your wishes for the order"
              onClick={toggleDropdown}
              value={props.value}
            />
          </div>
        </div>
        {
          isOpen &&
          <div className={classes.dropdownOpenContainer}>
            <div>
              <div
                className={clsx({
                  [classes.dropdownBody]: true,
                  [classes.open]: isOpen,
                })}
              >
                <TextInput
                  name="comment"
                  type="text"
                  className={clsx([`input`, classes.companyInput])}
                  wrapperClass={classes.companyInput}
                  placeholder="Write your wishes for the order"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
                <div className={classes.btnContainer}>
                  <button onClick={handleItemClick}>LEAVE</button>
                </div>
              </div>
            </div>
          </div>
          // }
        }
      </div>
    </div>
  )
}

export default ProductDetailsTextarea
