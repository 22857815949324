import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import loader from "../../../assets/svg/eos-icons_loading.svg"
import closeIcon from "../../../assets/svg/Cross.svg"
import Loading from '../../Loading/Loading'
import loadingGif from "../../../assets/gif/loading.gif"

const LoadingModal = ({ openModal, text }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setIsModalOpen(openModal);

        return () => {

        }
    }, [openModal])


    return (
        <Modal centered footer={false} closeIcon={<img src={closeIcon} alt="Cross Icon" />} className={"certificateUploadModal"} title={false} visible={isModalOpen} onCancel={handleCancel}>
            <div className='loadingSection' style={{ textAlign: "center" }}>
                <img style={{ width: "200px" }} src={loadingGif} alt="Loading" />
                <p style={{ textAlign: "center" }}>{text}</p>
            </div>
        </Modal>
    )
}

export default LoadingModal
