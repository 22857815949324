import React, { useEffect, useState } from 'react'
import classes from './EditModal.module.scss'
import CommonModal from '../../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../../constant/public/Input/InputElement'
import InputWithDropDown from '../../../../../constant/public/InputWithDropDown/InputWithDropDown'
import errorIcon from '../../../../../assets/svg/error_icon.svg'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Form, useFormik } from 'formik'
import * as yup from 'yup'
import { Col, Row } from 'antd'
import { OpenNotificationWithIcon } from '../../../../../constant/public/Notification/Notification'
import Loading from '../../../../../constant/Loading/Loading'

const EditAddressModal = ({
  modalOpened,
  setModalOpened,
  handleUpdate,
  addressObj
}) => {
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statee, setStatee] = useState('- Select a state -')
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const Formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address2: '',
      address1: '',
      state: '',
      country: '',
      zip: '',
      phone: '',
      city: ""
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required('First Name is Required'),
      lastName: yup
        .string()
        .required('Last Name is Required'),
      address1: yup
        .string()
        .required('Address is Required'),
      address2: yup
        .string(),
      state: yup
        .string()
        .required('State is Required'),
      country: yup
        .string()
        .required('Country is Required'),
      city: yup
        .string()
        .required('City is Required'),
      zip: yup
        .string()
        .required('Postal Code is Required'),
      phone: yup
        .string()
        .required('Phone Number is Required')
        .matches(
          // /^\+\d\s(?!0{2})\d{3}\(?!0{2})\d{3}\(?!0{3})\d{4}$/,
          /^\+\d\s(?!0{2})\d{3}(?!0{2})\d{3}(?!0{3})\d{4}$/,
          'Please enter valid phone number. Format: +X XXXXXXXXXX'
        )
        .max(15, 'Phone can only be upto 15 Characters'),

    }),
  })
  const { setFieldValue } = Formik;


  const filterStates = (value) => {
    let arr = [];
    data?.filter(d => d.country === value)[0]?.states.map((d, index) => arr.push({
      id: index,
      label: d,
      state: true
    }));
    setStateData(arr);
  }


  useEffect(() => {
    getAddressById(addressObj);
    axios
      .get(`${process.env.REACT_APP_API_URL}/countries/`)
      .then((res) => {
        setData(res.data.countries)
        let newArr = []
        let arr = [];
        res.data.countries.map((item, index) => {
          newArr.push({
            id: index + 1,
            label: item.country,
          })
        })
        setCountryData(newArr)
        res.data.countries?.filter(d => d.country === "USA")[0]?.states.map((d, index) => arr.push({
          id: index,
          label: d,
          state: true
        }));
        setStateData(arr);
      })
      .catch((err) => {
        console.log(err)
      })

    if (user !== null && user !== undefined) {
      setFieldValue("email", user.email);
    }

  }, [addressObj?._id])


  const getAddressById = (addressObj) => {
    console.log(addressObj)
    if (user && user !== null && user !== undefined) {
      setLoading(true);
      setFieldValue('lastName', addressObj?.lastName)
      setFieldValue('phone', addressObj?.phone)
      setFieldValue('firstName', addressObj?.firstName)
      setFieldValue('address1', addressObj?.address1)
      setFieldValue('address2', addressObj?.address2)
      setFieldValue('city', addressObj?.city)
      setFieldValue('state', addressObj?.state)
      setFieldValue('phone', addressObj?.phone)
      setFieldValue('country', addressObj?.country)
      setFieldValue('zip', addressObj?.zip);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }

  const handleSubmit = (id) => {
    Formik.handleSubmit();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${user?.id}/shipping-address/${id}`,
        {
          type: "home",
          firstName: Formik.values.firstName,
          lastName: Formik.values.lastName,
          address1: Formik.values.address1,
          address2: Formik.values.address2,
          city: Formik.values.city,
          state: Formik.values.state,
          country: Formik.values.country,
          zip: Formik.values.zip,
          phone: Formik.values.phone,
          default: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setModalOpened(false);
        OpenNotificationWithIcon("success", "Address Saved", "");
        handleUpdate();
        Formik.resetForm();
      })
      .catch((err) => {
        console.log(err)
      })
  }


  return (
    <CommonModal
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      title={'EDIT ADDRESS INFO'}
    >
      {
        loading ?
        <div>
        <Loading />
      </div>
          :
          <div className={classes.wrapper}>
            <div className={classes.form}>
              <div>
                <div className={classes.mainContent}>
                  <div className={classes.form}>
                    <div className={classes.inner}>
                      <Row className={classes.row} gutter={[19, 19]}>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={Formik.values.firstName}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.firstName && Formik.touched.firstName
                                ? Formik?.errors.firstName
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={Formik.values.lastName}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.lastName && Formik.touched.lastName
                                ? Formik?.errors.lastName
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="address1"
                            placeholder="Address Line 1"
                            value={Formik.values.address1}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.address1 && Formik.touched.address1
                                ? Formik?.errors.address1
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="address2"
                            placeholder="Address Line 2"
                            value={Formik.values.address2}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.address2 && Formik.touched.address2
                                ? Formik?.errors.address2
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <div className={classes.selectWrapper}>
                            <InputWithDropDown
                              dropdown={countryData}
                              width={'100%'}
                              placeholder="- Select a country -"
                              value={Formik?.values.country ? Formik?.values.country : "- Select a country -"}
                              onChange={(value) => setFieldValue("country", value)}
                              setDropdown={(value) => { filterStates(value) }}
                            />
                            {
                              Formik.values.country === "" || Formik.values.country === "- Select a country -" &&
                              <p className={classes.errorMessage}>
                                <img src={errorIcon} alt="Uplio" />{' '}
                                {Formik.errors.country}
                              </p>
                            }
                          </div>
                        </Col>
                        <Col xs={24}>
                          <div className={`${classes.selectWrapper} ${classes.stateSelect}`}>
                            <InputWithDropDown
                              dropdown={stateData}
                              width={'100%'}
                              placeholder="- Select a state -"
                              value={Formik.values.state ? Formik.values.state : "- Select a state -"}
                              setDropdown={(value) => { setStatee(value) }}
                              onChange={(value) => setFieldValue("state", value)}
                            />
                            {
                              Formik.values.state === "" || Formik.values.state === "- Select a state -" &&
                              <p className={classes.errorMessage}>
                                <img src={errorIcon} alt="Uplio" />{' '}
                                {Formik.errors.state}
                              </p>
                            }
                          </div>
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={Formik.values.city}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.city && Formik.touched.city
                                ? Formik?.errors.city
                                : null
                            }
                          />
                        </Col>
                        <Col xs={24}>
                          <InputElement
                            width="100%"
                            type="text"
                            name="zip"
                            placeholder="ZIP Code"
                            value={Formik.values.zip}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.zip && Formik.touched.zip
                                ? Formik?.errors.zip
                                : null
                            }
                          />
                        </Col>
                        {/* <Col xs={24}>
                          <InputElement
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            value={Formik.values.phone}
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            helperText={
                              Formik.errors.phone && Formik.touched.phone
                                ? Formik?.errors.phone
                                : null
                            }
                            width={'100%'}
                          />
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.submitWrapper}>
                {
                  Formik.values.firstName === "" || Formik.values.lastName === "" ||
                    Formik.values.country === "" ||
                    Formik.values.state === "- Select a state -" ||
                    Formik.values.address1 === "" ||
                    Formik.values.zip === "" ?
                    <button disabled type='buttpn' className={classes.submit} onClick={(evt) => handleSubmit(addressObj._id)}>
                      Save
                    </button>
                    :
                    <button type='buttpn' className={classes.submit} onClick={(evt) => handleSubmit(addressObj._id)}>
                      Save
                    </button>
                }
              </div>
            </div>
          </div>
      }
    </CommonModal>
  )
}

export default EditAddressModal
