import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import axios from 'axios'
import { MobileMenu } from '../MobileMenu/MobileMenu'
import './header.scss'
import logo from '../../../assets/svg/logo_rectangle_black.svg'
import bag from '../../../assets/svg/bag.svg'
import Avatar from '../../../assets/svg/Avatar Supplier.svg'
import Search from '../Search/Search'
import NotificationModal from '../NotificationModal/NotificationModal'
import {
  userLoggedOut,
  userDataStatus,
} from '../../../redux/actions/user.action'
import { NavigationsProvider } from './NavigationContext'
import CartPopup from '../CartPopup/CartPopup'

const Navigation = (props) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [profileDropdownOpened, setProfileDropdownOpened] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);


  const history = useHistory()

  const dispatch = useDispatch()

  // const userLoggedInState = true;

  const userLoggedInState = useSelector((state) => state.user.userLoggedIn)
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (
        !e.target.closest('#notification-modal') &&
        !e.target.closest('#profile')
      ) {
        setProfileDropdownOpened(false)
      }
    })
  }, [])

  const handleSignOut = () => {
    dispatch(userLoggedOut())
    dispatch(userDataStatus(''))
    sessionStorage.clear()
    history.push('/')
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/suppliers`)
      .then((res) => {
        setSearchResults((prev) => {
          const prevFiltered = prev.filter((item) => item.title !== 'suppliers')

          prevFiltered.push({ title: 'suppliers', data: res.data.data })

          return prevFiltered
        })
      })
      .catch((err) => {
        console.log(err)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/categories`)
      .then((res) => {
        // ;

        setSearchResults((prev) => {
          const prevFiltered = prev.filter(
            (item) => item.title !== 'categories'
          )

          prevFiltered.push({ title: 'categories', data: res.data.categories })

          return prevFiltered
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleProfileClick = () => {
    if (window.innerWidth > 991) {
      setProfileDropdownOpened(true)
    }
  }

  const handleSearchClick = () => {
  }

  return (
    <NavigationsProvider value={searchValue}>
      <header id={'header'} className="header">
        <div className="header-wrapper">
          <div className="container">
            <div className="header-content">
              <div className="header-content__logo">
                <Link
                  className="link"
                  to="/"
                  onClick={() => setMenuOpened(false)}
                >
                  <img height="48" src={logo} alt="Uplio" />
                </Link>
              </div>
              <div className="header-content__search">
                {
                  !userLoggedInState ?
                    <Search dropdownData={searchResults} handleClick={handleSearchClick} handleChange={(value) => setSearchValue("")} placeholder="Search" />
                    :
                    user.userType !== 'supplier' &&
                    <Search dropdownData={searchResults} handleClick={handleSearchClick} handleChange={(value) => setSearchValue("")} placeholder="Search" />
                }
              </div>
              <ul className="header-links">
                <li className="header-links__link">
                  <HashLink className="link" to="/#categories">
                    Categories
                  </HashLink>
                </li>
                <li className="header-links__link">
                  <HashLink className="link" to="/#how-it-works">
                    How it works
                  </HashLink>
                </li>
                <li className="header-links__link">
                  <HashLink className="link" to="/#faq">
                    FAQ
                  </HashLink>
                </li>
              </ul>

              {!userLoggedInState && JSON.parse(localStorage.getItem("products"))?.length > 0 && (
                <div className="header-account">
                  <button className="header-account__notifications" onClick={() => JSON.parse(localStorage.getItem("products"))?.length > 0 && setShow(!show)}>
                    <img
                      width="24"
                      height="24"
                      src={bag}
                      alt=""
                    />
                    <div>
                      <span>{JSON.parse(localStorage.getItem("products"))?.length}</span>
                    </div>
                  </button>
                  {
                    show &&
                    <CartPopup handlePopup={() => setShow(!show)} />
                  }
                </div>
              )}

              {!userLoggedInState && (
                <div className="header__sign">
                  <Link to="/signup" className="button">
                    Sign up
                  </Link>
                  <Link to="/login" className="link">
                    Sign in
                  </Link>
                </div>
              )}
              {/* <button className="header-account__notifications" onClick={() => JSON.parse(localStorage.getItem("products"))?.length > 0 && setShow(!show)}>
                  <img
                    width="24"
                    height="24"
                    src={bag}
                    alt=""
                  />
                </button>
                {
                  show &&
                  <CartPopup handlePopup={() => setShow(!show)} />
                } */}


              {userLoggedInState && (
                <div className="header-account">
                  {/* TODO: Add this bell icon and modal for it */}
                  <button className="header-account__notifications" onClick={() => JSON.parse(localStorage.getItem("products"))?.length > 0 && setShow(!show)}>
                    <img
                      width="24"
                      height="24"
                      src={bag}
                      alt=""
                    />
                    <div>
                      <span>{JSON.parse(localStorage.getItem("products"))?.length}</span>
                    </div>
                  </button>
                  {
                    show &&
                    <CartPopup handlePopup={() => setShow(!show)} />
                  }
                  {user.userType === 'supplier' ? (
                    <button
                      className="header-account__profile"
                      // to="/dashboard/supplier/profile"
                      onClick={handleProfileClick}
                      id={'profile'}
                    >
                      {
                        user.profileImage ?
                          <img
                            width="32"
                            height="32"
                            src={user.profileImage}
                            alt=""
                          />
                          :
                          <img
                            width="32"
                            height="32"
                            src={Avatar}
                            alt=""
                          />
                      }
                    </button>
                  ) : (
                    <button
                      className="header-account__profile"
                      // to="/dashboard/buyer/profile"
                      onClick={handleProfileClick}
                      id={'profile'}
                    >
                      {
                        user.profileImage ?
                          <img
                            width="32"
                            height="32"
                            src={user.profileImage}
                            alt=""
                          />
                          :
                          <img
                            width="32"
                            height="32"
                            src={Avatar}
                            alt=""
                          />
                      }
                    </button>
                  )}
                  <NotificationModal
                    user={user}
                    modalOpened={profileDropdownOpened}
                    setModalOpened={setProfileDropdownOpened}
                  />
                </div>
              )}
              {/* </div> */}
              <div className="header-burger">
                {
                  userLoggedInState &&
                  <button className="header-account__notifications" onClick={() => JSON.parse(localStorage.getItem("products"))?.length > 0 && setShow(!show)}>
                    <img
                      width="24"
                      height="24"
                      src={bag}
                      alt=""
                    />
                    <div>
                      <span>{JSON.parse(localStorage.getItem("products"))?.length}</span>
                    </div>
                  </button>
                }
                {
                  !userLoggedInState && JSON.parse(localStorage.getItem("products"))?.length > 0 &&
                  <button className="header-account__notifications" onClick={() => JSON.parse(localStorage.getItem("products"))?.length > 0 && setShow(!show)}>
                    <img
                      width="24"
                      height="24"
                      src={bag}
                      alt=""
                    />
                    <div>
                      <span>{JSON.parse(localStorage.getItem("products"))?.length}</span>
                    </div>
                  </button>
                }
                {
                  show &&
                  <CartPopup handlePopup={() => setShow(!show)} />
                }
                <button
                  type="button"
                  className={`burger header-burger ${menuOpened ? 'burger_close' : null
                    }`}
                  onClick={() => setMenuOpened((prev) => !prev)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <MobileMenu
                isSigned={userLoggedInState}
                menuOpened={menuOpened}
                setMenuOpened={setMenuOpened}
                searchResults={searchResults}
                handleClick={() => setMenuOpened(false)}
              />
            </div>
          </div>
        </div>
      </header>
    </NavigationsProvider >
  )
}

export default Navigation
