import React, { useEffect, useState } from 'react'
import Navigation from '../../../constant/public/Navigation/Navigation';
import Footer from '../../../constant/public/Footer/Footer'
import classes from "./Summary.module.scss";
import CartItem from '../../../constant/public/CartItem/CartItem';
import OrderSteps from '../../../constant/public/OrderSteps/OrderSteps';
import arrow from "../../../assets/svg/Arrow_indicator_left_black.svg"
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const Summary = () => {
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [groupedProducts, setGroupedProducts] = useState([]);


    useEffect(() => {
        JSON.parse(localStorage.getItem("products")) === null && history.push("/browse");
        setProducts(JSON.parse(localStorage.getItem("products")))
        JSON.parse(localStorage.getItem("products"))?.length > 0 && productGrouping(JSON.parse(localStorage.getItem("products")));
        return () => {

        }
    }, []);

    const productGrouping = (products) => {
        const groupByUser = products && products.length > 0 && products?.reduce((acc, product) => {
            const { name, _id, estimatedLeadTimeProduction } = product.user;
            if (!acc[name]) {
                acc[name] = { name, _id, estimatedLeadTimeProduction, products: [product] };
            } else {
                acc[name].products.push(product);
            }
            return acc;
        }, {});
        setGroupedProducts(Object.values(groupByUser))
    }



    const handleUpdateQty = (productId, newQuantity) => {
        let prevProducts = products?.map(product => {
            if (product._id === productId) {
                return { ...product, quantity: newQuantity };
            }
            return product;
        })
        setProducts(prevProducts);
        productGrouping(prevProducts);
    };

    const handleUpdateComment = (productId, newComment) => {
        console.log(productId, newComment)
        let prevProducts = products?.map(product => {
            if (product._id === productId) {
                return { ...product, comment: newComment };
            }
            return product;
        })
        setProducts(prevProducts);
        productGrouping(prevProducts);

        localStorage.setItem("products", JSON.stringify(prevProducts));

        console.log(prevProducts)
    };

    const handleRemoveProduct = (productId) => {
        let prevProducts = products?.filter(product => product._id !== productId)
        setProducts(prevProducts);
        productGrouping(prevProducts);
        localStorage.setItem("products", JSON.stringify(prevProducts))
    };


    return (
        <>
            <Navigation />
            <div className={`${classes.Summary} container`}>
                <div>
                    <div className={classes.topBar}>
                        <div>
                            <h4>Your Cart</h4>
                            <h5>{products?.length} Items</h5>
                        </div>
                    </div>
                    <div className={classes.steps}>
                        <OrderSteps active={3} />
                    </div>
                    <div className={classes.payment}>
                        <h5>Payment </h5>
                        <h4>{(products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0))?.toFixed(2)}$</h4>
                    </div>
                    <div className={classes.main}>
                        <div className={classes.inner}>
                            <div className={classes.left}>
                                {
                                    groupedProducts && groupedProducts?.length > 0 && groupedProducts?.map(user => {
                                        return (
                                            <>
                                                <h4 className={classes.supplierTitle}>{user.name}</h4>
                                                <div className={classes.itemsContainer}>
                                                    {
                                                        user.products && user.products?.length > 0 && user.products?.map(product => {
                                                            return (
                                                                <CartItem summary={true} product={product} handleUpdateQty={handleUpdateQty} handleUpdateComment={handleUpdateComment} handleRemoveProduct={handleRemoveProduct} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className={classes.priceContainer}>
                                                    <p>Total</p>
                                                    <h5>Cost: ${(user?.products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0)).toFixed(2)}</h5>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.btnContainer}>
                            <button onClick={() => history.push("/cart")}>
                                <img src={arrow} alt="Arrow" />
                                <span>EDIT ORDER</span>
                            </button>

                            <button
                                className={clsx(['button', classes.saveButton])}
                                onClick={() => history.push("/payment")}
                            >
                                PAYMENT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Summary
