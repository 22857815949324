import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OrderSteps from '../../../constant/public/OrderSteps/OrderSteps'
import classes from "./Shipping.module.scss"
import * as yup from 'yup'
import InputElement from '../../../constant/public/Input/InputElement'
import Navigation from '../../../constant/public/Navigation/Navigation'
import { Col, Radio, Row } from 'antd'
import InputWithDropDown from '../../../constant/public/InputWithDropDown/InputWithDropDown'
import axios from 'axios'
import errorIcon from '../../../assets/svg/error_icon.svg'
import arrow from "../../../assets/svg/Arrow_indicator_left_black.svg"
import clsx from 'clsx'
import Footer from '../../../constant/public/Footer/Footer'
import { useSelector } from 'react-redux'


const Shipping = () => {
  const history = useHistory();
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [country, setCountry] = useState('Please select a country')
  const [statee, setStatee] = useState('Please select a region or state')
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user.user);


  const Formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      address2: '',
      address1: '',
      city: '',
      country: '',
      zip: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Invalid email.')
        .required('Email is Required'),
      firstName: yup
        .string()
        .required('First Name is Required'),
      lastName: yup
        .string()
        .required('Last Name is Required'),
      address1: yup
        .string()
        .required('Address is Required'),
      address2: yup
        .string(),
      city: yup
        .string()
        .required('City is Required'),
      country: yup
        .string()
        .required('Country is Required'),
      zip: yup
        .string()
        .required('Postal Code is Required')

    }),
  })
  const { setFieldValue } = Formik;


  const filterStates = (value) => {
    let arr = [];
    data?.filter(d => d.country === value)[0]?.states.map((d, index) => arr.push({
      id: index,
      label: d,
      state: true
    }));
    setStateData(arr);
  }


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/countries/`)
      .then((res) => {
        setData(res.data.countries)
        let newArr = []
        let arr = [];
        res.data.countries.map((item, index) => {
          newArr.push({
            id: index + 1,
            label: item.country,
          })
        })
        setCountryData(newArr)
        res.data.countries?.filter(d => d.country === "USA")[0]?.states.map((d, index) => arr.push({
          id: index,
          label: d,
          state: true
        }));
        setStateData(arr);
      })
      .catch((err) => {
        console.log(err)
      })

    if (user !== null && user !== undefined) {
      setFieldValue("email", user.email);
      setFieldValue("firstName", user.name);
    }

  }, [])


  const handleSubmit = async () => {
    Formik.handleSubmit();
    localStorage.setItem("shippingAddress", JSON.stringify(Formik.values))
    history.push("/summary");
    // await handleProfileUpdated();
  }


  console.log(Formik.values.country)

  return (
    <>
      <Navigation />
      <div className={`${classes.Shipping} container`}>
        <div>
          <h2>Shipping</h2>
          <OrderSteps active={2} />
          <div className={classes.mainContent}>
            <h3>Your Email*</h3>
            <InputElement
              width="100%"
              type="text"
              name="email"
              placeholder="uplioclient@gmail.com"
              value={Formik.values.email}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              helperText={
                Formik.errors.email && Formik.touched.email
                  ? Formik?.errors.email
                  : null
              }
            />
            <div className={classes.form}>
              <h5>Shipping Address</h5>
              <div className={classes.inner}>
                <Row className={classes.row} gutter={[25, 16]}>
                  <Col xs={24} md={12}>
                    <h3>First Name*</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="firstName"
                      placeholder="John"
                      value={Formik.values.firstName}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.firstName && Formik.touched.firstName
                          ? Formik?.errors.firstName
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <h3>Last Name*</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="lastName"
                      placeholder="Black"
                      value={Formik.values.lastName}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.lastName && Formik.touched.lastName
                          ? Formik?.errors.lastName
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24}>
                    <h3>Address Line 1*</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="address1"
                      placeholder="809 N Alpine Dr, Beverly Hills"
                      value={Formik.values.address1}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.address1 && Formik.touched.address1
                          ? Formik?.errors.address1
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24}>
                    <h3>Address Line 2</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="address2"
                      placeholder="809 N Alpine Dr, Beverly Hills"
                      value={Formik.values.address2}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.address2 && Formik.touched.address2
                          ? Formik?.errors.address2
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <h3>City*</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="city"
                      placeholder="Beverly Hills"
                      value={Formik.values.city}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.city && Formik.touched.city
                          ? Formik?.errors.city
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <h3>State/Province*</h3>
                    <div className={`${classes.selectWrapper} ${classes.stateSelect}`}>
                      <InputWithDropDown
                        dropdown={stateData}
                        width={'100%'}
                        // closeCountryDropDownFun={() => setCloseCountryDropDown(true)}
                        placeholder="Please select a region or state"
                        value={statee ? statee : "Please select a region or state"}
                        setDropdown={(value) => { setStatee(value) }}
                        onChange={(value) => setFieldValue("state", value)}
                      />
                      <p className={classes.errorMessage}>
                        {Formik.errors.state ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                        {Formik.errors.state}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <h3>Zip/Postal Code*</h3>
                    <InputElement
                      width="100%"
                      type="text"
                      name="zip"
                      placeholder="90210"
                      value={Formik.values.zip}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      helperText={
                        Formik.errors.zip && Formik.touched.zip
                          ? Formik?.errors.zip
                          : null
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <h3>Country*</h3>
                    <div className={classes.selectWrapper}>
                      <InputWithDropDown
                        dropdown={countryData}
                        width={'100%'}
                        placeholder="Please select a country"
                        value={Formik?.values.country ? Formik?.values.country : "Please select a country"}
                        onChange={(value) => setFieldValue("country", value)}
                        setDropdown={(value) => { filterStates(value); setFieldValue("country", value) }}
                      />
                      <p className={classes.errorMessage}>
                        {Formik?.errors.country ? <img src={errorIcon} alt="Uplio" /> : null}{' '}
                        {Formik?.errors.country}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className={classes.default}>
                  <div className="egl-radio-with-check">
                    <div className="eachRadio">
                      <Radio
                        className="radio-input"
                        type="radio"
                        checked={defaultAddress}
                        onClick={() => setDefaultAddress(!defaultAddress)}
                        id={""}
                        name={""}
                        value={defaultAddress}
                      >
                        Make this my default address
                      </Radio>
                    </div>
                  </div>
                </div>
                <div className={classes.btnContainer}>
                  <button onClick={() => history.push("/cart")}>
                    <img src={arrow} alt="Arrow" />
                    <span>PREVIOUS</span>
                  </button>
                  {
                    Formik.values.firstName === "" || Formik.values.lastName === "" || Formik.values.email === "" || Formik.values.city === "" ||
                      Formik.values.country === "" ||
                      statee === "Please select a region or state" ||
                      Formik.values.address1 === "" ||
                      Formik.values.zip === "" ?
                      <button
                        disabled
                        className={classes.disableButton}
                      >
                        ORDER SUMMARY
                      </button>
                      :
                      <button
                        className={clsx(['button', classes.saveButton])}
                        onClick={() => handleSubmit()}
                      >
                        ORDER SUMMARY
                      </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Shipping
