import React from 'react'
import classes from './ContactList.module.scss'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import Avatar from '../../../../../assets/svg/Avatar Buyer.svg'

const ContactList = ({
  chats,
  setSelectedChat,
  className,
  isVisible,
  setMobileChatVisible,
}) => {
  const user = useSelector((state) => state.user.user)
  const handleChatSelect = (chat) => {
    setMobileChatVisible(true)
    setSelectedChat(chat)
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className={clsx([className, classes.wrapper])}>
      <ul className={classes.contacts}>
        {chats.map((chat, idx) => {
          // const messages = chat.messages
          // let lastMessage = messages[messages.length - 1].message

          // if (lastMessage.length > 32) {
          //   lastMessage = lastMessage.slice(0, 32) + '...'
          // }

          return (
            <li
              className={classes.contactsItemWrapper}
              key={idx}
            >
              {
                user.userType === "buyer" ?
                  <button
                    type="button"
                    className={classes.contactsItem}
                    onClick={() => handleChatSelect(chat)}
                  >
                    <div className={classes.contactsItemImg}>
                      <img
                        width="56"
                        height="56"
                        src={chat.supplier?.profileImage ? chat.supplier?.profileImage : Avatar}
                        alt="Uplio"
                      />
                    </div>
                    <div className={classes.contactsItemInfo}>
                      <h3 className={classes.contactsItemUser}>
                        {chat.supplier?.firstname} {chat.supplier?.lastname}<span>Supplier</span>
                      </h3>
                      <p className={classes.lastMessage}>{chat.lastMessage?.message}</p>
                    </div>
                  </button>
                  :
                  <button
                    type="button"
                    className={classes.contactsItem}
                    onClick={() => handleChatSelect(chat)}
                  >
                    <div className={classes.contactsItemImg}>
                      <img
                        width="56"
                        height="56"
                        src={chat.buyer?.profileImage ? chat.buyer?.profileImage : Avatar}
                        alt="Uplio"
                      />
                    </div>
                    <div className={classes.contactsItemInfo}>
                      <h3 className={classes.contactsItemUser}>
                        {chat.buyer?.firstname} {chat.buyer?.lastname}<span>Buyer</span>
                      </h3>
                      <p className={classes.lastMessage}>{chat.lastMessage?.message}</p>
                    </div>
                  </button>
              }
              {/* <span className={classes.messagesCounter}>2</span> */}
            </li>
          )
        })}
        <div className={classes.end}>
          <p>You’ve reached the end of&nbsp;your&nbsp;messages</p>
          <button className={classes.scrollMessages}>
            <svg
              width="16"
              height="37"
              viewBox="0 0 16 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.70711 0.292892C8.31658 -0.0976295 7.68342 -0.0976296 7.29289 0.292892L0.928934 6.65685C0.538409 7.04738 0.538409 7.68054 0.928933 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41422L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 37L9 1L7 1L7 37L9 37Z"
                fill="#303030"
              />
            </svg>
          </button>
        </div>
      </ul>
    </div>
  )
}

export default ContactList
