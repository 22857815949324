import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import classes from './Search.module.scss'
import cross from '../../../assets/svg/clear.svg'
import { useParams } from 'react-router'

const Search = (props) => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [clear, setClear] = useState(false)
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [filteredData, setFilteredData] = useState([])

  // Filters data by search value
  useEffect(() => {
    if (!props.dropdownData) {
      return
    }

    let isDropdownOpened = false

    const filteredDropdown = props.dropdownData.map((obj) => {
      const newData = obj.data.filter((string) => {
        const nameLower = string.name.toLowerCase()
        const searchLower = search.toLowerCase()

        return nameLower.includes(searchLower) ? true : false
      })

      if (newData[0] && search) {
        isDropdownOpened = true
      }

      const newObj = {
        title: obj.title,
        data: newData,
      }

      return newObj
    })

    setFilteredData(filteredDropdown)
    setDropdownOpened(isDropdownOpened)

    if (props.setDropdown) {
      props.setDropdown(isDropdownOpened)
    }
  }, [props.setDropdown, props.dropdownData, search])

  useEffect(() => {
    if (search !== '') {
      setClear(true)
    } else {
      setClear(false)
    }
  }, [search])

  const handleClear = () => {
    setSearch('');
    history.push(`/browse`);
  }

  const handleSearch = (e) => {
    if (e.key == 'Enter') {
      history.push(`/browse?${search}`);
      props.handleClick();
    }
  }

  return (
    <div
      className={
        props.containerClass ? props.containerClass : classes.inputContainer
      }
    >
      <input
        type="text"
        style={{ width: `${props.width}` }}
        className="input"
        value={search}
        onChange={(e) => { setSearch(e.target.value); props.handleChange(e.target.value); e.target.value === "" && history.push("/browse") }}
        placeholder={props.placeholder}
        onKeyPress={handleSearch}
      />
      <button
        onClick={() => handleClear()}
        className={!clear ? classes.icon : classes.iconVisible}
      >
        <img src={cross} alt="Uplio" />
      </button>
      {filteredData && (
        <ul
          className={
            props.dropdownClass ? props.dropdownClass : classes.dropdown
          }
          style={dropdownOpened ? { display: 'block' } : { display: 'none' }}
        >
          {filteredData.map((category) => {
            if (!category.data[0]) {
              return null
            }

            return (
              <React.Fragment key={category.title}>
                {category.title && (
                  <h4 className={classes.dropdownItemTitle}>
                    {category.title[0].toUpperCase() + category.title.slice(1)}
                  </h4>
                )}
                <ul className={classes.dropdownItem}>
                  {category.data.map((item) => {
                    const link =
                      category.title === 'categories'
                        ? `/browse?${item.slug}`
                        : category.title === 'suppliers'
                          ? `/profile/${item._id}`
                          : ''

                    return (
                      <li className={classes.dropdownItem} key={item.name}>
                        <a onClick={() => setDropdownOpened(!dropdownOpened)} className={classes.dropdownLink} href={link}>
                          {item.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </React.Fragment>
            )
          })}
        </ul>
      )}
    </div>
  )
}
export default Search
