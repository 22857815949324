import React from 'react'
import { Link } from 'react-router-dom'
const Newsroom = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Newsroom Section</h1>

      <Link to="/">Back to Home Page</Link>
    </div>
  )
}
export default Newsroom
