import React, { useEffect, useState } from "react";
import classes from "./ProductDetails.module.scss"
import Navigation from '../../../../constant/public/Navigation/Navigation'
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import axios from "axios";
import Footer from '../../../../constant/public/Footer/Footer'
import { useSelector } from "react-redux";
import { HeartOutlined, InfoCircleOutlined } from "@ant-design/icons";
import LeftArrow from "../../../../assets/images/left.svg"
import topLeftArrow from "../../../../assets/svg/MobilleBack.svg"
import downArrow from "../../../../assets/images/downArrow.svg"
import { Link, useLocation } from "react-router-dom";
import InputWithDropDownWithoutSearch from "../../../../constant/public/InputWithDropDownWithoutSearch/InputWithDropDownWithoutSearch";
import ProductSlider from "../../../../constant/public/ProductDetailsSlider/ProductSlider";
import ColorDropdown from "../../../../constant/public/ColorDropdown/ColorDropdown";
import CertificateShow from "../../../../constant/public/CertificateShow/CertificateShow";
import { decode } from "html-entities";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="h5">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      textColorPrimary
      style={{ fontSize: "13px", paddingLeft: "10px", fontWeight: "bold" }}
      selected
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
const ProductDetail = () => {
  const params = useLocation();
  const [loading, setLoading] = useState(false);
  const prodId = params.search.split("prodId=")[1]
  const token = sessionStorage.getItem('token')
  const user = useSelector((state) => state.user.user)

  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  const [product, setProduct] = useState({});
  const [qty, setQty] = useState(50);
  const [selectValues, setSelectValues] = useState([]);

  const handleSelectChange = (event, index) => {
    const newSelectValues = [...selectValues];
    newSelectValues[index] = event;
    setSelectValues(newSelectValues);
  };

  const length = 0;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user !== null && user !== undefined && prodId) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/users/${user.id}/products/${prodId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res.data
          setProduct(data);
          setQty(product?.minimum_quantity)
          // setQty(product?.minimum_quantity)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/users/supplier_profile/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res)
          const data = res.data
          setCompanyInfo(data)

        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])


  return (
    <>
      <div>
        <Navigation />
        <div className={classes.topBar}>
          <div className="container">
            <div className={classes.backBtn}>
              <div>
                <Link to="/dashboard/supplier/product-and-services" className='leftArrow'>
                  <img src={LeftArrow} alt="Left arrow" className={classes.desktopArrow} />
                  <img src={topLeftArrow} alt="Left arrow" className={classes.mobileArrow} />
                </Link>
              </div>
              <Link to="/dashboard/supplier/product-and-services" style={{ color: "white" }}>
                <span className={classes.long}>BACK TO PRODUCT SETUP</span><span className={classes.short}>Back to catalog</span>
              </Link>
            </div>
            <div className={classes.topBarRight}>
              <h2 className="" style={{ textAlign: "center", color: "white" }}><span className={classes.long}>WHAT YOUR PRODUCT PAGE LOOKS LIKE</span><span className={classes.short}>YOUR PRODUCT PAGE</span></h2>
            </div>
          </div>
        </div>
        {
          loading ?
            <div>Loading...</div>
            :
            <div className={`${classes.container} container ProductDetail`}>
              <div className={classes.mainContent}>
                <div className="row mt-5">
                  <div className={classes.imageSetting}>
                    {
                      // product?.images && product?.images?.length > 0 &&
                      <div className={classes.leftSlider}>
                        <ProductSlider initialSlide={product?.images?.findIndex(f => f.cover) || 0} images={product?.images} />
                      </div>
                    }
                    <div className={classes.right}>
                      <div className="row">
                        <div className="col-12" style={{ marginTop: "10px" }}>
                          <div>
                            <h2>{product?.name}</h2>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className={classes.wrapper}>
                            <h5>${product?.price} <small>/per item</small></h5>
                          </div>
                        </div>
                        {
                          product?.modifications && product?.modifications?.map((prod, index) => {
                            let newArr = []
                            prod.options.map((item, index) => (
                              newArr.push({
                                id: item.key,
                                label: item.value,
                              })
                            ))
                            return (
                              prod.type === "color" ?
                                <div classes="col-12">
                                  <div className={classes.wrapper}>
                                    <p className="mb-0">{prod.name}</p>
                                    <div className={classes.selectWrapper}>
                                      <ColorDropdown
                                        dropdown={newArr}
                                        width={'100%'}
                                        placeholder="-Select an Option-" //Prem
                                        value={color}
                                        onChange={(val) => setColor(val)}
                                        // onChange={(value) => setSize(value)}
                                        setDropdown={(value) => console.log(value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className={classes.wrapper}>
                                  <p className="mb-0">{prod.name}</p>
                                  <div className={classes.selectWrapper}>
                                    <InputWithDropDownWithoutSearch
                                      dropdown={newArr}
                                      width={'100%'}
                                      // placeholder={prod.name}
                                      value={size}
                                      placeholder="-Select an Option-" //Prem
                                      // onChange={(event) => handleSelectChange(event, index)}
                                      //Commented by Prem onChange={(value) => setSize(value)}
                                      setDropdown={(value) => console.log(value)}
                                    />
                                  </div>
                                </div>
                            )
                          })
                        }
                        <div classes="col-12">
                          <div className={classes.wrapper}>
                            <p>Quantity (Min {product.minimum_quantity} items)</p>
                            <input className={classes.input} value={qty} onChange={(e) => setQty(e.target.value)} type="number" style={{ width: "100%" }} />
                            <div className={classes.numbersArrow}>
                              <img src={downArrow} alt="arrow" onClick={() => qty > product.minimum_quantity && setQty((parseInt(qty) - 1))} className={classes.smallLeftArrow} />
                              <img src={downArrow} alt="arrow" onClick={() => setQty((parseInt(qty) + 1))} className={classes.smallRightArrow} />
                            </div>
                          </div>
                        </div>
                        {
                          product?.customization_option === "Allowed" &&
                          <div classes="col-12">
                            <div className={`${classes.wrapper} ${classes.last}`}>
                              <FormControl className={classes.formControl}>
                                <p style={{ display: "flex", alignItems: "center", gap: "6px" }}><span>Leave a Comment</span> <InfoCircleOutlined className="productIcon" /></p>
                                <input
                                  style={{ width: "100%" }}
                                  className={classes.input}
                                  placeholder="Write your wishes for the order"
                                />
                              </FormControl>
                            </div>
                          </div>
                        }
                        <div className="col-12">
                          <div>
                            <div className={classes.totalContainer}>
                              <p>Total Items Amount: <strong>{qty}</strong></p>
                              <p>Total Price: <strong>${qty ? Math.round((parseInt(qty) * parseInt(product.price)) * 100 / 100).toFixed(2) : 0.00}</strong></p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className={`${classes.wrapper} ${classes.buttonContainer}`}>
                            <button
                              className={clsx(['button', classes.saveButton])}
                            >
                              ADD TO CART
                            </button>
                            <button
                              className={clsx(['button', classes.saveButton])}
                            >
                              <HeartOutlined />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ color: "rgba(135, 135, 135, 0.5)" }} />
                <div className={`row mt-5 ${classes.details}`}>
                  <div className="col-6">
                    <AppBar position="static" className={classes.appBar} style={{ color: "black", fontSize: "20px !important" }}>
                      <Tabs
                        // style={{ backgroundColor: "white", fontSize: "20px" }}
                        variant="centered"
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        <LinkTab label="Product Description" href="/drafts" {...a11yProps(0)} />
                        <LinkTab label="About Manufacturer" href="/trash" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                      <div className="quill ql-editor">
                        <div dangerouslySetInnerHTML={{ __html: decode(product?.description) }}></div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className={classes.eachItem}>
                        Production Capacity: <strong>{product?.user?.productionCapacity} units per month</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Location: <strong>{product?.user?.state}, {product?.user?.country}</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Year Established: <strong>{product?.user?.yearEstablished}</strong>
                      </div>
                      <div className={classes.eachItem}>
                        Same manufacturer as: {product?.user?.notableBrands && product?.user?.notableBrands.map(function (item, i) {
                          return (
                            <strong>{item + " "}</strong>
                          )
                        })}
                      </div>

                      <div className={classes.eachItem}>
                        Certifications: {product?.user?.images && product?.user?.images?.filter(f => f.type === "LCI").map(function (item, i) {
                          return (
                            <CertificateShow name={item.name?.split(".")[0]} images={item.url} />
                          )
                        })}
                      </div>

                      {/* </div> */}
                    </TabPanel>
                  </div>
                </div>
                <hr style={{ color: "red", marginTop: "14px" }} />
                <div className={classes.returnAndRefundLink}>
                  <Link>Return and Refund Policies</Link>
                </div>
              </div>
            </div>
        }

      </div >
      <Footer></Footer>
    </>
  )
}


export default ProductDetail;
