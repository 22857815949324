import classes from './Leads.module.scss'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LeadsItem from './LeadsItem/LeadsItem'
import CommonModal from '../../../../constant/public/CommonModal/CommonModal'
import InputElement from '../../../../constant/public/Input/InputElement'
import ButtonElement from '../../../../constant/public/Button/ButtonElement'
import Loading from '../../../../constant/Loading/Loading'
import errorIcon from '../../../../assets/svg/error_icon.svg'
import OrdersWithCustomisation from './OrdersWithCustomisation/OrdersWithCustomisation'
import * as Yup from "yup"
import { useFormik } from 'formik'

const Leads = () => {
  const [quotes, setQuotes] = useState("")
  // const [minPrice, setMinPrice] = useState('')
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [page, setPage] = useState(1)
  // const [maxPrice, setMaxPrice] = useState('')
  const [qouteId, setQouteId] = useState('')
  const [activeTab, setActiveTab] = useState("orders")
  const [costModalOpened, setCostModalOpened] = useState(false)
  const [modalsAnswered, setModalsAnswered] = useState([])

  const token = sessionStorage.getItem('token')
  const user = useSelector((state) => state.user.user)
  const [loading, setLoading] = useState(false)
  const [leads, setLeads] = useState([])
  const [orders, setOrders] = useState([])
  const [totalOrders, setTotalOrders] = useState([])

  const formik = useFormik({
    initialValues: {
      minPrice: "",
      maxPrice: ""
    },
    validationSchema: Yup.object({
      minPrice: Yup.number().required("Min Price is required").min(1, 'Add value greater than 1'),
      maxPrice: Yup.number().required("Max Price is required").min(1, 'Add value greater than 1'),
    }),
    onSubmit: (values) => {
      // handleProfileUpdated
      alert(JSON.stringify(values, null, 2));
    },

  });
  const getAllOrders = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supplier/${user._id}/orders-with-customizations?limit=10&page=1`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        
        setLoading(false)
        setOrders(res.data.results)
        setTotalOrders(res.data.totalResults)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const getAllLeads = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/quotes/supplier-leads/${user._id}?limit=10&page=1`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        
        setLoading(false)
        setLeads(res.data.results)
        setQuotes(res.data.totalResults)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (user !== null) {
      getAllLeads();
      getAllOrders();
    }
  }, [])

  const submitCost = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/quotes/${qouteId}/supplier/${user._id}/respond`, {
        response: 1,
        minPrice: formik.values.minPrice,
        maxPrice: formik.values.maxPrice
      },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setCostModalOpened(false)
        setLoading(false)
        getAllLeads();
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const passLead = (response, val) => {
    console.log(response, val);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/quotes/${val}/supplier/${user._id}/respond`, {
        response
      },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setCostModalOpened(false)
        setLoading(false);
        getAllLeads();
        // setLeads(res.data.results)
        // setQuotes(res.data.totalResults)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const loadMoreLeads = () => {
    setLoadMoreLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/quotes/supplier-leads/${user._id}?limit=10&page=${page}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoadMoreLoading(false);
        if (leads.length < quotes) {
          setLeads(prevArray => [...prevArray, ...res.data?.results]);
        }
      })
      .catch((err) => {
        setLoadMoreLoading(false);
        console.log(err)
      })
  }

  const loadMoreOrders = () => {
    setLoadMoreLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supplier/${user._id}/orders-with-customizations?limit=10&page=${page}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoadMoreLoading(false);
        if (orders.length < totalOrders) {
          setOrders(prevArray => [...prevArray, ...res.data?.results]);
        }
        // setLeads(res.data.results)
      })
      .catch((err) => {
        setLoadMoreLoading(false);
        console.log(err)
      })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.menu}>
        <button onClick={() => { setActiveTab("orders"); setPage(1) }} className={activeTab === "orders" && classes.active}>Orders with Customization ({totalOrders})</button>
        <button onClick={() => { setActiveTab("qoutes"); setPage(1) }} className={activeTab === "qoutes" && classes.active}>Quotes ({quotes})</button>
      </div>
      {
        loading ?
          <Loading />
          :
          activeTab === "qoutes" ?
            <ul className={classes.quotesList}>
              {leads?.length > 0
                ? leads.map((item) => (
                  <LeadsItem
                    data={item}
                    answerHandler={(val) => { setQouteId(val); setCostModalOpened(true); }}
                    passLead={passLead}
                    id={item._id}
                    modalsAnswered={modalsAnswered}
                    setModalsAnswered={setModalsAnswered}
                    maxPrice={formik.values.maxPrice}
                    minPrice={formik.values.minPrice}
                    key={item.projectLaunchDate}
                  />
                ))
                : null}
              <div className={classes.commonModal}>
                <CommonModal
                  modalOpened={costModalOpened}
                  setModalOpened={setCostModalOpened}
                  title={'Approximate cost'}
                >
                  <div className={classes.modalContent}>
                    <p>
                      To make communication easier, you can provide buyer with
                      approximate unit price range:
                    </p>
                    <form className={classes.priceForm}>
                      <h4 className={classes.priceFormTitle}>Unit Price Range:</h4>
                      <div className={classes.priceInputsWrapper}>
                        <div>
                          <div className={classes.priceInput}>
                            <span>Min $:</span>
                            {/* <InputElement
                          name="min_price"
                          value={minPrice}
                          onChange={(evt) => setMinPrice(evt.target.value)}
                          placeholder="500"
                          type="text"
                        /> */}
                            <InputElement
                              name="minPrice"
                              type="number"
                              wrapperClass={classes.companyInput}
                              placeholder="500"
                              onChange={formik.handleChange}
                              value={formik.values.minPrice}
                            />
                          </div>
                          {
                            formik.errors.minPrice &&
                            <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                              {formik.errors.minPrice}</div>
                          }
                          {
                            !formik.errors.minPrice && (parseInt(formik.values.minPrice) > parseInt(formik.values.maxPrice) &&
                              <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                                Min Price should be less than Max Price</div>
                            )
                          }
                        </div>
                        <div>
                          <div className={classes.priceInput}>
                            <span>Max $:</span>
                            {/* <InputElement
                          name="max_price"
                          value={maxPrice}
                          onChange={(evt) => setMaxPrice(evt.target.value)}
                          placeholder="1000"
                          type="text"
                        /> */}
                            <InputElement
                              name="maxPrice"
                              type="number"
                              wrapperClass={classes.companyInput}
                              placeholder="1000"
                              onChange={formik.handleChange}
                              value={formik.values.maxPrice}
                            />
                          </div>
                          {
                            formik.errors.maxPrice &&
                            <div className={classes.errorMessage}><img src={errorIcon} alt="Uplio" />{' '}
                              {formik.errors.maxPrice}</div>
                          }
                        </div>
                      </div>
                      <div className={classes.submitWrapper}>
                        {
                          formik.values.minPrice === "" || formik.values.maxPrice === "" ||
                            formik.errors.minPrice || formik.errors.maxPrice ||
                            parseInt(formik.values.minPrice) > parseInt(formik.values.maxPrice)
                            ?
                            <div className={classes.disabledBtn}>
                              <ButtonElement
                                content="Submit"
                                type="submit"
                                width={'325px'}
                              />
                            </div>
                            :
                            <ButtonElement
                              content="Submit"
                              type="submit"
                              width={'325px'}
                              function={(evt) => submitCost(evt)}
                            />
                        }
                        <button type={'button'} onClick={() => passLead(2, qouteId)} className={classes.skip}>
                          PASS
                        </button>
                      </div>
                    </form>
                  </div>
                </CommonModal>
              </div>
              <div
                className="blocker"
                style={costModalOpened ? { opacity: '1' } : { opacity: '0' }}
              ></div>
            </ul>
            :
            activeTab === "orders" &&
            <OrdersWithCustomisation orders={orders} update={() => { setPage(1); getAllOrders() }} />
      }
      <div className={classes.loadMore}>
        {
          loadMoreLoading ?
            <Loading />
            :
            activeTab === "orders" ?
              <button disabled={quotes === orders.length} onClick={async () => { setPage(page + 1); loadMoreOrders() }}>
                Load Older
              </button>
              :
              <button disabled={quotes === leads.length} onClick={async () => { setPage(page + 1); loadMoreLeads() }}>
                Load Older
              </button>
        }
      </div>
    </div>
  )
}

export default Leads
