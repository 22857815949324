import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import classes from "../Payment.module.scss"
import arrow from "../../../../assets/svg/Arrow_indicator_left_black.svg"
import clsx from "clsx";
import { notification, Radio } from "antd";
import "./CheckoutForm.css"


export default function CheckoutForm(props) {
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    let products = JSON.parse(localStorage.getItem("products"))
    let shippingAddress = JSON.parse(localStorage.getItem("shippingAddress"));
    const token = sessionStorage.getItem('token');

    const [email, setEmail] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [defaultAddress, setDefaultAddress] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [groupedProducts, setGroupedProducts] = useState([]);

    const productGrouping = async () => {
        const groupByUser = await products && products.length > 0 && products?.reduce((acc, product) => {
            const {
                name,
                _id,
            } = product.user;
            if (!acc[name]) {
                product.estTime = 0;
                product.shippingCharges = 0;
                acc[name] = {
                    supplier: _id,
                    _id,
                    shippingCharges: 0,
                    "buyerEmail": email,
                    total: parseInt(JSON.parse(localStorage.getItem("products"))?.filter(f => f.user._id === _id).reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2)),
                    subTotal: parseInt(JSON.parse(localStorage.getItem("products"))?.filter(f => f.user._id === _id).reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2)),
                    "tax": 0,
                    estTime: 0,
                    products: [product]
                };
            } else {
                acc[name].products.push(product);
            }
            return acc;
        }, {});
        setGroupedProducts(Object.values(groupByUser))
    }

    //    const getIt = 
    console.log(groupedProducts)

    const addOrder = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}/orders`,
                {
                    total: parseInt(JSON.parse(localStorage.getItem("products"))?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2)),
                    subTotal: parseInt(JSON.parse(localStorage.getItem("products"))?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2)),
                    tax: 0,
                    shippingAddress,
                    buyerEmail: shippingAddress?.email,
                    paymentChargeId: props.paymentId,
                    "isBillingAddressSame": true,
                    orderSuppliers: groupedProducts

                }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.status === 201) {
                    localStorage.removeItem("products");
                    history.push("/order-complete")
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        })
    }

    useEffect(() => {
        productGrouping();
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            props.clientSecret
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            console.log(paymentIntent)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    props.onChangeStep(5);
                    openNotificationWithIcon('success', '', "Payment succeeded!");
                    addOrder();
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);


    console.log(elements)

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(elements);

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: window.location.href,
                payment_method_data: {
                    billing_details: {
                        name: cardHolderName
                    }
                }
            },
        });

        if (!error) {
            props.onChangeStep(5);
            addOrder();
        }

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error?.type === "card_error" || error?.type === "validation_error") {
            setMessage(error?.message);
            openNotificationWithIcon('error', '', error?.message);
        } else if (error && error.type) {
            setMessage("An unexpected error occurred.");
            openNotificationWithIcon('error', '', "An unexpected error occurred.");
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
        name: cardHolderName,
        // fields: {
        //     billingDetails: {
        //         name: 'never',
        //     }
        // }
    }



    return (
        <>
            {/* <h5>Shipping Address</h5>
            <div className={classes.defaultCheck}>
                <div className="egl-radio-with-check">
                    <div className="eachRadio">
                        <Radio
                            className="radio-input"
                            type="radio"
                            checked={defaultAddress}
                            onClick={() => setDefaultAddress(!defaultAddress)}
                            id={""}
                            name={""}
                            value={defaultAddress}
                        >
                            Billing Address same as Shipping Address
                        </Radio>
                    </div>
                </div>
            </div> */}
            <form id="payment-form" onSubmit={handleSubmit}>
                <LinkAuthenticationElement
                    id="link-authentication-element"
                    onChange={(e) => setEmail(e.value.email)}
                />
                <PaymentElement className="payment-element" id="payment-element" options={paymentElementOptions} />
                {/* <div className={classes.default}>
                    <div className="egl-radio-with-check">
                        <div className="eachRadio">
                            <Radio
                                disabled
                                className="radio-input"
                                type="radio"
                                checked={defaultAddress}
                                onClick={() => setDefaultAddress(!defaultAddress)}
                                id={""}
                                name={""}
                                value={defaultAddress}
                            >
                                Make this my default payment method
                                <p>Accelerated payment on Uplio and thousands of other platforms</p>
                            </Radio>
                        </div>
                    </div>
                </div> */}
                <div className={classes.btnContainer}>
                    <button type="button" onClick={() => history.push("/summary")}>
                        <img src={arrow} alt="Arrow" />
                        <span>PREVIOUS</span>
                    </button>
                    <button
                        disabled={isLoading || !stripe || !elements}
                        className={clsx(['button', classes.saveButton])}
                        type="submit"
                    >
                        Place Order
                    </button>
                </div>
            </form>
        </>
    );
}