import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row, notification } from 'antd'

import classes from './SignIn.module.scss'
import * as yup from 'yup'
import {
  userLoggedIn,
  userDataStatus
} from '../../../redux/actions/user.action.js'
import { connect, useSelector, useDispatch } from 'react-redux'
import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'
import InputElement from '../../../constant/public/Input/InputElement'
import ButtonWithRightArrow from '../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import ResetModal from './ResetModal/ResetModal'

const SignIn = (props) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  })

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])

  const quote = useSelector((state) => state.singleQuote)

  const history = useHistory()
  const Formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Invalid email.')
        .required('Email is Required'),
      password: yup
        .string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
        //   'Invalid Format'
        // )
        .required('Password is required.'),
    }),
  })
  const { setFieldValue } = Formik;

  useEffect(() => {
    if (quote.quote !== null) {
      setFieldValue('email', quote.quote.email)
    }

    axios.get("https://countriesnow.space/api/v0.1/countries/states").then(res => console.log(res))
  }, [])

  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    })
  }

  const handleError = (data) => {
    if (data.message === 'You are not registered with us') {
      history.push({ pathname: '/signup' })
    }
  }

  const handleSignIn = (email, password) => {
    setLoader(true)
    Formik.handleSubmit();
    // dispatch(postUserLogin(user))
    if (Formik.values.email && Formik.values.password) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
          email: email,
          password: password,
        })
        .then((res) => {
          setLoader(false)
          if (!res.data.error) {
            dispatch(userDataStatus(res.data.user))
            dispatch(userLoggedIn())
            // props.userDataStatus(userData)

            sessionStorage.setItem('token', res.data.tokens.access.token)
            sessionStorage.setItem('refresh', res.data.tokens.refresh.token)

            if (res.data.user.userType === 'supplier') {
              history.push({ pathname: '/dashboard/supplier/profile' })
            } else {
              history.push({ pathname: '/dashboard/buyer/profile' })
            }
          } else {
            openNotificationWithIcon('warning', 'Oops', res.data.message)
            handleError(res.data)
          }
        })
        .catch((err) => {
          console.log(err.response)
          setLoader(false)
          openNotificationWithIcon('error', err.response?.data?.message, '')
          if (err.response.status === 401 && err.response.data.message !== "Your password is incorrect") {
            history.push("/signup");
          }
        })
    }
  }

  return (
    <>
      <Navigation />
      <div className={classes.sideTitle}>
        <h2>SIGN IN</h2>
        <h4>SIGN IN</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <h3>Email</h3>
          <InputElement
            width="100%"
            type="text"
            name="email"
            placeholder="Email"
            value={Formik.values.email}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            helperText={
              Formik.errors.email && Formik.touched.email
                ? Formik?.errors.email
                : null
            }
          />
          <h3>Password</h3>
          <InputElement
            width="100%"
            name="password"
            type="password"
            value={Formik.values.password}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            placeholder="Password"
            helperText={
              Formik.errors.password && Formik.touched.password
                ? Formik?.errors.password
                : null
            }
          />
          <ResetModal />

          <Row>
            <Col lg={12} md={12} sm={0} xs={0}></Col>
            <Col lg={12} md={12} sm={24} xs={24} align="right">
              <div className={classes.actionButton}>
                {
                  Formik.errors.email ||
                    Formik.errors.password ?
                    <ButtonWithRightArrow
                      disabled
                      type="button"
                      content="SIGN IN"
                      function={() =>
                        handleSignIn(Formik.values.email, Formik.values.password)
                      }
                    />
                    :
                    <ButtonWithRightArrow
                      type="button"
                      content="SIGN IN"
                      function={() =>
                        handleSignIn(Formik.values.email, Formik.values.password)
                      }
                    />
                }
                <h4>
                  Don’t have an account? <Link to="/signup">Sign Up</Link>
                </h4>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedIn: () => dispatch(userLoggedIn()),

    userDataStatus: () => dispatch(userDataStatus()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
