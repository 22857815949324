import React, { useState } from 'react'
import classes from './InvoiceTable.module.scss'
import BlackArrow from '../../../../../assets/svg/Arrow_Right_Black.svg'
import axios from 'axios'
import moment from 'moment'
import InvoiceModal from '../../../Supplier/Invoices/InvoiceTable/InvoiceModal/InvoiceModal'
import { useHistory } from 'react-router-dom'

const InvoiceTable = ({ data, buyer, update }) => {
  const history = useHistory();
  const [status, setStatus] = useState('In Production')
  const [modalOpened, setModalOpened] = useState(false)
  const [modalData, setModalData] = useState(null)

  const handleModal = (item) => {
    getOrderDetails(item?._id);
  }

  const token = sessionStorage.getItem('token')

  const getOrderDetails = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setModalData(res.data);
        setModalOpened(true);
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  return (
    <>
      <div className={classes.table}>
        {data?.length > 0 && data?.map((item) => {
          return (
            <div className={classes.tableRow} key={"id"}>
              <div className={classes.orderId}>
                <h3 className={classes.rowTitle}>Invoice ID:</h3>
                <p>{item?.invoiceNumber}</p>
              </div>
              <div className={classes.buyer}>
                <h3 className={classes.rowTitle}>Supplier:</h3>
                <p>{item?.buyer?.firstname} {item?.supplier?.lastname}</p>
              </div>
              <div className={classes.date}>
                <h3 className={classes.rowTitle}>Quote ID:</h3>
                <p>{item?.quote?.quoteNumber}</p>
              </div>
              <div className={classes.date}>
                <h3 className={classes.rowTitle}>Issue Date:</h3>
                <p>{moment(item?.issueDate).format("MM-DD-YYYY")}</p>
              </div>
              <div className={classes.status}>
                <h3 className={classes.rowTitle}>status:</h3>
                <p className={`${classes.statusLabel} ${item?.status === 1 ? classes.status1 : item?.status === 2 ? classes.status2 : item?.status === 3 && classes.status3}`}>
                  {
                    item?.status === 1 ?
                      "Pending Payment"
                      :
                      item?.status === 2 ?
                        "Partially Paid"
                        :
                        item?.status === 3 &&
                        "Fully Paid"
                  }
                </p>
              </div>
              <div className={classes.seeMore}>
                <button
                  type="button"
                  className={classes.seeMoreBtn}
                >
                  <div className={classes.seeMoreBtn}>
                    <button onClick={() => handleModal(item)}>
                      <p className="title title_item-s">See more</p>
                      <img className={classes.BlackArrow} src={BlackArrow} alt="Arrow" />
                    </button>
                    {
                      // item?.status !== 3 &&
                      <button className={item?.status === 3 && classes.disabled} onClick={() => history.push({ pathname: "/dashboard/buyer/invoice-payment", state: item._id })}>
                        <p className="title title_item-s">Pay</p>
                        <img className={classes.BlackArrow} src={BlackArrow} alt="Arrow" />
                      </button>
                    }
                  </div>
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <InvoiceModal
        modalData={modalData}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        status={status}
        buyer={buyer ? true : false}
      />
      <div
        className={`${modalOpened ? 'blocker blocker_opened' : 'blocker'}`}
      ></div>
    </>
  )
}

export default InvoiceTable
