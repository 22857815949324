import React, { useState, useEffect } from 'react'
import classes from './Chatbox.module.scss'
import clsx from 'clsx'
import InputElement from './Input/InputElement'
import pic from '../../../../../assets/svg/pic.svg'
import doc from '../../../../../assets/svg/doc.svg'
import chatExample from '../../../../../assets/images/chat-example.png'
import { useSelector } from 'react-redux'
import Avatar from '../../../../../assets/svg/Avatar Buyer.svg'
import moment from 'moment'
import axios from 'axios'
import { FieldTimeOutlined } from '@ant-design/icons'

const Chatbox = ({ selectedChat, setSelectedChat, className, isVisible, handleMessageSend, messages }) => {
  const user = useSelector((state) => state.user.user);
  const token = sessionStorage.getItem('token')
  const [inputValue, setInputValue] = useState('')
  const [pinModalOpened, setPinModalOpened] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pinModalHovered, setPinModalHovered] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 767 ? false : true
  )

  // Check isMobile using viewport width
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 767) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    })

    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth > 767) {
          setIsMobile(false)
        } else {
          setIsMobile(true)
        }
      })
    }
  }, [])

  const handleSearch = (e) => {
    // Send message
  }

  const handleKeyCheck = (e) => {
    console.log(e);
    if (e.key === 'Enter') {
      sendMessage(e.target.value)
    }
  }

  const sendMessage = (value) => {
    if (value !== '') {
      // setSelectedChat((prev) => {
      //   const newChat = Object.assign({}, prev)
      //   newChat?.messages?.push({
      //     messageType: 'supplier',
      //     message: value,
      //     time: '12:12 AM',
      //   })
      //   return newChat
      // })
      handleMessageSend(value, uploadedFiles);
      setInputValue("");
      setUploadedFiles([]);
    }
  }

  useEffect(() => {
    if (!pinModalHovered) {
      setPinModalOpened(false)
    }
  }, [pinModalHovered])

  if (!isVisible) {
    return null
  }


  const handleFileUpload = async (file, type) => {
    let data = new FormData();
    data.append("file", file)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/conversations/${selectedChat?._id}/messages/media`, data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setUploadedFiles(uploadedFiles.concat({ type, url: res.data?.url }));
      })
      .catch((err) => {
        console.log(err)
      })
  }


  return (
    <div className={clsx([className, classes.wrapper])}>
      <div className={classes.chatbox}>
        <div className={classes.chatWrapper}>
          <div className={classes.timestamp}>
            <time>{moment(messages[0]?.createdAt).format("dddd, MMMM DD")}</time>
          </div>
          <ul className={classes.messageList}>
            {/* Image and doc LayoutExample */}
            {/* {selectedChat && (
              <li className={classes.messageItemWrapper}>
                <div className={classes.messageItem}>
                  <div className={classes.messageAuthor}>
                    {
                      user?.userType === "buyer" ?
                        <img src={selectedChat?.buyer?.profileImage} alt="Uplio" />
                        :
                        <img src={selectedChat?.supplier?.profileImage} alt="Uplio" />
                    }
                  </div>
                  <div
                    className={classes.messageContent}
                    style={{
                      gridTemplateColumns: 'calc(100% - 84px - 12px) 84px',
                      gridColumnGap: '12px',
                    }}
                  >
                    {
                      user?.userType === "buyer" ?
                        <h3 className={classes.messageName}>
                          {selectedChat?.buyer?.firstname} {selectedChat?.buyer?.lastname}
                        </h3>
                        :
                        <h3 className={classes.messageName}>
                          {selectedChat?.supplier?.firstname} {selectedChat?.supplier?.lastname}
                        </h3>
                    }
                    <div className={classes.messageContentWrapper}>
                      <a className={classes.messageDownload} href="/" download>
                        Uplio Attachment.doc
                      </a>
                      <img
                        className={classes.messageImg}
                        width={191}
                        src={chatExample}
                        alt=""
                      />
                    </div>
                    <time className={classes.messageTime}>{'12:22 AM'}</time>
                  </div>
                </div>
                {false && (
                  <div className={classes.mobileMessageTime}>
                    <time>{'12:22 AM'}</time>
                  </div>
                )}
              </li>
            )} */}
            {/* LayoutExample */}
            {messages &&
              messages?.map((message, idx) => {

                return (
                  <li
                    className={classes.messageItemWrapper}
                    key={idx}
                  >
                    <div className={classes.messageItem}>
                      <div className={classes.messageAuthor}>
                        <img
                          src={`${message?.sender?._id === user?._id
                            ? (user?.profileImage ? user?.profileImage : Avatar)
                            : (message?.sender?.profileImage ? message?.sender?.profileImage : Avatar)
                            }`}
                          alt="Uplio"
                        />
                      </div>
                      <div
                        className={classes.messageContent}
                        style={
                          isMobile && message?.message?.length >= 35
                            ? {
                              gridTemplateColumns: '100%',
                            }
                            : {
                              gridTemplateColumns:
                                'calc(100% - 84px - 12px) 84px',
                              gridColumnGap: '12px',
                            }
                        }
                      >
                        <h3 className={classes.messageName}>
                          {message?.sender?._id === user?._id
                            ? user?.firstname + " " + user?.lastname
                            : message?.sender?.firstname + " " + message?.sender?.lastname
                          }
                        </h3>
                        <div className={classes.messageContentWrapper}>
                          {message?.message && (
                            <p className={classes.messageText}>
                              {message?.message}
                            </p>
                          )}
                          {message?.media?.length > 0 &&
                            message?.media?.map(media => {
                              return (
                                media.type === '2' ?
                                  <a
                                    className={classes.messageDownload}
                                    href={media?.url}
                                    download
                                  >
                                    Uplio Attachment.doc
                                  </a>
                                  :
                                  media.type === '1' &&
                                  <img
                                    className={classes.messageImg}
                                    src={media?.url}
                                    alt=""
                                  />
                              )
                            })
                          }
                          {/* {message?.media?.length > 0 && (
                            <img
                              className={classes.messageImg}
                              src={chatExample}
                              alt=""
                            />
                          )} */}
                        </div>
                        {(!isMobile || message?.message?.length < 35) && (
                          <time className={classes.messageTime}>
                            {moment(message?.createdAt).format("hh:mm a")}
                          </time>
                        )}
                      </div>
                    </div>
                    {isMobile && message.message.length >= 35 && (
                      <div className={classes.mobileMessageTime}>
                        <time>{moment(message?.createdAt).format("hh:mm a")}</time>
                      </div>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
      <div className={classes.chatInput}>
        <div className={classes.pinWrapper}>
          <button
            type="button"
            className={classes.pin}
            onClick={() => setPinModalOpened(true)}
            onMouseOver={() => setPinModalOpened(true)}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.72456 16.7761L16.7758 7.7249C17.676 6.82465 18.897 6.3189 20.1702 6.3189C21.4433 6.3189 22.6643 6.82465 23.5646 7.7249C24.4648 8.62516 24.9706 9.84616 24.9706 11.1193C24.9706 12.3925 24.4648 13.6135 23.5646 14.5137L12.8158 25.2609C12.3631 25.6981 11.7569 25.94 11.1276 25.9345C10.4983 25.9291 9.89637 25.6766 9.45139 25.2317C9.00641 24.7867 8.75401 24.1847 8.74854 23.5555C8.74307 22.9262 8.98498 22.3199 9.42216 21.8673L19.0382 12.2513C19.1884 12.1013 19.2729 11.8978 19.273 11.6855C19.2732 11.4732 19.189 11.2695 19.039 11.1193C18.889 10.9691 18.6854 10.8846 18.4731 10.8845C18.2608 10.8843 18.0572 10.9685 17.907 11.1185L8.29096 20.7345C7.54613 21.4857 7.12918 22.5013 7.13135 23.5591C7.13351 24.6169 7.55462 25.6308 8.30251 26.379C9.05041 27.1271 10.0642 27.5485 11.122 27.5509C12.1798 27.5534 13.1956 27.1367 13.947 26.3921L24.6958 15.6449C25.8962 14.4446 26.5707 12.8167 26.5709 11.1191C26.571 9.42149 25.8968 7.79338 24.6966 6.5929C23.4963 5.39243 21.8683 4.71792 20.1707 4.71777C18.4731 4.71762 16.845 5.39184 15.6446 6.5921L6.59176 15.6449C6.44603 15.7958 6.3654 15.9979 6.36722 16.2076C6.36904 16.4174 6.45318 16.618 6.6015 16.7664C6.74983 16.9147 6.95048 16.9988 7.16024 17.0006C7.36999 17.0025 7.57208 16.9218 7.72296 16.7761H7.72456Z"
                fill="#C1C1C1"
              />
            </svg>
          </button>
          <div
            className={classes.pinModal}
            style={pinModalOpened ? { display: 'block' } : { display: 'none' }}
            onMouseOver={() => setPinModalHovered(true)}
            onMouseLeave={() => setPinModalHovered(false)}
          >
            <label className={classes.pinItem}>
              <div className={classes.pinItemType}>
                <img width="16" height="16" src={pic} alt="images" />
                Images
              </div>
              <input
                type="file"
                accept='image/*'
                style={{ display: 'none' }}
                onChange={(evt) => handleFileUpload(evt.target.files[0], 1)}
              />
            </label>
            <label className={classes.pinItem}>
              <div className={classes.pinItemType}>
                <img width="16" height="16" src={doc} alt="docs" />
                Files
              </div>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={(evt) => handleFileUpload(evt.target.files[0], 2)}
              />
            </label>
          </div>
        </div>
        <div className={classes.messageInputWrapper}>
          <InputElement
            type="text"
            width={'100%'}
            placeholder={'Message...'}
            value={inputValue}
            className={classes.messageInput}
            onChange={(evt) => setInputValue(evt.target.value)}
            onKeyPress={handleKeyCheck}
          />
        </div>
        <div className={classes.sendMessageWrapper}>
          <button
            type="button"
            className={classes.sendMessage}
            onClick={() => sendMessage(inputValue)}
          >
            <svg
              width="41"
              height="16"
              viewBox="0 0 41 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.7071 8.70711C41.0976 8.31659 41.0976 7.68342 40.7071 7.2929L34.3431 0.928935C33.9526 0.538411 33.3195 0.538411 32.9289 0.928935C32.5384 1.31946 32.5384 1.95262 32.9289 2.34315L38.5858 8L32.9289 13.6569C32.5384 14.0474 32.5384 14.6805 32.9289 15.0711C33.3195 15.4616 33.9526 15.4616 34.3431 15.0711L40.7071 8.70711ZM-8.74228e-08 9L40 9L40 7L8.74228e-08 7L-8.74228e-08 9Z"
                fill="#303030"
              />
            </svg>
          </button>
        </div>
      </div>
      {
        uploadedFiles?.length > 0 &&
        <div className={classes.uploadedFilesCont}>
          {
            uploadedFiles?.map(file => {
              return (
                <div>
                  {
                    file?.type === 1 ?
                      <img src={file?.url} alt="Media" width="100" />
                      :
                      file?.type === 2 &&
                      <p>{file.url}</p>
                  }
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default Chatbox
