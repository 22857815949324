import React, { useEffect, useState } from 'react'
import classes from './DepositMethods.module.scss'
import ButtonElement from '../../../../constant/public/Button/ButtonElement'
import AddDepositMethodModal from './AddDepositMethodModal/AddModal'
import EditDepositMethodModal from './EditDepositMethodModal/EditModal'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import Loading from '../../../../constant/Loading/Loading'
import cardPoints from '../../../../assets/svg/card-points.svg'

const DepositMethods = () => {
  const [depositMethods, setDepositMethods] = useState({})
  const [addShippingModal, setAddShippingModal] = useState(false)
  const [editShippingModal, setEditShippingModal] = useState(false)
  const [addPaymentModal, setAddPaymentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);
  const [depositMethodObj, setDepositMethodObj] = useState({});
  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const getDepositMethods = () => {
    if (user !== null && user !== undefined) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${user._id}/stripe/connect-account-bank`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("bank", res)
          setDepositMethods(res.data)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        })
    }
  }

  useEffect(() => {
    getDepositMethods();

    return () => {

    }
  }, []);



  const handleUpdate = () => {
    getDepositMethods();
    setDepositMethodObj({});
  }

  return (
    <>
      <Navigation />
      <div>
        {
          loading ?
            <div>
              <Loading />
            </div>
            :
            <div className={classes.containerWrapper}>
              <div className={classes.mainSectionWrapper}>
                <div className={classes.deposit}>
                  <div className={classes.wrapper}>
                    {
                      loading ?
                        <Loading />
                        :
                        depositMethods?.bank_name ? (
                          <div className={classes.depositsWrapper}>
                            <h2 className={classes.title}>Deposit method</h2>

                            <ul className={classes.depositList}>
                              <li className={classes.depositItem}>
                                <h4 className={classes.depositTitle}>
                                  METHOD №1
                                  <button
                                    type="button"
                                    className={classes.depositEditBtn}
                                    onClick={() => { setDepositMethodObj(depositMethods); setEditShippingModal(true) }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.0524 3.69766L12.4435 1.07543C12.2711 0.903907 12.0378 0.807617 11.7946 0.807617C11.5514 0.807617 11.3181 0.903907 11.1457 1.07543L1.89683 10.311L1.05238 13.9554C1.02325 14.0887 1.02425 14.2267 1.0553 14.3595C1.08636 14.4923 1.14669 14.6165 1.23188 14.7229C1.31707 14.8294 1.42497 14.9155 1.5477 14.975C1.67044 15.0344 1.8049 15.0657 1.94127 15.0665C2.00481 15.073 2.06884 15.073 2.13238 15.0665L5.81683 14.2221L15.0524 4.99543C15.2239 4.82304 15.3202 4.58974 15.3202 4.34654C15.3202 4.10335 15.2239 3.87005 15.0524 3.69766ZM5.37238 13.4221L1.91905 14.1465L2.70572 10.7599L9.62572 3.86654L12.2924 6.53321L5.37238 13.4221ZM12.8879 5.88877L10.2213 3.2221L11.7679 1.68432L14.3902 4.35099L12.8879 5.88877Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </button>
                                </h4>
                                <ul className={classes.depositInfo}>
                                  <li className={classes.depositInfoItem}>
                                    <p>Bank Name</p>
                                    <span>{depositMethods?.bank_name}</span>
                                  </li>
                                  <li className={classes.depositInfoItem}>
                                    <p>Routing Number </p>
                                    <span>{depositMethods?.routing_number}</span>
                                  </li>
                                  <li className={classes.depositInfoItem}>
                                    <p>Bank Location</p>
                                    <span>{depositMethods?.country}</span>
                                  </li>
                                  <li className={classes.depositInfoItem}>
                                    <p>Account Number </p>
                                    <span>
                                      <span className={classes.cardNumber}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </span>
                                      {depositMethods?.last4}
                                    </span>
                                  </li>
                                  <li className={classes.depositInfoItem}>
                                    <p>Account Holder Name </p>
                                    <span>{depositMethods?.account_holder_name}</span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            {
                              !depositMethods &&
                              <div className={classes.anotherMethod}>
                                <p>Add another method</p>
                                <button
                                  className={classes.addBtn}
                                  type="button"
                                  onClick={() => setAddShippingModal(true)}
                                >
                                  <PlusOutlined />
                                </button>
                              </div>
                            }
                          </div>
                        ) : (
                          <div className={classes.depositWrapper}>
                            <h2 className={classes.title}>Deposit method</h2>
                            <p className={classes.depositText}>
                              No bank information or deposit method added yet
                            </p>
                            <div className=''>
                              <ButtonElement
                                className={classes.setMethod}
                                content={'Set Deposit Method'}
                                function={() => setAddShippingModal(true)}
                              />
                            </div>
                          </div>
                        )}
                  </div>
                  <AddDepositMethodModal
                    modalOpened={addShippingModal}
                    setModalOpened={setAddShippingModal}
                    handleUpdate={handleUpdate}
                    setDepositMethods={setDepositMethods}
                  />
                  <EditDepositMethodModal
                    methodObj={depositMethodObj}
                    modalOpened={editShippingModal}
                    handleUpdate={handleUpdate}
                    setModalOpened={setEditShippingModal}
                    setDepositMethods={setDepositMethods}
                  />
                  <div
                    className={`blocker ${addShippingModal ||
                      editShippingModal ||
                      addPaymentModal ||
                      deleteModal
                      ? 'blocker_opened'
                      : null
                      }`}
                  ></div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default DepositMethods
