import React from 'react'
import classes from "./ProductCard.module.scss"
import plus from "../../../../../assets/svg/plusBox.svg"
import cross from "../../../../../assets/svg/crosswhite.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Radio } from 'antd'
import EditIt from '../EditProductsModel/EditIt'
import { RGBAtoHEX } from '../../../../../constant/RgbaToHex'


const ProductCard = ({ data, handleDuplicateProducts, handleUpdate, handleRefresh, selectedProducts, handleDelete }) => {


    return (
        <div className={classes.ProductCard}>
            <div style={{ position: "relative" }}>
                <div className={classes.swiperCon}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={1}
                        loop={true}
                        dots={false}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                        }}
                        className="mySwiper"
                    >
                        { //this block added by Prem
                            // data?.images && data?.images?.length > 0 && data?.images?.filter((i) => i.cover === true).length > 0 &&
                            data?.images?.filter((i) => i.cover === true).map(image => {
                                return (
                                    <SwiperSlide key={image?._id} className={classes.slide}>
                                        {/* <Link to={`/product/${data?._id}`}> */}
                                        <img src={image.url} alt="Shirt" className={classes.productImg} />
                                        {/* </Link> */}
                                    </SwiperSlide>
                                )
                            })
                        }
                        {//filter added by Prem
                            data?.images && data?.images && data?.images?.length > 0 && data?.images?.filter((i) => i.cover === false).map(image => {
                                return (
                                    <SwiperSlide key={image?._id} className={classes.slide}>
                                        {/* <Link to={`/product/${data?._id}`}> */}
                                        <img src={image.url} alt="Shirt" className={classes.productImg} />
                                        {/* </Link> */}
                                    </SwiperSlide>
                                )
                            })
                            // :
                            // <SwiperSlide className={classes.slide}>
                            //     <img src={shirt} alt="Shirt" className={classes.productImg} />
                            // </SwiperSlide>
                        }
                    </Swiper>
                </div>
                <div className={classes.btnContainer}>
                    <EditIt productId={data._id} product={data} handleUpdate={handleRefresh} />
                    <button onClick={() => handleDuplicateProducts(data?.user, data?._id)}>
                        <img src={plus} alt="plus" />
                    </button>
                    <button onClick={() => { handleUpdate(data._id); handleDelete() }}>
                        <img src={cross} alt="cross" />
                    </button>
                </div>
            </div>
            <div className={classes.BottomSection}>
                <div className={classes.titleAndPrice}>
                    <div>
                        <div className="egl-radio-with-check">
                            <div className="eachRadio">
                                <Radio
                                    className="radio-input"
                                    type="radio"
                                    checked={selectedProducts?.includes(data._id)}
                                    onClick={(e) => handleUpdate(e.target.value)}
                                    value={data._id}
                                >
                                    <h5 className={classes.title}>{data.name} </h5>
                                </Radio>
                            </div>
                        </div>
                    </div>
                    <h5>${parseInt(data?.price).toFixed(2)}</h5>
                </div>
                <div className={classes.sameManufacturer}>
                    {
                        data?.modifications?.filter(f => f.type === "color") && data?.modifications?.filter(f => f.type === "color")?.map((color, index) => {
                            return (
                                <p>{color.name}:
                                    {
                                        color?.options?.map(opt => {
                                            return (
                                                opt.key && opt.value &&
                                                <span className={classes.brands}>
                                                    <span>{opt?.key} {RGBAtoHEX((opt?.value))}; </span>
                                                </span>
                                            )
                                        })
                                    }
                                </p>
                            )
                        })
                    }
                    {
                        data?.modifications?.filter(f => f.type !== "color") && data?.modifications?.filter(f => f.type !== "color")?.map((text, index) => {
                            return (
                                <p>{text.name}:
                                    {
                                        text?.options?.map(opt => {
                                            return (
                                                <span className={classes.brands}>
                                                    <span>{opt.key} {opt.value}; </span>
                                                </span>
                                            )
                                        })
                                    }
                                </p>
                            )
                        })
                    }
                </div>
                <div>
                    <p>Lead time: {data?.user?.estimatedLeadTimeProduction} weeks</p>
                </div>
                <div>
                    <p>Min Qty: {data?.minimum_quantity}</p>
                </div>
                <div>
                    <p>Customization: {data?.customization_option}</p>
                </div>
            </div >
        </div >
    )
}

export default ProductCard
