import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../constant/Loading/Loading';
import SortSelect from '../../Buyer/Invoices/SortSelect/SortSelect';
import classes from "./Invoices.module.scss";
import InvoiceTable from './InvoiceTable/InvoiceTable';

const Invoices = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [invoices, setInvoices] = useState([]);
    const token = sessionStorage.getItem('token')
    const [sort, setSort] = useState(1);
    const [page, setPage] = useState(1);
    const user = useSelector((state) => state.user.user)

    const getInvoices = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/invoice/supplier/${user.id}?limit=10000&page=1&sortBy=createdAt:desc&status=${sort === 0 ? "" : sort}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                ;
                setInvoices(res.data?.results);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response)
            })
    }


    useEffect(() => {
        getInvoices();

        return () => {

        }
    }, [sort])

    const sortOptionsData = [
        {
            label: "All",
            value: "0"
        },
        {
            label: "Fully paid",
            value: "3"
        },
        {
            label: "Partially paid",
            value: "2"
        },
        {
            label: "Pending payment",
            value: "1"
        },
    ]

    return (
        <div className={classes.invoices}>
            <div className={classes.topBar}>
                <h2>invoices info</h2>
                <div className={classes.sortDropdownSection}>
                    <SortSelect setValue={(val) => setSort(parseInt(val))} optionsData={sortOptionsData} />
                </div>
            </div>
            {
                loading ?
                    <Loading />
                    :
                    invoices?.length === 0 ?
                        <div className={classes.noInvoicesCont}>
                            {/* <h2>invoices info</h2> */}
                            <p>No invoice added yet</p>
                            <button onClick={() => history.push("/dashboard/supplier/add-invoice")}>ADD INVOICE</button>
                        </div>
                        :
                        <div className={classes.main}>
                            {/* <h2>invoices info</h2> */}
                            <InvoiceTable update={getInvoices} data={invoices} />
                            <div className={classes.bottom}>
                                <button onClick={() => history.push("/dashboard/supplier/add-invoice")}>ADD INVOICE</button>
                            </div>
                        </div>
            }
        </div>
    )
}

export default Invoices
