import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import classes from './Messages.module.scss'
import MessageSearch from './MessageSearch/MessageSearch'
import clsx from 'clsx'
import ContactList from './ContactList/ContactList'
import Chatbox from './Chatbox/Chatbox'
import Navigation from '../../../../constant/public/Navigation/Navigation'
import Loading from '../../../../constant/Loading/Loading'
import QuoteModal from './QuoteModal/QuoteModal'
import { useSelector } from 'react-redux'
import OrdersModal from '../../../common/OrdersTable/OrdersModal/OrdersModal'
import Avatar from '../../../../assets/svg/Avatar Buyer.svg'


const Messages = () => {
  // const token = sessionStorage.getItem('token')
  // const userId = useSelector((state) => state.user.user.id)
  const history = useHistory();
  const params = useLocation();
  const user = useSelector((state) => state.user.user)
  const targetUser = params.state?.targetUser;
  const contextId = params.state?.contextId;
  const contextType = params.state?.contextType;

  const [selectedChat, setSelectedChat] = useState(null)
  const [order, setOrder] = useState({});
  const [quote, setQuote] = useState({});
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quoteModalOpened, setQuoteModalOpened] = useState(false)
  const [picModalOpened, setPicModalOpened] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const token = sessionStorage.getItem('token')
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 991 ? false : true
  )
  const [mobileChatVisible, setMobileChatVisible] = useState(false)

  // Check isMobile using viewport width

  const createConversation = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/conversations`,
        {
          targetUser,
          contextId,
          contextType
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        getConversations();
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  console.log(params);


  const getConversations = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/conversations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        setConversations(res.data?.results);
        if (targetUser && contextType && contextId) {
          if (contextType === 1) {
            setSelectedChat(res.data.results?.filter(f => f?.contextType === 1 && f?.contextId === contextId && (f?.buyer?._id === targetUser || f?.supplier?._id === targetUser))[0]);
            getConversationMessages(res.data.results?.filter(f => f?.contextType === 1 && f?.contextId === contextId && (f?.buyer?._id === targetUser || f?.supplier?._id === targetUser))[0]?._id);
            isMobile && setMobileChatVisible(true);
          } else if (contextType === 2) {
            setSelectedChat(res.data.results?.filter(f => f?.contextType === 2 && f?.contextId === contextId && (f?.buyer?._id === targetUser || f?.supplier?._id === targetUser))[0]);
            getConversationMessages(res.data.results?.filter(f => f?.contextType === 2 && f?.contextId === contextId && (f?.buyer?._id === targetUser || f?.supplier?._id === targetUser))[0]?._id);
            isMobile && setMobileChatVisible(true);
          }
        } else {
          setSelectedChat(res.data.results[0]);
          getConversationMessages(res.data.results[0]?._id);
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  console.log(selectedChat)

  const handleMessageSend = (val, files) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/conversations/${selectedChat?._id}/messages`, {
        "message": val,
        "media": files
      },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setMessages(messages.concat(res.data));
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getConversationMessages = (id) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/conversations/${id}/messages`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        setMessages(res.data?.results?.reverse());
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const getQuoteById = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/quotes/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setQuote(res.data);
        setQuoteModalOpened(true)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const getOrderById = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setOrder(res.data);
        setQuoteModalOpened(true)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }


  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 991) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    })

    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth > 991) {
          setIsMobile(false)
        } else {
          setIsMobile(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (targetUser && contextId && contextType) {
      createConversation();
    }

    getConversations();

    return () => {

    }
  }, [contextType])

  const handleSearch = (val) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/conversations?search=${val}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setConversations(res.data?.results);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }


  return (
    <>
      <Navigation />
      <div className={`${classes.container} container`}>
        <div>
          {
            loading ?
              <Loading />
              :
              <div>
                <div className={classes.wrapper}>
                  <div
                    className={clsx([classes.block, classes.nav])}
                    style={
                      isMobile && mobileChatVisible
                        ? { display: 'none' }
                        : { display: 'flex' }
                    }
                  >
                    <div className={classes.backWrapper}>
                      <button
                        type="button"
                        className={classes.backBtn}
                        onClick={() => history.goBack()}
                      >
                        <svg
                          width="41"
                          height="16"
                          viewBox="0 0 41 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.2929C-0.0976296 7.68342 -0.0976295 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928935C7.68054 0.538411 7.04738 0.538411 6.65685 0.928935L0.292892 7.2929ZM41 7L1 7L1 9L41 9L41 7Z"
                            fill="#303030"
                          />
                        </svg>
                        <span>Back to dashboard</span>
                      </button>
                    </div>
                    <div className={classes.searchWrapper}>
                      <MessageSearch placeholder="Search Messages" handleSearch={handleSearch} />
                    </div>
                    {/* {loading ? <Empty /> : renderContactList(data)} */}
                  </div>
                  <div
                    className={clsx({
                      [classes.block]: true,
                      [classes.profile]: selectedChat,
                    })}
                    style={
                      isMobile && !mobileChatVisible
                        ? { display: 'none' }
                        : { display: 'block' }
                    }
                  >
                    {/* {loading ? <Empty /> : renderChatBox(selectedChat)} */}
                    {selectedChat && (
                      <div className={classes.profileWrapper}>
                        <div className={classes.profileBack}>
                          <button
                            type="button"
                            className={classes.profileBackBtn}
                            style={isMobile ? { display: 'flex' } : { display: 'none' }}
                            onClick={() => setMobileChatVisible(false)}
                          >
                            <svg
                              width="37"
                              height="16"
                              viewBox="0 0 37 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.292892 7.2929C-0.0976296 7.68342 -0.0976295 8.31659 0.292893 8.70711L6.65686 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928935C7.68054 0.53841 7.04738 0.53841 6.65685 0.928935L0.292892 7.2929ZM37 7L1 7L1 9L37 9L37 7Z"
                                fill="#303030"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className={classes.profileImg}>
                          {
                            user?.userType === "buyer" ?
                              <img
                                width="72"
                                height="72"
                                src={selectedChat.supplier?.profileImage ? selectedChat.supplier?.profileImage : Avatar}
                                alt="profile"
                              ></img>
                              :
                              <img
                                width="72"
                                height="72"
                                src={selectedChat.buyer?.profileImage ? selectedChat.buyer?.profileImage : Avatar}
                                alt="profile"
                              ></img>
                          }
                        </div>
                        <div className={classes.profileInfo}>
                          {
                            user?.userType === "buyer" ?
                              <h2 className={classes.profileUserName}>
                                {selectedChat?.supplier?.firstname} {selectedChat?.supplier?.lasttname}
                              </h2>
                              :
                              <h2 className={classes.profileUserName}>
                                {selectedChat?.buyer?.firstname} {selectedChat?.buyer?.lastname}
                              </h2>
                          }
                          <p
                            className={classes.profileBrandName}
                            style={
                              isMobile
                                ? { display: 'none' }
                                : { display: 'inline-block' }
                            }
                          >
                            {selectedChat?.buyer?.companyName}
                          </p>
                          <button
                            type="button"
                            className={classes.profileQuoteBtn}
                            onClick={() => selectedChat?.contextType === 1 ? getQuoteById(selectedChat?.contextId) : getOrderById(selectedChat?.contextId)}
                            style={
                              isMobile
                                ? { display: 'inline-block' }
                                : { display: 'none' }
                            }
                          >
                            View {selectedChat?.contextType === 1 ? "quote" : "order"}
                          </button>
                        </div>
                        <div className={classes.profileQuote}>
                          {
                            selectedChat?.contextType === 1 ?
                              <button
                                type="button"
                                className={classes.profileQuoteBtn}
                                onClick={() => getQuoteById(selectedChat?.contextId)}
                              >
                                View quote
                              </button>
                              :
                              <button
                                type="button"
                                className={classes.profileQuoteBtn}
                                onClick={() => getOrderById(selectedChat?.contextId)}
                              >
                                View order
                              </button>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                  <ContactList
                    isVisible={isMobile && mobileChatVisible ? false : true}
                    setMobileChatVisible={setMobileChatVisible}
                    chats={conversations}
                    setSelectedChat={(val) => { setSelectedChat(val); getConversationMessages(val?._id) }}
                    className={classes.block}
                  />
                  <Chatbox
                    isVisible={isMobile && !mobileChatVisible ? false : true}
                    setMobileChatVisible={setMobileChatVisible}
                    messages={messages}
                    selectedChat={selectedChat}
                    handleMessageSend={handleMessageSend}
                    setSelectedChat={setSelectedChat}
                    className={classes.block}
                  />
                </div>
                {
                  selectedChat?.contextType === 1 ?
                    <QuoteModal
                      modalOpened={quoteModalOpened}
                      setModalOpened={setQuoteModalOpened}
                      modalData={quote}
                      setImage={setSelectedImage}
                      setPicModalOpened={setPicModalOpened}
                    ></QuoteModal>
                    :
                    <OrdersModal
                      modalData={order}
                      modalOpened={quoteModalOpened}
                      setModalOpened={setQuoteModalOpened}
                      // status={status}
                      buyer={user?.userType === "buyer" ? true : false}
                    />
                }
                {picModalOpened && (
                  <div className={classes.picModal}>
                    <div className={classes.picModalWrapper}>
                      <button
                        type="button"
                        className={classes.close}
                        onClick={() => setPicModalOpened(false)}
                        aria-label="close"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                            fill="#FFFDFD"
                          />
                        </svg>
                      </button>
                      <div className={classes.picModalCounter}>
                        <span>
                          {selectedImage.index}/{selectedImage.length}
                        </span>
                      </div>
                      <div className={classes.selectedImage}>
                        <img src={selectedImage.image} alt="uplio" />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={quoteModalOpened ? 'blocker blocker_opened' : 'blocker'}
                ></div>
                <div
                  className={clsx({
                    [classes.blocker]: true,
                    [classes.blockerOpened]: picModalOpened,
                  })}
                ></div>
              </div>
          }
        </div>
      </div>
    </>
  )
}
export default Messages
