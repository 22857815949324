import React from 'react'
import classes from './Placeholder.module.scss'
import placeholder from "../../../assets/svg/g10.svg"
import ButtonElement from '../../../constant/public/Button/ButtonElement'
import { useSelector } from 'react-redux'

const Placeholder = ({ btnText, btnFunction }) => {
  const user = useSelector((state) => state.user.user)

  return (
    <div className={classes.placeholder}>
      <div className={classes.placeholderText}>
        <h1 className={classes.placeholderTitle}>
          {user.firstname} {user.lastname}, You&nbsp;are&nbsp;a step away from realizing your dream.
        </h1>
        <p className={classes.subtitle}>Let's Accelerate that!</p>
        <ButtonElement
          className={classes.browseBtn}
          content={btnText}
          function={btnFunction}
        />
      </div>
      <div className={classes.placeholderImg}>
        <img
          width="594"
          height="459"
          src={placeholder}
          srcSet={placeholder && `${placeholder} 1x, ${placeholder} 2x`}
          alt="uplio"
        />
      </div>
    </div>
  )
}

export default Placeholder
