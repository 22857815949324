import React, { useRef } from 'react'
import axios from 'axios'
import classes from './UploadFiles.module.scss'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import CertificateUpload from '../../../components/Admin/Supplier/CompanyInfo/CertificateUpload/CertificateUpload'
import { NotificationWithIconComp } from '../../Notification'
import { Modal } from 'antd'
import closeIcon from "../../../assets/svg/Cross.svg"
import loadingGif from "../../../assets/gif/loading.gif"

const UploadFiles = (props) => {
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openAttachment = () => {
    inputFile.current.click()
  }

  const user = useSelector((state) => state.user.user)
  const token = sessionStorage.getItem('token')

  const handlePPI = (event) => {
    if (props.userData?.profileCompleted) {

    } else {
      if (event.target.files[0].size / 1000000 < 200) {
        setIsModalOpen(!isModalOpen)
        setLoading(true);
        const formData = new FormData();
        const baseURL = process.env.REACT_APP_API_URL
        formData.append('file', event.currentTarget.files[0])
        formData.append('type', props.type)
        axios({
          method: "PATCH",
          url: baseURL + '/users/' + user?.id + "/media/",
          data: formData,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "bearer " + token
          }
        })
          .then(function (response) {
            setLoading(false);
            if (response.status == 200) {
              props.handleUpdate(response);
              setIsModalOpen(false)
            }
            else if (response.status == 400) {
              setIsModalOpen(false)
            }
          })
      } else {
        NotificationWithIconComp("error", "Files less than 200MB are supported", "")
      }
    }
  }
  return (
    <div>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".doc, .docx"
        name='image'
        onClick={(e) => e.target.value = null}
        onChange={
          handlePPI
        }
        ref={inputFile}
      />
      <div className={`${classes.input} ${props.type === "LCD" && classes.LCISection}`}>
        {
          props.type === "LCI" ?
            <CertificateUpload userData={props.userData} type={props.type} disabled={props.disabled} handleUpdate={props.handleUpdate} onClick={() => openAttachment()} />
            // <button
            //   disabled={props.disabled}
            //   id="attachment"
            //   type="button"
            //   class="file"
            //   name="image"
            //   onClick={() => openAttachment()}
            // >
            //   {!props.disabled ? (
            //     <PlusOutlined width={32} />
            //   ) : (
            //     <LoadingOutlined style={{ fontSize: 24 }} spin />
            //   )}
            // </button>
            :
            <div>
              <button
                disabled={props.disabled}
                id="attachment"
                type="button"
                // style={{ width: "209px", height: "40px" }}
                className={classes.docButton}
                name="image"
                onClick={() => openAttachment()}
              >
                {!props.disabled ? (
                  <PlusOutlined width={32} />
                ) : (
                  <LoadingOutlined style={{ fontSize: 24 }} spin />
                )}
              </button>
            </div>
        }
      </div>
      <div>
        <Modal centered footer={false} closeIcon={<img src={closeIcon} alt="Cross Icon" />} className={"certificateUploadModal"} title={false} visible={isModalOpen} onCancel={() => setIsModalOpen(false)}>
          <div className='loadingSection' style={{ textAlign: "center" }}>
            <img style={{ width: "200px" }} src={loadingGif} alt="Loading" />
            <p style={{ textAlign: "center" }}>Please wait. The license is being loaded..</p>
          </div>
        </Modal>
      </div>
    </div>
  )
}
export default UploadFiles
