import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'

import classes from './BuyerDetails.module.scss'

import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import axios from 'axios'
import InputElement from '../../../../constant/public/Input/InputElement'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import {
  userLoggedIn,
  userDataStatus,
} from '../../../../redux/actions/user.action'
import { NotificationWithIconComp } from '../../../../constant/Notification'

const BuyerDetails = (props) => {
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)
  const [disable, setDisable] = useState(true)


  const handleSignUp = () => {
    props.Formik.handleSubmit();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        name: props.Formik.values.name,
        phone: props.Formik.values.phone,
        email: props.Formik.values.email,
        password: props.Formik.values.password,
        userType: 'buyer',
      })
      .then((res) => {
        dispatch(userDataStatus(res.data.user))
        dispatch(userLoggedIn())
        // props.userDataStatus(userData)
        sessionStorage.setItem('token', res.data.tokens.access.token)
        sessionStorage.setItem('refresh', res.data.tokens.refresh.token)
        setLoader(false)
        props.setCurrentView('postSignUp')
      })
      .catch((err) => {
        console.log(err);
        NotificationWithIconComp("error", "Oops", err.response?.data?.message)
        // props.Formik.setFieldError("email", err.response?.data?.message)
        setLoader(false)
      })
  }

  return (
    <>
      <div className={classes.sideTitle}>
        <h2>SIGN UP</h2>
        <h4>SIGN UP</h4>
      </div>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <h3>Name*</h3>
          <InputElement
            type="text"
            name="name"
            placeholder="Name*"
            value={props.Formik.values.name}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.name && props.Formik.touched.name
                ? props.Formik?.errors.name
                : null
            }
            width={'100%'}
          />
          <h3>Email*</h3>
          <InputElement
            type="text"
            name="email"
            placeholder="Email*"
            value={props.Formik.values.email}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.email && props.Formik.touched.email
                ? props.Formik?.errors.email
                : null
            }
            width={'100%'}
          />
          <h3>Phone Number (Optional)</h3>
          <InputElement
            type="text"
            name="phone"
            placeholder="+1 1231231234"
            value={props.Formik.values.phone}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.phone && props.Formik.touched.phone
                ? props.Formik?.errors.phone
                : null
            }
            width={'100%'}
          />
          <h3>Password*</h3>
          <InputElement
            type="password"
            name="password"
            value={props.Formik.values.password}
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            placeholder="Password"
            helperText={
              props.Formik.errors.password && props.Formik.touched.password
                ? props.Formik?.errors.password
                : null
            }
            width={'100%'}
          />
          <h3>Confirm the Password*</h3>
          <InputElement
            name="confirmPassword"
            type="password"
            placeholder="Password"
            onChange={props.Formik.handleChange}
            onBlur={props.Formik.handleBlur}
            helperText={
              props.Formik.errors.confirmPassword &&
                props.Formik.touched.confirmPassword
                ? props.Formik?.errors.confirmPassword
                : null
            }
            value={props.Formik.values.confirmPassword}
            width={'100%'}
          />
        </div>
        <Row>
          <Col lg={12} md={12} sm={0} xs={0}></Col>
          <Col lg={12} md={12} sm={24} xs={24} align="right">
            <div className={classes.actionButton}>
              {
                props.Formik.errors.name ||
                  props.Formik.errors.phone ||
                  props.Formik.errors.email ||
                  props.Formik.errors.password ||
                  props.Formik.errors.confirmPassword
                  ?
                  <div className={classes.disableButton}>
                    <ButtonWithRightArrow
                      disabled={true}
                      function={() => handleSignUp()}
                      content="SIGN UP"
                      width={'325px'}
                    />
                  </div>
                  :
                  <ButtonWithRightArrow
                    disabled={!disable}
                    function={() => handleSignUp()}
                    content="SIGN UP"
                    width={'325px'}
                  />
              }
              <h4>
                By submitting this form, you acknowledge that you have read and
                agree to our{' '}
                <Link to="/terms-conditions">Terms of Service </Link>
                and <Link to="/privacy-policy">Privacy Policy</Link>.
              </h4>
              <h4>
                Already have an account? <Link to="/login">Sign In</Link>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    singleQuote: state,
  }
}

export default connect(mapStateToProps)(BuyerDetails)

// {isQuoteCompleted ? (
//   <>
//     <h3>
//       <span>Sign Up</span> to submit a quote request!
//     </h3>
//     {/* <h5>Create your sign in credentials.</h5>
// <p>(*Required field)</p> */}
//     <Form layout="vertical">
//       <Form.Item label="*Name">
//         <Input
//           onChange={(e) => setName(e.target.value)}
//           value={name}
//           size="large"
//           placeholder="Full Name..."
//         />
//       </Form.Item>

//       <Form.Item label="*Email">
//         <Input
//           onChange={(e) => setEmail(e.target.value)}
//           value={email}
//           size="large"
//           placeholder="Email address..."
//         />
//       </Form.Item>
//       <Form.Item label="Phone">
//         <InputNumber
//           onChange={(e) => setPhone(e)}
//           value={phone}
//           style={{ width: '100%' }}
//           size="large"
//           placeholder="Phone..."
//         />
//       </Form.Item>
//       <Form.Item
//         label="*Password"
//         validateStatus={errorFeedback}
//         hasFeedback={errorStatus}
//         help={errorMessage}
//       >
//         <Input.Password
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           type="password"
//           size="large"
//           placeholder="Password..."
//         />
//       </Form.Item>
//       <Form.Item
//         validateStatus={errorFeedback}
//         hasFeedback={errorStatus}
//         help={errorMessage}
//         label="*Re-enter Password"
//       >
//         <Input.Password
//           value={confirmPassword}
//           onChange={(e) => setConfirmPassword(e.target.value)}
//           size="large"
//           placeholder="Re-enter password..."
//         />
//       </Form.Item>
//       <div className={classes.footerContent}>
//         <h3>
//           {/* *Passwords must contain a combination of letters, numbers and{' '} */}
//           <br />
//           {/* special characters (ex. @). Please use at least (1) capital
//       letter. */}
//         </h3>
//         <button
//           onClick={() => handleSignUp()}
//           className={classes.buttonLogin}
//         >
//           {loader ? (
//             <Spin style={{ marginBottom: '24px' }} />
//           ) : (
//             <h6>Done</h6>
//           )}
//         </button>
//         <h5>
//           You acknowledge that you have read and agree <br /> to our{' '}
//           <Link to="/terms-conditions">Terms of Service</Link> and{' '}
//           <Link to="/privacy-policy">Privacy Policy</Link>.
//         </h5>
//       </div>
//     </Form>
//   </>
// ) : (
//   <>
//     <h3>
//       <span>Thank you!</span>
//     </h3>
//     <h5>
//       Thank you for being a part of the Uplio family. We will be in
//       touch with you very soon. Keep an eye on your inbox for our
//       “next steps” email.
//     </h5>
//     {/* <p>(*Required field)</p> */}
//     <Row gutter={12}>
//       <Col span={12} xs={24}>
//         <button
//           onClick={() => {
//             history.push({ pathname: '/' })
//           }}
//           className={classes.buttonLoginBack}
//         >
//           <h6>Back to Home page</h6>
//         </button>
//       </Col>
//       <Col span={12} xs={24}>
//         <button
//           onClick={() => {
//             history.push({ pathname: '/quote' })
//           }}
//           className={classes.buttonLogin}
//         >
//           <h6>Request a new quote</h6>
//         </button>
//       </Col>
//     </Row>
//   </>
// )}
