import { Select } from 'antd';
import React, { useState } from 'react'
import classes from "./SortSelect.module.scss"
import downArrow from "../../../assets/images/downArrow.svg"


const { Option } = Select;

const SortSelect = ({ setValue, optionsData }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.sortDropdownSection}>
            <Select
                className={`${classes.sortSelect} sortSelect`}
                // defaultValue={optionsData[0]?.value}
                bordered={false}
                placement="bottomLeft"
                onClick={() => setOpen(!open)}
                onBlur={() => setOpen(false)}
                onSelect={() => setOpen(!open)}
                dropdownClassName="sortSelect"
                onChange={setValue}
                suffixIcon={!open ? <img src={downArrow} alt="Down Arrow" /> : <img src={downArrow} className={classes.downArrow} alt="Down Arrow" />}
            >
                {
                    optionsData && optionsData?.length > 0 && optionsData?.map(opt => {
                        return (
                            <Option value={opt.value}>{opt.label}</Option>
                        )
                    })
                }
                {/* <Option value="price:asc">$ Low to High</Option>
                <Option value="price:desc">$ High to Low</Option> */}
            </Select>
        </div>
    )
}

export default SortSelect
