import React, { useEffect, useState } from 'react'
import Navigation from '../../../constant/public/Navigation/Navigation';
import Footer from '../../../constant/public/Footer/Footer'
import classes from "./Cart.module.scss";
import CartItem from '../../../constant/public/CartItem/CartItem';
import RecentlyViewed from '../../../constant/public/RecentlyViewed/RecentlyViewed';
import OrderSteps from '../../../constant/public/OrderSteps/OrderSteps';
import AuthPopup from './AuthPopup/AuthPopup';
import { useHistory } from 'react-router-dom';

const Cart = () => {
    const [products, setProducts] = useState([]);
    const [groupedProducts, setGroupedProducts] = useState([]);

    const history = useHistory()

    useEffect(() => {
        setProducts(JSON.parse(localStorage.getItem("products")))
        productGrouping(JSON.parse(localStorage.getItem("products")));
        return () => {

        }
    }, []);

    const productGrouping = (products) => {
        const groupByUser = products && products.length > 0 && products?.reduce((acc, product) => {
            const { name, _id, estimatedLeadTimeProduction } = product.user;
            if (!acc[name]) {
                acc[name] = { name, _id, estimatedLeadTimeProduction, products: [product] };
            } else {
                acc[name].products.push(product);
            }
            return acc;
        }, {});
        setGroupedProducts(Object.values(groupByUser))
        localStorage.setItem("productGrouping", JSON.stringify(groupedProducts));
    }



    const handleUpdateQty = (productId, newQuantity) => {
        let prevProducts = products?.map(product => {
            if (product.uuid === productId) {
                return { ...product, quantity: newQuantity };
            }
            return product;
        })
        setProducts(prevProducts);
        productGrouping(prevProducts);
        localStorage.setItem("products", JSON.stringify(prevProducts));
    };

    const handleUpdateComment = (productId, newComment) => {
        console.log(productId, newComment)
        let prevProducts = products?.map(product => {
            if (product.uuid === productId) {
                return { ...product, comment: newComment };
            }
            return product;
        })
        setProducts(prevProducts);
        productGrouping(prevProducts);
        localStorage.setItem("products", JSON.stringify(prevProducts));
    };

    const handleRemoveProduct = (productId) => {
        let prevProducts = products?.filter(product => product.uuid !== productId)
        setProducts(prevProducts);
        productGrouping(prevProducts);
        localStorage.setItem("products", JSON.stringify(prevProducts))
        if (prevProducts.length === 0) {
            history.push({ pathname: '/browse' })
        }
    };


    return (
        <>
            <Navigation />
            <div className={`${classes.cart} container`}>
                <div className={classes.topBar}>
                    <div>
                        <h4>Your Cart</h4>
                        <h5>{products?.length} Items</h5>
                    </div>
                    <div className={classes.breadcrump}>

                    </div>
                </div>
                <div className={classes.steps}>
                    <OrderSteps active={1} />
                </div>
                <div className={classes.main}>
                    <div className={classes.inner}>
                        <div className={classes.left}>
                            {
                                groupedProducts && groupedProducts?.length > 0 && groupedProducts?.map(user => {
                                    return (
                                        <>
                                            <h4 className={classes.supplierTitle}>{user.name}</h4>
                                            <div className={classes.itemsContainer}>
                                                {
                                                    user.products && user.products?.length > 0 && user.products?.map(product => {
                                                        return (
                                                            <CartItem product={product} handleUpdateQty={handleUpdateQty} handleUpdateComment={handleUpdateComment} handleRemoveProduct={handleRemoveProduct} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className={classes.priceContainer}>
                                                <p>Total</p>

                                                <h5>Cost: ${(user?.products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0)).toFixed(2)}</h5>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={classes.right}>
                        <div>
                            <h4>Order Summary</h4>
                            <div className={classes.summaryItem}>
                                <p>Subtotal</p>
                                <p>${(products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0).toFixed(2))}</p>
                            </div>
                            {/* <div className={classes.summaryItem}>
                                <p>Taxes</p>
                                <p>$0.00</p>
                            </div> */}
                            <div className={`${classes.summaryItem} ${classes.total}`}>
                                <p>Total</p>
                                <p>${(products?.reduce((a, b) => a + parseInt(b?.price) * parseInt(b?.quantity), 0) + 0).toFixed(2)}</p>
                            </div>
                            <AuthPopup />
                        </div>
                    </div>
                </div>
                <div className={classes.RecentlyViewed}>
                    <RecentlyViewed cartPage={true} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Cart
