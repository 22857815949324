import React, { useEffect, useState } from 'react'
import Navigation from '../../../constant/public/Navigation/Navigation'
import Footer from '../../../constant/public/Footer/Footer'

import AccountType from './AccountType/AccountType'
import BuyerDetails from './BuyerDetails/BuyerDetails'
import Greetings from './Greetings/Greetings'
import { useFormik } from 'formik'
import PostSignUp from './PostSignUp/PostSignUp'
import * as yup from 'yup'
import { ref } from 'yup'
import SupplierDetails from './SupplierDetails/SupplierDetails'
import { useSelector } from 'react-redux'

const SignUp = () => {
  const [currentView, setCurrentView] = useState('accountType')
  const [accountType, setAccountType] = useState('buyer')

  const Formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      name: '',
      businessName: '',
    },
    validationSchema: yup.object({
      businessName: yup
        .string()
        .required('Business name is required.')
        .matches(
          /^[A-Za-z0-9 ]+$/,
          'Special Characters are not allowed.'
        )
        .min(2, 'Name should be more then 2 Characters')
        .max(50, 'Name should be less then 50 Characters'),
      name: yup
        .string()
        .required('Name is required.')
        .matches(
          /^[A-Za-z ]+$/,
          'Numbers and Special Characters are not allowed.'
        )
        .min(2, 'Name should be more then 2 Characters')
        .max(50, 'Name should be less then 50 Characters'),
      email: yup.string().email('Invalid email.').required('Email is Required'),
      phone: yup
        .string()
        .matches(
          // /^\+\d\s(?!0{2})\d{3}\(?!0{2})\d{3}\(?!0{3})\d{4}$/,
          /^\+\d\s(?!0{2})\d{3}(?!0{2})\d{3}(?!0{3})\d{4}$/,
          'Please enter valid phone number. Format: +X XXXXXXXXXX'
        )
        .max(15, 'Phone can only be upto 15 Characters'),
      password: yup
        .string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
        //   'Invalid Format'
        // )
        .required('Password is required.'),
      confirmPassword: yup
        .string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&{}\[\]()])[\S]*$/,
        //   'Invalid Format'
        // )
        .oneOf([ref('password')], 'Passwords do not match')
        .required('Password is required.'),
    }),
    onSubmit: (values) => {
      // handleProfileUpdated
      alert(JSON.stringify(values, null, 2));
    },
  })


  const { setFieldValue, values } = Formik
  const quote = useSelector((state) => state.singleQuote)

  useEffect(() => {
    if (quote.quote !== null) {
      setFieldValue('email', quote.quote.email)
      setFieldValue('name', quote.quote.name)
    }
  }, [])

  const handleSignUpView = () => {
    if (currentView === 'accountType') {
      return <AccountType Formik={Formik} setCurrentView={setCurrentView} />
    } else if (currentView === 'userDetails') {
      return (
        <BuyerDetails
          setAccountType={setAccountType}
          Formik={Formik}
          setCurrentView={setCurrentView}
        />
      )
    } else if (currentView === 'supplierDetails') {
      return (
        <SupplierDetails
          setAccountType={setAccountType}
          Formik={Formik}
          setCurrentView={setCurrentView}
        />
      )
    } else if (currentView === 'greetings') {
      return <Greetings Formik={Formik} setCurrentView={setCurrentView} />
    } else if (currentView === 'postSignUp') {
      return <PostSignUp setCurrentView={setCurrentView} />
    }
  }


  return (
    <>
      <Navigation />
      {handleSignUpView()}
      <Footer />
    </>
  )
}
export default SignUp
