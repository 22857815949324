import React from 'react'
import { useHistory } from 'react-router-dom'
import classes from "./OrderComplete.module.scss"
import Navigation from '../../../constant/public/Navigation/Navigation'
import completed from "../../../assets/svg/order-complete.svg"
import cross from "../../../assets/svg/Cross.svg"
import Footer from '../../../constant/public/Footer/Footer'
import ButtonWithRightArrow from '../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import { useSelector } from 'react-redux'


const OrderComplete = () => {
  const history = useHistory();
  const userLoggedInState = useSelector((state) => state.userLoggedIn)
  const user = useSelector((state) => state.user.user)

  return (
    <div className={classes.OrderCompleteContainer}>
      <Navigation />
      <button className={classes.crossBtn} onClick={() => history.push("/browse")}>
        <img src={cross} alt="Cross" />
      </button>
      <div className={`${classes.OrderComplete} container`}>
        <div>
          <img className={classes.mainImg} src={completed} alt="Completed" />
        </div>
        <div>
          <h2>THANK YOU FOR THE ORDER!</h2>
          {
            user !== null && user !== undefined ?
              <p className={classes.para}>You can check your order status in your dashboard</p>
              :
              <p className={classes.para}>SUGGEST YOU SIGN IN TO YOUR ACCOUNT TO CHECK YOUR ORDER</p>
          }
          <div className={classes.btn}>
            {
              user !== null && user !== undefined ?
                <button
                  onClick={() => history.push({ pathname: '/dashboard/buyer/orders' })}
                >
                  Go to my orders
                </button>
                :
                <ButtonWithRightArrow
                  content="SIGN IN"
                  function={() => history.push({ pathname: '/login' })}
                />
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default OrderComplete
