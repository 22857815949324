import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import classes from "../Payment.module.scss"
import arrow from "../../../../../../../assets/svg/Arrow_indicator_left_black.svg"
import clsx from "clsx";
import "./CheckoutForm.css"
import { OpenNotificationWithIcon } from "../../../../../../../constant/public/Notification/Notification";


export default function CheckoutForm(props) {
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const token = sessionStorage.getItem('token');

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const addOrder = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}/invoice/${props.invoiceId}/payments/${props.paymentId}/confirm`,
                {
                    amount: ""

                }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // if (res.status === 201) {
                history.push("/dashboard/buyer/payment-complete");
                // }
            })
            .catch((err) => {
                console.log(err.response)
                console.log(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            props.clientSecret
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            console.log(paymentIntent)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    props.onChangeStep(5);
                    OpenNotificationWithIcon('success', 'Payment succeeded!', "");
                    // addOrder();
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    console.log(message);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: window.location.href,
            },
        });

        if (!error) {
            addOrder();
        }

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error?.type === "card_error" || error?.type === "validation_error") {
            setMessage(error?.message);
            OpenNotificationWithIcon('error', '', error?.message);
        } else if (error && error.type) {
            setMessage("An unexpected error occurred.");
            OpenNotificationWithIcon('error', '', "An unexpected error occurred.");
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    }



    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>
                <LinkAuthenticationElement
                    id="link-authentication-element"
                    onChange={(e) => setEmail(e.value.email)}
                />
                <PaymentElement className="payment-element" id="payment-element" options={paymentElementOptions} />
                <div className={classes.btnContainer}>
                    <button type="button" onClick={() => history.push("/dashboard/buyer/invoices")}>
                        <img src={arrow} alt="Arrow" />
                        <span>PREVIOUS</span>
                    </button>
                    <button
                        disabled={isLoading || !stripe || !elements}
                        className={clsx(['button', classes.saveButton])}
                        type="submit"
                    >
                        Pay Invoice
                    </button>
                </div>
            </form>
        </>
    );
}