import { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import ScrollToTop from '../../hooks/ScrollToTop'
import Aboutus from '../../components/Public/Aboutus/Aboutus.js'
import Career from '../../components/Public/Career/Career.js'
import Helpcenter from '../../components/Public/Helpcenter/Helpcenter.js'
import Homepage from '../../components/Public/Homepage/Homepage.js'
import Newsroom from '../../components/Public/Newsroom/Newsroom.js'
import SignUp from '../../components/Auth/SignUp/SignUp.js'
import SignIn from '../../components/Auth/SignIn/SignIn.js'

import SupplierProfile from '../../components/Public/SupplierProfile/SupplierProfile.js'
import Products from '../../components/Public/Products/Products'
import Quote from '../../components/Public/Quote/Quote.js'
import ForgotPassword from '../../components/Auth/ForgotPassword/ForgotPassword.js'
import Overview from '../../components/Admin/Supplier/Overview/Overview'

import PrivacyPolicy from '../../components/Public/PrivacyPolicy/PrivacyPolicy.js'
import FrequentlyAskedQuestion from '../../components/Public/FrequentlyAskedQuestion/FrequentlyAskedQuestion.js'
import TermsAndConditions from '../../components/Public/TermsAndConditions/TermsAndConditions'
import BuyerMyInquiries from '../../components/Admin/Buyer/MyInquiries/MyInquiries'
import BuyerMyQuote from '../../components/Admin/Buyer/MyQuote/MyQuote'
import BuyerDashboardProfilePaymentMethod from '../../components/Admin/Buyer/PaymentMethod/PaymentMethod'
import BuyerDashboardProfileShippingAddress from '../../components/Admin/Buyer/ShippingAddress/ShippingAddress'
import BlogListing from '../../components/Public/Blog/BlogListing/BlogListing'
import Blog from '../../components/Public/Blog/Blog/Blog'
import Profile from '../../components/Admin/Supplier/Profile/Profile'
import ComingSoon from '../../components/Public/ComingSoon/ComingSoon'
import ProducDetails from '../../components/Admin/Supplier/ProductDetail/ProductDetail.js'
import BrowseProducts from '../../components/Public/BrowseProducts/BrowseProducts'
import PublicProductDetail from '../../components/Public/ProductDetail/ProductDetail'
import Cart from '../../components/Public/Cart/Cart'
import Shipping from '../../components/Public/Shipping/Shipping'
import Payment from '../../components/Public/Payment/Payment'
import Summary from '../../components/Public/Summary/Summary'
import OrderComplete from '../../components/Public/OrderComplete/OrderComplete'
import BuyerProfile from '../../components/Admin/Buyer/Profile/Profile'
import Messages from '../../components/Admin/Supplier/Messages/Messages'


const PublicRoutes = () => (
  <Suspense fallback={<p>Loading ..</p>}>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/about" component={Aboutus} />
          <Route exact path="/newsroom" component={Newsroom} />
          <Route exact path="/careers" component={Career} />
          <Route exact path="/help-center" component={Helpcenter} />
          <Route exact path="/blog" component={BlogListing} />
          <Route exact path="/blog/:id" component={Blog} />

          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/shipping" component={Shipping} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/summary" component={Summary} />
          <Route exact path="/order-complete" component={OrderComplete} />

          {/* TODO remove link  */}
          <Route
            exact
            path="/dashboard/message"
            component={Messages}
          />
          <Route
            exact
            path="/dashboard/supplier/:profileTab"
            component={Profile}
          />
          {/* <Route
            exact
            path="/dashboard/supplier/add-product"
            component={AddProduct}
          /> */}
          <Route
            exact
            path="/dashboard/supplier/profile/overview/"
            component={Overview}
          />
          <Route
            exact
            path="/dashboard/supplier/profile/productDetails/"
            component={ProducDetails}
          />
          {/* <Route
            exact
            path="/dashboard/buyer/inquiries"
            component={BuyerMyInquiries}
          /> */}
          <Route
            exact
            path="/dashboard/buyer/quote/:id"
            component={BuyerMyQuote}
          />
          <Route
            exact
            path="/dashboard/buyer/:profileTab"
            component={BuyerProfile}
          />

          <Route
            exact
            path="/dashboard/buyer/profile/payment-method"
            component={BuyerDashboardProfilePaymentMethod}
          />
          <Route exact path="/coming-soon" component={ComingSoon} />
          <Route
            exact
            path="/dashboard/buyer/profile/shipping-address"
            component={BuyerDashboardProfileShippingAddress}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />

          <Route exact path="/faq" component={FrequentlyAskedQuestion} />
          <Route
            exact
            path="/terms-conditions"
            component={TermsAndConditions}
          />

          <Route exact path="/profile/:id" component={SupplierProfile} />
          <Route exact path="/products/:id" component={Products} />
          <Route exact path="/browse" component={BrowseProducts} />
          <Route
            exact
            path="/product/:id"
            component={PublicProductDetail}
          />
          <Route exact path="/quote/:id" component={Quote} />

          {/* <Route
            exact
            path="/dashboard/supplier/lead"
            component={SupplierDashboard}
          />
          <Route
            exact
            path="/dashboard/supplier/direct-lead"
            component={SupplierDashboardDirectLead}
          />
          <Route
            exact
            path="/dashboard/supplier/message"
            component={SupplierDashboardMessage}
          />

          <Route
            exact
            path="/dashboard/supplier/services"
            component={SupplierDashboardService}
          />
          <Route
            exact
            path="/dashboard/supplier/orders"
            component={SupplierDashboardOrders}
          /> */}
          {/* <Route
            exact
            path="/dashboard/supplier/profile"
            component={SupplierDashboardProfile}
          /> */}
          <Route exact path="/change-password" component={ForgotPassword} />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </Router>
  </Suspense>
)

export default PublicRoutes
