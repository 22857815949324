import React, { useEffect, useState } from 'react'
import "./CertificateShow.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import "./CertificateShow.css"

// Import Swiper styles
import "swiper/css";
import { Modal } from 'antd';

const CertificateShow = ({ images, imagesArray, name, index }) => {
    const [swiper, setSwiper] = useState(null);
    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setCurrent(0)

        return () => {

        }
    }, []);
    return (
        <div className='CertificateShow'>
            {
                <a onClick={showModal}>{name}{imagesArray?.length > index + 1 && ","}</a>
            }
            <Modal className='CertificateShowModal' title={false} visible={isModalOpen} footer={false} onCancel={handleCancel}>

                {
                    <Swiper
                        onSwiper={setSwiper}
                        onSlideChange={(e) => setCurrent(e.realIndex)}
                        slidesPerView={1}
                        loop={true}
                        dots={false}
                        centeredSlides={false}
                        className="mySwiper"
                    >
                        <SwiperSlide >
                            <h4>{name}</h4>
                            <img src={images} alt="images" />
                        </SwiperSlide>

                    </Swiper>
                }
            </Modal>
        </div>
    )
}

export default CertificateShow
