import React, { useState, useEffect, useRef } from 'react'
import classes from './Overview.module.scss'
// import classes from '../CompanyRow/CompanyRow.module.scss'
import verified_tag from '../../../../assets/svg/verified_tag.svg'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import rightArrow from '../../../../assets/svg/Arrow_indicator_left_black.svg'
import leftArrow from '../../../../assets/svg/Arrow_indicator_right_black.svg'
import ButtonWithRightArrow from '../../../../constant/public/ButtonWithRightArrow/ButtonWithRightArrow'
import supplierLogo from '../../../../assets/png/supplierlogo.png'
import sullpierImage from '../../../../assets/images/supplierimage1.jpg'
import sampleImage from '../../../../assets/images/sample_logo_img.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation as SwiperNavigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import { Spin } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'

import Navigation from '../../../../constant/public/Navigation/Navigation'
import Footer from '../../../../constant/public/Footer/Footer'
import { useSelector, useDispatch } from 'react-redux'
// import { singleQuoteData } from '../../../../redux/actions/singleQuote.action'

const Overview = () => {
  const history = useHistory()
  const user = useSelector((state) => state.supplierProfile.user)
  const supplierData = useSelector((state) => state.supplierProfile.user)
  useEffect(() => {
    if (user !== undefined && user !== null) {
      return
    } else {
      history.push({ pathname: '/' })
    }
  }, [user])

  const [loading, setLoading] = useState(false)
  const [swiper, setSwiper] = useState(null)
  const [swiperThumbs, setSwiperThumbs] = useState(null)
  const [realIndex, setRealIndex] = useState(0)

  useEffect(() => {
    if (swiper) {
      setRealIndex(swiper.realIndex)
    }
  }, [swiper])

  // const handleQuote = () => {

  //   dispatch(
  //     singleQuoteData({
  //       productCategory: supplierData.uCategory[0].name,
  //     })
  //   )

  //   history.push({
  //     pathname: `/quote/${id}`,
  //   })
  // }

  const handleSlideChange = (swiper) => {
    const lastMainSlide = swiper.slides.length - 1

    if (swiperThumbs !== null) {
      const additionalSwiper = swiperThumbs

      const activeIndex = swiper.activeIndex
      const prevIndex = swiper.previousIndex

      // console.log(activeIndex, prevIndex, lastMainSlide);

      if (prevIndex === 0 || prevIndex === lastMainSlide) {
        return
      }

      if (activeIndex > prevIndex) {
        additionalSwiper.slideNext()
      } else {
        additionalSwiper.slidePrev()
      }
    }
  }

  const dispatch = useDispatch()

  return (
    <>
      <Navigation />
      <div className={classes.topLine}>
        <div className={classes.container}>
          <div className={classes.topLineWrapper}>
            <Link
              className={classes.topLineBack}
              to={'/dashboard/supplier/profile'}
            >
              <span>Back to profile setup</span>
            </Link>
            <h2 className={classes.topLineText}>
              What your profile looks like
            </h2>
          </div>
        </div>
      </div>
      {!loading ? (
        <>
          <div className={clsx(classes.container, classes.main)}>
            <div className={classes.companyDetails}>
              <img
                width={48}
                height={48}
                className={classes.logoImg}
                src={supplierData.companyLogo}
                alt="uplio"
              />
              <div className={classes.detailsWrapper}>
                <h1>{user.companyName}</h1>
                <h4>
                  <img src={verified_tag} alt="Uplio" />
                  Verified Supplier
                </h4>
              </div>
            </div>

            {!loading ? (
              <div className={classes.mainSection}>
                <div className={classes.slider}>
                  <div className={classes.sliderMain}>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={24}
                      onSwiper={(swiper) => setSwiper(swiper)}
                      onSlideChange={(swiper) => {
                        setRealIndex(swiper.realIndex)
                        handleSlideChange(swiper)
                      }}
                      loop={true}
                    >
                      {user.pastProjectImages.map((item, idx) => {
                        return (
                          // TODO: delete idx from key if there's render with data
                          <SwiperSlide key={item + idx}>
                            <img
                              width={560}
                              height={420}
                              src={item !== undefined ? item : null}
                              alt="uplio"
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                  <div className={classes.sliderThumbs}>
                    <Swiper
                      direction={'vertical'}
                      slidesPerView={2}
                      spaceBetween={24}
                      loop={true}
                      onSwiper={(swiper) => setSwiperThumbs(swiper)}
                      initialSlide={1}
                      slideToClickedSlide={false}
                      allowTouchMove={false}
                      breakpoints={{}}
                    >
                      {user.pastProjectImages.map((item, idx) => {
                        return (
                          // TODO: delete idx from key if there's render with data
                          // <SwiperSlide key={item}>
                          <SwiperSlide key={item + idx}>
                            <img
                              width={264}
                              src={item !== undefined ? item : null}
                              alt="uplio"
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
                <div className={classes.quoteButton}>
                  <ButtonWithRightArrow
                    // function={() => handleQuote()}
                    content="GET A QUOTE"
                  />
                  <button type="button" className={classes.contactBtn}>
                    Contact supplier
                  </button>
                </div>
                <div className={classes.actionButton}>
                  <div className={classes.slidesCount}>
                    <span>{realIndex + 1}</span>/
                    <span>{swiper && swiper.slides.length - 2}</span>
                  </div>
                  <div className={classes.actionButtonBlock}>
                    <button
                      onClick={() => {
                        swiperThumbs.slidePrev()
                        swiper.slidePrev()
                      }}
                    >
                      <img src={leftArrow} alt="Uplio" />
                    </button>
                    <button
                      onClick={() => {
                        swiperThumbs.slideNext()
                        swiper.slideNext()
                      }}
                    >
                      <img src={rightArrow} alt="Uplio" />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              className={clsx(
                classes.supplierSection,
                classes.supplierSectionTopBorder
              )}
            >
              <h2>About Supplier</h2>
              <h3>
                Categories:{' '}
                <strong>
                  {/* {supplierData.uCategory.map((item) => item.name)} */}
                  Apparel, Textile
                </strong>
              </h3>
              <h3>
                Production capacity:{' '}
                <strong>
                  {/* {supplierData.productionCapacity} */}
                  {user.productionCapacity}
                  units/per month
                </strong>
              </h3>
              <h3>
                Minimum Order:{' '}
                <strong>{user.minimumOrderQuantity} units</strong>
              </h3>
              <h3>
                Est. Leadtime: <strong>{user.estimatedLeadTimeSample}</strong>
              </h3>
              <h3>
                Established: <strong>{user.yearEstablished}</strong>
              </h3>
              <h3>
                Location:{' '}
                <strong>
                  {/* {supplierData.city}, {supplierData.country} */}
                  {user.state}, {user.country}
                </strong>
              </h3>
              {/* <h3>
                Response Rate: <strong>Usually responds within 3 hours</strong>
              </h3> */}
              {/* <h3>Response Rate:</h3> */}
            </div>

            <div
              className={clsx(
                classes.supplierSection,

                classes.supplierSectionTopBorder
              )}
            >
              <h2>Overview</h2>
              <p>{user.companyOverview}</p>
            </div>
            <div
              className={clsx(
                classes.supplierSection,
                classes.supplierSectionTopBorder,
                classes.supplierSectionBottomBorder
              )}
            >
              <h2>Specialization</h2>

              <ul className={classes.spezializationList}>
                {user.specialization.split(',').map((item) => (
                  <li>
                    <Link to={`/products/${item}`}>{item}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <div className={classes.spin}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default Overview
