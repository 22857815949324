import React from 'react'
import classes from './Respond.module.scss'
import { Link } from 'react-router-dom'
import icon from '../../../../../assets/png/supplierlogo.png'
import ButtonElement from '../../../../../constant/public/Button/ButtonElement'
import { useHistory } from 'react-router-dom'

const Respond = ({ data, quoteSuppliers, qouteSupplierIndex, selectedQuote }) => {
  const history = useHistory()

  console.log(data)
  return (
    <div className={classes.respond}>
      {/* <Link to="/"> */}
      <h3 className={classes.title}>
        <img wdith={24} height={24} src={data?.supplier?.profileImage ? data?.supplier?.profileImage : icon} alt="supplier avatar" />
        {/* <span>Supplier {qouteSupplierIndex}</span> */}
        <span>{data?.supplier?.firstname} {data?.supplier?.lastname}</span>
      </h3>
      {/* </Link> */}
      <ul className={classes.respondInfo}>
        <li className={classes.respondInfoItem}>
          <span>Unit Price Range:</span>
          <p>${data?.minPrice} - {data.maxPrice}</p>
        </li>
        <li className={classes.respondInfoItem}>
          <span>MOQ:</span>
          <p>{data?.supplier?.MOQ}</p>
        </li>
        <li className={classes.respondInfoItem}>
          <span>Lead Time:</span>
          <p>{data?.supplier?.estimatedLeadTimeProduction}</p>
        </li>
      </ul>
      <div className={classes.messageBtnWrapper}>
        <ButtonElement
          content={'Message supplier'}
          function={() => history.push({ pathname: `/dashboard/message`, state: { targetUser: data?.supplier?._id, contextId: selectedQuote?._id, contextType: 1 } })}
          // function={() => history.push('/coming-soon')}
          width={'100%'}
        />
      </div>
    </div>
  )
}

export default Respond
