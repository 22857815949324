import React, { useEffect } from 'react'
import classes from './ProductAndServices.module.scss'
import axios from 'axios'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import bannerImg from "../../../../assets/svg/g10.svg"
import checkedCheckbox from "../../../../assets/svg/CheckedCheckbox.svg"
import cross from "../../../../assets/svg/crosswhite.svg"
import crossBlack from "../../../../assets/svg/Cross.svg"
import downArrow from "../../../../assets/images/downArrow.svg"
import { useHistory } from 'react-router-dom'
import ProductCard from './ProductCard/ProductCard'
import SortSelect from '../../../../constant/public/SortSelect/SortSelect'
import { Modal, Radio } from 'antd'
import "./ProductAndServices.css" 
import Loading from '../../../../constant/Loading/Loading'



const ProductAndServices = (props) => {
    const history = useHistory();
    const [originalProducts, setOriginalProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState("");
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState();
    const [categoryId, setCategoryId] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [sort, setSort] = useState("createdAt:desc");
    const [sortValue, setSortValue] = useState("");
    const [categories, setCategories] = useState([]);
    const token = sessionStorage.getItem('token');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const user = useSelector((state) => state.user.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [confirmationModel, setConfirmationModel] = useState(false);
    const showConfirmationModel = () => {
        setConfirmationModel(true);
        setIsModalOpen(false)
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleConfirmationCancel = () => {
        setConfirmationModel(false);
        setIsModalOpen(false)
    };

    const getAllProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/users/${user.id}/products?limit=1000&page=1`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setProducts(res.data.results);
                setOriginalProducts(res.data.results);
                setTotalProducts(res.data.totalResults)
                setLoading(false)

            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const getAllCategoriesAndCount = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/users/${user.id}/products/count`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async (res) => {
                setLoading(false);
                setCategories(res.data.categories);
                setProductsCount(res.data.totalProducts);
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        if (user !== null && user !== undefined) {
            setLoading(true);
            setCategoryId("")
            getAllProducts();
            getAllCategoriesAndCount();
        }
    }, []);

    const handleUpdate = async (value) => {
        if (selectedProducts.includes(value)) {
            setSelectedProducts(selectedProducts.filter((i) => i !== value));
        } else {
            setSelectedProducts([...selectedProducts, value]);
        }
    };


    const sortOptionsData = [
        {
            label: "New",
            value: "createdAt:desc"
        },
        {
            label: "$ Low to High",
            value: "price:asc"
        },
        {
            label: "$ High to Low",
            value: "price:desc"
        },
    ]

    const handleDelete = () => {
        showModal();
    }

    const deleteHandler = () => {
        handleDeleteProducts();
    }

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/users/${user?.id}/products?limit=1000&page=1&sortBy=${sort}&category=${categoryId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setProducts(res.data.results);
                setOriginalProducts(res.data.results);
                setTotalProducts(res.data.totalResults)

            })
            .catch((err) => {
                console.log(err)
            })

        return () => {

        }
    }, [sort, categoryId]);


    // const handleCategoryFilter = (val) => {
    //     if (val === "all") {
    //         setProducts(products.filter(f => f.category === val));
    //     }
    // }

    // useEffect(() => {
    //   handf

    //   return () => {
    //     second
    //   }
    // }, [third])



    const handleDuplicateProducts = (userId, prodId) => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/users/${user._id}/products/${prodId}/duplicate`, {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                getAllProducts();
                getAllCategoriesAndCount();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDeleteProducts = () => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/users/${user._id}/products/delete`, { "products": selectedProducts },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                getAllCategoriesAndCount();
                getAllProducts();
                handleConfirmationCancel();
            })
            .catch((err) => {
                console.log(err);
            })
    }


    const handleRefresh = () => {
        getAllCategoriesAndCount();
        getAllProducts();
    }

    return (
        loading ?
        <div>
        <Loading />
      </div>
            :
            <div>
                <div className={`${classes.productsAndServices} productsAndServices`}>
                    <h5 className={classes.title}>my products</h5>
                    {
                        originalProducts && originalProducts?.length === 0 ?
                            <div className={classes.noProductsContainer}>
                                <div>
                                    <h2>
                                        oops! You don't <br /> have any product.
                                    </h2>
                                    <p>Create your first product!</p>
                                    <button onClick={() => history.push("/dashboard/supplier/add-product")}>add product</button>
                                </div>
                                <div>
                                    <img src={bannerImg} alt="Banner Img" />
                                </div>
                            </div>
                            :
                            <div className={classes.productsContainer}>
                                <div className={classes.menu}>
                                    <button onClick={() => { setActiveTab(""); setCategoryId("") }} className={activeTab === "" && classes.active}>All products ({productsCount})</button>
                                    {
                                        categories && categories?.map((cat) => {
                                            return (
                                                <button onClick={() => { setActiveTab(cat._id); setCategoryId(cat._id) }} className={activeTab === cat._id && classes.active}>{cat.name} ({cat.count})</button>
                                            )
                                        })
                                    }
                                </div>
                                <div className={`${classes.sortSection} ${classes.desktopSortSection}`}>
                                    <div className="egl-radio-with-check">
                                        <div className="eachRadio">
                                            <Radio
                                                className="radio-input"
                                                type="radio"
                                                onChange={(e) => console.log(e)}
                                                checked={selectedProducts.length === products.length}
                                                onClick={() => selectedProducts.length !== products.length ? setSelectedProducts(products.map(prod => prod._id)) : setSelectedProducts([])}
                                                value={"data._id"}
                                            >
                                                Select all products
                                            </Radio>
                                        </div>
                                    </div>
                                    <div className={classes.sortDropdownSection}>
                                        <SortSelect setValue={(val) => setSort(val)} optionsData={sortOptionsData} />
                                    </div>
                                </div>
                                <div className={classes.mobileSortSection}>
                                    <div>
                                        <div className="egl-radio-with-check">
                                            <div className="eachRadio">
                                                <Radio
                                                    className="radio-input"
                                                    type="radio"
                                                    onChange={(e) => console.log(e)}
                                                    checked={selectedProducts.length === products.length}
                                                    onClick={() => selectedProducts.length !== products.length ? setSelectedProducts(products.map(prod => prod._id)) : setSelectedProducts([])}
                                                    value={"data._id"}
                                                >
                                                    Select all products
                                                </Radio>
                                            </div>
                                        </div>
                                        <button className={classes.sortValueContainer} onClick={() => { setShowSort(!showSort) }}>
                                            <h4>Sort by:</h4>
                                            {/* <h4>{sortValue}</h4> */}
                                            <img
                                                src={downArrow}
                                                style={
                                                    !showSort
                                                        ? { transform: 'none' }
                                                        : { transform: 'rotate(180deg)' }
                                                }
                                                alt="Uplio"
                                            />
                                        </button>
                                    </div>
                                    {
                                        showSort &&
                                        <div className={classes.sortDropDownSection}>
                                            <button className={sort === "createdAt:desc" && classes.boldBtn} onClick={() => { setSort("createdAt:desc"); setSortValue("New") }}>New</button>
                                            <button className={sort === "price:asc" && classes.boldBtn} onClick={() => { setSort("price:asc"); setSortValue("$Lth") }}>$ Low to High</button>
                                            <button className={sort === "price:desc" && classes.boldBtn} onClick={() => { setSort("price:desc"); setSortValue("$Htl") }}>$ High to Low</button>
                                        </div>
                                    }
                                </div>
                                <div className={classes.productsList}>
                                    {
                                        products && products?.length > 0 && products.map((product) => {
                                            let findIndex = product?.images?.findIndex(f => f.cover === true);
                                            return (
                                                <div>
                                                    <ProductCard
                                                        data={product}
                                                        initialSlide={findIndex}
                                                        handleRefresh={handleRefresh}
                                                        handleUpdate={handleUpdate}
                                                        handleDeleteProducts={handleDeleteProducts}
                                                        handleDelete={handleDelete}
                                                        selectedProducts={selectedProducts}
                                                        handleDuplicateProducts={handleDuplicateProducts} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className={classes.footer}>
                                    <div className={classes.btnContainer}>
                                        <button onClick={() => history.push("/dashboard/supplier/add-product")}>add new product</button>
                                        <button onClick={handleConfirmationCancel}>Cancel</button>
                                    </div>
                                </div>
                                {
                                    selectedProducts.length > 0 && isModalOpen &&
                                    <div className={classes.productsSelectedDeleteModel}>
                                        <div>
                                            <button className={classes.closeBtn} onClick={() => setIsModalOpen(!isModalOpen)}>
                                                <img src={cross} alt="Cross" />
                                            </button>
                                            <div className={classes.main}>
                                                <div>
                                                    <img src={checkedCheckbox} alt="Checkbox" /> {selectedProducts.length} selected items
                                                </div>
                                                <button className={classes.deleteBtn} onClick={showConfirmationModel}>DELETE</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <Modal centered title={false} className="productConfirmModal" closeIcon={<img src={crossBlack} alt="Cross" />} footer={false} visible={confirmationModel} onCancel={handleConfirmationCancel}>
                                    <div className={classes.productsSelectedDeleteModel}>
                                        <div>
                                            <div className='textContainer'>
                                                <h4>Are you sure you want to delete the selected items?</h4>
                                                <p>Items cannot be restored after deletion</p>
                                            </div>
                                            <div className="btnContainer">
                                                <button onClick={deleteHandler}>DELETE</button>
                                                <button onClick={handleConfirmationCancel}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                    }
                </div>
            </div >
    )
}

export default ProductAndServices
